<template>
  <el-card class="main-full-content box-card">
    <div class="f-p-b-15 flex_center_start_a">
      <el-button
        @click="addFee"
        type="primary"
        v-if="buttonShowList.add && order.costLock === '0'"
        size="medium"
        >新增一行
      </el-button>
      <el-button
        @click="delCommissionBatch"
        type="primary"
        size="medium"
        v-if="buttonShowList.delB"
        :disabled="selectionRows2.length === 0"
        >批量删除
      </el-button>
      <el-button
        @click="lockOrder"
        type="primary"
        size="medium"
        v-if="buttonShowList.sdddcb"
        :disabled="order.costLock === '1'"
        >锁定订单成本
      </el-button>
      <el-button
        @click="addAttach"
        type="primary"
        size="medium"
        v-if="buttonShowList.tjfsfy  && order.costLock === '0'
        &&  vailCheck(order.performanceCheckStatus,order.salesmanCommission)"
        >添加附属费用  </el-button
      >
      <el-button
        @click="unlockOrder"
        type="primary"
        size="medium"
        v-if="buttonShowList.jsddcb && order.costLock === '1'"
        :disabled="isRealLock || order.salesmanCommission =='1'"
        >解锁订单成本
      </el-button>
      <el-button
        type="primary"
        size="medium"
        @click="downloadModel(url.downloadCommissionExcel, '批量导入成本账单模板')"
        v-if="buttonShowList.pldrmbxz"
        >批量导入模板下载
      </el-button>
      <el-upload
        action="/"
        :show-file-list="false"
        :http-request="uploadSettleCommissionFile"
        accept=".xls,.xlsx"
        style="margin: 0 10px; display: inline"
      >
        <el-button
          size="medium"
          type="primary"
          :loading="btnLoading"
          v-if="buttonShowList.pldr"
          >批量导入成本
        </el-button>
      </el-upload>
      <el-button
        @click="recheckProfit"
        type="primary"
        size="medium"
        v-if="buttonShowList.ddfhlr"
        :disabled="profitRecheck === '1'"
        >订单复核利润
      </el-button>
      <el-button
        type="primary"
        size="medium"
        v-if="buttonShowList.plsh && this.isLoseMoney === '1' && !this.auditReason"
        @click="dialogFormVisibleBatchAudit = true"
      >
        亏本订单审核
      </el-button>
      <el-button
        @click="batchCheckLock(1)"
        type="primary"
        size="medium"
        v-if="buttonShowList.jcB"
        :disabled="selectionRows2.length === 0"
        >批量检查
      </el-button>
      <el-button
        @click="batchCheckLock(0)"
        type="primary"
        size="medium"
        v-if="buttonShowList.jcB"
        :disabled="selectionRows2.length === 0"
        >批量解锁检查
      </el-button>
      <el-button
        @click="profitRecheckRemark"
        type="primary"
        size="medium"
        :disabled="showRecheckRemark"
        v-if="buttonShowList.lrfhhdbz"
        >利润复核核对备注
      </el-button>
      <el-button
          @click="batchCreateByOrderNos"
          type="primary"
          size="medium"
          v-if="buttonShowList.bathInsetHyFee"
      >生成海运费
      </el-button>
      <div class="f-m-b-5 f-m-l-10" :class="totalResult.isHighProfit == '1' ? 'redPer' : 'greenPer'">利润平均值：<span v-text="totalResult.avgProfitStr"></span></div>
    </div>
    <!--      <el-dialog-->
    <!--          title="更新销售锁价服务"-->
    <!--          :visible.sync="dialogProfitReviewCollateRemarkFormVisible"-->
    <!--          :close-on-click-modal="false"-->
    <!--          :before-close="handleClose"-->
    <!--      >-->
    <!--        <el-form :model="profitReviewForm" ref="profitReviewForm" :rules="rules">-->
    <!--          <el-form-item-->
    <!--              label="利润复核核对备注"-->
    <!--              prop="reviewRemark"-->
    <!--          >-->
    <!--            <el-form-item label="备注">-->
    <!--              <el-input v-model="profitReviewForm.reviewRemark" type="textarea"-->
    <!--                        :rows="3"-->
    <!--                        placeholder="请输入备注"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-form-item>-->
    <!--        </el-form>-->
    <!--        <div slot="footer" class="dialog-footer">-->
    <!--          <el-button @click="dialogProfitReviewCollateRemarkFormVisible = false"-->
    <!--          >取 消</el-button-->
    <!--          >-->
    <!--          <el-button type="primary" @click="updateService">确 定</el-button>-->
    <!--        </div>-->
    <!--      </el-dialog>-->
    <el-dialog
      title="复制订单"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="copy" ref="copy" :rules="rules">
        <el-form-item
          class="el_form_item"
          label="订单号"
          label-width="140px"
          prop="orderNo"
        >
          <f-select
            v-model="copy.orderNo"
            :isNeed="copy.orderNo"
            :dict="'os_order_no'"
          ></f-select>
        </el-form-item>
        <el-form-item
          class="el_form_item"
          label="结算2对象"
          label-width="140px"
          prop="settleId"
        >
          <f-select
            v-model="copy.settleId"
            :isNeed="copy.settleId"
            :dict="'os_contact'"
          ></f-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button size="medium" type="primary" @click="copySubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="同步到"
      :visible.sync="syncFeeDialogVisible"
      width="15%"
      center
    >
      <span slot="footer" class="dialog-footer">
        <el-button
            type="primary"
            @click="syncFeeByType(1)"
        >应收</el-button>
        <el-button
            type="primary"
            @click="syncFeeByType(3)"
            v-if="buttonShowList.cbfftbdyf"
        >应付</el-button>
        <el-button type="primary" @click="syncFeeDialogVisible = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="亏本订单审核"
      :visible.sync="dialogFormVisibleBatchAudit"
      :close-on-click-modal="false"
      append-to-body
      width="50%"
      height="100%"
    >
      <el-form>
        <el-form-item prop="checkStatus" label="亏本原因">
          <f-select
            v-model="auditReason"
            :is-need="auditReason"
            :dict="'lose_money_reason'"
          >
          </f-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisibleBatchAudit = false"
          >取 消</el-button
        >
        <el-button
          size="medium"
          type="primary"
          @click="batchAuditLoseMoneyOrders()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-form :model="form" ref="payref" :rules="rules">
      <el-table
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ padding: '0px', margin: '0px' }"
        ref="goodtab"
        :data="form.osOrderSettleList"
        style="width: 99%"
        class="customTable"
        v-loading="goodsloading"
        @selection-change="handleSelectionChange2"
        @sort-change="handleTableChange"
      >
        <el-table-column type="selection" width="30"> </el-table-column>
        <el-table-column prop="action" label="操作" minWidth="120">
          <template slot-scope="scope">
            <div v-if="order.costLock == '0'">
              <el-button
                type="text"
                @click="checkLock(scope.row, 1)"
                :disabled="!scope.row.id"
                v-if="buttonShowList.jc && scope.row.checkLock === '0'"
                >检查
              </el-button>
              <el-button
                type="text"
                @click="checkLock(scope.row, 0)"
                :disabled="!scope.row.id"
                v-if="buttonShowList.jc && scope.row.checkLock === '1'"
                >解锁检查
              </el-button>
              <el-button
                type="text"
                @click="syncButton(scope.row)"
                :disabled="!scope.row.id"
                v-if="buttonShowList.tb"
                >同步
              </el-button>
            </div>
            <!--<div v-if="order.costLock=='1' && scope.row.isAttach ==='0'">-->
            <div v-if="order.costLock == '1'">
              <el-button
                type="text"
                @click="checkLock(scope.row, 1)"
                :disabled="!scope.row.id"
                v-if="buttonShowList.jc && scope.row.checkLock === '0'"
                >检查
              </el-button>
              <el-button
                type="text"
                @click="checkLock(scope.row, 0)"
                :disabled="!scope.row.id"
                v-if="buttonShowList.jc && scope.row.checkLock === '1'"
                >解锁检查
              </el-button>
              <el-form-item class="el_form_item">
                <span style="color: red">已锁定</span>
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="feeName"
          label="检查状态"
          minWidth="150"
          :show-overflow-tooltip="true"
        >
          <!-- 标头需要加必填标识 -->
          <template slot="header">
            <template>
              <span class="is_require_form">检查状态</span>
            </template>
          </template>
          <template slot-scope="scope">
            <el-form-item>
              <span
                style="color: #00a0e9"
                v-if="
                  scope.row.feeName !== null &&
                  scope.row.feeName !== '' &&
                  scope.row.createTime !== '' &&
                  scope.row.checkLock === '1'
                "
                >已检查</span
              >
              <span
                style="color: red"
                v-if="
                  scope.row.feeName !== null &&
                  scope.row.feeName !== '' &&
                  scope.row.createTime !== '' &&
                  scope.row.checkLock === '0'
                "
                >未检查</span
              >
            </el-form-item>
          </template>
        </el-table-column>
        <!--<el-table-column-->

        <!--prop="isAttach"-->
        <!--label="是否附属费用"-->
        <!--minWidth="150"-->
        <!--:show-overflow-tooltip="true"-->
        <!--&gt;-->
        <!--&lt;!&ndash; 标头需要加必填标识 &ndash;&gt;-->
        <!--<template slot="header">-->
        <!--<template>-->
        <!--<span class="is_require_form">是否附属费用</span>-->
        <!--</template>-->
        <!--</template>-->
        <!--<template slot-scope="scope">-->
        <!--<span v-if = "scope.row.isAttach == '0'">否</span>-->
        <!--<span v-if = "scope.row.isAttach == '1'">是</span>-->

        <!--</template>-->
        <!--</el-table-column>-->
        <el-table-column
          prop="feeName"
          label="费用中文名"
          minWidth="150"
          :show-overflow-tooltip="true"
        >
          <!-- 标头需要加必填标识 -->
          <template slot="header">
            <template>
              <span class="is_require_form">费用中文名</span>
            </template>
          </template>
          <template slot-scope="scope">
            <el-form-item
              class="el_form_item"
              :prop="'osOrderSettleList.' + scope.$index + '.feeName'"
              :rules="rules.feeName"
            >
              <span
                v-if="
                  scope.row.feeName !== null &&
                  scope.row.feeName !== '' &&
                  scope.row.createTime !== ''
                "
                >{{ scope.row.feeName }}</span
              >
              <f-select
                v-else
                :data-list="hyFeeSelectedList"
                :dict-size="tableCount"
                @change="updateFeeType(scope.row, scope.$index)"
                v-model="scope.row.feeName"
                :is-need="scope.row.feeName"
              >
              </f-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="quotation" label="价格" minWidth="80">
          <!-- 标头需要加必填标识 -->
          <template slot="header">
            <template>
              <span class="is_require_form">价格</span>
            </template>
          </template>
          <template slot-scope="scope">
            <el-form-item
              class="el_form_item"
              :prop="'osOrderSettleList.' + scope.$index + '.quotation'"
              :rules="rules.quotation"
            >
              <el-input
                v-model="scope.row.quotation"
                type="number"
                @input="getSummaries2(scope.row)"
                :disabled="getIsDisable(scope.row)"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="数量" minWidth="80">
          <!-- 标头需要加必填标识 -->
          <template slot="header">
            <template>
              <span class="is_require_form">数量</span>
            </template>
          </template>
          <template slot-scope="scope">
            <el-form-item
              class="el_form_item"
              :prop="'osOrderSettleList.' + scope.$index + '.num'"
              :rules="rules.num"
            >
              <el-input
                v-model="scope.row.num"
                type="number"
                @input="getSummaries2(scope.row)"
                :disabled="getHyNumDisable(scope.row)"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="total" label="合计" minWidth="100">
          <template slot-scope="scope">
            <span>{{ scope.row.total }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="salePrice"
          key="salePrice"
          label="指导报价"
          minWidth="100"
        >
          <template slot-scope="scope">
            <el-form-item
              class="el_form_item"
              :prop="'osOrderSettleList.' + scope.$index + '.salePrice'"
              :rules="rules.salePrice"
            >
              <el-input
                v-model="scope.row.salePrice"
                type="number"
                @input="getSummaries2(scope.row)"
                :disabled="getIsDisable(scope.row)"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="minPrice"
          key="minPrice"
          label="成本红线"
          minWidth="100"
        >
          <template slot-scope="scope">
            <el-form-item
              class="el_form_item"
              :prop="'osOrderSettleList.' + scope.$index + '.minPrice'"
              :rules="rules.minPrice"
            >
              <el-input
                v-model="scope.row.minPrice"
                type="number"
                @input="getSummaries2(scope.row)"
                :disabled="getIsDisable(scope.row)"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="realCur" label="交易币种" min-width="100">
          <!-- 标头需要加必填标识 -->
          <template slot="header">
            <template>
              <span class="is_require_form">交易币种</span>
            </template>
          </template>
          <template slot-scope="scope">
            <el-form-item
              class="el_form_item"
              :prop="'osOrderSettleList.' + scope.$index + '.realCur'"
              :rules="rules.realCur"
            >
              <f-select
                v-model="scope.row.realCur"
                :isNeed="scope.row.realCur"
                :dict="'bd_cur'"
                :disabled="getIsDisable(scope.row)"
              ></f-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="rate" label="汇率" minWidth="80">
          <template slot-scope="scope">
            <span>{{ scope.row.rate }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="cremark" label="备注" minWidth="120">
          <template slot-scope="scope">
            <el-form-item
              class="el_form_item f-p-5"
              :prop="'osOrderSettleList.' + scope.$index + '.cremark'"
            >
              <el-input
                v-model="scope.row.cremark"
                maxlength="250"
                type="textarea"
                :disabled="getIsDisable(scope.row)"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column
          prop="createBy"
          label="录入人"
          minWidth="100"
          show-tooltip-when-overflow
        >
          <template slot-scope="scope">
            <el-form-item
              class="el_form_item"
              :prop="'osOrderSettleList.' + scope.$index + '.createBy'"
            >
              <span class="span-ellipsis">{{
                parseDict(scope.row.createBy, "sys_create_by")
              }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="录入时间"
          minWidth="100"
          show-tooltip-when-overflow
        >
          <template slot-scope="scope">
            <span>
              {{ formateTime(scope.row.createTime) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="检查时间"
          minWidth="100"
          show-tooltip-when-overflow
        >
          <template slot-scope="scope">
            <span>
              {{ formateTime(scope.row.checkTime) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="costTaxPosition"
          label="关税位置"
          minWidth="100"
          show-tooltip-when-overflow
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.costTaxPosition }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createBy"
          label="修改人"
          minWidth="100"
          show-tooltip-when-overflow
        >
          <template slot-scope="scope">
            <el-form-item
              class="el_form_item"
              :prop="'osOrderSettleList.' + scope.$index + '.createBy'"
            >
              <span class="span-ellipsis">{{
                parseDict(scope.row.updateBy, "sys_create_by")
              }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="修改时间"
          minWidth="100"
          show-tooltip-when-overflow
        >
          <template slot-scope="scope">
            <span>
              {{ formateTime(scope.row.updateTime) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex_wrap_between">
        <div>
          <div style="display: flex; flex-direction: column">
            <span
              v-for="item in formatNewProfitTotal.split('\r\n')"
              v-text="item"
            ></span>
          </div>
          <div>提成：<span v-text="commissionTotal"></span></div>
        </div>
        <!-- {{ss}} -->
        <el-button
          class="margin_5"
          @click="updateBatch"
          type="primary"
          size="medium"
          v-if="buttonShowList.bc"
        >
          保存
        </el-button>
      </div>
      <el-card class="box-card">
        <fee-log-module ref="fee" @ok="init" :type="'2'"></fee-log-module>
      </el-card>
    </el-form>

    <el-dialog
      title="复核订单"
      :visible.sync="batchReview"
      :close-on-click-modal="false"
    >
      <el-form
        :model="batchForm"
        label-width="120px"
        :rules="batchFormRules"
        ref="batchForm"
      >
        <el-form-item label="核对是否通过">
          <el-radio-group v-model="batchFormProfitRecheck">
            <el-radio :label="'1'">通过</el-radio>
            <el-radio :label="'0'">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="textarea"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="batchReviewFormCancel"
          >取 消</el-button
        >
        <el-button size="medium" type="primary" @click="batchReviewFormSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-divider></el-divider>
  </el-card>
</template>

<script>
import { deleteAction, getAction, putAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import { postAction } from "../../../api/manage";
import { caculateShequ } from "@/utils/caculate";
import FeeLogModule from "./FeeLogModule";

export default {
  name: "CostModule",
  mixins: [QueryMixins],
  components: {
    FeeLogModule,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    countFeeNum: {
      type: String,
      default: false,
    },
    order:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      countFeeFlag: false,
      // profitReviewForm:{},
      // profitReviewDto,
      payColumns: [
        {
          prop: "total",
          label: "金额",
          /*  sortable: 'total',*/
        },
        {
          prop: "realCur",
          label: "币种",
          /* sortable: 'realCur',*/
        },
      ],
      fenzuList: [],
      dictCodes: ["sys_create_by"],
      allSettlelist: [],
      allFeeTypelist: [],
      btnLoading: false,
      formData2: {
        pageIndex: 1,
        pageSize: 100,
      },
      //GBP
      totalgbp: 0,
      // SGD
      totalsgd: 0,
      // USD
      totalusd: 0,
      // NCD
      totalncd: 0,
      // AUD
      totalaud: "",
      // GBP

      // VND
      totalvnd: "",
      // NZD
      totalnzd: "",
      // HKD
      totalhkd: "",
      // CAD
      totalcad: "",
      // EUR
      totaleur: "",
      // INR
      totalinr: "",
      // CNY
      totalcny: "",
      // KRW
      totalkrw: "",
      // NOK
      totalnok: "",
      // JPY
      totaljpy: "",
      // NZD
      ss: "",
      // TWD
      totaltwd: "",

      visible: false,
      row: {},
      feeTypeList: [],
      feeTypeMap: {},
      form: { osOrderSettleList: [] },
      url: {
        bdFeeTypeList: "/sys/feeType/hylist",
        getSettleCommissionList: "/finance/fee/settleCommission/list",
        copy: "/order/os/settle/copyOrderFee",
        delete: "/order/os/settle/delete",
        deleteBatch: "/finance/fee/settleCommission/del",
        lock: "/order/os/settle/lock",
        unlock: "/order/os/settle/unlock",
        lockBatch: "/order/os/settle/lockBatch",
        lockOrder: "/order/os/settle/lockOrder",
        unlockOrder: "/order/os/settle/unlockOrder",
        settlelist: "/order/os/settle/out/settle",
        unlockBatch: "/order/os/settle/unlockBatch",
        getProfitAndCommission:
          "/finance/fee/settleCommission/getProfitAndCommission",
        updateBatch: "/finance/fee/settleCommission/updateBatch",
        batchCheckFee: "/finance/fee/settleCommission/batchCheckFee",
        getCurRate: "/sys/cur/rate/getList",
        downloadModel: "/order/os/settle/fee/downloadCommissionExcel",
        importExcel: "/order/os/settle/tax/in/import",
        downloadCommissionExcel:
          "/finance/fee/settleCommission/downloadCommissionExcel",
        importCommission: "/finance/fee/settleCommission/importCommission",
        getSupplierList: "/sys/supplier/getSupplierList",
        manualsyncFee: "/finance/fee/order/manualsyncFee",
        recheckProfit: "/finance/fee/settleCommission/recheckProfit",
        batchAuditLoseMoneyOrders:
          "finance/fee/settleCommission/batchAuditLoseMoneyOrders",
        profitReviewRemark: "/finance/fee/settleCommission/profitReviewRemark",
         batchCreateByOrderNos: "finance/fee/order/batchCreateByOrderNos",
      },
      userlist: [],
      fbaloading: false,
      storeloading: false,
      warehouseList: [],
      fbalist: [],
      addresslist: [],
      //            dialogProfitReviewCollateRemarkFormVisible : false,
      addressloading: false,
      goodsloading: false,
      selectedRowKeys2: [],
      selectionRows2: [],
      //校验
      rules: {
        feeName: [
          { required: true, message: "请选择费用中文名", trigger: "change" },
        ],
        quotation: [
          { required: true, message: "请输入价格", trigger: "blur" },
          /* {validator: validateUnitValue, trigger: "change"},*/
        ],
        // reviewRemark:[
        //   {
        //     max: 255, message: '长度不能超过255', trigger: 'change'
        //   }
        // ],
        qty: [
          { required: true, message: "请填写数量", trigger: "blur" },
          // {validator: validateCtn, trigger: "change"},
        ],
        realCur: [{ required: true, message: "请选择币种", trigger: "change" }],
        orderNo: [
          { required: true, message: "请选择订单号", trigger: "change" },
        ],
        invoiceNoThird: [
          { max: 255, message: "长度不能超过255", trigger: "change" },
        ],
      },
      batchFormRules: {
        // profitRecheck: [
        //   { required: true, message: "请输入渠道名称", trigger: "change" },
        //   { max: 100, message: "长度不能超过100", trigger: "change" },
        // ],
        // profitRecheckRemark: [
        //   { required: false, message: "请输入备注", trigger: "change" },
        // ],
      },
      copy: {},
      dialogFormVisible: false,
      syncFeeDialogVisible: false,
      dialogFormVisibleBatchAudit: false,
      auditReason: "",
      recheckTimeStart: "",
      recheckTimeEnd: "",
      recheckManId: "",
      handleType: "",
      isouri: false,
      cploading: false,
      proMap: {},
      prolist: [],
      coloading: false,
      sysCurRateList: [],
      curCacu: [],
      settleProfit: [],
      profit: "",
      preProfit: "",
      yszj: "",
      yfzj: "",
      settlement: "",
      receiveList: [],
      payList: [],
      preList: [],

      //结算对象集合
      settlementList: [],
      //结算对象下拉框
      settlementSelectedList: [],
      //费用对象 集合
      hyFeeList: [],
      //费用对象 下拉框
      hyFeeSelectedList: [],
      //费用个数
      tableCount: 0,
      newProfitTotal: "",
      formatNewProfitTotal: "",
      commissionTotal: "",
      costLock: "",
      salesmanCommission:"",
      performanceCheckStatus:"",
      profitRecheck: "",
      isRealLock: false,
      showRecheckRemark: false,
      isLoseMoney: "",
      isLose: "",
      syncRow: null,
      //   批量复核
      batchReview: false,
      batchForm: {},
      textarea: "",
      batchFormProfitRecheck: "1",
      totalResult: {},
    };
  },
  methods: {
    // addAttach() {
    //     this.form.osOrderSettleList.push({
    //         perferFee:'',
    //         settlement: '',
    //         feename: '',
    //         feenameUs: '',
    //         locker: '0',
    //         price: '',
    //         quantity: '',
    //         moneyType: '',
    //         total: '',
    //         remark: '',
    //         withinRemark:'',
    //         createBy: '',
    //         createTime: '',
    //         lockTime: '',
    //         realMoneyType: '',
    //         exchangeRate: '',
    //         exchangeTotal: '',
    //         moneyWay: '',
    //         fkCode: '',
    //         xzCode: '',
    //         fkTime: '',
    //         xzTime: '',
    //         isAttach: '1'
    //     })
    // },
    changeRealCur(val) {
      if (this.form.osOrderSettleList) {
        this.form.osOrderSettleList.forEach((s) => {
          if (val && val.id && s.id === val.id) {
            s.realCur = val.realCur;
          }
        });
      }
    },
    profitRecheckRemark() {
      this.batchReview = true;
    },
    batchReviewFormSubmit() {
      this.$refs["batchForm"].validate((valid) => {
        if (valid) {
          const params = {};
          params.orderNo = this.$route.query.orderNo;
          params.textarea = this.textarea;
          params.profitRecheck = this.batchFormProfitRecheck;
          console.log(params, "params");
          console.log(this.batchForm, "batchForm");
          postAction(this.url.profitReviewRemark, params).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.initBase()
            this.batchReview = false;
          });
        }
      });
    },
    batchReviewFormCancel() {
      this.textarea = "";
      this.batchFormProfitRecheck = "1";
      this.batchReview = false;
    },
    batchAuditLoseMoneyOrders() {
      let orderNos = [];
      orderNos.push(this.$route.query.orderNo);
      postAction(
        this.url.batchAuditLoseMoneyOrders + "?auditReason=" + this.auditReason,
        orderNos
      ).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.form = {};
        this.dialogFormVisibleBatchAudit = false;
        this.initBase();
      });
    },

    syncButton(row) {
      this.syncFeeDialogVisible = true;
      this.syncRow = row;
    },

    syncFeeByType(type) {
      postAction(
        this.url.manualsyncFee + "?source=2&target=" + type,
        this.syncRow
      ).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.initBase();
      });
      this.syncFeeDialogVisible = false;
    },

    changeCellStyle(row, column, rowIndex, columnIndex) {
      // 应收-是否已销账添加背景色
      if (row.row.status && ["1", "2", "9"].indexOf(row.row.status) !== -1) {
        return "table_green_bg";
      } else {
        return "table_red_bg";
      }
    },

    copyFuc(index) {
      //获取input对象
      var input = document.getElementById("copySettleId" + index);
      //console.log(input,'input')
      //选中input标签
      input.select();
      //执行复制
      document.execCommand("copy");
      this.$message.success("复制成功!");
    },
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importExcel, form).then((res) => {
        //这里做上传之后的操作
        if (res.success) {
          this.$message.success(res.message);
          this.initBase();
        }
      });
    },
    downloadModel(url, fileName) {
      let params = { ...this.queryForm, ...this.filterForm };
      postAction(url, params, { responseType: "blob" }).then((response) => {
        if (!response) {
          return;
        }
        const blob = new Blob([response]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          let u = window.URL.createObjectURL(response);
          let aLink = document.createElement("a");
          aLink.style.display = "none";
          aLink.href = u;
          aLink.setAttribute("download", fileName + ".xls");
          document.body.appendChild(aLink);
          aLink.click();
          document.body.removeChild(aLink);
          window.URL.revokeObjectURL(u);
        }
      });
    },
    // 批量海运应付导入
    uploadSettleCommissionFile(param) {
      this.btnLoading = true;
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      this.$confirm("是否确定导入到成本?", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        distinguishCancelAndClose: true,
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          form.append("type", "1");
          postAction(this.url.importCommission, form)
            .then((res) => {
              this.btnLoading = true;
              //这里做上传之后的操作
              if (res.success) {
                this.$message.success(res.message);
                this.initBase();
              }
            })
            .finally(() => {
              this.btnLoading = false;
            });
        })
        .catch((action) => {});
    },
    getIsDisable(row) {
      //已分摊的费用不能修改不能变
      console.log(row, 'row')
      if (row.isAttach === "1") {
        return false;
      } else if (
        (row.id && this.order.payLock === "1") ||
        row.locker === "1" ||
        row.containerSettlementId
      ) {
        return true;
      } else {
        return false;
      }
    },

    getHyNumDisable(row) {
      if (row.countFeeFlag) {
        return true;
      }
      if (row.feeName === "海运费" && row.num != null) {
        return true;
      }
    },
    initCurRate() {},
    getChangeTotal(item) {
      if (
        !isNaN(parseFloat(item.total)) &&
        !isNaN(parseFloat(item.exchangeRate))
      ) {
        item.exchangeTotal =
          Math.round(
            parseFloat(item.total) * parseFloat(item.exchangeRate) * 100
          ) / 100;
      } else {
        item.exchangeTotal = 0;
      }
    },
    getRate(row) {
      if (row.realCur && row.realrealCur) {
        let obj = this.sysCurRateList.find((f) => {
          return f.amout == row.realCur && f.amoutTo == row.realrealCur;
        });
        console.log("有没有obj", obj);
        if (obj) {
          this.$set(row, "exchangeRate", obj.rate);
        } else {
          this.$set(row, "exchangeRate", "");
        }
      }
    },
    init() {
      console.log("费用操作记录", this.$refs["fee"]);
      if (this.$refs["fee"]) {
        this.$refs["fee"].initVue();
      }
    },
    handleClearSelection() {
      this.$refs["shiptab"].clearSelection();
    },
    handleClearSelection2() {
      this.$refs["goodtab"].clearSelection();
    },

    //导入产品数据

    getTag(item) {
      return item == "1" ? "已锁定" : "未锁定";
    },
    removeSettle(index, feeName) {
      let id = this.form.osOrderSettleList[index].id;
      //v-if="$btnIsShow('docpage/modules/CostModule','1','删除')"
      if (!id) {
        this.form.osOrderSettleList.splice(index, 1);
        return ture;
      } else {
        if (!this.buttonShowList.sc) {
          this.$message.warning("您暂时没有权限删除费用哦亲");
          return false;
        }
        let containerSettlementId =
          this.form.osOrderSettleList[index].containerSettlementId;
        if (containerSettlementId) {
          this.$message.warning("柜子已分摊的费用不允许删除");
          return false;
        }
        this.$confirm(`确定删除${feeName || ""}吗`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
          closeOnClickModal: false,
        }).then(() => {
          if (id && id.length > 1) {
            deleteAction(this.url.delete, { id: id }).then((res) => {
              this.$message.success(res.message);
              this.initBase();
            });
          }
        });
      }
    },
    delCommissionBatch() {
      let ids = this.selectionRows2.map((s) => s.id).join(",");
      let isAllDelete = true;
      let warningMsg = "";
      let indexList = [];
      this.selectionRows2.forEach((s) => {
        if (!s.id) {
          indexList.push(this.form.osOrderSettleList.indexOf(s));
        }
        if (s.locker == "1") {
          warningMsg = "已锁定的费用不允许删除";
          isAllDelete = false;
          return false;
        }
      });
      if (!isAllDelete) {
        this.$message.warning(warningMsg);
        return;
      }

      this.$confirm(`确定删除吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          if (ids && ids.length > 1) {
            postAction(
              this.url.deleteBatch,
              this.selectionRows2.map((s) => s.id)
            ).then((res) => {
              this.$message.success(res.message);
              this.initBase();
            });
          }
          if (indexList && indexList.length > 0) {
            indexList.forEach((item, index) => {
              this.form.osOrderSettleList.splice(item, 1);
            });
          }
        })
        .catch(() => {});
    },
    copyFee() {
      this.copy = {};
      this.dialogFormVisible = true;
    },
    copySubmit() {
      console.log(this.copy, "this.copy");
      this.$refs["copy"].validate((va) => {
        if (va) {
          this.copy.type = "1";
          this.copy.oldOrderNo = this.$route.query.orderNo;
          putAction(
            this.url.copy +
              "?orderNo=" +
              this.copy.orderNo +
              "&type=1&oldOrderNo=" +
              this.copy.oldOrderNo +
              "&settleId=" +
              this.copy.settleId
          ).then((res) => {
            this.$message.success(res.message);
            this.dialogFormVisible = false;
            this.initBase();
          });
        }
      });
    },
    vailCheck(performanceCheckStatus,salesmanCommission){
      if(performanceCheckStatus==='1' || salesmanCommission ==='1'){
        return this.buttonShowList.tjfsfy1;
      }else{
        return true
      }
    },
    addFee() {
      this.form.osOrderSettleList.push({
        settlement: "",
        feeName: "",
        feeNameUs: "",
        quotation: "",
        num: 1,
        realCur: "CNY",
        total: "",
        cremark: "",
        withinRemark: "",
        createBy: "",
        createTime: "",
        lockTime: "",
        realrealCur: "",
        exchangeRate: "",
        exchangeTotal: "",
        moneyWay: "",
        fkCode: "",
        xzCode: "",
        fkTime: "",
        xzTime: "",
        locker: "0",
      });
    },
    addAttach() {
      this.form.osOrderSettleList.push({
        settlement: "",
        feeName: "",
        feeNameUs: "",
        quotation: "",
        num: 1,
        realCur: "CNY",
        total: "",
        cremark: "",
        withinRemark: "",
        createBy: "",
        createTime: "",
        lockTime: "",
        realrealCur: "",
        exchangeRate: "",
        exchangeTotal: "",
        moneyWay: "",
        fkCode: "",
        xzCode: "",
        fkTime: "",
        xzTime: "",
        locker: "0",
        isAttach: "1",
      });
    },
    formatDecimal(num, decimal) {
      num = num.toString();
      let index = num.indexOf(".");
      if (index !== -1) {
        num = num.substring(0, decimal + index + 1);
      } else {
        num = num.substring(0);
      }
      return parseFloat(num).toFixed(decimal);
    },
    getAllPrice(item) {
      // console.log('allPrice',(item.num * item.quotation).toFixed(3))
      if (!isNaN(parseFloat(item.num)) && !isNaN(parseFloat(item.quotation))) {
        // item.total = this.formatDecimal( (item.num * item.quotation).toFixed(3),2 );
        item.total = caculateShequ(item.num, item.quotation);
      } else {
        item.total = 0;
      }
    },

    getAllPriceLiu2(item) {
      if (!isNaN(parseFloat(item.num)) && !isNaN(parseFloat(item.quotation))) {
        item.total = this.formatDecimal(
          parseFloat(item.num) * parseFloat(item.quotation),
          2
        );
      } else {
        item.total = 0;
      }
    },
    getAllPriceZero(row) {
      console.log("要计算的对象", row);

      if (!isNaN(parseFloat(row.num)) && !isNaN(parseFloat(row.quotation))) {
        let per = 0;
        let total = parseInt(parseFloat(row.num) * parseFloat(row.quotation));
        if (row.perferType === "数值") {
          per = row.perferFee ? row.perferFee : 0;
          row.total =
            parseInt(parseFloat(row.num) * parseFloat(row.quotation)) -
            Number(per);
        } else if (row.perferType === "百分比") {
          per = parseInt(
            parseFloat(total) *
              parseFloat(row.perferFee ? row.perferFee / 100 : 0)
          );
          if (parseFloat(total) > parseFloat(per)) {
            row.total = parseInt(
              parseFloat(total) - parseFloat(per).toFixed(3)
            );
          } else {
            row.total = 0;
          }
        } else {
          row.total = total;
        }
      } else {
        row.total = 0;
      }
    },

    formateTime(val) {
      if (val) {
        val = val.substring(0, 10);
        return val;
      } else {
        return val;
      }
    },
    updateFeeType(row, index) {
      row.countFeeFlag = false;
      console.log("费用的map", this.feeTypeMap);
      if (this.feeTypeMap[row.feeName]) {
        this.$set(
          this.form.osOrderSettleList[index],
          "feeNameUs",
          this.feeTypeMap[row.feeName]["englishName"]
        );
        this.$set(
          this.form.osOrderSettleList[index],
          "feeName",
          this.feeTypeMap[row.feeName]["chineseName"]
        );
        this.$set(
          this.form.osOrderSettleList[index],
          "feeId",
          this.feeTypeMap[row.feeName]["feeId"]
        );
        this.$set(
          this.form.osOrderSettleList[index],
          "fileType",
          this.feeTypeMap[row.feeName]["type"]
        );
      }
      if (row.feeName === "海运费") {
        row.countFeeFlag = true;
        let hyFeeNum = this.countFeeNum;
        hyFeeNum = hyFeeNum.replace("CBM", "");
        hyFeeNum = hyFeeNum.replace("KG", "");
        // 海运费计费数量小于最低消费的时候，数量直接取最低消费， 之前的逻辑是直接取计费数量  2023.10.24
        let minCharge = this.order.minCharge || '0';
        minCharge = minCharge.replace("CBM", "");
        minCharge = minCharge.replace("KG", "");
        if(parseFloat(hyFeeNum) < parseFloat(minCharge)) {
          this.$set(this.form.osOrderSettleList[index], "num", minCharge);
        } else {
          this.$set(this.form.osOrderSettleList[index], "num", hyFeeNum);
        }
        this.getAllPrice(row);
      }
    },
    initBdFeeTypeList() {
      this.coloading = true;
      this.feeTypeList = [];
      getAction(this.url.bdFeeTypeList, { type: "1" })
        .then((res) => {
          if (res.success) {
            res.result.forEach((r) => {
              if (r.type === "1") {
                r.label = "头程-----" + r.chineseName;
              } else {
                r.label = "海外仓-----" + r.chineseName;
              }
            });
            this.allFeeTypelist = res.result;
            this.feeTypeList = this.allFeeTypelist;
            this.feeTypeMap = this.listToMap(this.allFeeTypelist, "id");
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });

      this.coloading = false;
    },
    async initProduct() {
      this.cploading = true;
      //获取所有海运供应商
      console.log("你是不是没有单号", this.$route.query.orderNo);
      getAction(
        this.url.getSupplierList + "?orderNo=" + this.$route.query.orderNo
      )
        .then((res) => {
          if (res.success) {
            this.allSettlelist = res.result;
            this.prolist = res.result;
            this.proMap = this.listToMap(res.result, "id");
            this.cploading = false;
          } else {
            this.$message.warning("获取结算对象表失败");
            this.cploading = false;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
      this.cploading = false;
    },

    handleSelectionChange2(selection) {
      // 多选
      let arr = [];
      this.nameList = [];
      this.selectionRows2 = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        this.nameList.push(selection[i]["feeName"]);
        if (selection[i]["locker"] === "0") {
        }
        this.selectionRows2.push(selection[i]);
      }

      this.selectedRowKeys2 = [...arr];
      console.log("选中的集合", this.selectionRows2);
    },

    getSummaries2(param) {
      //遍历集合
      this.totalgbp = 0;
      this.totalsgd = 0;
      this.totalusd = 0;
      this.totalncd = 0;
      this.totalaud = 0;
      this.totalvnd = 0;
      this.totalnzd = 0;
      this.totalhkd = 0;
      this.totalcad = 0;
      this.totaleur = 0;
      this.totalinr = 0;
      this.totalcny = 0;
      this.totalkrw = 0;
      this.totalnok = 0;
      this.totaljpy = 0;
      this.totaltwd = 0;
      if (this.form.osOrderSettleList) {
        this.form.osOrderSettleList.forEach((s) => {
          if (param && s.id === param.id) {
            this.getAllPrice(s);
          }
        });
      }
      // this.showSumm();
      // console.log('展示费用总计', this.ss);
    },
    loadmore() {
      this.formData.pageIndex++;
      this.getMorePortlist(this.formData);
    },
    getMorePortlist(v) {
      let num = ~~this.formData.pageIndex * ~~this.formData.pageSize;
      this.prolist = this.allSettlelist.filter((item, index, arr) => {
        return index < num;
      });
    },
    loadmore2() {
      this.formData2.pageIndex++;
      this.getMorePortlist2(this.formData2);
    },
    getMorePortlist2(v) {
      let num = ~~this.formData2.pageIndex * ~~this.formData2.pageSize;
      this.feeTypeList = this.allFeeTypelist.filter((item, index, arr) => {
        return index < num;
      });
    },
    updateBatch() {
      this.$refs["payref"].validate((valid) => {
        if (valid) {
          //全选了才判断
          this.form.osOrderSettleList.forEach((s) => {
            s.type = "1";
            s.orderNo = this.$route.query.orderNo;
            if (this.feeTypeMap[s.feeName]) {
              s.feeName = this.feeTypeMap[s.feeName]["chineseName"];
              s.feecode = this.feeTypeMap[s.feeName]["code"];
              s.fileType = this.feeTypeMap[s.feeName]["type"];
              s.feeId = this.feeTypeMap[s.feeName]["id"];
            }
            // this.getAllPrice(s);
          });
          postAction(
            this.url.updateBatch + "?orderNo=" + this.$route.query.orderNo,
            this.form.osOrderSettleList
          )
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.initBase();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((res) => {
              this.$message.error(res.message);
            });
        }
      });
    },

    handleQuery2() {
      this.goodsloading = true;
      let form = { ...this.queryForm, ...this.filterForm };
      for (let item in form) {
        if (typeof form[item] === "string") {
          form[item] = form[item].trim();
          if (form[item] === "") {
            form[item] = null;
          }
        }
      }
      const { prop, order } = this.isorter;
      // 传入的参数
      const params = {
        ...form,
        column: prop,
      };
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === "startTime") {
          this.$set(params, "column", "startTime");
        } else {
          this.$set(params, "column", "createTime");
          this.$set(params, "order", "asc");
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage;
        params.pageSize = this.ipagination.pageSize;
      }
      console.log("查询对象", params);

      postAction(this.url.getSettleCommissionList, params)
        .then((res) => {
          if (res.success) {
            this.form.osOrderSettleList = res.result.list;
            this.costLock = res.result.costLock;
            this.performanceCheckStatus = res.result.performanceCheckStatus;
            this.salesmanCommission = res.result.salesmanCommission;
            this.profitRecheck = res.result.profitRecheck;
            this.isRealLock = res.result.isRealLock;
            this.showRecheckRemark = res.result.showRecheckRemark;
            this.isLoseMoney = res.result.isLoseMoney;
            this.auditReason = res.result.auditReason;
            this.recheckTimeStart = res.result.recheckTimeStart;
            this.recheckTimeEnd = res.result.recheckTimeEnd;
            this.recheckManId = res.result.recheckManId;
            this.handleType = res.result.handleType;
            // this.fenzuList = res.result.fenzuList;
            console.log("表单数据", this.order, this.tableData);
            // this.form.osOrderSettleList = this.tableData;
            // // this.getSummaries2('');
            // this.settlementList = res.result.settlementList;
            // //构建结算对象下拉框
            // this.settlementSelectedList = [];
            // for (let i=0;i<this.settlementList.length;i++) {
            //     let obj = this.settlementList[i];
            //     this.settlementSelectedList.push({value:obj.id,label:obj.chinaseName});
            // }
            // this.proMap = this.listToMap(this.settlementList, 'id');
            this.hyFeeList = res.result.bdFeeTypeList;
            this.feeTypeMap = this.listToMap(this.hyFeeList, "chineseName");
            //构建费用名称下拉框
            this.hyFeeSelectedList = [];
            for (let i = 0; i < this.hyFeeList.length; i++) {
              let obj = this.hyFeeList[i];
              this.hyFeeSelectedList.push({
                value: obj.chineseName,
                label: obj.chineseName,
              });
            }
            // //个数
            // this.tableCount = this.tableData.length;
          }
        })
        .finally(() => {
          this.goodsloading = false;
        });
    },
    handleDel() {},

    handleReset() {
      this.queryForm = {};
      this.queryForm.orderNo = this.$route.query.orderNo;
      this.queryForm.type = "1";
      this.ipagination.currentPage = 1;
      this.handleQuery2();
    },

    initSettleList() {
      this.queryForm.orderNo = this.$route.query.orderNo;
      this.queryForm.type = "1";
      this.handleQuery2();
    },

    initButton() {
      const buttons = document.querySelectorAll(".el-button");
      if (this.isUse) {
        buttons.forEach((b) => {
          b.disabled = this.isUse;
        });
      }
      console.log("所有的按钮", buttons);
    },

    initBase() {
      this.disableMixinMounted = true;
      this.getProfitAndCommission().then((res) => {
        this.initSettleList();
      });
      this.init();
      this.$emit('ok','cost');
      },

    lockOrder() {
      this.$confirm(`确定锁定${this.$route.query.orderNo}成本?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(
            this.url.lockOrder +
              "?orderNo=" +
              this.$route.query.orderNo +
              "&type=2"
          ).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.initBase();

          });
        })
        .catch(() => {});
    },
    unlockOrder() {
      this.$confirm(`确定解锁${this.$route.query.orderNo}成本?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(
            this.url.unlockOrder +
              "?orderNo=" +
              this.$route.query.orderNo +
              "&type=2"
          ).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.initBase();

          });
        })
        .catch(() => {});
    },

    recheckProfit() {
      this.$confirm(`确定复核利润吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(
            this.url.recheckProfit + "?orderNo=" + this.$route.query.orderNo
          ).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.form = {};
            this.handleSearch();
          });
          this.dialogFormVisibleBatchAudit = false;
          this.handleQuery();
        })
        .catch(() => {});
    },

    checkLock(row, checkLock) {
      if (!row.id) {
        this.$message.warning("请先保存数据");
        return;
      }
      let arr = [];
      arr.push(row.id);
      postAction(this.url.batchCheckFee + "?checkLock=" + checkLock, arr).then(
        (res) => {
          this.$message.success(res.message);
          this.initBase();
        }
      );
    },

    batchCheckLock(checkLock) {
      postAction(
        this.url.batchCheckFee + "?checkLock=" + checkLock,
        this.selectedRowKeys2
      ).then((res) => {
        this.$message.success(res.message);
        this.initBase();
      });
    },

    async getProfitAndCommission() {
      getAction(
        this.url.getProfitAndCommission +
          "?orderNo=" +
          this.$route.query.orderNo
      )
        .then((res) => {
          if (res.success) {
            this.totalResult = res.result;
            if (res.result != null && res.result.newProfitTotal != null) {
              this.newProfitTotal = res.result.newProfitTotal || "";
            }
            if (res.result != null && res.result.formatNewProfitTotal != null) {
              this.formatNewProfitTotal = res.result.formatNewProfitTotal || "";
            }
            if (res.result != null && res.result.commissionTotal != null) {
              this.commissionTotal = res.result.commissionTotal || "";
            }
            if (res.result != null && res.result.totalEnd != null) {
              if (Number(res.result.totalEnd) < 10) {
                this.isLose = "1";
              }
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    batchCreateByOrderNos(){
 postAction(this.url.batchCreateByOrderNos, [this.$route.query.orderNo]).then(res => {
                    if (res.success) {
                        this.$message.success(res.message);
                        this.handleQuery2();

                    }
                }).catch(err=>{
                    this.$message.error;(err.message);
                })
    }
  },
  created() {
    this.initBase();
  },
  computed : {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('docpage/modules/CostModule', '0', '新增一行'),
        'delB':this.$btnIsShow('docpage/modules/CostModule', '0', '批量删除'),
        'sdddcb':this.$btnIsShow('docpage/modules/CostModule', '0', '锁定订单成本'),
        'tjfsfy':this.$btnIsShow('docpage/modules/CostModule', '0', '添加附属费用'),
        'jsddcb':this.$btnIsShow('docpage/modules/CostModule', '0', '解锁订单成本'),
        'pldrmbxz':this.$btnIsShow('docpage/modules/CostModule', '0', '批量导入模板下载'),
        'pldr':this.$btnIsShow('docpage/modules/CostModule', '0', '批量导入'),
        'ddfhlr':this.$btnIsShow('docpage/modules/CostModule', '0', '订单复核利润'),
        'plsh':this.$btnIsShow('docpage/modules/CostModule', '0', '批量审核'),
        'jcB':this.$btnIsShow('docpage/modules/CostModule', '0', '检查'),
        'jc':this.$btnIsShow('docpage/modules/CostModule', '1', '检查'),
        'lrfhhdbz':this.$btnIsShow('docpage/modules/CostModule', '0', '利润复核核对备注'),
        'bathInsetHyFee':this.$btnIsShow('docpage/modules/CostModule', '0', '批量生成海运费'),
        'cbfftbdyf':this.$btnIsShow('docpage/modules/CostModule', '1', '成本费用同步到应付'),
        'tb':this.$btnIsShow('docpage/modules/CostModule', '1', '同步'),
        'bc':this.$btnIsShow('docpage/modules/CostModule', '0', '保存'),
        'sc':this.$btnIsShow("docpage/modules/CostModule", "1", "删除"),
        'tjfsfy1':this.$btnIsShow('docpage/modules/RecModule','0','添加附属费用1'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.redPer {
  color: red;
  font-size: 15px !important;
}
.greenPer {
  color: rgb(103, 194, 58);
  font-size: 15px !important;
}
.span-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90px;
  line-height: 100%;
}

.margin_5 {
  margin: 10px 20px 5px 0;
}
</style>
