import { render, staticRenderFns } from "./SlycModule.vue?vue&type=template&id=72ef5374&scoped=true&"
import script from "./SlycModule.vue?vue&type=script&lang=js&"
export * from "./SlycModule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ef5374",
  null
  
)

export default component.exports