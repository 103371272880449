<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div class="title_tab_text">订单文件列表</div>
      <el-form
          class="input_top"
          ref="form"
          :model="queryForm"
          label-width="100px"
          size="medium"
      >
        <el-card class="box-card">
          <el-row class="inputs_row">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="文件名">
                <el-input v-model="queryForm.name"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="文件类型">
                <f-select
                    :dict="'os_order_filelist'"
                    v-model="queryForm.type3"
                    :isNeed="queryForm.type3"
                    :removeValue="removeValue"
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label-width="30px">
                <el-upload
                    class="upload-demo"
                    action=""
                    :http-request="addUploadOssClirnt"
                    :show-file-list="false"
                    ref="add"
                    :multiple="true"
                    :before-upload="beforeUpload"
                    style="float: left;">
                  <el-button type="primary" size="small" >上传</el-button>
                </el-upload>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleQuery"
                >查询
                </el-button>
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item
                  label="报关方式："
                  label-width="110px"
                  style="font-weight: bold;"
              >
                <span >{{ getDeclareType(order.declareType) }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <el-card class="box-card last_card">

        <div class="f-p-b-15">

          <el-button
              type="primary"
              size="medium"
              class="c-theme-error-button"
              @click="handleBatDel"
              :disabled="selectionRows.length === 0"
              v-if="buttonShowList.plsc"

          >批量删除
          </el-button>
          <el-button
              type="primary"
              size="medium"
              icon="el-icon-download"
              @click="exportOs"
              v-if="buttonShowList.plxz"
          >批量下载
          </el-button>
          <span style="margin-left: 20px">报关资料是否被下载：
            <span style="color: green" v-if="!isOpeDownload">未下载</span>
            <span style="color: red" v-if="isOpeDownload">已下载</span>
          </span>
        </div>
        <div class="c-alert f-m-b-10">
          已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
          <span
              class="stress f-m-l-12 f-cursor-pointer"
              @click="handleClearSelection"
          >清空</span
          >
        </div>
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :cell-style="{ 'text-align': 'center' }"
            @selection-change="handleSelectionChange"
            @sort-change="handleTableChange"
        >
          <template v-slot="scope">
            <template v-if="scope.column.property === 'action'">
              <el-button
                  type="text"
                  @click="handleEdit(scope.row)"
                  v-if="buttonShowList.bj && scope.row.isOlc === '0'"

              >编辑
              </el-button>
              <el-button
                  type="text"
                  @click="handDel(scope.row)"
                  v-if="buttonShowList.sc && scope.row.isOlc === '0'"

              >删除</el-button>
            </template>
            <template v-else-if="scope.column.property === 'size'">
              <span>{{ scope.row.size + "KB" }}</span>
            </template>
            <template v-else-if="scope.column.property === 'type'">
              <span>{{ parseDict(scope.row.type, "os_order_filelist") }}</span>
            </template>
            <template v-else-if="scope.column.property === 'uploadType'">
              <span v-if="scope.row.uploadType === '2'">客户端</span>
              <span v-if="scope.row.uploadType === '1'">员工端</span>
            </template>
            <template v-else-if="scope.column.property==='name'">
              <a type="text" @click.prevent="downloadFile2(scope.row)" style="cursor: pointer;color:#57a3f3;">{{scope.row.name}}</a>
            </template>
            <template v-else-if="scope.column.property === 'isOlc'">
              <span v-if="scope.row.isOlc === '0'">FBA系统</span>
              <span v-else>海外仓系统</span>
            </template>
          </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </el-card>
      <el-dialog
          :title="form.id ? '编辑文件' : '新增文件'"
          :visible.sync="dialogFormVisible"
          v-if="dialogFormVisible"
          width="30%"
          :close-on-click-modal="false"
      >
        <el-form :model="form" :rules="rules" ref="form" size="medium">
          <el-row>
            <!--<el-col :span="24">
                          <el-form-item prop="name" label="文件名" label-width="110px">
                              <el-input
                                      v-model="form.name"
                                      maxlength="255"
                                      show-word-limit
                              ></el-input>
                          </el-form-item>
                      </el-col>-->

            <el-col :span="24">
              <el-form-item prop="type" label="文件类型" label-width="110px">
                <f-select
                    :is-need="form.type"
                    :dict="'os_order_filelist'"
                    v-model="form.type"

                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="文件" label-width="110px">
                <f-upload
                    v-model="form.fileList"
                    :limit="form.id ? 1 : 1000"
                    :dir="'osOrder'"
                    :drag="true"
                ></f-upload>
              </el-form-item>

            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button size="medium" type="" @click="dialogFormVisible = false"
          >取 消
          </el-button>
          <el-button size="medium" type="primary" @click="saveFile"
          >确 定
          </el-button>
        </div>
      </el-dialog>
    </el-card>
    <el-card class="box-card" >
      <div class="title_tab_text">报关文件下载记录</div>
      <file-download-log-module :table-data-page="logList"></file-download-log-module>
    </el-card>

    <el-card
        class="box-card"
        v-if="buttonShowList.canSeeCusFile"
    >
      <div class="title_tab_text">客户文件列表</div>
      <cus-file-module :cus-id="order.cusId"></cus-file-module>
    </el-card>
  </div>
</template>

<script>
import { deleteAction, getAction, httpAction,postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins";
import Tempcclate from "../../isystem/Dict";
import CusFileModule from "../../customer/modules/CusFileModule";

import FileDownloadLogModule from "@/views/docpage/modules/FileDownloadLogModule";
import OssClientMixins from "../../../mixins/OssClientMixins";
var upLoadClicktag = 0;
export default {
  name: "FileModule",
  mixins: [QueryMixins,OssClientMixins],
  components: {
    Tempcclate,CusFileModule,FileDownloadLogModule
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    order:{
      type:Object,
      default:()=>{}
    },
  },
  data() {
    return {
      canUploadFile:true,
      logList:{},
      uploadForm:{fileList:[]},
      dictCodes: ["os_order_filelist"],
      ids: [],
      dialogFormVisible: false,
      isOpeDownload:false,
      needCheckCao:'0',
      form: {
        url: "",
        size: "",
        fileType: "",
        type: "",
        id: "",
        cusId: "",
        name: "",
        ossId: "",
      },
      btnDisable: false,
      //表单校验
      rules: {
        /*name: [{required: true, message: "请输入文件名", trigger: "blur"}],*/
        type: [
          { required: true, message: "请选择文件类型", trigger: "change" },
        ],
      },
      fileList: [],
      row: {},
      visible: false,
      assignVisible: false,
      msgVisible: false,
      emailVisible: false,
      followTitle: "",
      assignTitle: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "name",
          label: "文件名",
          sortable: "custom",
        },
        {
          prop: "type",
          label: "文件类型",
          sortable: "custom",
        },
        {
          prop: "size",
          label: "文件大小",
          sortable: "custom",
        },
        {
          prop: "createBy",
          label: "上传人",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",
        },
        {
          prop: "isOlc",
          label: "所属系统",
        },
        // {
        //   prop: "uploadType",
        //   label: "来源",
        // },
      ],
      url: {
        list: "/interface/ttOrder/getFilePage",

        delById: "/interface/ttOrder/deleteFileById/",
        add: "/interface/ttOrder/addOneFile",
        edit: "/interface/ttOrder/updateOneFile",
        getbyid: "/interface/ttOrder/getOneFileById",
        deleteBatch: "/interface/ttOrder/deleteFileBatch",
        upload: "/sys/oss/file/upload",
        delOss: "/sys/oss/file/delete",
        downloadFile:'/sys/oss/file/download',
        downloadBatch:'/sys/oss/file/batchDownloadFile',
        uploadDataSave: '/sys/oss/file/uploadDataSave'
      },
      declareType: "",
      declareType_dictText: "",
      removeValue: "",
      ossClient: null,
      fullscreenLoading: false,
      queryCount:1,
    };
  },
  created() {

    this.queryForm.orderNo = this.$route.query.orderNo;
    // this.$emit('init');

    console.log('要排除的类型',this.removeValue);
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true
      let form = {...this.queryForm, ...this.filterForm, ...this.initData}
      // for (let item in form) {
      //     if (typeof form[item] === 'string') {
      //         form[item] = form[item].trim()
      //         if (form[item] === '') {
      //             form[item] = null
      //         }
      //     }
      // }
      //清空字符串的前后空格
      this.trimParamsStr(form);
      const {prop, order} = this.isorter
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: (order && order.indexOf('asc') !== -1) ? 'asc' : 'desc',
      }
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === 'startTime') {
          this.$set(params, 'column', 'startTime')
        } else if (this.enabledCreatetime){
          this.$set(params, 'column', 'createTime')
        }
        //提货中查询
        if (this.queryForm.column === 'distributionTime') {
          this.$set(params, 'column', 'distributionTime')
        }
        //排序
        //提货中查询
        if (this.queryForm.column === 'sort') {
          this.$set(params, 'column', 'sort')
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage
        params.pageSize = this.ipagination.pageSize
      }
      // let method = "GET";
      // if (this.isUsePost) {
      //     method = "POST";
      // }
      if (!this.isUsePost) {
        getAction(this.url.list, params).then((res) => {
          console.log(res)
          if (res.success) {
            this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
            if (res.result) {
              const {records, total} = res.result.page;
              this.tableData = records;
              this.ipagination.total = total;
              this.logList = res.result.logList;
              this.isOpeDownload = res.result.isOpeDownload;
              this.needCheckCao = res.result.needCheckCao;
              this.declareType = res.result.declareType;
              if (this.declareType === '1') {
                this.removeValue = "0";
              }
              if (this.declareType === '0') {
                this.removeValue = "1";
              }
            } else {

              this.tableData = [];
              this.ipagination = {
                currentPage: 1,
                pageSize: 50,
                pageSizes: [50, 100, 200, 300, 400, 500],
                total: 0
              };
            }

          }
          this.loading = false;
          this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
        }).catch(err => {
          this.loading = false;
          // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
        })
      }else {
        postAction(this.url.list, params).then((res) => {
          console.log(res)
          if (res.success) {
            this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
            if (res.result) {
              const {records, total} = res.result
              this.tableData = records || res.result;

              this.ipagination && (this.ipagination.total = total || res.result.length)
            } else {

              this.tableData = [];

              this.ipagination = {
                currentPage: 1,
                pageSize: 50,
                pageSizes: [50, 100, 200, 300, 400, 500],
                total: 0
              };
            }

          }
          this.loading = false;
          this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
        }).catch(err => {
          this.loading = false;
          // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
        })
      }

    },

    getDeclareType(type){
      if (type ==='0') {
        return "买单";
      }else if (type==="1"){
        return "退税";
      }else if (type==="2"){
        return "买单+退税";
      }else if (type==="3"){
        return "自拖自报";
      }else if (type=="4"){
        return "手册报关";
      }else {
        return "";
      }
    },
    addUpload(data) {
      if (!this.queryForm.type3){
        this.$message.warning('请选择文件类型在上传');
        return false;
      }
      this.fullscreenLoading = true;
      // if(this.queryForm.type == '2') {
      //   // FBA标签要到派送及发票上传
      //   this.$message.warning('无法上传FBA标签，请到派送及发票中上传');
      //   return false;
      // }
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("dir", 'osOrder');
      formData.append("orderNo", this.$route.query.orderNo);

      postAction(this.url.upload, formData).then(res => {
        if (res.success) {
          this.fullscreenLoading = false;
          //上传成功,返回结果赋值给form对象的文件集合
          let {id, size, ext, name, url} = res.result;
          //this.fileList.push({ossId: id, size, ext, name: fileName, url});
          this.$set(this.form,'url',url);
          this.$set(this.form,'ossId',id);
          this.$set(this.form,'size',size);
          this.$set(this.form,'type',ext);
          this.$set(this.form,'name',name);
          this.$set(this.form,'fileName',name);
          this.$set(this.form,'type',this.queryForm.type3);
          this.$set(this.form,'orderNo',this.$route.query.orderNo);
          // this.$set(this.form,'url',url);
          // this.form.url = url;
          // this.form.ossId = id;
          // this.form.size = size;
          // this.form.type= ext;
          // this.form.name= name;
          // this.form.fileType= this.queryForm.fileType;
          // this.$message.success('上传成功');
          httpAction(this.url.add, 'post', this.form)
                  .then(res => {
                    if (res.success) {

                      this.dialogFormVisible = false;
                      this.$message.success(res.message);
                      this.$set(this.queryForm,'type','');
                      this.queryCount = this.queryCount+1;
                      this.$set(this.queryForm,'queryCount',this.queryCount);
                      this.handleQuery();
                    } else {
                      this.btnDisable = false;
                    }
                  }).catch(err => {

          })
          // this.fileList.push(res.result);
        }else {
          this.fullscreenLoading = false;
        }
      })
    },
    // 获取uuid
    generateUUID(length) {
      var d = new Date().getTime();
      let uuid = d.toString() + Math.random().toString().substr(3, length);
      return uuid;
    },
    //使用阿里云上传文件
    async addUploadOssClirnt(request) {
      if (!this.canUploadFile) {
        return false;
      }else {
        if (!this.queryForm.type3){
          this.$message.warning('请选择文件类型在上传');
          return false;
        }else {
          let isUploadBaoguan = this.queryForm.type3 === '0' || this.queryForm.type3 === '1' || this.queryForm.type3 === '32';
          // 如果操作下载过
          if (this.isOpeDownload && isUploadBaoguan) {
            this.$confirm('操作已下载报关资料，如上传更新报关资料，请务必立即通知到排柜操作', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'error',
              closeOnClickModal: false,
            }).then(() => {
              this.realUploadFile(request);
            }).catch(() => {

            })
          }else {
            this.realUploadFile(request);
          }
        }

      }

    },
    async realUploadFile(request){
      let uuid = this.generateUUID(6);
      const fileItem = await this.handleUploadOss2(request,'docFile','osOrder',uuid);
      console.log("上传返回的结果：" , fileItem);
      if (!fileItem) {
        that.$message.warning('上传文件失败');
        return false;
      }
      const that = this;
      let file = request['file'];
      console.log(file, "阿里云OSS文件上传");
      this.$store.commit("setFullscreenLoading", true);
      httpAction(that.url.add, 'post', {url:fileItem.url,ossId:fileItem.id,size:file.size/1000,type:that.queryForm.type3,name:file.name,orderNo:that.$route.query.orderNo})
          .then(res => {
            this.$store.commit("setFullscreenLoading", false);
            if (res.success) {
              that.dialogFormVisible = false;
              that.$message.success(res.message);
              that.$set(that.queryForm,'type','');
              that.handleQuery();
            } else {
              // that.btnDisable = false;
            }
          }).catch((err) => {
        this.$store.commit("setFullscreenLoading", false);
      })
    },
    //文件上传之前校验文件合法
    beforeUpload(file) {
      // 对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15
      if (!isLt2M) {
        this.$message.warning('文件大小不能超过15MB');
        this.canUploadFile = false;
        return false;
      }
      let name = "";
      if (file && file.name) {
        name = file.name;
      }
      const isFile = name.indexOf('.') !== -1;
      if (!isFile) {
        this.$message.warning('该文件名不合法');
        this.canUploadFile = false;
        return false;
      }
      this.canUploadFile = true;
      return  isFile;
    },
    exportOs(){
      let list = this.tableData;
      if (this.selectionRows && this.selectionRows.length>0 && Array.isArray(this.selectionRows)){
        list = this.selectionRows;
      }
      postAction(this.url.downloadBatch+"?downloadSource=2",list,{responseType: 'blob'})
              .then(response=>{
                let u = window.URL.createObjectURL(response)
                let aLink = document.createElement('a')
                aLink.style.display = 'none'
                aLink.href = u
                aLink.setAttribute('download', "客户海运文件.zip")
                document.body.appendChild(aLink)
                aLink.click()
                document.body.removeChild(aLink)
                window.URL.revokeObjectURL(u);
                this.handleQuery();
              })
    },
    downloadFile2(file){
      if (file && Number(file.size) > 1624000) {
        window.open(file.url);
      }else {
        this.downloadFile(file);
      }



    },
    downloadFile: function(file) {
      postAction(this.url.downloadFile, file, {responseType: 'blob'})
              .then(response => {
                let u = window.URL.createObjectURL(response)
                let aLink = document.createElement('a')
                aLink.style.display = 'none'
                aLink.href = u;
                let type = this.parseDict(file.type,'os_order_filelist');
                let ss=file.orderNo+"-"+type+"-"+file.name;
                if (file.type === '0' || file.type === '1') {
                  //买单： 单号-买单
                  //退税不核对草单：单号-退税
                  //退税核对草单：核对草单-单号-退税
                  ss = type + "-" + file.orderNo;
                  if (this.needCheckCao === '1') {
                    ss = '核对草单' + "-" + ss;
                  }
                  let ext = file.name.substring(file.name.lastIndexOf('.'));
                  ss = ss + ext;
                }
                aLink.setAttribute('download', ss)
                document.body.appendChild(aLink)
                aLink.click()
                document.body.removeChild(aLink)
                window.URL.revokeObjectURL(u);
                this.handleQuery();
              })
    },
    onexceed() {
      this.$message.warning("最多上传一个文件哦,亲");
    },

    saveFile: function () {
      if (upLoadClicktag == 0) {
        upLoadClicktag = 1;
        console.log("文件对象", this.form.fileList);
        this.btnDisable = true;
        this.form.orderNo = this.$route.query.orderNo;
        //文件的删除和新增统一放到F-upload，这边传递表单对象过去
        this.$refs["form"].validate((valid) => {
          if (valid) {
            let method = "post";
            let url = this.url.add;
            if (this.form.fileList.length <= 0) {
              this.$message.error("请选择文件");
              this.btnDisable = false;
              return false;
            } else {
              //讲文件中的fileType he name赋值
              this.$set(this.form, "name", this.form.fileList[0].name);
              if (this.form.id) {
                url = this.url.edit;
                method = "put";
              }
              httpAction(url, method, this.form)
                .then((res) => {
                  if (res.success) {
                    this.dialogFormVisible = false;
                    this.$message.success(res.message);
                    this.handleQuery();
                  } else {
                    this.btnDisable = false;
                  }
                })
                .catch((err) => {
                  this.btnDisable = false;
                });
            }
          } else {
            this.btnDisable = false;
          }
        });
        //3秒过后可以再次点击
        setTimeout(function () {
          upLoadClicktag = 0;
        }, 3000);
      }
    },
    handleReset() {
      this.queryForm = {};
      this.queryForm.orderNo = this.$route.query.orderNo;
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },

    handleAdd() {
      this.form = { fileList: [] };
      this.btnDisable = false;
      this.declareType = "";
      this.declareType_dictText = "";
      this.removeValue = "";
      console.log("儿子", this.$refs.child);

      // this.$refs.filemodule.init();
      this.dialogFormVisible = true;
    },
    handleEdit({ id }) {
      this.btnDisable = false;
      getAction(this.url.getbyid, { id }).then((res) => {
        this.form = res.result.file;
        if (this.form) {
          if (res.result) {
            console.log("要编辑的文件", res.result);

            this.declareType = res.result.declareType;
            this.declareType_dictText = res.result.declareType_dictText;
            if (this.declareType == 1 || this.declareType == 3) {
              this.removeValue = "0";
            }
            if (this.declareType == 0 || this.declareType == 3) {
              this.removeValue = "1";
            }

            this.dialogFormVisible = true;
          }
        }
      });
    },
    handDel({ id, name }) {
      this.$confirm(`删除${name}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delById + id).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch((err) => {
          if(err.messag){
            this.$message.error(err.message);
          }
        });
    },

    handleBatDel() {
      //判断是否有转运单类型的文件
      let olcFileList = [];
      this.selectionRows.forEach(s=>{
        if (s && s.isOlc === '1') {
          olcFileList.push(s.id);
        }
      })
      if (olcFileList.length > 0) {
        this.$message.warning("海外仓系统的文件不允许删除，请重新选择");
        return;
      }
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.deleteBatch, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch((err) => {
          if(err.message){
            this.$message.error(err.message);
          }
        });
    },
  },
  computed : {
    buttonShowList() {
      return {
        'plsc':this.$btnIsShow('docpage/modules/FileModule','0','批量删除'),
        'plxz':this.$btnIsShow('docpage/modules/FileModule','1','批量下载'),
        'bj':this.$btnIsShow('docpage/modules/FileModule','1','编辑'),
        'sc':this.$btnIsShow('docpage/modules/FileModule','1','删除'),
        'canSeeCusFile':this.$btnIsShow('isystem/RoleManage','0','是否可以查看客服页的客户文件'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-demo {
  margin-right: 10px;
}
</style>
