<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="100px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="业务员">
              <f-select
                :dict="'sys_user_id'"
                v-model="queryForm.visitor"
                :is-need="queryForm.visitor"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="拜访时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeStart"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  :picker-options="editStartOptions"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  :picker-options="editStopOptions"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="拜访方式">
              <f-select
                :dict="'visit_type'"
                v-model="queryForm.visitType"
                :is-need="queryForm.visitType"
              >
              </f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          size="medium"
          type="primary"
          icon="el-icon-download"
          @click="handleExportXls"
          v-if="buttonShowList.dc"
          >导出</el-button
        >
      </div>

      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="62vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <!--          <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>-->
            <!--          <el-button type="text" @click="handDel(scope.row)">删除</el-button>-->
            <!--          <el-button type="text" @click="handRecord(scope.row)" v-if="scope.row.tag=='0'">备案</el-button>-->
            <!--          <el-button type="text" @click="handqxRecord(scope.row)" v-if="scope.row.tag=='1'">取消备案</el-button>-->
          </template>
          <template v-else-if="scope.column.property === 'visitType'">
            <span> {{ parseDict(scope.row.visitType,'visit_type')}}</span>
          </template>
          <template v-else-if="scope.column.property==='visitor'">
            <span>{{parseDict(scope.row.visitor,'all_user_id')}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { getallsalesmans } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";

export default {
  name: "VisitModule",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      dictCodes:['all_user_id','visit_type'],
      ids: [],
      row: {},
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.queryForm.createTimeEnd) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.queryForm.createTimeEnd);
          }
        },
      },
      editStopOptions: {
        disabledDate: (time) => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
            time.getTime() < new Date(this.queryForm.createTime) ||
            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },

      assignTitle: "",

      columns: [
        {
          prop: "communicate",
          label: "沟通事项",
          sortable: "custom",
        },

        {
          prop: "visitor",
          label: "业务员",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "拜访时间",
          sortable: "custom",
        },
        {
          prop: "visitType",
          label: "拜访方式",
          sortable: "custom",
        },
        {
          prop: "communicationResult",
          label: "沟通结果",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "workPlan",
          label: "工作/收款计划",
          sortable: "custom",
          width: 160,
        },
      ],
      url: {
        list: "/customer/visitLog/list",
        exportXlsUrl: "/customer/visitLog/exportXls",
        check: "/customer/userPassById",
        deleteBatch: "",
        approvalBatch: "/customer/userPassBatch",
        fronzenUrl: "/customer/forbidUserById/",
        baturl: "/customer/tuihui",
        batbatch: "/customer/tuihuiBatch",
        getsalesmanlist: "/sys/user/salesman/list",
        getuserlist: "/sys/user/list",
      },
    };
  },
  methods: {
    handleEdit({ id }) {
      // this.$router.push(`/customer/Edit?id=${id}`)
    },

    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          // deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
          //     this.$message({
          //         type: 'success',
          //         message: res.message
          //     })
          //     this.handleSearch()
          // })
        });
    },

    //初始化获取业务员列表

    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.queryForm.cusId = this.$route.query.id;
      this.ipagination.currentPage = 1;

      this.handleQuery();
    },
  },
  created() {
    this.queryForm.cusId = this.$route.query.id;
    this.queryForm.initUser = 1;
  },
  computed : {
    buttonShowList() {
      return {
        'dc':this.$btnIsShow('customer/Edit','0','导出拜访记录'),

      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
