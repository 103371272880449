import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "@/utils/downloadJasper";
import {getAction} from "@/api/manage";
import store from "@/store";
import {postAction} from "../api/manage";

export default {
    data(){
        return{
            url:{
                getHblBuLiaoUrl: "/file/jasper/getHblBuLiao", //船司补料文件
                getVgmUrl: "/file/jasper/getVgm", //VGM
                getGuoWaiQingguanUrl: '/file/jasper/getGuoWaiQingguan', //下载国外清关文件
                getPaiGuiUrl: "/file/jasper/getPaiGui", //排柜表
                getPaiSongUrl: "/file/jasper/getPaiSong",  //派送文件
                getOceanFeeUrl: "/file/jasper/getOceanFee", //清关发票文件pdf
                queryInvoiceCodeUrl: '/order/os/file/queryInvoiceCode', //检查发票编码
                getContinentUrl: '/order/os/file/getContinent', //获取柜子目的港所在的洲
                getContinentUrlPlus:'/file/jasperOpe/getContinentByContainerId', //file 获取柜子目的港所在的州
                downloadCargoQingguan:'/file/jasperOpe/downloadCargoQingguan',
                downloadFlyingQingguan:'/file/jasperOpe/downloadFlyingQingguan',
                downloadJapanQingguan:'/file/jasperOpe/downloadJapanQingguan',
                downloadQingguanInvoice:'/file/jasperOpe/downloadQingguanInvoice',
                selectFileContainerId:'/order/os/file/selectFileContainerId',
                saveBaoguanFileDownloadLog:'/sys/oss/file/saveBaoguanFileDownloadLog',

            },
            progress: 0,
            downloading: false,
        }
    },
    methods: {
        updateProgress(completed, total) {
            this.progress = (completed / total) * 100;
            console.log("进度条", this.progress);
        },

        async downloadAndZipFiles(fileList, zipName) {
            let successFiles = [];
            store.commit("setFullscreenLoading", true);
            let zip = null;
            let isHttp = false;
            let isHttps = false;
            try {
                zip = new JSZip();
                let currentProtocol = window.location.protocol;
                if (currentProtocol === 'https:') {
                    console.log('当前页面是HTTPS');
                    isHttps = true;
                } else if (currentProtocol === 'http:') {
                    console.log('当前页面是HTTP');
                    isHttp = true;
                }
            }catch (error){
                this.$message("下载资料失败，请联系管理员");
                store.commit("setFullscreenLoading", false);
                return false;
            }

            await Promise.all(fileList.map(async (item) => {
                try {
                    let url = item['url'];
                    if (isHttps && url.indexOf('http://') !== -1) {
                        url = url.replace("http://", "https://");
                    }
                    let fileName = item['fileName'];
                    // 使用fetch 异步下载文件
                    const response = await fetch(url);
                    if (!response.ok) {
                        throw new Error(`Failed to ferch ${url}`);
                    }
                    // 将文件添加到 jszip示例中
                    const blob = response.blob();
                    zip.file(fileName, blob);
                    // 下载成功 添加进那个成功的文件列表
                    if (item.type === '0' || item.type === '1' || item.type === '32') {
                        successFiles.push(item);
                    }
                } catch (error) {
                    console.log(error);
                }
            }));
            // 生成zip 文件 并且保存到 用户设备上
            try {
                const content = await zip.generateAsync({ type: 'blob' });
                saveAs(content, zipName + '.zip');
            }catch (error) {
                console.error('Error generating zip file:', error);
            } finally {
                store.commit("setFullscreenLoading", false);
                //
                if (successFiles && successFiles.length > 0) {
                    postAction(this.url.saveBaoguanFileDownloadLog,successFiles)
                        .then(res => {
                            this.$message.success(res.message);
                        })
                }
            }

        },
        downloadFileListAndType(fileType, fileName) {
            const that = this;
            getAction(this.url.selectFileContainerId + '?fileType=' + fileType + '&containerId=' + this.$route.query.id + '&orderNo=' + this.selectedRowOrderNos.join(','))
                .then(res => {
                    // if (res.status=='false'){
                    //     this.$message.warning(res.message);
                    // }
                    if (res.success) {
                        console.log("查询到的文件", res.result);
                        that.downloadAndZipFiles(res.result, fileName);
                    }
                }).catch(err => {
                
                    this.$message.warning(err.message);
            })
        },
        //下载船司补料文件
        downloadChuansi() {
            console.log("封装的船司补料");
            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                this.url.getHblBuLiaoUrl + '?containerId=' + this.$route.query.id,
                {},
                this.infomation.week + "-船司补料文件.xls",
                this
            );
        },
        //下载vgm文件
        downloadVGM() {
            console.log("封装的VGM");
            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                this.url.getVgmUrl + '?containerId=' + this.$route.query.id,
                {},
                this.infomation.week + "-VGM文件.xls",
                this);
        },
        //下载加/澳清关文件
        async downloadAusAndCANGuowaiQingguan(isHebing) {
            console.log("封装的国外清关文件");
            let that = this;
            let pu = false;
            //判断勾选的单是否都设置发票编码
            await getAction(this.url.queryInvoiceCodeUrl + '?orderNo=' + this.selectedRowOrderNos.join(',') + '&containerId=' + this.$route.query.id)
                .then(res => {
                    if (res.success) {
                        pu = true;
                    }
                })
            if (pu) {
                console.log('封装的根据柜子获取目的港')
                //根据柜子获取目的港
                getAction(this.url.getContinentUrl + '?containerId=' + this.$route.query.id).then(res => {
                    if (res.success) {
                        let continent = res.result;
                        if (continent) {
                            //非欧洲
                            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                                this.url.getGuoWaiQingguanUrl + '?containerId=' + this.$route.query.id
                                + '&isHebing=' + isHebing + '&continent=' + continent + '&type=2&orderNo=' + this.selectedRowOrderNos.join(',') + '&downloadType=1',
                                {},
                                '国外清关文件.zip',
                                that
                            );
                        }
                    }
                })
            }

        },
        //下载cargo清关
        downloadCargo() {
            console.log("CARGO 清关文件", this.infomation);
            let that = this;
            let cno = "";
            if (this.infomation.containerNo && this.infomation.containerNo.length > 0) {
                cno = this.infomation.containerNo;
            }
            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                this.url.downloadCargoQingguan + '?containerId=' + this.$route.query.id,
                {},
                cno + "-CARGO 清关文件.zip",
                that
            );
        },
        //下载flying清关
        downloadFlying() {
            console.log("FLYING 清关文件", this.infomation);
            let that = this;
            let cno = "";
            if (this.infomation.containerNo && this.infomation.containerNo.length > 0) {
                cno = this.infomation.containerNo;
            }
            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                this.url.downloadFlyingQingguan + '?containerId=' + this.$route.query.id,
                {},
                cno + "-FLYING 清关文件.zip",
                that
            );
        },
        downloadQingguanInvoice(){
            console.log("清关发票文件",this.infomation);
            let that = this;
            let cno = "";
            if (this.infomation.week && this.infomation.week.length > 0) {
                cno = this.infomation.week;
            }
            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                this.url.downloadQingguanInvoice + '?containerId=' + this.$route.query.id,
                {},
                cno + "-清关发票文件.zip",
                that
            );
        },
        //下载日本清关
        downloadJapan() {
            console.log("日本 清关文件", this.infomation);
            let that = this;
            let cno = "";
            if (this.infomation.containerNo && this.infomation.containerNo.length > 0) {
                cno = this.infomation.containerNo;
            }
            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                this.url.downloadJapanQingguan + '?containerId=' + this.$route.query.id,
                {},
                cno + "-日本 清关文件.zip",
                that
            );
        },
        //下载国外清关文件
        async downloadGuowaiQingguan(isHebing) {
            console.log("封装的国外清关文件");
            let that = this;
            let pu = false;
            //判断勾选的单是否都设置发票编码
            await getAction(this.url.queryInvoiceCodeUrl + '?orderNo=' + this.selectedRowOrderNos.join(',') + '&containerId=' + this.$route.query.id)
                .then(res => {
                    if (res.success) {
                        pu = true;
                    }
                })
            if (pu) {
                console.log('封装的根据柜子获取目的港')
                //根据柜子获取目的港
                getAction(this.url.getContinentUrl + '?containerId=' + this.$route.query.id).then(res => {
                    if (res.success) {
                        let continent = res.result;
                        if (continent) {
                            if (continent === "EU") { //欧洲
                                //给出提示 是否按照单号拆分
                                this.$confirm(`该柜子的目的港是欧洲，文件是否按照单号拆分?`, '提示', {
                                    confirmButtonText: '是',
                                    cancelButtonText: '否',
                                    type: 'info',
                                    closeOnClickModal: false,
                                    distinguishCancelAndClose: true,//区分取消与关闭
                                }).then(() => {
                                    //按照单号拆分
                                    downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                                        this.url.getGuoWaiQingguanUrl + '?containerId=' + this.$route.query.id
                                        + '&isHebing=' + isHebing + '&continent=' + continent + '&type=1&orderNo=' + this.selectedRowOrderNos.join(','),
                                        {},
                                        '国外清关文件.zip',
                                        that
                                    );

                                }).catch(action => {
                                    if (action === 'cancel') {
                                        downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                                            this.url.getGuoWaiQingguanUrl + '?containerId=' + this.$route.query.id
                                            + '&continent=' + continent + '&type=2&orderNo=' + this.selectedRowOrderNos.join(','),
                                            {},
                                            '国外清关文件.zip',
                                            that
                                        );
                                    }
                                })
                            } else {
                                //非欧洲
                                downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                                    this.url.getGuoWaiQingguanUrl + '?containerId=' + this.$route.query.id
                                    + '&isHebing=' + isHebing + '&continent=' + continent + '&type=2&orderNo=' + this.selectedRowOrderNos.join(','),
                                    {},
                                    '国外清关文件.zip',
                                    that
                                );
                            }
                        }
                    }
                })
            }

        },
        //下载排柜表
        downloadPaigui() {
            console.log("封装的排柜表")
            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                this.url.getPaiGuiUrl + '?containerId=' + this.$route.query.id,
                {},
                "排柜表" + this.infomation.week + ".xls",
                this
            );
        },
        //下载派送文件
        downloadPaisong(containerNo) {
            console.log("封装的派送文件");
            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                this.url.getPaiSongUrl + '?containerId=' + this.$route.query.id + '&orderNo=' + this.selectedRowOrderNos.join(','),
                {},
                containerNo ? containerNo + "-派送文件.xls" : "派送文件.xls",
                this);
        },
        //下载清关运费文件
        downloadOceanFee() {
            console.log("封装的清关download运费文件");
            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("get",
                this.url.getOceanFeeUrl + '?containerId=' + this.$route.query.id + '&orderNo=' + this.selectedRowOrderNos.join(','),
                {},
                "清关运费文件.zip",
                this);
        }

    }


}
