<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="100px" size="medium">
        <el-card class="box-card">
            <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="8">
          <el-form-item label="文件名">
            <el-input v-model="queryForm.name"></el-input>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="8">
          <el-form-item label="文件类型">
            <f-select :dict="'customer_file'"
                      v-model="queryForm.fileType"
                      :isNeed="queryForm.fileType"
            >
            </f-select>
          </el-form-item>

        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="8">
          <el-form-item label-width="30px">
            <el-upload
                    class="upload-demo"
                    action=""
                    :http-request="uploadOss2"
                    :show-file-list="false"
                    ref="add"
                    :before-upload="beforeUpload"
                    style="float: left;margin-right: 20px;">
              <el-button type="primary" size="small"
              v-if="buttonShowList.upload"
              >上传</el-button>
            </el-upload>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
        </el-card>

    </el-form>
    <el-card class="box-card last_card">


    <div class="f-p-b-15">
        <el-button size="medium" type="primary" class="c-theme-error-button" @click="handleBatDel"
                   :disabled="selectionRows.length === 0"
                   v-if="buttonShowList.delB"
                   >批量删除</el-button>
        <el-button size="medium" type="primary" icon="el-icon-download" @click="handleExportXls"
                   v-if="buttonShowList.down">导出</el-button>
    </div>

    <div class="c-alert f-m-b-10">
      已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
      <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
    </div>
    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="58vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleEdit(scope.row)"
                     v-if="buttonShowList.edit">编辑</el-button>
          <el-button type="text" @click="handDel(scope.row)"
                     v-if="buttonShowList.del">删除</el-button>
        </template>
        <template v-else-if="scope.column.property === 'name'">
          <span style="cursor: pointer" @click="handlePreview(scope.row)">{{scope.row.name}}</span>
        </template>
        <template v-else-if="scope.column.property === 'size'">
          <span>{{scope.row.size + 'KB'}}</span>
        </template>
        <template v-else-if="scope.column.property === 'fileType'">
          <span>{{parseDict(scope.row.fileType,'customer_file')}}</span>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    </el-card>


    <el-dialog :title="form.id?'编辑文件':'新增文件'" :visible.sync="dialogFormVisible"
               v-if="dialogFormVisible"
               center width="30%" :close-on-click-modal="false">

      <el-form :model="form" :rules="rules" ref="form" size="medium" >
        <el-row>
          <!--<el-col :span="24">
            <el-form-item prop="fileName" label="文件名" label-width="110px">
              <el-input v-model="form.fileName" ></el-input>
            </el-form-item>
          </el-col>-->


          <el-col :span="24">
            <el-form-item prop="fileType" label="文件类型" label-width="110px">

             <f-select :dict="'customer_file'"
                       v-model="form.fileType"
                       :is-need="form.fileType" v-if="fsee"></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item  label="文件"  label-width="110px" >
              <el-upload
                      class="upload-demo"
                      action=""
                      :http-request="uploadOss1"
                      :file-list="fileList"
                      :limit="1"
                      ref="upload"
                      :before-upload="beforeUpload"
                      :on-remove="removeFile"
                      :on-preview="handlePreview"
                      style="float: left;margin-right: 20px;">
                <el-button type="primary" size="small" v-if="fileList.length !== 1">上传</el-button>
              </el-upload>

            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="saveFile" :disabled="btnDisable">确 定</el-button>
      </div>

    </el-dialog>
    <el-dialog
            width="800px"
            title="预览"
            append-to-body
            :visible="visible"
            custom-class="c-custom-dialog"
            :before-close="() => { visible = false }">
      <img :src="imgUrl" width="100%" >
    </el-dialog>
  </div>
</template>

<script>
  import {deleteAction, getAction, httpAction, postAction} from "../../../api/manage";
  import QueryMixins from "../../../mixins/QueryMixins";
  import Tempcclate from "../../isystem/Dict";
  import OssClientMixins from "../../../mixins/OssClientMixins";
  export default {
  name: 'FileModule',
  mixins: [QueryMixins,OssClientMixins],
  components: {
    Tempcclate

  },
  data() {
    return {
      imgUrl:'',
      fsee:false,
      dictCodes:['customer_file'],
      ids: [],
      dialogFormVisible:false,
      form:{
        url:'',
        size:'',
        fileType:'',
        type:'',
        id:'',
        cusId:'',
        name:'',
        ossId:'',
      },
      btnDisable:false,
      //表单校验
      rules: {
        /*name: [
          {required: true, message: '请输入文件名', trigger: 'blur'},
          {max: 50, message: '长度不能超过50', trigger: 'change'},
        ],*/
        fileType: [
          {required: true, message: '请选择文件类型', trigger: 'change'}
        ]



      },
      fileList:[],
      row: {},
      visible: false,
      assignVisible: false,
      msgVisible: false,
      emailVisible: false,
      followTitle: '',
      assignTitle: '',
      columns: [
        {
          type: 'selection',
          width: 50
        },
        {
          prop: 'action',
          label: '操作',

        },
        {
          prop: 'name',
          label: '文件名',
          sortable: 'custom',

        },
        {
          prop: 'fileType',
          label: '文件类型',
          sortable: 'custom',

        },
        {
          prop: 'size',
          label: '文件大小',
          sortable: 'custom',

        },
        {
          prop: 'createBy',
          label: '上传人',
          sortable: 'custom',

        },
        {
          prop: 'createTime',
          label: '创建时间',
          sortable: 'custom',
        },

      ],
      url: {
        list: '/customer/file/list',
        exportXlsUrl: '/customer/file/exportXls',
        delById:'/customer/file/delete',
        add:'/customer/file/add',
        edit:'/customer/file/edit',
        editNew:'/customer/file/editNew',
        getbyid:'/customer/file/getbyid',
        deleteBatch:'/customer/file/deleteBatch',
        upload:'/sys/oss/file/upload',
        delOss:'/sys/oss/file/delete',

      },




    }
  },
  created() {
    this.queryForm.cusId = this.$route.query.id;

    },
  mounted() {

  },
  methods: {
    handlePreview(file) {


      if (!file.url) {
        this.$message.error('下载失败')
        return
      }

      const type = file.url.substring(file.url.lastIndexOf('.')+1);
      if (type === 'jpg' || type === 'jpeg' || type ==='img' || type === 'gif' || type === 'png' || type === 'pneg'){
        this.imgUrl = file.url
        this.visible = true
      }
      // 判断文件类型
      else if (type === 'doc' || type === 'docx' || type === 'xlsx' || type === 'xls' || type === 'ppt' || type === 'pptx') {
        // 在当前浏览器直接下载
        document.location.href = file.url
      } else {
        // 图片在浏览器打开 新的页面展示
        window.open(file.url, 'hello')
      }

    },
    addUpload(data) {
      if (!this.queryForm.fileType){
        this.$message.warning('请选择文件类型在上传');
        return false;
      }
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("dir", 'customer');

      postAction(this.url.upload, formData).then(res => {
        if (res.success) {
          //上传成功,返回结果赋值给form对象的文件集合
          let {id, size, ext, name, url} = res.result;
          //this.fileList.push({ossId: id, size, ext, name: fileName, url});
          this.$set(this.form,'url',url);
          this.$set(this.form,'ossId',id);
          this.$set(this.form,'size',size);
          this.$set(this.form,'type',ext);
          this.$set(this.form,'name',name);
          this.$set(this.form,'fileType',this.queryForm.fileType);
          this.$set(this.form,'cusId',c);
          // this.$set(this.form,'url',url);
          // this.form.url = url;
          // this.form.ossId = id;
          // this.form.size = size;
          // this.form.type= ext;
          // this.form.name= name;
          // this.form.fileType= this.queryForm.fileType;
          // this.$message.success('上传成功');
          httpAction(this.url.add, 'post', this.form)
                  .then(res => {
                    if (res.success) {
                      this.dialogFormVisible = false;
                      this.$message.success(res.message);
                      this.$set(this.queryForm,'fileType','');
                      this.handleQuery();
                    } else {
                      this.btnDisable = false;
                    }
                  }).catch(err => {
            this.btnDisable = false;
          })
          // this.fileList.push(res.result);
        }
      })
    },
    //根据字典文本获取字典集合
    //根据数据字典格式化文本
    //上传文件到oss
    uploadOss1(data){
      this.handleUploadCusFile(data,null,"customer","cusFile");
    },
    uploadOss2(data){
      this.handleUploadCusFile(data,this.$route.query.id,"customer","cusFile");
    },
    handleUpload(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("dir", 'customer');

      postAction(this.url.upload, formData).then(res => {
        if (res.success) {
          //上传成功,返回结果赋值给form对象的文件集合
          let {id, size, ext, name, url} = res.result;
          //this.fileList.push({ossId: id, size, ext, name: fileName, url});
          this.form.url = url;
          this.form.ossId = id;
          this.form.size = size;
          this.form.type= ext;
          this.form.name= name;
          this.$message.success('上传成功');

          this.fileList.push(res.result);
        }
      })
    },
    removeFile(val){
      if (val && val.status==="success") {
        // 成功时候的方法
        //删除oss的文件
        const index = this.fileList.indexOf(val);
        deleteAction(this.url.delOss,{id:this.form.ossId})
                .then(res=>{
                  if (res.success){
                    this.fileList.splice(index,1);
                    //同时清空文件的url
                    this.form.url = '';
                    this.form.ossId = '';
                    this.$message.success('删除文件成功')
                  }else {
                    //清空filelist
                    this.$refs.upload.clearFiles();
                  }
                }).catch(err=>{
          this.$refs.upload.clearFiles();
        })
        this.fileList = [];
      }

    },
    //文件上传之前校验文件合法
    beforeUpload(file) {
      //对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15
      if (!isLt2M) {
        this.$message.warning('文件大小不能超过15MB')
      }
      const isFile = file.name.indexOf('.') !== -1;
      if (!isFile) {
        this.$message.warning('该文件名不合法')
      }
      return isLt2M && isFile;
    },
    saveFile: function () {
      this.btnDisable = true;
      this.form.cusId = this.$route.query.id;

      //文件的删除和新增统一放到F-upload，这边传递表单对象过去
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let method = "post";
          let url = this.url.add;
          if (this.fileList.length <= 0) {
            this.$message.error('请选择文件')
            this.btnDisable = false;
            return false;
          } else {
             this.form.fileName = this.fileList[0].name;
             this.form.name = this.fileList[0].name;
            //讲文件中的fileType he name赋值

            if (this.form.id) {
              url = this.url.edit;
              method = "put";
            }
            httpAction(url, method, this.form)
                    .then(res => {
                      if (res.success) {
                        this.dialogFormVisible = false;
                        this.$message.success(res.message);
                        this.handleQuery();
                      } else {
                        this.btnDisable = false;
                      }
                    }).catch(err => {
              this.$message.error(err.message)
              this.btnDisable = false;
            })

          }


        } else {
          this.btnDisable = false;
        }
      })


    },
    handleReset(){
      this.queryForm = {};
      this.queryForm.cusId = this.$route.query.id;
      this.ipagination.currentPage = 1
      this.handleQuery()
    },



    handleAdd(){
      this.fsee = false;
      this.form = {};
      this.fsee = true;
      this.fileList = [];
      this.btnDisable = false;

      // this.$refs.filemodule.init();
    this.dialogFormVisible = true;
    },
    handleEdit({id}) {
      this.fsee = false;
      this.btnDisable = false;
      getAction(this.url.getbyid,{id:id})
      .then(res=>{
        this.form = res.result;
        if (this.form){
          this.fileList = [];
          if (this.form.url){
            if (res.result){

                let id = res.result.ossId;
                let size = res.result.size;
                let url = res.result.url;
                let name = res.result.name;
                this.fileList.push({id,size,url,name});
                this.fsee = true;


            }
          }
        }

      })
      this.dialogFormVisible = true;
    },
    handDel({id, name}) {
      this.$confirm(`删除${name}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.delById+"?ids="+this.selectedRowKeys, {id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },





    handleBatDel() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
            this.$message({
                type: 'success',
                message: res.message
            })
            this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },

  },
    computed : {
      buttonShowList() {
        return {
          'upload':this.$btnIsShow('customer/modules/FileModule','0','上传'),
          'delB':this.$btnIsShow('customer/modules/FileModule','0','批量删除'),
          'down':this.$btnIsShow('customer/Edit','0','下载客户文件'),
          'edit':this.$btnIsShow('customer/modules/FileModule','1','编辑'),
          'del':this.$btnIsShow('customer/modules/FileModule','1','删除'),
        }
      }
    }
}
</script>

<style lang="scss" scoped>

</style>
