<template>
    <el-dialog
        :title="title"
        :visible="visible"
        width="560px"
        custom-class="c-custom-dialog"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="type" label="订单类型">
                        <f-select
                                v-model="form.type"
                                :isNeed="form.type"
                                :dict="'customer_order_type'"></f-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="followList" label="跟进人">
                        <f-select
                                v-model="form.followList"
                                :isNeed="form.followList"
                                :multiple="true"
                                :dict="'sys_user_id'"></f-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'


export default {
    name: 'AddFollowerDialog',
    props: {
        visible: {
            type: Boolean
        },
        title: {
            type: String,
            default: '跟进人'
        },
        ids: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            form: {},
            rules: {
                follow: [
                    { required: true, message: '请选择跟进人', trigger: 'change' },

                ],
                type:[
                    { required: true, message: '请选择订单类型', trigger: 'change' },
                ],
            },
            url: {
                add: '/sys/role/add',
                edit: '/sys/role/edit',
                setfollow:'/customer/setFollow',
                 setfollowbatch:'/customer/setFollowBatch',
                salesmanlist:'/sys/user/list',
            },
          followlist:[]
        }
    },
    methods: {
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                  let params = {ids : this.ids.join(',')}
                  let url = this.url.setfollowbatch;
                  if (this.title == '跟进人'){
                    url = this.url.setfollow;
                    params = {id : this.ids.join(',')}
                  }
                    httpAction(url+'?ids='+params.ids, "PUT", this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose()
                      this.$parent.handleSearch();
                    })
                }
            })
        },
        handleClose() {
            this.form = {}
            this.$emit('update:visible', false)
            this.$refs.form.resetFields();
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
