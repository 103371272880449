<template>
  <el-dialog
    title="发起提货"
    :visible="visible"
    width="50%"
    custom-class="c-custom-dialog"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
  >
    <el-form
      class="addEditBox"
      :model="form"
      :rules="rules"
      ref="form"
      size="medium"
    >
      <!-- 提货地址信息-->
      <el-row class="flex_center" :gutter="30">
        <el-col :span="13">
          <el-form-item
            prop="contactId"
            label="提货地址信息"
            key="contactId"
            :label-width="formLabelWidth"
          >
            <f-select
              v-model="form.contactId"
              :is-need="form.contactId"
              :data-list="selectSupplierList"
              @changet="updateThinfo"
            ></f-select>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="thSupplier"
            label="公司"
            :label-width="formLabelWidth"
            key="thSupplier"
          >
            <el-input
              v-model="form.thSupplier"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="contact"
            label="联系人"
            :label-width="formLabelWidth"
            key="contact"
          >
            <el-input
              v-model="form.contact"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="contactTel"
            label="联系电话"
            :label-width="formLabelWidth"
            key="contactTel"
          >
            <el-input
              v-model="form.contactTel"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="contactAdd"
            label="地址"
            :label-width="formLabelWidth"
            key="contactAdd"
          >
            <el-input
              v-model="form.contactAdd"
              maxlength="255"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
              prop="isNeedSendTakeDriverPhone"
              label="提货司机信息短信推送"
              :label-width="formLabelWidth"
              key="contactAdd"
          >
            <el-radio
                v-model="form.isNeedSendTakeDriverPhone"
                :label="'1'"
                @click.native.prevent="radioClick4('1')"
            >是</el-radio>
            <el-radio
                v-model="form.isNeedSendTakeDriverPhone"
                :label="'0'"
                @click.native.prevent="radioClick4('0')"
            >否</el-radio>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="getsTime"
            label="提货时间"
            :label-width="formLabelWidth"
            key="getsTime"
          >
            <el-date-picker
              id="getTakeTime"
              v-model="form.getsTime"
              type="datetime"
              :format="'yyyy-MM-dd HH:mm'"
              placeholder="选择时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="isWeiban"
            key="isWeiban"
            label="是否需要尾板车"
            :label-width="formLabelWidth"
          >
            <el-select v-model="form.isWeiban" clearable filterable>
              <el-option value="1" label="是"></el-option>
              <el-option value="0" label="否"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="isBanyun"
            key="isBanyun"
            label="是否需要搬运装车"
            :label-width="formLabelWidth"
          >
            <f-select
              v-model="form.isBanyun"
              :isNeed="form.isBanyun"
              :dict="'is_default'"
            ></f-select>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="palletNum"
            label="托盘数"
            :label-width="formLabelWidth"
            key="palletNum"
          >
            <el-input v-model="form.palletNum" type="number"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="remark"
            label="备注"
            :label-width="formLabelWidth"
            key="remark"
          >
            <el-input
              v-model="form.remark"
              maxlength="500"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-table :data="selectOrderList" border show-summary max-height="200">
        <el-table-column prop="orderNo" label="订单号">
          <template slot-scope="scope">
            <span>{{ scope.row.orderNo }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ctn" label="箱数">
          <template slot-scope="scope">
            <el-input v-model="scope.row.ctn"></el-input>
<!--            <span>{{ scope.row.ctn }}</span>-->
          </template>
        </el-table-column>
        <el-table-column prop="kg" label="重量">
          <template slot-scope="scope">
            <el-input v-model="scope.row.kg"></el-input>
<!--            <span>{{ scope.row.kg }}</span>-->
          </template>
        </el-table-column>
        <el-table-column prop="cmb" label="体积">
          <template slot-scope="scope">
            <el-input v-model="scope.row.cmb"></el-input>
<!--            <span>{{ scope.row.cmb }}</span>-->
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/manage";
import { validateCtn,validateCmb } from "../../../utils/validator";

export default {
  name: "addTakeDeliveryInfoDialog",
  props: {
    visible: {
      type: Boolean,
    },
    selectedTrailerIdList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isEdit: false,
      form: {},
      formLabelWidth: "130px",
      selectSupplierList: [],
      selectOrderList: [],
      rules: {
        contactId: [
          { required: true, message: "请选择提货地址信息", trigger: "change" },
        ],
        getsTime: [
          { required: true, message: "请选择提货时间", trigger: "change" },
        ],
        palletNum: [{ validator: validateCtn, trigger: "change" }],
      },
      url: {
        //生成其他出库数据
        realSendTake: "/warehouse/takeDelivery/updateRealSendTake",
        getTakeWarehouseInfoByIds:
          "/warehouse/takeDelivery/getTakeWarehouseInfoByIds",
      },
      getsTime: "",
    };
  },
  watch: {
    visible(val) {
      if (val && this.selectedTrailerIdList) {
        postAction(
          this.url.getTakeWarehouseInfoByIds,
          this.selectedTrailerIdList
        ).then((res) => {
          if (res && res.result) {
            let map = res.result;
            this.selectOrderList = map["orderList"];
            this.selectSupplierList = map["supplierList"];
            this.selectSupplierMap = map["supplierMap"];
            this.$set(this.form,'isWeiban',map['isWeiban']);
            this.$set(this.form,'isBanyun',map['isBanyun']);
            this.$set(this.form,'palletNum',map['oceanPkgs']);
            this.$set(this.form,'remark',map['remark']);
            this.$set(this.form,'getsTime',map['takeTime']);
            this.$set(this.form,'contactId',map['supplierId']);
            this.updateThinfo();
          }
        });
      }
    },
  },
  methods: {
    radioClick4(e){
      e === this.form.isNeedSendTakeDriverPhone? (this.form.isNeedSendTakeDriverPhone ='') :(this.form.isNeedSendTakeDriverPhone = e)
    },
    updateThinfo() {
      if (this.form.contactId) {
        let obj =
          this.selectSupplierMap && this.selectSupplierMap[this.form.contactId];
        if (obj) {
          this.$set(this.form, "thSupplier", obj.name);
          this.$set(this.form, "contact", obj.contact);
          this.$set(this.form, "contactTel", obj.tel);
          this.$set(this.form, "contactAdd", obj.address);
          this.$set(this.form, "isNeedSendTakeDriverPhone", obj.isNeedSendTakeDriverPhone);
        }
      }
    },
    handleSubmit() {
      if (!this.form.getsTime) {
        this.$message.warning("请选择提货时间");
        return false;
      }
      this.form.getsTime = this.$momonet(new Date(this.form.getsTime)).format(
        "yyyy-MM-DD HH:mm:ss"
      );
      this.form.trailerIds = this.selectedTrailerIdList;
      this.form.orderList = this.selectOrderList;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          postAction(this.url.realSendTake, this.form).then((res) => {
            this.$alert(
              `生成的用车编号是<br><span style="color: green">${res.message}</span>`,
              "提货结果",
              {
                dangerouslyUseHTMLString: true,
              }
            );
            this.handleClose();
            this.$emit("ok");
          });
        }
      });
    },
    handleClose() {
      this.form = {};
      this.selectSupplierList = [];
      this.selectSupplierMap = {};
      this.$emit("ok");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px 40px 20px 6px;
}
.addEditBox {
  max-height: 615px;
  overflow: scroll;
}
</style>
