<template>
    <el-dialog
            :title="title"
            :visible="visible"
            width="680px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleClose">
        <el-form :model="billForm" :rules="rules" ref="billForm">
            <el-row>
                <el-col :span="23">
                    <el-form-item prop="realMoneyType" label="折合币种">
                        <f-select v-model="billForm.realMoneyType"
                                  :dict="'bd_cur'"
                                  placeholder="请选择折合币种"
                                  clearable
                                  filterable
                                  @change="curChangeBank"
                                  :disabled="disable"
                                  :is-need="billForm.realMoneyType"
                                  class="f-full-width"></f-select>
<!--                        <el-input v-model="billForm.realMoneyType"-->
<!--                                  :disabled="disable">-->

<!--                        </el-input>-->
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="bankId" label="银行账户">
                        <el-select v-model="billForm.bankId"
                                  placeholder="根据币种选择银行"
                                  clearable
                                  filterable
                                  class="f-full-width">
                            <el-option v-for="item in banks" :key="item.id" :value="item.id" :label="item.bankName">
                                {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="billTitle" label="账单类型">
<!--                        <el-select v-model="billForm.billTitle"-->
<!--                                   placeholder="根据币种选择银行"-->
<!--                                   clearable-->
<!--                                   filterable-->
<!--                                   class="f-full-width">-->
<!--                            <el-option v-for="item in banks" :value="item.id" :label="item.abbr">-->
<!--                                {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }} >-->
<!--                            </el-option>-->
<!--                        </el-select>-->
                        <f-select clearable filterable class="f-full-width"
                                  v-if="settleType=='0'"
                                  :dict="'receive_bill_type'"
                                  v-model="billForm.billTitle"
                                  :fatherVal="billForm.billTitle"
                                  :is-need="billForm.billTitle"
                                  placeholder="请选择" style="width:100%">
                        </f-select>
                        <el-select v-model="billForm.billTitle" v-if="settleType=='3'">
                            <el-option value="1" label="海外仓-上海账单"></el-option>
                            <el-option value="3" label="海外仓-美东账单"></el-option>
                            <el-option value="4" label="海外仓-深圳账单">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="lang" label="账单语言">
                        <el-radio v-model="billForm.lang" label="cn">中文</el-radio>
                        <el-radio v-model="billForm.lang" label="us">英文</el-radio>
                    </el-form-item>
                </el-col>

            </el-row>
        </el-form>
        <span slot="footer">
            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAction, postAction} from '@/api/manage'
     import QueryMixins from "../../../../mixins/QueryMixins";

    export default {
        name: 'convertBill',
        mixins: [QueryMixins],

        data() {
            return {
                // id:'',
                disableMixinMounted: true,
                customerName:'',
                flag: false,
                visible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                treeData: [],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                billForm: {
                    billType: '1',  //0是非折合 1是折合
                    realMoneyType:'',
                    bankId:'',
                    billTitle: '',
                    lang: '',
                    ids: [],
                    orderNos: [],
                },
                url: {
                    // list: '/sys/bdCountry/list',
                    // deleteBatch: '/sys/bdCountry/deleteBatch',
                    openBill:  '/file/jasper/openReceiveBill',
                    checkBill: '/shipping/hySettleReceive/checkReceiveSettleId',
                    curGetBank:'/shipping/hySettleReceive/curGetBank',
                    sendBillMail: '/shipping/hySettleReceive/sendBillMail',
                },
                rules: {
                    realMoneyType: [
                        {required: true, message: '请选择折合币种', trigger: 'change'},
                    ],
                    bankId: [
                        {required: true, message: '请选择银行账号', trigger: 'change'},
                    ],
                    billTitle: [
                        {required: true, message: '请选择账单抬头', trigger: 'change'},
                    ],
                    lang: [
                        {required: true, message: '请选择账单语言', trigger: 'change'},
                    ],

                },
                title: '折合账单',
                settleIds: [],
                banks:[],
                disable:false,
                settleType:'',
            }
        },
        methods: {
            curChangeBank(val){
                // console.log(val)
                this.billForm.bankId = ''
                this.banks = []
                getAction(this.url.curGetBank,{cur:val, useType:1}).then((res)=>{
                    if (res.result.length === 0){
                        this.$message.warning("该币种下无相应账号！")
                        this.billForm.bankId = ''
                        this.banks = []
                    }else {
                        this.banks = res.result
                        this.billForm.bankId = ''
                    }

                })
            },
            handleSubmit() {
                this.$refs.billForm.validate((valid) => {
                    if (valid) {
                        this.ifDis = true
                        this.enabled()
                        let nos = this.billForm.orderNos.join('-')
                        let filename = this.customerName +'-'+ this.getDateStr()+ '折合账单' + nos
                        let that = this;
                        postAction(this.url.checkBill,this.billForm.ids).then((res) => {
                            //检测是同一个结算对象 才进行下载
                            // console.log('res',res)
                            if(res.success){
                                postAction(this.url.openBill, this.billForm, {responseType: 'blob'})
                                    .then(res => {
                                      if (res && res.type === 'application/json') {
                                        const reader = new FileReader()
                                        reader.onload = function () {
                                          const message = JSON.parse(reader.result).message
                                          that.$message.error(message);// 将错误信息显示出来
                                        }
                                        reader.readAsText(res)
                                        return false;
                                      }else {
                                        var binaryData = [];
                                        binaryData.push(res);
                                        let urlencoded = window.URL.createObjectURL(new Blob(binaryData,
                                            {"type": "application/pdf"}));
                                        window.open("/static/pdf/web/viewer.html?filename="+filename.replace("&","-")+"&file=" + encodeURIComponent(urlencoded));

                                        //以下为开账单，按需求关闭发送账单
                                        // let file = new Blob(binaryData)
                                        // file.lastModifiedDate = new Date();
                                        // file.name = "mailAtt.pdf"
                                        // let formData = new FormData();
                                        // formData.append("file", file);
                                        // formData.append("orderNo", this.billForm.orderNos[0])
                                        // //发送账单邮件给客户
                                        // postAction(this.url.sendBillMail,formData).then((res)=>{
                                        //     // console.log(res)
                                        // })

                                        this.visible=false
                                        setTimeout(()=>{   //设置延迟执行
                                          this.$message.success("开账单成功！")
                                          this.$emit('ok')
                                        },3000)
                                      }

                                    })


                                // this.handleQuery();
                            }
                        })


                    }
                })
            },
            handleClose() {
                // this.$emit('update:visible', false)
                // this.$refs.form.resetFields()
                this.visible = false;

            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)
            },
            edit(ids, nos, customerName,type) {
                this.settleType = type
                this.visible = true
                this.disable = false
                this.billForm.ids = ids;
                this.billType='1'
                this.billForm.orderNos = Array.from(new Set(nos));
                this.customerName = customerName;
                // console.log(this.billForm)
            },
            openAgain(ids, nos, customerName,cur,type){
                // console.log('账单再开币种',cur)
                this.settleType = type
                this.visible = true
                this.billForm.ids = ids;
                this.billForm.orderNos = Array.from(new Set(nos));
                // this.billForm.realMoneyType = cur
                this.disable = true
                this.customerName = customerName;
                this.billType='3'//3代表重复开账单
                this.$nextTick(()=>{
                    // this.billForm.realMoneyType = cur
                    this.$set(this.billForm,'realMoneyType',cur)
                    this.curChangeBank(cur)
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

</style>
