<template>
  <div>
    <el-card class="box-card">
      <el-form :model="isf" :rules="rules" ref="isf">
        <el-form-item
          label="制造商名称和地址 Manufacturer name and address:"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            v-model="isf.manNameAdd"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="销售商名称和地址 Seller name and address:"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            v-model="isf.sellNameAdd"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="购买方名称和地址 Buyer name and address:"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            v-model="isf.buyNameAdd"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="货物送达名称和地址 Ship to name and address:"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            v-model="isf.shipNameAdd"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="集装箱及装箱地点 Container stuffing location:"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            v-model="isf.conLocation"
            maxlength="250"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="集装箱拼箱人名称和地址 Consolidator name and address:"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            v-model="isf.conNameAdd"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="进口申报人/保税区的海运备案登记代码 EIN of importer of Record:"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            v-model="isf.importerEin"
            maxlength="250"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="收货人的海关备案登记代码 EIN of Consignee:"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            v-model="isf.consigneeEin"
            maxlength="250"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注 Remark:" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model="isf.remark"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="f-p-b-15" style="text-align: center">
        <el-button size="medium" type="primary" @click="saveisf"
          >保存</el-button>
        <el-button type="text" class="f-m-l-10" @click="hbloneexcel">
        下载ISF
        </el-button>
      </div>
<!--      <a type="text" class="f-m-l-10 el-button&#45;&#45;text f-cursor-pointer" @click="hblone"-->
<!--      >PDF</a>-->
      
    </el-card>


  </div>
</template>

<script>
import { getAction, postAction } from "../../../api/manage";

export default {
  name: "ISFandHblModule",
  props:{
    isShow:{
      type:Boolean,
      default:false,
    }
  },
  components: {},
  data() {
    return {
      isf: {},
      hbl: {},
      formLabelWidth: "340px",
      formLabelWidth2: "120px",
      url: {
        getisf: "/interface/ttOrder/getISFByOrderNo",
        saveisf: "/interface/ttOrder/addOrUpdateIsf",
        hblone: "/file/jasper/downloadISF",
        hbloneexcel: "/file/jasper/downloadISF?t=excel",
      },
      //表单校验
      rules: {
        name: [{ required: true, message: "请输入文件名", trigger: "blur" }],
        type: [
          { required: true, message: "请选择文件类型", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.$set(this.isf, "orderNo", this.$route.query.orderNo);
    this.$set(this.hbl, "orderNo", this.$route.query.orderNo);
    this.initIsf();
  },
  mounted() {},
  methods: {
    downloadExcel(url, fileName, params, fileType) {
      getAction(url, params, {responseType: 'blob'})
          .then(response => {
            if (!response) {
              return
            }
            const blob = new Blob([response])
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName)
            } else {
              let u = window.URL.createObjectURL(response)
              let aLink = document.createElement('a')
              aLink.style.display = 'none'
              aLink.href = u
              aLink.setAttribute('download', fileName + (fileType || '.xls'))
              document.body.appendChild(aLink)
              aLink.click()
              document.body.removeChild(aLink)
              window.URL.revokeObjectURL(u)
            }
          })
    },
    //预览pdf
    preview(url, params, fileName) {
      getAction(url, params, {responseType: 'blob'})
          .then(res => {
            var binaryData = [];
            binaryData.push(res);
            let urlencoded = window.URL.createObjectURL(new Blob(binaryData,
                {"type": "application/pdf"}));
            if(fileName) {
              var dllink = document.createElement('a');
              dllink.href = urlencoded;
              dllink.setAttribute("download", fileName + ".pdf"); // Added Line
              dllink.click();
            } else {
              window.open(urlencoded)
            }
            // 打开之后再下载
            // window.open("/static/pdf/web/viewer.html?filename=" + fileName + "&file=" + encodeURIComponent(urlencoded));
          })
    },
    hblone() {
      this.preview(
          this.url.hblone + "?orderNo=" + this.$route.query.orderNo,
          {},'ISF-'+this.$route.query.orderNo
      );
    },
    hbloneexcel() {
      this.downloadExcel(
          this.url.hbloneexcel + "&orderNo=" + this.$route.query.orderNo,
          "ISF-"+ this.$route.query.orderNo,
          {}
      );
    },
    initIsf() {
      getAction(this.url.getisf, {
        orderNo : this.$route.query.orderNo || ''}).then(
              (res) => {
                if (res.success) {
                  if (res.result.id) {
                    this.isf = res.result;
                  }
                }
              }
      );
    },


    saveisf() {
      // this.$set(this.isf, "orderNo", this.$route.query.orderNo || '');
      this.$set(this.isf, "containerId", this.$route.query.id);
      postAction(this.url.saveisf, this.isf).then((res) => {
        this.$message.success(res.message);
        this.initIsf();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
