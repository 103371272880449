<template>
  <el-card class="box-card">
          <div class="title_tab_text">操作记录</div>
          <div style="display: flex;flex-direction: row;justify-content: end;margin: 0 5px 10px 0">
              <el-date-picker
                  v-if="buttonShowList.a"
                  v-model="newCargoArrivedTime"
                  type="datetime"
                  size="small"
                  placeholder="到仓时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 180px"
              >
              </el-date-picker>
              <span v-else>{{ order.cargoArrivedStart || '' }}</span>
              <el-button
                  class="f-m-l-20"
                  type="primary"
                  size="small"
                  icon="check" @click="handleUpdateCargoArrivedTime"
                  v-if="buttonShowList.a"
              >保存到仓时间
              </el-button>
          </div>

    <!-- <el-card class="box-card"> -->

      <f-table
        v-loading="loading"
        ref="GTable"
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property   ==='createBy'">
            <span>{{parseDict(scope.row.createBy,'sys_create_by')}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    <!-- </el-card> -->
  </el-card>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {postAction} from "../../../api/manage";

export default {
  name: "KaidanWarehouseLogModule",
  mixins: [QueryMixins],
  components: {},
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
        order: {
            type: Object,
            default: () => {
            }
        },
    },

  data() {
    return {
      newCargoArrivedTime: this.order.cargoArrivedStart,
      n: 0,
      topSteps: [],
      midSteps: {},
      bottomSteps: [],
      midFlag: false,
      midNum: 0,
      stepVis: false,
      columns: [

        {
          prop: "createBy",
          label: "操作人",
        },
        {
          prop: "createTime",
          label: "操作时间",
        },
        {
          prop: "suozaiCangkuName",
          label: "操作人所属仓库",
        },
       
         {
          prop: "warehouseName",
          label: "开单仓库",
        },
          {
          prop: "receiptTime",
          label: "开单时间",
        },
      ],
      url: {
        list: "/interface/ttOrder/getKaidanLogIPage",
        updateCargoArrivedTime: '/interface/ttOrder/updateCargoArrivedTime',
      },

    };
  },
  methods: {
      handleUpdateCargoArrivedTime() {
          this.$store.commit("setFullscreenLoading", true);
          postAction(this.url.updateCargoArrivedTime, {orderNo: this.$route.query.orderNo, cargoArrivedTime: this.order.cargoArrivedStart, newCargoArrivedTime: this.newCargoArrivedTime,isCheck:'1'})
              .then((res) => {
                  if(res.success){
                      this.$store.commit("setFullscreenLoading", false);
                      this.$emit('init','basic2');
                      this.$message.success("保存成功！")
                  }
              }).catch(err => {
              this.$store.commit("setFullscreenLoading", false);
          })
      },
  },
  watch:{

  },

  mounted() {
  },
  created() {
    this.queryForm.orderNo = this.$route.query.orderNo;
    this.handleQuery();
  },
  computed : {
    buttonShowList() {
      return {
        'a':this.$btnIsShow('docpage/modules/KaidanWarehouseLogModule','0','保存到仓时间'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.circle {
  width: 160px;
  height: 84px;
  margin-top: 10px;
  border: 5px solid #c0c4cc;
  border-radius: 0 30% 30% 0/50%;
  border-left: none;
  position: relative;
  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    width: 36px;
    height: 36px;
    transform: translate(55%, -50%);
    background-color: #fff;
    border-radius: 50%;
    color: #c0c4cc;
    text-align: center;
    font-size: 12px;
    border: 8px solid #ffffff;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-icon-success {
      font-size: 24px;
      color: #cecece;
    }
    .tips {
      display: flex;
      position: absolute;
      right: 30px;
      font-size: 12px;
      color: #c0c4cc;
      width: 80px;
      padding-right: 8px;
      flex-direction: column;
      text-align: center;
      > span:first-child {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &.finish {
    border-color: #409eff;
    .icon {
      .el-icon-success {
        color: #409eff;
      }
      .tips {
        color: #409eff;
      }
    }
  }
}
.custom-steps {
  padding: 40px;
  display: flex;
  .left-steps {
    display: flex !important;
    width: 50px !important;
    /*width: calc(~"100% - 160px");*/
    /*float: left;*/
  }
  .right-steps {
    /*float: right;*/
    margin-left: -140px;
  }
  ::v-deep.el-step__icon {
    /*background: #f6f6f6;*/
  }
  ::v-deep.el-step__icon-inner {
    font-size: 36px;
  }
  ::v-deep.el-step__line {
    background: transparent;
    border-top: 5px solid;
    .el-step__line-inner {
      border-width: 0 !important;
    }
  }
  ::v-deep.el-step {
    position: relative;
    .el-step__head.is-process {
      color: #c0c4cc;
      border-color: #c0c4cc;
    }
    .el-step__title.is-process {
      color: #c0c4cc;
      font-weight: unset;
    }
  }
}
</style>
