<template>
  <div class="main-full-content">
    <el-card class="box-card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleAdd({})"
          v-if="buttonShowList.add"
          >新增</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :isorter="isorter"
        :options="typelist"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="74vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
                       v-if="buttonShowList.edit"
              >编辑</el-button
            >
            <el-button type="text" @click="handleDel(scope.row)"
                       v-if="buttonShowList.del"
              >删除</el-button
            >
            <el-button
              type="text"
              @click="handlSetToDefault(scope.row)"
              v-if="scope.row.isDefault === '0' && buttonShowList.setD"
              >设为默认</el-button
            >
            <el-button
              type="text"
              @click="qxDefault(scope.row)"
              v-if="scope.row.isDefault === '1' && buttonShowList.canD"
              >取消默认</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'type'">
            <span v-if="scope.row.type === '0'">公司负责人</span>
            <span v-else-if="scope.row.type === '1'">报价对接人</span>
            <span v-else-if="scope.row.type === '2'">操作联系人</span>
            <span v-else>财务联系人</span>
          </template>
          <template v-else-if="scope.column.property === 'isDefault'">
            <span v-if="scope.row.isDefault === '0'">否</span>
            <span v-else>是</span>
          </template>
          <template v-else-if="scope.column.property === 'bookEmail'">
            <span v-if="scope.row.bookEmail === '0'">否</span>
            <span v-else>是</span>
          </template>
          <template v-else-if="scope.column.property === 'bookMes'">
            <span v-if="scope.row.bookMes === '0'">否</span>
            <span v-else>是</span>
          </template>
        </template>
      </f-table>
    </el-card>

    <el-dialog
      :title="form.id ? '编辑联系人' : '新增联系人'"
      :visible.sync="dialogFormVisible"
      width="30%"
      center
      :close-on-click-modal="false"
      v-if="dialogFormVisible"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="姓名" prop="name" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="联系人岗位"
          prop="type"
          :label-width="formLabelWidth"
        >
          <f-select
            v-model="form.type"
            :dict="'cus_contact'"
            :is-need="form.type"
            v-if="fsee"
          >
          </f-select>
        </el-form-item>
        <el-form-item label="手机号" prop="phone" :label-width="formLabelWidth">
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="座机" prop="tel" :label-width="formLabelWidth">
          <el-input v-model="form.tel" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="qq" prop="qq" :label-width="formLabelWidth">
          <el-input v-model="form.qq" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
          <el-input
            v-model="form.remark"
            autocomplete="off"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="bookEmail" label="是否订阅邮箱">
          <el-radio-group v-model="form.bookEmail">
            <el-radio :label="'0'">否</el-radio>
            <el-radio :label="'1'">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="bookMes" label="是否订阅短信">
          <el-radio-group v-model="form.bookMes">
            <el-radio :label="'0'">否</el-radio>
            <el-radio :label="'1'">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="medium"
          type="primary"
          @click="saveContact"
          :disabled="btnDisable"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { mobilePattern, emailPattern } from "@/utils/pattern";
import { deleteAction, postAction, putAction, getAction } from "@/api/manage";

export default {
  name: "ContactModule",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      fsee: false,
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      typelist: [
        { value: "0", label: "公司负责人" },
        { value: "1", label: "报价对接人" },
        { value: "2", label: "操作联系人" },
        { value: "3", label: "财务联系人" },
      ],
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      //表单校验
      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择联系人岗位", trigger: "change" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          // {
          //   pattern: mobilePattern,
          //   message: "请输入正确的手机号",
          //   trigger: "change",
          // },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: emailPattern,
            message: "请输入正确的邮箱",
            trigger: "change",
          },
        ],
      },

      row: {},
      visible: false,
      columns: [
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "name",
          label: "姓名",
          // headerType : 'string',
          minWidth: 100,
          sortable: "custom",
        },
        {
          prop: "type",
          label: "职位",
          // headerType: 'select',
          sortable: "custom",
          minWidth: 100,
        },
        {
          prop: "phone",
          label: "手机号",
          // headerType: 'string',
          minWidth: 140,
          sortable: "custom",
        },
        {
          prop: "tel",
          label: "座机",
          // headerType: 'string',
          minWidth: 100,
          sortable: "custom",
        },
        {
          prop: "qq",
          label: "QQ",
          // headerType: 'string',
          minWidth: 100,
          sortable: "custom",
        },
        {
          prop: "email",
          label: "邮箱",
          // headerType: 'string',
          minWidth: 120,
          sortable: "custom",
        },
        {
          prop: "isDefault",
          label: "是否默认",
          // headerType: 'string',

        },
        {
          prop: "bookEmail",
          label: "是否订阅邮箱",
          // headerType: 'string',

        },
        {
          prop: "bookMes",
          label: "是否订阅短信",
          // headerType: 'string',
        },
        {
          prop: "remark",
          label: "备注",
          // headerType: 'string',
          minWidth: 120,
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "创建时间",
          // headerType: 'datetime',
          minWidth: 140,
          sortable: "custom",
        },
      ],
      url: {
        list: "/customer/contacts/list",
        getbyid: "/customer/contacts/getById",
        delbyid: "/customer/contacts/delete",
        setDefault: "/customer/contacts/setDefault",
        qxDefault: "/customer/contacts/qxDefault",
        add: "/customer/contacts/add",
        edit: "/customer/contacts/edit",
      },
    };
  },
  created() {
    this.queryForm.cusId = this.$route.query.id;
  },
  methods: {
    handlSetToDefault(row) {
      this.$confirm(`确定将${row.name}设为默认吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(this.url.setDefault, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    qxDefault(row) {
      this.$confirm(`确定将${row.name}取消默认吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(this.url.qxDefault, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    delCacheDict(){
      sessionStorage.removeItem("dict:email_template_customer")
      this.$store.state.dict['dict:email_template_customer'] = null;
      this.getDictList('dict:email_template_customer"')

    },
    handleDel(row) {
      this.$confirm(`确定删除${row.name}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delbyid, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    handleAdd() {
      this.fsee = false;
      this.form = {};
      this.fsee = true;
      //判断是否超过10
      let total = this.ipagination.total;
      if (total >= 20) {
        this.$message.warning("联系人最多20个，无法新增了亲");
      } else {
        this.dialogFormVisible = true;
      }
      this.btnDisable = false;
    },
    handleEdit(row) {
      this.fsee = false;
      getAction(this.url.getbyid, { id: row.id })
        .then((res) => {
          if (res.success) {
            this.form = res.result;
          } else {
            this.from = row;
          }
          this.fsee = true;
        })

      //显示弹窗，给对象赋值
      this.dialogFormVisible = true;
      this.btnDisable = false;
    },
    //新增或修改
    saveContact() {
      this.delCacheDict();
      //给客户id赋值
      this.btnDisable = true;
      this.form.cusId = this.$route.query.id;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            //有id，编辑
            putAction(this.url.edit, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  //关闭弹窗
                  this.dialogFormVisible = false;
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          } else {
            //新增
            postAction(this.url.add, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.dialogFormVisible = false;
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          }
        } else {
          this.btnDisable = false;
        }
      });
    },
  },
  computed: {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('customer/modules/ContactModule','0','新增'),
        'edit':this.$btnIsShow('customer/modules/ContactModule','1','编辑'),
        'del':this.$btnIsShow('customer/modules/ContactModule','1','删除'),
        'setD':this.$btnIsShow('customer/modules/ContactModule','1','设为默认'),
        'canD':this.$btnIsShow('customer/modules/ContactModule','1','取消默认'),
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
