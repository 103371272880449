<template>
  <div class="main-full-content">
    <el-form class="no_margin" ref="form" :model="queryForm" label-width="100px" size="medium">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :span="4">
            <el-form-item label="产品名" label-width="50px">
              <el-input v-model="queryForm.cname" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="产品编码" label-width="70px">
              <el-input v-model="queryForm.hscode" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="国家" label-width="50px">
              <f-select
                  v-model="queryForm.country"
                  :isNeed="queryForm.country"
                  :dict="'bd_country'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="是否备案" label-width="70px">
              <f-select
                  v-model="queryForm.tag"
                  :isNeed="queryForm.tag"
                  :dict="'is_record'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">

    <div class="f-p-b-15">
        <el-button size="medium" icon="el-icon-plus" type="primary" @click="handleAdd"
                   v-if="buttonShowList.add"
        >新增</el-button>
        <el-button size="medium" type="primary" icon="el-icon-download" @click="handleExportXls"
                   v-if="buttonShowList.dc">导出</el-button>
      <el-button  @click="handleDelBatch" :disabled="selectedRowKeys.length === 0"
                 v-if="buttonShowList.delB" type="primary">批量删除</el-button>
      <el-button  @click="handleQxRecordBatch" :disabled="selectedRowKeys.length === 0"
                 v-if="buttonShowList.canBaB" type="primary">批量取消备案</el-button>
    </div>
    <div class="c-alert f-m-b-10">
      已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
      <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
    </div>
    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="58vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleEdit(scope.row)"
                     v-if="buttonShowList.edit">编辑</el-button>
          <el-button type="text" @click="handDel(scope.row)"
                     v-if="buttonShowList.del">删除</el-button>

          <el-button type="text" @click="handRecord(scope.row)"
                     v-if="scope.row.tag ==='0' && buttonShowList.ba" >备案</el-button>
          <el-button type="text" @click="handqxRecord(scope.row)"
                     v-if="scope.row.tag ==='1' && buttonShowList.caba">取消备案</el-button>

        </template>
        <template v-else-if="scope.column.property === 'tag'">
          <span>{{parseDict(scope.row.tag,'is_record')}}</span>
        </template>
        <template v-else-if="scope.column.property === 'taxRate'">
          <span>{{formatTaxrate(scope.row.taxRate)}}</span>
        </template>
        <template v-else-if="scope.column.property === 'recorder'">
          <span>{{parseDict(scope.row.recorder,'sys_create_by')}}</span>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    </el-card>

    <product-dialog :visible.sync="dialogFormVisible" :row="selectRow" @ok="handleSearch"></product-dialog>
<!--    <msg-dialog :visible.sync="msgVisible" :row="row"></msg-dialog>-->
<!--    <assign-dialog :visible.sync="assignVisible" :ids="ids" :row="row" :title="assignTitle"></assign-dialog>-->
<!--    <add-follower-dialog :visible.sync="visible" :ids="ids" :row="row" :title="followTitle"></add-follower-dialog>-->
  </div>
</template>

<script>
import {putAction, deleteAction, getAction,getallsalesmans,getallusers,postAction} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js"
import MsgDialog from './MsgDialog'
import AssignDialog from './AssignDialog'
import AddFollowerDialog from './AddFollowerDialog'
import {mobilePattern,zhognwenPattern,yingwenPattern,baifenshuPattren} from "@/utils/pattern";
import {sanweixiaoshu} from "../../../utils/pattern";
import ProductDialog from "../../conformance/modules/ProductDialog";
export default {
  name: 'ProductModule',
  mixins: [QueryMixins],
  components: {
    MsgDialog,
    AssignDialog,
    AddFollowerDialog,
    ProductDialog
  },
  data() {
    return {

      fsee:false,
      dictCodes:['is_record','sys_create_by'],
      span:12,
      btnDisable:false,
      dialogFormVisible:false,
      form:{},
      rules: {
        cname: [
          { required: true, message: '请输入中文名', trigger: 'change' },
          { pattern: zhognwenPattern, message: '请输入中文', trigger: 'change' },
        ],
        ename: [
          { required: true, message: '请输入英文名', trigger: 'change' },
          // { pattern: yingwenPattern, message: '请输入英文', trigger: 'change' },
        ],
        country: [
          { required: true, message: '请选择国家', trigger: 'change' },
        ],
        hscode: [
          { required: true, message: '请输入产品编码', trigger: 'blur' },
          { max: 50, message: '长度不能超过50', trigger: 'change' },
        ],
        taxRate: [
          { required: true, message: '请输入税率', trigger: 'blur' },
          { pattern: sanweixiaoshu, message: '最多三位小数', trigger: 'change' },
        ],
        material: [
          { required: true, message: '请输入材质', trigger: 'change' },
        ],
        purpose: [
          { required: true, message: '请输入用途', trigger: 'change' },
        ],
        maker: [
          { required: true, message: '请输入厂家', trigger: 'change' },
        ],
      },
      ids: [],
      row: {},
      selectRow:{},
      visible: false,
      assignVisible: false,
      msgVisible: false,
      emailVisible: false,
      followTitle: '',
      assignTitle: '',

      columns: [
        {
          type: 'selection',
          width: 50
        },
        {
          prop: 'action',
          label: '操作',
        },
        {
          prop: 'cname',
          label: '产品中文名',

        },
        {
          prop: 'ename',
          label: '产品英文名',

        },
        {
          prop: 'hscode',
          label: '产品编码',

        },
          {
              prop: 'addtionalCode',
              label: '301编码',

          },
        {
          prop: 'taxRate',
          label: '税率',

        },
        {
          prop: 'country',
          label: '国家',

        },

        {
          prop: 'brand',
          label: '品牌',

        },
        {
          prop: 'model',
          label: '产品型号',

        },
        {
          prop: "demand",
          label: "清关要求",
        },
        {
          prop: "material",
          label: "材质",
          sortable: "custom",
        },
        {
          prop: "purpose",
          label: "用途",
        },
        {
          prop: "remark",
          label: "备注",
          sortable: "custom",
        },
        {
          prop: "maker",
          label: "厂家",
          sortable: "custom",
        },
        {
          prop: "makerAdd",
          label: "厂家地址",
        },
        // {
        //   prop: "brand",
        //   label: "品牌",
        // },
        // {
        //   prop: "model",
        //   label: "产品型号",
        // },
        {
          prop: 'recordTime',
          label: '备案时间',

        },
        {
          prop: 'recorder',
          label: '备案人',

        },
        {
          prop: 'tag',
          label: '备案状态',

        },
      ],
      url: {
        list: '/customer/product/list',
        exportXlsUrl: '/customer/product/exportXls',
        recordUrl:'/customer/product/record',
        quxiaoUrl:'/customer/product/recordCancel',
        add:'/customer/product/add',
        edit:'/customer/product/edit',
        getbyid:'/customer/product/getById',
        delbyid:'/customer/product/delete',
        delbatch:'/customer/product/deleteBatch',
        qxRecordBatch:'/customer/product/qxRecoedBatch',
      },
      salesmanlist:[],
      userlist:[],


      countrylist:[],
      isUsePost:true
    }
  },
  methods: {
    handleQxRecordBatch(){
      this.$confirm('确定批量取消备案这些产品吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.qxRecordBatch, this.selectedRowKeys).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })

          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleDelBatch(){
      this.$confirm('确定批量删除这些产品吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {

        deleteAction(this.url.delbatch+"?ids=" + this.selectedRowKeys.join(',')).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })

          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    showRate(val){
      if (val.indexOf('%')!==-1){
        return val;
      }else {
        return  val + "%";
      }
    },
    formatTaxrate(val){
      if(val && val.length>5 || val==='FREE'){
        return val;
      }else if(val && val.indexOf('%')===-1){
        return val + "%";
      }else{
        return val;
      }
    },
    saveProduct(){
      this.btnDisable = true;
      this.form.cusId = this.$route.query.id;
      this.$refs['form'].validate((valid)=>{
        if (valid){
          if (this.form.id){
            putAction(this.url.edit,this.form)
            .then(res=>{
              if (res.success){
                this.$message.success(res.message);
                this.dialogFormVisible = false;
                this.handleQuery();
              }else {
                this.btnDisable = false;
              }
            }).catch(err=>{
              this.btnDisable = false;
            })
          }else {
            postAction(this.url.add,this.form)
                .then(res=>{
                  if (res.success){
                    this.$message.success(res.message);
                    this.dialogFormVisible = false;
                    this.handleQuery();
                  }else {
                    this.btnDisable = false;
                  }

                }).catch(err=>{
              this.btnDisable = false;
            })
          }
        }else {
          this.btnDisable = false;
        }
      })
    },
    handleAdd(){
      this.selectRow = {};
      this.dialogFormVisible = true
    },
    //获取国家基础数据

    handleEdit(row) {
      this.selectRow = row;
      this.dialogFormVisible = true
    },
    handRecord({id, cname,hscode,taxRate}) {
      if (!hscode || !taxRate) {
        this.$message.warning('产品编码和税率不能为空');
        return false;
      }
      this.$confirm(`确定备案${cname}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.recordUrl+"?ids="+this.selectedRowKeys, {id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handqxRecord({id, cname}) {
      this.$confirm(`确定取消备案${cname}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.quxiaoUrl+"?ids="+this.selectedRowKeys, {id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    //退回
    handleBack(row) {
      this.$confirm(`确定将【${row.name}】退回到公海?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.baturl, {id:row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleBatApproval() {
      console.log("批量操作对象：" ,this.selectedRowKeys);
      var cname = this.selectedRowKeys;
      this.$confirm(`确定将【${cname}】退回到公海?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.approvalBatch, {id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleBatTransfer() {
      this.ids = this.selectedRowKeys
      this.assignTitle = '批量转让'
      this.assignVisible = true
    },
    handleBatBack() {
      console.log("批量操作对象：" ,this.selectedRowKeys);
      var cname = this.selectedRowKeys;
      this.$confirm(`确定将【${cname}】退回到公海?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.batbatch+"?ids="+this.selectedRowKeys.join(','), {ids: this.selectedRowKeys.join(',')}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleBatAddFollower() {
      this.ids = this.selectedRowKeys
      this.followTitle = '批量添加跟进人'
      this.visible = true
    },
    handDel({id,cname}) {
      this.$confirm(`确定删除${cname}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.delbyid, {id: id}).then(res => {
            this.$message({
                type: 'success',
                message: res.message
            })
            this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleSendEmail() {
      this.row = this.selectionRows[0]
      this.emailVisible = true
    },
    handleSendMsg() {
      this.row = this.selectionRows[0]
      this.msgVisible = true
    },
    handleFrozen(row) {
      this.$confirm(`确定将【${row.name}】冻结?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.fronzenUrl, {id:row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handlTransfer(row) {
      this.row = row
      this.ids = [row.id]
      this.assignTitle = '转让'
      this.assignVisible = true
    },
    handlAddRecord() {

    },
    handlAddFollower(row) {
      this.row = row
      this.ids = [row.id]
      this.followTitle = '添加跟进人'
      this.visible = true
    },


    handleReset(){
      this.queryForm = {}
      this.queryForm.cusId = this.$route.query.id
      this.ipagination.currentPage = 1

      this.handleQuery()
    },
  },
  created(){
    //初始化国家
    this.$set(this.queryForm,'tag','1');
    this.queryForm.cusId = this.$route.query.id
  },
  computed : {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('customer/modules/ProductModule','0','新增'),
        'dc':this.$btnIsShow('customer/Edit','0','导出产品'),
        'delB':this.$btnIsShow('customer/modules/ProductModule','0','批量删除'),
        'canBaB':this.$btnIsShow('customer/modules/ProductModule','0','批量取消备案'),
        'edit':this.$btnIsShow('customer/modules/ProductModule','1','编辑'),
        'del':this.$btnIsShow('customer/modules/ProductModule','1','删除'),
        'ba':this.$btnIsShow('customer/modules/ProductModule','1','备案'),
        'caba':this.$btnIsShow('customer/modules/ProductModule','1','取消备案'),
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
