<!--待收货-->
<template>
    <div class="main-full-content">
        <el-card class="box-card last_card">
              <div slot="header" class="clearfix flex_wrap_between">
                <span style="font-size: 16px">产品信息</span>
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd" :disabled="order.isEditInvoice=='1' ">新增</el-button>
              </div>
              <el-form class="input_top" ref="form" :model="queryForm" label-width="60px" size="medium">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="产品名称">
                            <el-input v-model="queryForm.name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)" :disabled="order.isEditInvoice=='1' ">编辑</el-button>
                    <el-button type="text" @click="handleDele(scope.row)" :disabled="order.isEditInvoice=='1' ">删除</el-button>
                </template>
            </template>
        </f-table>
        <el-row class="f-p-v-8 bottom_flex">
            <el-col :span="12" class="total_price">
              {{'产品总价：' + order.price}}
            </el-col>
            <el-col class="f-text-right" :span="12">
              <el-pagination
                      v-bind="ipagination"
                      layout="total, sizes, prev, pager, next, jumper"
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange">
              </el-pagination>
            </el-col>
        </el-row>
        </el-card>
        <el-dialog :title="form.id?'编辑产品':'新增产品'" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
                   v-if="dialogFormVisible">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="中文品名" :label-width="formLabelWidth" prop="cusProductId">
                    <el-select v-model="form.cusProductId" placeholder="请选择" clearable filterable @click.native="initCusProduct" @change="getEname"
                               style="width: 100%">
                        <el-option :label="item.label" :value="item.value" v-for="(item,index) in cusProductList"
                                   :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="英文品名" :label-width="formLabelWidth" prop="ename">
                    <el-input v-model="form.ename" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="海关编码" :label-width="formLabelWidth" prop="hscode">
                    <el-input v-model="form.hscode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="税率(%)" :label-width="formLabelWidth" prop="taxRate">
                    <el-input v-model="form.taxRate" autocomplete="off" type="number"></el-input>
                </el-form-item>
                <el-form-item label="数量" :label-width="formLabelWidth" prop="qty">
                    <el-input v-model="form.qty" autocomplete="off" type="number" @change="countTotal()"></el-input>
                </el-form-item>
                <el-form-item label="单位" :label-width="formLabelWidth" prop="unit">
                    <el-input v-model="form.unit" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单价(USD)" :label-width="formLabelWidth" prop="unitValue">
                    <el-input v-model="form.unitValue" autocomplete="off" type="number" @change="countTotal()"></el-input>
                </el-form-item>
                <el-form-item label="总价" :label-width="formLabelWidth" prop="totalValue">
                    <el-input v-model="form.totalValue" autocomplete="off" type="number"></el-input>
                </el-form-item>
                <el-form-item label="箱数(CTNS)" :label-width="formLabelWidth" prop="ctn">
                    <el-input v-model="form.ctn" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="毛重(KGS)" :label-width="formLabelWidth" prop="kg">
                    <el-input v-model="form.kg" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="体积(CMB)" :label-width="formLabelWidth" prop="cmb">
                    <el-input v-model="form.cmb" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="材质" :label-width="formLabelWidth" prop="material">
                    <el-input v-model="form.material" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用途" :label-width="formLabelWidth" prop="purpose">
                    <el-input v-model="form.purpose" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="制造商" :label-width="formLabelWidth" prop="maker">
                    <el-input v-model="form.maker" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="form.remark" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="混装编码" :label-width="formLabelWidth" prop="mixed">
                    <f-select v-model="form.mixed" autocomplete="off" :dict="'mixed'" :is-need="form.mixed"
                              ></f-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {deleteAction, postAction, putAction,getAction} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";
    import {yingwenPattern, zhognwenPattern,zhengshuPattern,zhengshuxiaoshuPartten} from '@/utils/pattern'

    export default {
        name: 'Potential',
        mixins: [QueryMixins],
        props: {
            order: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                rules: {
                    name: [
                        {required: true, message: '请选择中文品名', trigger: 'change'},
                      /*  {pattern: zhognwenPattern, message: '请输入中文', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},*/
                    ],
                    ename: [
                        {required: true, message: '请输入英文品名', trigger: 'change'},
                        // {pattern: yingwenPattern, message: '请输入英文', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    hscode: [
                        {required: true, message: '请输入海关编码', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    taxRate: [
                        {required: true, message: '请输入税率', trigger: 'change'},
                        {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
                    ],
                    material: [
                        {required: true, message: '请输入材质', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    purpose: [
                        {required: true, message: '请输入用途', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    maker: [
                        {required: true, message: '请输入厂家', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    remark: [
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    unitValue: [
                        {required: true, message: '请输入单价', trigger: 'change'},
                        {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
                    ],
                    totalValue: [
                        {required: true, message: '请输入总价', trigger: 'change'},
                        {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
                    ],
                    qty: [
                        {required: true, message: '请输入数量', trigger: 'change'},
                        {pattern: zhengshuPattern, message: '请输入正整数', trigger: 'change'}
                    ],
                    ctn: [
                        {pattern: zhengshuPattern, message: '请输入正整数', trigger: 'change'}
                    ],
                    kg: [
                        {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
                    ],
                    cmb: [
                        {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
                    ],
                },
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '100px',
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'name',
                        label: '中文品名',
                        sortable: 'custom',

                    },
                    {
                        prop: 'ename',
                        label: '英文品名',
                        sortable: 'custom',

                    },
                    {
                        prop: 'hscode',
                        label: '税号',
                        sortable: 'custom',

                    },
                    {
                        prop: 'taxRate',
                        label: '税率',
                        sortable: 'custom',

                    },
                    {
                        prop: 'qty',
                        label: '数量',
                        sortable: 'custom',

                    },
                    {
                        prop: 'unitValue',
                        label: '单价(USD)',
                        sortable: 'custom',

                    },
                    {
                        prop: 'totalValue',
                        label: '总价',
                        sortable: 'custom',

                    },
                    {
                        prop: 'ctn',
                        label: '箱数',
                        sortable: 'custom',

                    },
                    {
                        prop: 'kg',
                        label: '毛重',
                        sortable: 'custom',

                    },
                    {
                        prop: 'cmb',
                        label: '体积',
                        sortable: 'custom',

                    },
                    {
                        prop: 'material',
                        label: '材质',
                        sortable: 'custom',

                    },
                    {
                        prop: 'purpose',
                        label: '用途',
                        sortable: 'custom',

                    },
                    {
                        prop: 'mixed',
                        label: '混装编码',
                        sortable: 'custom',

                    }, {
                        prop: 'remark',
                        label: '备注',
                        sortable: 'custom',

                    },
                ],
                url: {
                    list: '/order/kdOrderGoods/list',
                    add: '/order/kdOrderGoods/add',
                    edit: '/order/kdOrderGoods/edit',
                    getById: '/order/kdOrderGoods/queryById',
                    delete: '/order/kdOrderGoods/delete',
                    initCusProduct: '/customer/product/getList',
                },
                cusProductList: [],
                cusProductMap:{},
            }
        },
        methods: {

            countTotal(){
                this.$set(this.form, 'totalValue', this.form.qty * this.form.unitValue);
            },
            //初始化客户的产品
            initCusProduct() {
                if (this.cusProductList.length >= 1) {
                    return;
                } else {
                    //获取发货人列表
                    getAction(`${this.url.initCusProduct}?cusId=${this.order.cusId}`)
                        .then(res => {
                            if (res.success) {
                                let list = res.result;
                                for (var i = 0; i < list.length; i++) {
                                    let value = list[i].id;
                                    let label = list[i].cname;
                                    this.cusProductList.push({value, label});
                                }
                                this.cusProductMap = this.listToMap(res.result, 'id');
                            } else {
                                this.$message.warning('获取客户产品列表失败')
                            }
                        })
                }
            },
            getEname(val){
                if (this.cusProductMap[val]) {
                    this.form.name = this.cusProductMap[val]['cname']
                    this.$set(this.form, 'ename', this.cusProductMap[val]['ename']);
                }
            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.form = JSON.parse(JSON.stringify(row));
                console.log('this.form', this.form)
                this.dialogFormVisible = true;
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                        this.$emit('getOrderData');
                                    }
                                })
                        } else {
                            this.form.orderNo = this.order.orderNo;
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                        this.$emit('getOrderData');
                                    }
                                })
                        }
                    }
                })

            },
            handleDele(row) {
                this.$confirm(`确定删除产品`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery();
                        this.$emit('getOrderData');
                    })
                }).catch(() => {

                })
            },
            handleReset() {
                this.queryForm = {};
                this.queryForm.orderNo = this.order.orderNo;
                this.ipagination.currentPage = 1
                this.handleQuery();
                this.$emit('getOrderData');
            }
        }, created() {
            console.log('this.order', this.order)
        this.isorter.prop = 'createTime';
        this.isorter.order = 'asc';
            if (this.order) {

                this.queryForm.orderNo = this.order.orderNo;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
