<template>
  <el-card class="box-card">
      <div class="title_tab_text">柜子日志记录</div>
      <div style="display: flex;justify-content: space-between;">
          <div style="display: flex;flex-wrap:wrap;">
              <div style="display: flex;color: #666666;" v-for="(item, i) in infomationList" :key="i">
                  <div class="grid-content bg-purple margin_2">{{item.content}}</div>
                  <div v-if="i!==infomationList.length-1" class="grid-content bg-purple margin_2">-------></div>
              </div>
          </div>
      </div>
    <!-- <el-card class="box-card"> -->
<!--      <div class="f-p-b-15">-->
<!--        <el-button-->
<!--          type="primary"-->
<!--          icon="el-icon-download"-->
<!--          @click="handleExportXls"-->
<!--          size="small" :disabled="isShow"-->
<!--          >导出</el-button-->
<!--        >-->
<!--        <el-button type="primary" size="medium" @click="syncOrderLogOld">手动同步历史操作记录</el-button>-->
<!--      </div>-->
      <f-table
        v-loading="loading"
        ref="GTable"
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
<!--        <template v-slot="scope">-->
<!--          <template v-if="scope.column.property   ==='createBy'">-->
<!--            <span>{{parseDict(scope.row.createBy,'sys_create_by')}}</span>-->
<!--          </template>-->
<!--        </template>-->
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    <!-- </el-card> -->
  </el-card>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { getAction } from "../../../api/manage";
import {setRouteQuery} from "@/utils/util";
import cabinetRoute from "@/views/operation/cabinet/cabinetRoute.vue";

export default {
  name: "YundangLogModule",
  mixins: [QueryMixins],
  components: {cabinetRoute},


  data() {
    return {


      columns: [

        {
          prop: "type",
          label: "类型",
        },
        {
          prop: "bookNo",
          label: "订舱号",

        },
        {
          prop: "statusCode",
          label: "状态码",
        },
          {
              prop: "statusDesc",
              label: "状态码中文",
          },
          {
              prop: "statusCodeStr",
              label: "状态码翻译",
          },
          {
              prop: "isPre",
              label: "是否预计",
          },
          {
              prop: "createTime",
              label: "创建时间",
          },
          {
              prop: "shipName",
              label: "船名",
          },
          {
              prop: "voy",
              label: "航次",
          },
          {
              prop: "statusTime",
              label: "状态时间",
          },
          {
              prop: "jsonStr",
              label: "json数据",
              overFlow:true
          },
      ],
      url: {
        list: "/operation/container/getDingyueGuiziLog",
          infomation: '/operation/container/infomation',

          // syncOrderLogOld: "/interface/ttOrder/syncOsOrderLogOld",
        // exportXlsUrl: "/file/export/excel/downloadOsOrderLogExcel",
      },
      isUsePost:true,
        infomationList: [],

    };
  },
  methods: {
      initInfomation() {
          let id = this.$route.query.id;
          let that = this;
          console.log(id)
          if (id) {
              getAction(this.url.infomation, {containerId: this.$route.query.id})
                  .then(res => {
                      console.log(res)
                      // console.log(res.result['order'])
                      if (res.result['order'] != null) {
                          that.infomation = res.result['order']
                      } else {
                          that.infomation = {}
                      }

                      if (res.result['list'] != null) {
                          that.infomationList = res.result['list']
                      } else {
                          that.infomationList = []
                      }

                      if (res.result['osContainer'] != null) {
                          // 初始值的保存
                          that.addCabinetForm = res.result['osContainer'];

                          setRouteQuery(that.addCabinetForm.week, that.$route.fullPath);
                          that.oldBookNo = that.addCabinetForm.bookNo || '';
                          that.addCabinetFormTwo = res.result['osContainer'];
                          that.oldOsContainer.shipCompanyId = res.result['osContainer'].shipCompanyId;
                          that.oldOsContainer.shipNo = res.result['osContainer'].shipNo;
                          that.oldOsContainer.containerNo = res.result['osContainer'].containerNo;
                          that.oldOsContainer.pol = res.result['osContainer'].pol;
                          that.oldOsContainer.podEnd = res.result['osContainer'].podEnd;
                          that.oldOsContainer.etd = res.result['osContainer'].etd;
                          that.oldOsContainer.loadTime = res.result['osContainer'].loadTime;
                          that.oldOsContainer.bookAgentId = res.result['osContainer'].bookAgentId;
                          that.oldOsContainer.cabinetCategory = res.result['osContainer'].cabinetCategory;
                      }
                      if (res.result['osContainerInfo'] != null) {
                          that.addCabinetFormThree = res.result['osContainerInfo'];
                          //回显ams 单号
                          if (res.result['osContainerInfo'].amsOrderNo) {
                              this.$set(this.addCabinetFormThree, 'amsOrderNoList', res.result['osContainerInfo'].amsOrderNo.split(','));
                          } else {
                              this.$set(this.addCabinetFormThree, 'amsOrderNoList', []);
                          }
                          // 初始值的保存
                          that.oldOsContainer.si = res.result['osContainer'].si;
                          that.oldOsContainer.isAms = res.result['osContainer'].isAms;
                          that.oldOsContainer.isAmsMore = res.result['osContainer'].isAmsMore;
                          that.oldOsContainer.isCangdan = res.result['osContainer'].isCangdan;
                          that.oldOsContainer.contract = res.result['osContainer'].contract;
                          that.oldOsContainer.mianGuiQi = res.result['osContainer'].mianGuiQi;
                          that.oldOsContainer.huanDanFee = res.result['osContainer'].huanDanFee;
                          that.oldOsContainer.podEndFee = res.result['osContainer'].podEndFee;
                      }
                      // 文件信息中第一行的初始值(如果之前有保存-直接显示保存的值，没有保存显示order中的对应值作为初始值)
                      if (res.result['osContainerInfo'] == null || (res.result['osContainerInfo'].ctn == null && res.result['osContainerInfo'].kg == null
                          && res.result['osContainerInfo'].cmb == null && res.result['osContainerInfo'].realCtn == null
                          && res.result['osContainerInfo'].realKg == null && res.result['osContainerInfo'].realCmb == null)) {
                          this.$set(that.addCabinetFormThree, 'ctn', (res.result['order'] && res.result['order'].ctn) || 0);
                          this.$set(that.addCabinetFormThree, 'kg', (res.result['order'] && res.result['order'].kg) || 0);
                          this.$set(that.addCabinetFormThree, 'cmb', (res.result['order'] && res.result['order'].cmb) || 0);
                          this.$set(that.addCabinetFormThree, 'realCtn', (res.result['order'] && res.result['order'].realCtn) || 0);
                          this.$set(that.addCabinetFormThree, 'realKg', (res.result['order'] && res.result['order'].realKg) || 0);
                          this.$set(that.addCabinetFormThree, 'realCmb', (res.result['order'] && res.result['order'].realCmb) || 0);
                          console.log('文件信息中第一行的初始值赋值', that.addCabinetFormThree);
                      }

                      if (this.$refs['addCabinetForm']) {
                          this.$refs['addCabinetForm'].clearValidate();
                      }
                      if (this.$refs['addCabinetFormTwo']) {
                          this.$refs['addCabinetFormTwo'].clearValidate();
                      }
                      if (this.$refs['addCabinetFormThree']) {
                          this.$refs['addCabinetFormThree'].clearValidate();
                      }
                  })
          }
      },





    initVue() {
        //获取url里的参数
      this.queryForm.containerId = this.$route.query.id;
      this.initInfomation();
      this.handleQuery();
    },
  },


  mounted() {
  },
  created() {
    this.initVue();
  },
};
</script>

<style lang="scss" scoped>
.circle {
  width: 160px;
  height: 84px;
  margin-top: 10px;
  border: 5px solid #c0c4cc;
  border-radius: 0 30% 30% 0/50%;
  border-left: none;
  position: relative;
  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    width: 36px;
    height: 36px;
    transform: translate(55%, -50%);
    background-color: #fff;
    border-radius: 50%;
    color: #c0c4cc;
    text-align: center;
    font-size: 12px;
    border: 8px solid #ffffff;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-icon-success {
      font-size: 24px;
      color: #cecece;
    }
    .tips {
      display: flex;
      position: absolute;
      right: 30px;
      font-size: 12px;
      color: #c0c4cc;
      width: 80px;
      padding-right: 8px;
      flex-direction: column;
      text-align: center;
      > span:first-child {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &.finish {
    border-color: #409eff;
    .icon {
      .el-icon-success {
        color: #409eff;
      }
      .tips {
        color: #409eff;
      }
    }
  }
}
.custom-steps {
  padding: 40px;
  display: flex;
  .left-steps {
    display: flex !important;
    width: 50px !important;
    /*width: calc(~"100% - 160px");*/
    /*float: left;*/
  }
  .right-steps {
    /*float: right;*/
    margin-left: -140px;
  }
  ::v-deep.el-step__icon {
    /*background: #f6f6f6;*/
  }
  ::v-deep.el-step__icon-inner {
    font-size: 36px;
  }
  ::v-deep.el-step__line {
    background: transparent;
    border-top: 5px solid;
    .el-step__line-inner {
      border-width: 0 !important;
    }
  }
  ::v-deep.el-step {
    position: relative;
    .el-step__head.is-process {
      color: #c0c4cc;
      border-color: #c0c4cc;
    }
    .el-step__title.is-process {
      color: #c0c4cc;
      font-weight: unset;
    }
  }
}
</style>
