<template>
    <div class="main-full-content">
        <el-card class="box-card">
            <div class="f-p-b-15">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleAdd({})"
                        v-if="buttonShowList.add"
                >新增
                </el-button
                >
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="74vh"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.edit"
                        >编辑</el-button>
                        <el-button type="text" @click="handleDel(scope.row)"
                                   v-if="buttonShowList.del"
                        >删除</el-button>
                    </template>
                </template>
            </f-table>
        </el-card>

        <el-dialog
                :title="form.id ? '编辑收货方' : '新增收货方'"
                v-if="dialogFormVisible"
                :visible.sync="dialogFormVisible"
                center
                width="30%"
                :close-on-click-modal="false"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                        label="联系人"
                        prop="contact"
                        :label-width="formLabelWidth"
                >
                    <el-input v-model="form.contact" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item
                        label="公司名称"
                        prop="name"
                        :label-width="formLabelWidth"
                >
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="tel" :label-width="formLabelWidth">
                    <el-input v-model="form.tel" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
                    <el-input v-model="form.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="国家" prop="country" :label-width="formLabelWidth">
                    <f-select
                            v-model="form.country"
                            :is-need="form.country"
                            v-if="fsee"
                            :dict="'bd_country'"
                            @change="initStateList"
                    >
                    </f-select>
                </el-form-item>
                <el-form-item label="州/省"  :label-width="formLabelWidth">
                    <el-select
                            class="full_width"
                            v-model="form.state"
                            placeholder="请选择"
                            filterable
                            clearable
                            @change="initCityList"
                    >
                        <el-option
                                v-for="item in statelist"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="城市" :label-width="formLabelWidth">
                    <el-select
                            class="full_width"
                            v-model="form.city"
                            placeholder="请选择"
                            filterable
                            clearable
                    >
                        <el-option
                                v-for="item in citylist"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="详细地址"
                        prop="address"
                        :label-width="formLabelWidth"
                >
                    <el-input
                            v-model="form.address"
                            type="textarea"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false"
                >取 消
                </el-button
                >
                <el-button
                        size="medium"
                        type="primary"
                        @click="saveShipper('form')"
                        :disabled="btnDisable"
                >确 定
                </el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {emailPattern, mobilePattern} from "@/utils/pattern";
    import {deleteAction, getAction, httpAction} from "@/api/manage";
    import {getcityList, getstateList} from "../../../api/manage";

    export default {
        name: "ConsignorModule",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                fsee: false,
                countrylist: [],
                statelist: [],
                citylist: [],
                btnDisable: false,
                row: {},
                formLabelWidth: "120px",
                dialogFormVisible: false,
                form: {},
                //校验
                rules: {
                    contact: [
                        {required: true, message: "请输入联系人", trigger: "blur"},
                        {max: 50, message: "长度不能超过50", trigger: "change"},
                    ],
                    country: [{required: true, message: "请选择国家", trigger: "change"}],
                    // state: [{required: true, message: "请选择省/州", trigger: "change"}],
                    // city: [{required: true, message: "请选择城市", trigger: "change"}],
                    address: [
                        {required: true, message: "请输入地址", trigger: "blur"},
                        {max: 250, message: "长度不能超过250", trigger: "change"},
                    ],
                    tel: [
                        {required: true, message: "请输入手机号", trigger: "blur"},
                        // {
                        //     pattern: mobilePattern,
                        //     message: "请输入正确的手机号",
                        //     trigger: "change",
                        // },
                    ],
                    email: [
                        {required: true, message: "请输入邮箱", trigger: "blur"},
                        {
                            pattern: emailPattern,
                            message: "请输入正确的邮箱",
                            trigger: "change",
                        },
                    ],
                },
                visible: false,
                columns: [
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "contact",
                        label: "联系人",
                        sortable: "custom",
                    },
                    {
                        prop: "name",
                        label: "公司名",
                        sortable: "custom",
                    },
                    {
                        prop: "tel",
                        label: "手机号",
                        sortable: "custom",
                    },
                    {
                        prop: "email",
                        label: "邮箱",
                        sortable: "custom",
                    },
                    {
                        prop: "address",
                        label: "收货地址",
                        sortable: "custom",
                    },
                ],
                url: {
                    list: "/customer/receive/list",
                    add: "/customer/receive/add",
                    edit: "/customer/receive/edit",
                    delbyid: "/customer/receive/delete",
                    getbyid: "/customer/receive/queryById",
                },
            };
        },
        created() {
            this.queryForm.cusId = this.$route.query.id;
            this.queryForm.initCountry = 1;
        },
        methods: {
            initStateList(val) {
                this.statelist = [];
                this.citylist = [];
                if (val) {
                    getstateList({cnName: val}).then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.records.length; i++) {
                                let obj = {};
                                obj.value = res.result.records[i].cname;
                                obj.label =
                                    res.result.records[i].code + "-" + res.result.records[i].cname;
                                this.statelist.push(obj);
                            }
                        }
                    });
                } else {
                    // this.form.city = '';
                    // this.form.state = '';
                    // this.form.bdState = '';
                    // this.form.bdCity = '';
                }
            },
            initCityList(val) {
                this.citylist = [];
                if (val) {
                    getcityList({cname: val}).then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.records.length; i++) {
                                let obj = {};
                                obj.value = res.result.records[i].cname;
                                obj.label =
                                    res.result.records[i].code + "-" + res.result.records[i].cname;
                                this.citylist.push(obj);
                            }
                        }
                    });
                } else {
                }
            },
            //初始化国家列表

            handleDel({id, name}) {
                this.$confirm(`确定删除${name}?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delbyid, {id}).then((res) => {
                        this.$message.success(res.message);
                        this.handleQuery();
                    });
                });
            },
            handleAdd() {
                this.fsee = false;
                this.form = {};
                this.fsee = true;
                this.dialogFormVisible = true;
                this.btnDisable = false;
            },
            handleEdit(row) {
                this.fsee = false;
                this.btnDisable = false;
                getAction(this.url.getbyid, {id: row.id}).then((res) => {
                    this.form = res.result;
                    this.fsee = true;
                });
                this.dialogFormVisible = true;
            },
            saveShipper(formName) {
                this.btnDisable = true;
                this.btnDisable = true;
                let url = this.url.add;
                let method = "post";
                this.form.cusId = this.$route.query.id;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.form.id) {
                            url = this.url.edit;
                            method = "put";
                        }
                        httpAction(url, method, this.form)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                } else {
                                    this.btnDisable = false;
                                }
                            });
                    } else {
                        this.btnDisable = false;
                    }
                });
            },
        },
      computed : {
        buttonShowList() {
          return {
            'add':this.$btnIsShow('customer/modules/ReceiveModule','0','新增'),
            'edit':this.$btnIsShow('customer/modules/ReceiveModule','1','编辑'),
            'del':this.$btnIsShow('customer/modules/ReceiveModule','1','删除'),

          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
