<template>
  <el-card class="box-card">
    <div class="title_tab_text">物流信息</div>
    <span v-if="trackNo != null">快递单号：{{ trackNo }}</span>
    <a style="color: #00a0e9" @click.prevent="goPage()" v-if="showMore">更多单号...</a>
    <!-- <el-card class="box-card"> -->
    <el-table :data="tableData" class="c-th-has-bgcolor" style="width: 100%">
      <el-table-column label="创建时间" width="180px">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" width="">
        <template slot-scope="scope">
          <span v-html="scope.row.description"></span>

          <!--                    <el-popover trigger="hover" placement="top">-->
          <!--                        <p>描述: {{ scope.row.description }}</p>-->

          <!--                        <div slot="reference" class="name-wrapper">-->
          <!--                            <el-tag size="medium">{{ scope.row.content }}</el-tag>-->
          <!--                        </div>-->
          <!--                    </el-popover>-->
        </template>
      </el-table-column>
      <el-table-column label="创建人" width="200px">
        <template slot-scope="scope">
          <span>{{ scope.row.createBy }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- </el-card> -->
  </el-card>
</template>

<script>
import {deleteAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import {getAction} from "../../../api/manage";

export default {
  name: "StatusModule",
  mixins: [QueryMixins],
  components: {},
  props: {
    shipRes: {
      type: Object
    },
  },
  data() {
    return {
      disableMixinMounted: true,
      visible: false,
      url: {
        list: "/interface/ttOrder/getStatusList",
        getTrackLogNoByOrderNo: "/interface/ttOrder/getTrackLogNoByOrderNo",
        getTrackInfoList: "operation/17track/push/getTrackInfoList",
        exportXlsUrl: "/file/export/excel/downloadOsOrderStatusExcel",
      },
      tableData: [],
      trackNo: '',
      showMore: false,
      queryCount: 1,
    };
  },
  methods: {
    goPage() {
      this.$router.push(
          "/publicPage/LogisticsTracking?orderNo=" + this.$route.query.orderNo);
    },
    sync17tractDataInStatusList() {
      this.queryCount = this.queryCount + 1;
      if (!this.shipRes) {
        this.handleQuery();
      }
      // getAction(this.url.getTrackInfoList + "?orderNo=" + this.queryForm.orderNo
      //     + '&queryCount=' + this.queryCount).then((res) => {
      //   this.initTrackNoList();
      //
      // });
    },
    handleEdit(row) {
      this.row = row;
      this.visible = true;
    },
    initStatusList() {
      getAction(this.url.list, this.queryForm).then((res) => {
        this.statusList = res.result;
        this.visible = true;
      });
    },
    initTrackNoList() {
      getAction(this.url.getTrackLogNoByOrderNo + "?orderNo=" + this.$route.query.orderNo).then((res) => {
        this.trackNo = res.result.trackNo;
        this.showMore = res.result.showMore;
      });
    },
    initVue() {
      this.queryForm.orderNo = this.$route.query.orderNo;
      console.log("created,status初始化....");
      this.handleQuery();
    },

    handleThaw(row) {
      this.$confirm(`确定解冻${row.name}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
          .then(() => {
            deleteAction(this.url.jiedong, {id: row.id}).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();
            });
          });
    },
    show(item) {
      this.visible = false;
      item.visible = true;
      console.log("咖啡机卡德加罚款1", item.visible);
      this.visible = true;
    },
    hide(item) {
      this.visible = false;
      item.visible = false;
      console.log("咖啡机卡德加罚款2", item);
      this.visible = true;
    },
  },
  created() {
    // this.disableMixinMounted = true;
    this.queryForm.orderNo = this.$route.query.orderNo;
    this.queryForm.queryCount = this.queryCount;
    this.sync17tractDataInStatusList()
    // this.initStatusList();
  },
  watch: {
    shipRes(val) {
      if (val) {
        console.log("父亲传递过来status的数据：", val);

        this.tableData = val.osOrderStatusList;

      } else {
        this.handleQuery();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 800;
}

.item {
  display: flex;
  margin-bottom: 10px;
}

.time {
  margin-right: 50px;
}

.bb {
  position: absolute;
  max-width: 800px;
  width: 1000px;
  max-height: 100px;
  bottom: 39px;
  overflow-y: auto;
  border: 1px solid;
  border-radius: 4px;
  background-color: #fff;
}
</style>
