<template>
  <!-- 计算费用配置弹窗 -->
  <div>
    <el-dialog title="计算费用配置" :visible.sync="visible" width="70%" :close-on-click-modal="false" :destroy-on-close="destroyOnClose"
      :before-close="() => { handleClose('0') }">
      <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleCreateFee" :disabled="selectionRows.length === 0">选择费用类型立即计算</el-button>
      <el-form class="input_top" ref="form" :model="queryForm" label-width="auto" size="medium" @submit.native.prevent>
        <el-card class="box-card">
          <el-row>
            <el-col :span="5">
              <el-form-item label=" 费用名称">
                <el-input v-model="queryForm.feeName" @keyup.enter.native="handleSearch" placeholder="支持模糊查询"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label-width="30px">
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <el-form :model="form" :rules="rules" ref="form" label-width="auto" style="height: 100%;">
        <el-card class="box-card">
          <f-table :columns="columns" :data="tableData" @selection-change="handleSelectionChange">
            <template #default="scope">
              <template v-if="scope.column.property === 'action'">
                <el-button v-if="scope.row.isBatch == '1'" type="text" @click="handleBatch(scope.row)">分批</el-button>
              </template>
              <template v-else-if="scope.column.property === 'isChoose'">
                <el-radio-group v-model="scope.row.isChoose" size="small">
                  <el-radio label="1" :disabled="scope.row.isCanUpdate==false">是</el-radio>
                  <el-radio label="0" :disabled="scope.row.isCanUpdate==false">否</el-radio>
                </el-radio-group>
              </template>
            </template>
          </f-table>
        </el-card>
      </el-form>
      <template #footer>
        <el-button size="small" @click="handleClose('0')">取消</el-button>
        <el-button type="primary" size="small" @click="handleSubmit">
          确定
        </el-button>
      </template>
    </el-dialog>
    <!-- 分批量弹窗 -->
    <el-dialog title="分批" :visible.sync="batchVisible" :close-on-click-modal="false" :destroy-on-close="destroyOnClose"
      :before-close="() => { handleClose('1') }">
      <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd">新增一行
      </el-button>
      <!--<el-button type="primary" size="medium" class="c-theme-error-button" @click="handleBatDel"-->
        <!--:disabled="selectionRows.length === 0">批量删除-->
      <!--</el-button>-->
      <el-form class="f-m-t-10" :model="formBatch" :rules="rules" ref="formBatch" label-width="0px"
        style="height: 100%;">
        <el-card class="box-card">
          <f-table :columns="columnsBatch" :data="formBatch.tableDataBatch" @selection-change="handleSelectionChange">
            <template #default="scope">
              <template v-if="scope.column.property === 'workDate'">
                <el-form-item :prop="'tableDataBatch.' + scope.$index + '.workDate'" label-width="0px" :rules="rules.workDate">
                  <el-date-picker v-model="scope.row.workDate" type="datetime" size="small" placeholder="请选择时间"
                    default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>
              </template>
              <template v-else-if="scope.column.property === 'num'">
                <el-form-item :prop="'tableDataBatch.' + scope.$index + '.num'" label-width="0px" :rules="rules.num">
                  <el-input v-model.trim="scope.row.num" type="number" :disabled="scope.row.isCreateFee =='1'"></el-input>
                </el-form-item>
              </template>
              <template v-else-if="scope.column.property === 'unit'">
                <el-form-item :prop="'tableDataBatch.' + scope.$index + '.unit'" label-width="0px" :rules="rules.unit">
                  <el-input v-model.trim="scope.row.unit" :disabled="scope.row.unit.length > 0"></el-input>
                </el-form-item>
              </template>
            </template>
          </f-table>
        </el-card>
      </el-form>
      <template #footer>
        <el-button size="small" @click="handleClose('1')">取消</el-button>
        <el-button type="primary" size="small" @click="handleSubmitBatch">
          确定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>

import { postAction, getAction} from '@/api/manage';
import QueryMixins from "@/mixins/QueryMixins";
export default {
  components: {
  },
  props: {
    row: {
      type: Object,
      default: () => { }
    },
    visible: {
      type: Boolean,
    },
    // 当关闭 Dialog 时，销毁其中的元素
    destroyOnClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      batchVisible: false,
      queryForm: {},
      rules: {
        unit: [
          { required: true, message: '请输入单位', trigger: "change" },
        ],
        num: [
          { required: true, message: '请输入数量', trigger: "change" },
        ],
        workDate: [
          { required: true, message: '请选择操作时间', trigger: "change" },
        ],
      },
      form: {},
      url: {
        queryChooseListByOrderNo: '/finance/fee/order/queryChooseListByOrderNo',
        save: '/finance/fee/order/updateOrderFeeConfig',
        queryWorkByFeeOrderId: '/finance/feeWorkRecord/queryWorkByFeeOrderId',
        saveBatch: '/finance/feeWorkRecord/update',
        createOrderFeeByNo: '/finance/fee/order/createOrderFeeByNo',
        delBatch: '/finance/feeWorkRecord/del'
      },
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "feeName",
          label: '费用名称',
        },
        {
          prop: "price",
          label: '报价',
        },
        {
          prop: "feeUse",
          label: '应收/成本/应付',
        },
        // {
        //   prop: "isChoose",
        //   label: '是否可选',
        // },
        {
          prop: "action",
          label: '操作',
        },
        {
          prop: "calculationWays",
          label: '费用规则',
          width: 777
        },
      ],
      tableData: [],
      formBatch: {tableDataBatch: []},
      columnsBatch: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "workDate",
          label: '操作时间',
          headerType: 'require',
        },
        {
          prop: "num",
          label: '操作数量',
          headerType: 'require',
        },
        {
          prop: "unit",
          label: '单位',
          headerType: 'require',
        },
      ],
      selectionRows: [],
      selectedRowKeys: [],
      rowBatch: {},
      unit: '',
      feeInfoId: '',
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getList();
      }
    },
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    handleBatDel() {
      let deleteIndex = [];
      this.selectionRows && this.selectionRows.forEach((item, index) => {
        if (item.id) {

        } else {
          deleteIndex.push(item.index);
        }
      })
      deleteIndex = deleteIndex.sort((a, b) => a - b);
      for (let i = deleteIndex.length - 1; i >= 0; i--) {
        this.formBatch.tableDataBatch.splice(deleteIndex[i], 1);
      };
      if (this.selectedRowKeys && this.selectedRowKeys.length) {
        postAction(this.url.delBatch, this.selectedRowKeys).then(res => {
          this.$message.success(res.message);
          this.handleBatch(this.rowBatch)
        })
      }
    },
    handleAdd() {
      this.formBatch.tableDataBatch.push({ feeOrderId: this.rowBatch.id, index: this.formBatch.tableDataBatch.length, unit: this.unit})
    },
    handleRuleName() {
      if (!this.form.type) {
        this.$message.warning('请先选择活动类型');
      }
    },
    handleOption(row, index) {
      if (!row.fieldDictId) {
        this.$message.warning('请先选择规则名');
      }
    },
    handleSearch() {
      this.getList();
    },
    handleReset() {
      this.queryForm = {};
    },
    // 获取计算配置内容接口
    getList() {
      getAction(this.url.queryChooseListByOrderNo, { orderNo: this.row.orderNo, feeName: this.queryForm.feeName,}).then(res => {
        this.tableData = res.result;
        this.tableData.forEach((item, index) => {
          item.orderNo = this.row.orderNo;
        })
      })
    },
    // 分批
    handleBatch(row) {
      this.rowBatch = JSON.parse(JSON.stringify(row));
      this.formBatch.tableDataBatch = [];
      if (row.id) {
        getAction(this.url.queryWorkByFeeOrderId, { feeOrderId: row.id }).then(res => {
          this.formBatch.tableDataBatch = res.result || [];
          this.formBatch.tableDataBatch && this.formBatch.tableDataBatch.forEach((item, index) => {
            item.index = index;
          })
          this.unit = row.unit
          this.feeInfoId = row.feeInfoId
          this.batchVisible = true;
        }).catch(err => {
          this.batchVisible = true;
        })
      } else {
        this.$message.warning('请先把当前活动是否可选保存之后再进行配置操作');
      }
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.commit("setFullscreenLoading", true);
          postAction(this.url.save, this.tableData).then((res) => {
            this.getList();
            this.$store.commit("setFullscreenLoading", false);
            this.$message.success(res.message);
            this.handleClose(0);
          }).catch(err => {
            this.$store.commit("setFullscreenLoading", false);
          });
        }
      });
    },
    handleSubmitBatch() {
      this.$refs.formBatch.validate((valid) => {
        if (valid) {
          this.$confirm(`请审核输入的作业数量，保存后会实时计算新加入数量的费用？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success",
            closeOnClickModal: false,
          }).then(() => {
            postAction(
                    this.url.saveBatch + "?orderNo=" + this.$route.query.orderNo + "&feeInfoId=" +this.feeInfoId,
                    this.formBatch.tableDataBatch
            ).then((res) => {
              this.$store.commit("setFullscreenLoading", false);
              this.batchVisible = false;
              this.$message.success(res.message);
            }).catch(err => {
              this.$store.commit("setFullscreenLoading", false);
            });
          });
        }
      });
    },
    handleCreateFee() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let createFeeInfoIdIndex = [];
          this.selectionRows && this.selectionRows.forEach((item, index) => {
              createFeeInfoIdIndex.push(item.feeInfoId)
          })
          const params={
            orderNo: this.$route.query.orderNo,
            idList:createFeeInfoIdIndex
          }
          this.$store.commit("setFullscreenLoading", true);
          postAction(
                  this.url.createOrderFeeByNo ,params
          ).then((res) => {
            this.$store.commit("setFullscreenLoading", false);
            this.batchVisible = false;
            this.$message.success(res.message);
          }).catch(err => {
            this.$store.commit("setFullscreenLoading", false);
          });
        }
      });
    },
    handleClose(type) {
      if (type == '0') {
        this.$emit("update:visible", false);
        this.tableData = [];
      } else if (type == '1') {
        this.batchVisible = false;
        this.formBatch = {tableDataBatch: []};
      }
    },
    handleSelectionChange(selection) { // 多选
      let arr = []
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['id'])
      }
      this.selectionRows = selection
      this.selectedRowKeys = [...arr];
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
