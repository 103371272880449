<template>
    <el-dialog
        title="发送邮件"
        :visible="visible"
        width="50%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="94px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="receiver" label="收件人">
                        <el-input v-model="form.receiver" placeholder="多个用;分隔"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="cc" label="推送人邮箱">
                        <el-input v-model="form.cc"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="esTitle" label="标题">
                        <el-input v-model="form.esTitle"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="esContent" label="内容" ref="content">
                        <!-- <el-input v-model="form.text" type="textarea" rows="5"></el-input> -->
                        <!-- 富文本编辑 -->
                        <quill-editor
                                ref="text"
                                v-model="form.esContent"
                                class="myQuillEditor"
                                :options="quillOption"
                        />
                        <!-- ueditor方式 -->
                        <!-- <vue-ueditor-wrap v-model="form.text" :config="myConfig" @beforeInit="addCustomButtom"></vue-ueditor-wrap> -->
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="fileList" label="附件">
                        <f-upload v-model="form.fileList" :dir="'customer'">

                        </f-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
import {quillEditor} from "vue-quill-editor";
import quillConfig from "../../../utils/quill-config"
import {getAction} from "../../../api/manage";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// import VueUeditorWrap from "vue-ueditor-wrap";
export default {
    name: 'EmailGroupDialog',
    components:{quillEditor},
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        },
      userName:{
        type :String
      }
    },
    data() {
        return {
            content: '<h2>hello quill-editor</h2>',
            quillOption: quillConfig,
            form: {},
            options: [],
            rules: {
                receiver: [
                    { required: true, message: '请输入收件人', trigger: 'blur' },
                ],
                esContent: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                ],
                esTitle: [
                    { required: true, message: '请输入标题', trigger: 'blur' },
                ],
            },
            url: {
                add: '/sys/role/add',
                edit: '/sys/role/edit',
                sendEmail:'/interface/ttOrder/sendEmail',

            }
        }
    },
    watch: {
        visible(val) {
            if(val && this.row) {
                for (var name in this.row){
                    this.$set(this.form,name,this.row[name])
                }
            } else {
                this.$set(this,'form',{});
                this.$set(this.form,'fileList',[]);
            }
            if (this.form.fileList && this.form.fileList.length>0){

            }else {
                this.$set(this.form,'fileList',[]);

            }


        },
        esContent(val){
            if (val && val.length > 0){
                if (this.$refs['content']){
                    this.$refs['content'].clearValidate();
                }
            }
        }
    },
    computed:{
        esContent(){
            return this.form.esContent;
        }
    },
    methods: {
        handleSubmit() {
            let url = this.url.sendEmail
            let method = 'POST'
            /*封装esReceiver*/
            this.$set(this.form,'esReceiver',this.form.receiver.split(';'))
            if (this.form.cc){
                this.$set(this.form,'esCc',this.form.cc.split(';') || '')
            }

            this.$set(this.form,'file',{})

            if (this.form.fileList && this.form.fileList.length>0){
                this.form.fileList.forEach(ff=>{
                    this.$set(this.form.file,ff.name,ff.url)
                })
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    httpAction(url, method, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose()
                        this.$emit('ok')
                    })
                }
            })
        },
        handleClose() {
            this.$emit('update:visible', false)
            this.$refs.form.resetFields()
        }
    },

}
</script>

<style lang="scss" scoped>
::v-deep
    .el-dialog__body {
        padding: 20px 30px 20px 24px;
    }
.ql-editor{
  height:200px;
}
</style>
