<template>
    <div class="main-full-content">
        <el-divider class="acb" content-position="left">柜子编辑</el-divider>
        <el-tabs tab-position="left" @tab-click="handleClick" v-model="clickLabel">
            <el-tab-pane
                v-for="(tab, index) in tabs"
                :key="index"
                :label="tab.label"
                :lazy="true"
                v-if="isShow && tab.label!='子账户' || tab.label == '基本信息' || ( isShow && tab.label=='子账户' && !parentId)"
                :name="tab.label"
            >
                <component :is="tab.component" v-if="tab.show" @changeTabs="changeTabs"></component>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>

import {getAction} from "@/api/manage";
import ToSailBasicModule from "@/views/operation/cabinet/ToSailBasicModule";
import DealMoudule from "@/views/operation/cabinet/DealMoudule";
// import ProductModule from "@/views/operation/cabinet/ProductModule";
import ISFModule from "@/views/docpage/modules/ISFModule";
import MBlModule from "@/views/docpage/modules/MBlModule";
import ConLogModule from "@/views/operation/cabinet/ConLogModule";
import ConStatusModule from "@/views/operation/cabinet/ConStatusModule.vue";
export default {
    name: 'Edit',
    components: {
        ToSailBasicModule,
        DealMoudule,
        // ProductModule,
        ISFModule,
        MBlModule,
      ConLogModule,
        ConStatusModule
    },
    data() {
        return {
            originTabs:[],
            tabs: [
                { label: '基本信息', component: 'ToSailBasicModule', show: true },
                { label: '应付账单', component: 'DealMoudule', show: false },
                // { label: '产品信息', component: 'ProductModule', show: false },
                { label: 'ISF', component: 'ISFModule', show: false },
                { label: 'HBL提单', component: 'MBlModule', show: false },
              { label: '操作记录', component: 'ConLogModule', show: false },

              // { label: '银行账户信息', component: 'AccountModule', show: false },
                // { label: '联系人', component: 'AccountantModule', show: false },
                // { label: '文件管理', component: 'FileModule', show: false },
            ],
            tabs2: [
                { label: '基本信息', component: 'ToSailBasicModule', show: true },
                { label: '应付账单', component: 'DealMoudule', show: false },
                // { label: '产品信息', component: 'ProductModule', show: false },
                { label: 'ISF', component: 'ISFModule', show: false },
                { label: 'HBL提单', component: 'MBlModule', show: false },
                { label: '操作记录', component: 'ConLogModule', show: false },
                { label: '物流记录', component: 'ConStatusModule', show: false },
                // { label: '银行账户信息', component: 'AccountModule', show: false },
                // { label: '联系人', component: 'AccountantModule', show: false },
                // { label: '文件管理', component: 'FileModule', show: false },
            ],
          tabs3: [
            { label: '基本信息', component: 'ToSailBasicModule', show: true },
          ],
          clickLabel: '基本信息', //选中的tab
          isShow : this.$route.query.id?true:false,
          parentId:'',
          url:{
              getbyid:'/sys/supplier/queryById',
          }
        }
    },

    watch: {
      '$route': {
          deep: true,
          handler() {
              this.clickLabel = '基本信息';
              this.tabs.forEach((item, index) => {
                item.show = false;
              })
              setTimeout(() => {this.tabs[0].show = true;},50);
          }
      }
    },
    methods:{
        changeTabs(val){
            // console.log("变化之前的tab",this.tabs,this.originTabs,val);
            this.originTabs = [];
            let flag = false;
            this.tabs.forEach(t => {
                if (t.label === '应付账单') {
                    flag = true;
                    if (val){
                        this.originTabs.push(t);
                    }
                }else {
                    this.originTabs.push(t);
                }
            })
            // console.log("隐藏",flag,val)
            if (!flag && val) {
                this.originTabs.splice(1,0,{ label: '应付账单', component: 'DealMoudule', show: false });
            }
            //只可以看基本信息
            let qq = this.$canEditCabinetInfo();
            console.log("是否可以编辑柜子信息", qq);
            if (!qq) {
              this.originTabs = this.tabs3;
            }
            this.tabs = this.originTabs;
            // console.log("变化之后的tab",this.tabs,this.originTabs);
        },
        handleClick(tab){
            console.log('点击选中的tab',tab);

            this.tabs.map(t=>{
                if (t.label !== tab.label){
                    t.show = false;
                }else {
                    t.show = true;
                }
            })
        },
    },
  created() {
      let id = this.$route.query.id;
      // if (id){
      //   getAction(this.url.getbyid,{id:this.$route.query.id})
      //       .then(res=>{
      //         this.parentId = res.result.parentId;
      //       })
      // }
      let flag = this.buttonShowList.see;
      if (flag) {
          this.tabs = this.tabs2;
      }
  },
  computed:{
      buttonShowList() {
        return {
          'see':this.$btnIsShow('operation/cabinet/Edit','0','不给看物流信息就揍你')
        }
      }
  }
}
</script>

<style lang="scss" scoped>
.el-divider--horizontal {
    margin-top: 16px;
    margin-bottom: 20px;
    .el-divider__text {
        font-size: 20px;
    }
}
</style>
