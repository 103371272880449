<template>
  <!-- 柜子航线详情信息时间轴 -->
  <div class="route_box">
    <el-timeline class="trajectory_line" v-if="trajectoryDataListShow.length">
      <el-timeline-item color="#00a329" v-for="(activity, index) in trajectoryDataListShow" :key="index"
        :timestamp="activity.statustime" :hide-timestamp="true">
        <div>
          <div class="time_line_title">{{ activity.statusplace }}</div>
          <div class="time_line_text" :class="activitySub.cancelid == 'Y' ? 'red_text' : ''"
            v-for="(activitySub, indexSub) in activity.subList" :key="indexSub">
            <div class="max_width_50">
              {{ (activitySub.statedescription || activitySub.statusdesc) + (activitySub.isest == 'Y' ? '(预计)' : '') }}
            </div>
            <div class="justify_text width_120">
              {{ activitySub.statustimeShow }}
            </div>
          </div>
          <div v-if="activity.vslname || activity.voy" class="err_message_box">
            <div v-if="activity.vslname">船名：{{ activity.vslname }}</div>
            <div v-if="activity.voy" class="f-m-t-2">
              航次：{{ activity.voy }}
            </div>
          </div>
        </div>
      </el-timeline-item>
    </el-timeline>
    <div v-else-if="loading" class="flex_center"><i class="el-icon-loading"></i></div>
    <div v-else class="flex_center">暂无数据</div>
  </div>
</template>
<script>
import { initSetDataInfo } from "@/utils/util";
import { getAction } from "@/api/manage";
export default {
  components: {},
  props: {
    bookNo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      trajectoryDataList: {
      },
      trajectoryDataListShow: [],
      url: {
        list: '/interface/fbaJob/getContainerSequence'
      }
    };
  },
  watch: {},
  created() {
    this.bookNo && this.getTrajectoryList();
  },
  mounted() { },
  methods: {
    getTrajectoryList() {
      this.loading = true;
      getAction(this.url.list, { bookNo: this.bookNo }).then(res => {
        this.loading = false;
        this.trajectoryDataList = res.result;
        this.initTrajectoryData();
      }).catch(err => {
        this.loading = false;
      })
    },
    // 格式化轨迹数据
    initTrajectoryData() {
      let subList = [];
      this.trajectoryDataListShow = [];
      this.trajectoryDataList.forEach((item, index) => {
        let itemContent = JSON.parse(item.content)
        // if (itemContent.isest == "N") {
        if (subList.length == 0) {
          itemContent.statustimeShow = initSetDataInfo(itemContent.statustime);
          this.trajectoryDataList.newStatedescription =
            itemContent.statedescription || itemContent.statusdesc;
          subList.push(itemContent);
        } else if (
          itemContent.statusplacecd == subList[0].statusplacecd ||
          itemContent.placecd == subList[0].placecd
        ) {
          itemContent.statustimeShow = initSetDataInfo(itemContent.statustime);
          this.trajectoryDataList.newStatedescription =
            itemContent.statedescription || itemContent.statusdesc;
          subList.push(itemContent);
        } else {
          this.trajectoryDataListShow.push({
            statusplacecd: subList[0].statusplacecd,
            statusplace: subList[0].statusplace || subList[0].place,
            vslname: subList[0].vslname,
            voy: subList[0].voy,
            subList: subList,
          });
          subList = [];
          itemContent.statustimeShow = initSetDataInfo(itemContent.statustime);
          this.trajectoryDataList.newStatedescription =
            itemContent.statedescription || itemContent.statusdesc;
          subList.push(itemContent);
        }
        // }
      });
      if (subList.length) {
        this.trajectoryDataListShow.push({
          statusplacecd: subList[0].statusplacecd,
          statusplace: subList[0].statusplace || subList[0].place,
          vslname: subList[0].vslname,
          statustime: subList[0].statustime,
          voy: subList[0].voy,
          subList: subList,
        });
      }
      console.log(
        this.trajectoryDataListShow,
        this.trajectoryDataList,
        "123456"
      );
    },
  },
};
</script>
<style lang="scss">
.route_box {
  max-height: 300px;
  width: 400px;
  overflow-y: scroll;
  ul {
    padding-left: 0 !important;
  }

  .el-timeline-item__content,
  .el-timeline-item__timestamp {
    text-align: left;
  }
  .el-icon-loading {
    font-size: 20px !important;
  }
}

.trajectory_line {
  .el-timeline-item {
    padding-bottom: 15px;
  }

  .el-timeline-item__tail {
    border-left: 2px solid #00a329;
    left: 13px;
  }

  .el-timeline-item__node--normal {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: 10px;
  }
}
</style>
<style lang="scss" scoped>
.route_box {
  z-index: 99999;
  background: white;
  box-shadow: 0 0.142857rem 0.857143rem 0 rgba(0,0,0,0.1);
  border-radius: 5px;
  padding: 20px;

  .info_bot_testa {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .time_line_title {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    line-height: 20px;
    margin-bottom: 2px;
  }

  .time_line_text {
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 500;
    text-align: left;
    color: rgba(21, 21, 33, 0.6);
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .err_message_box {
    padding: 5px;
    background-color: rgba(21, 21, 33, 0.05);
    font-weight: 500;
    font-size: 10px;
    color: rgba(21, 21, 33, 0.7);
  }

  .max_width_50 {
    width: 150px;
    word-break: break-word;
  }
}
</style>
