<template>
  <div style="font-size: 16px;position: relative;">
    <div ref="bsfixTop">
      <el-card class="box-card">
        <div class="font_weight" style="display: flex;flex-wrap:wrap;color: #666666;">
          <div class="grid-content bg-purple margin_15">周数 {{ addCabinetForm.week }}</div>
          <div class="grid-content bg-purple margin_15">预计件数
            {{ infomation.ctn == null ? 0 : infomation.ctn }}
          </div>
          <div class="grid-content bg-purple margin_15">总件数
            {{ infomation.realCtn == null ? 0 : infomation.realCtn }}
          </div>
          <div class="grid-content bg-purple margin_15">预计重量
            {{ infomation.kg == null ? 0 : infomation.kg }}
          </div>
          <div class="grid-content bg-purple margin_15">总重量 {{
              infomation.realKg == null
                  ? 0 : infomation.realKg
            }}
          </div>
          <div class="grid-content bg-purple margin_15">预计体积
            {{ infomation.cmb == null ? 0 : infomation.cmb }}
          </div>
          <div class="grid-content bg-purple margin_15">总体积 {{
              infomation.realCmb == null
                  ? 0 : infomation.realCmb
            }}
          </div>
          <div class="grid-content bg-purple margin_15">
            柜子类型：
            <span v-if="addCabinetForm.weekStatus==='0'">自拼</span>
            <span v-if="addCabinetForm.weekStatus==='1'">非拼/整柜</span>
            <span v-if="addCabinetForm.weekStatus==='2'">传统物流</span>
          </div>
          <div class="grid-content bg-purple margin_15">
            柜子创建时间：
            <span>{{ addCabinetForm.createTime }}</span>
          </div>
          <div class="grid-content bg-purple margin_15">
            柜型：
            <span>{{ addCabinetForm.size }}</span>
          </div>
          <div class="grid-content bg-purple margin_15">
            是否线上仓位：
            <span>{{ addCabinetForm.isOnlineWarehouse === '1' ? '是' : '否' }}</span>
          </div>
          <div class="grid-content bg-purple margin_15">
            是否已发pv：
            <span>{{ addCabinetForm.isPv === '1' ? '是' : '否' }}</span>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;">
          <div style="display: flex;flex-wrap:wrap;">
            <div style="display: flex;color: #666666;" v-for="(item, i) in infomationList" :key="i">
              <div class="grid-content bg-purple margin_2">{{ item.content }}</div>
              <div v-if="i!==infomationList.length-1" class="grid-content bg-purple margin_2">------->
              </div>
            </div>
          </div>
          <el-button @click="showCabinet = !showCabinet" type="primary"
                     v-if="buttonShowList.gzxxt && $canEditCabinetInfo()">柜子线形图
          </el-button>
          <cabinet-route class="cabinet_box" v-if="showCabinet"
                         :bookNo="addCabinetForm.bookNo"></cabinet-route>
        </div>
      </el-card>
    </div>

    <div ref="fixMain" :style="{overflow: 'auto', height: divHeight}">


      <el-row>
        <el-card class="box-card">
          <div slot="header" class="clearfix flex_wrap_between">
            <div>
              <span>柜子信息</span>
              <el-button class="f-m-l-10" size="medium" type="primary" @click="orderCompent"
                         v-if="addCabinetForm.weekStatus=='1' && buttonShowList.fastC && $canEditCabinetInfo()">
                订单快速完成
              </el-button>
            </div>
            <el-button size="medium" type="primary"
                       @click="saveCabinet"
                       v-if="buttonShowList.saveB && $canEditCabinetInfo()">保存
            </el-button>
          </div>
          <div class="text item">
            <el-form ref="addCabinetForm" :model="addCabinetForm" label-width="120px" size="medium"
                     :rules="rules">
              <el-row class="inputs_row">
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="船公司" prop="shipCompanyId" label-width="70px">
                    <f-select
                        :isNeed="addCabinetForm.shipCompanyId"
                        v-model="addCabinetForm.shipCompanyId"
                        :fatherVal="addCabinetForm.shipCompanyId"
                        :dict="'ship_company'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="订舱号" prop="bookNo" label-width="70px"> <!--待提交 已拒绝-->
                    <!-- <f-select :isNeed="addCabinetForm.bookNo"
                              v-model="addCabinetForm.bookNo"
                              :fatherVal="addCabinetForm.bookNo"
                              :dict="'so'"
                              ref="bookNo"
                              @changet="bookNoChange()"
                              @getAllOptions="selectClick()"
                    ></f-select> -->
                    <el-input v-model="addCabinetForm.bookNo" maxlength="50"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="排柜操作" prop="docId" label-width="70px">
                    <f-select
                        :isNeed="addCabinetForm.operateId"
                        v-model="addCabinetForm.operateId"
                        :fatherVal="addCabinetForm.operateId"
                        :dict="'sys_user_id'"></f-select>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="船名航次" prop="shipNo" label-width="80px">
                    <el-input v-model="addCabinetForm.shipNo" maxlength="50"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="柜号" prop="containerNo" label-width="70px">
                    <el-input v-model="addCabinetForm.containerNo" maxlength="50"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="封条" prop="originalNo" label-width="70px">
                    <el-input v-model="addCabinetForm.originalNo" maxlength="50"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="起运港" prop="pol" label-width="70px">
                    <f-select
                        :isNeed="addCabinetForm.pol"
                        v-model="addCabinetForm.pol"
                        :fatherVal="addCabinetForm.pol"
                        :dict="'sys_port'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="卸货港" prop="pod" label-width="70px">
                    <f-select
                        :isNeed="addCabinetForm.pod"
                        v-model="addCabinetForm.pod"
                        :fatherVal="addCabinetForm.pod"
                        :dict="'sys_port'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="目的港" prop="podEnd" label-width="70px">
                    <f-select
                        :isNeed="addCabinetForm.podEnd"
                        v-model="addCabinetForm.podEnd"
                        :fatherVal="addCabinetForm.podEnd"
                        :dict="'sys_port'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="ETD" prop="etd" label-width="70px">
                    <el-date-picker
                        class="c-full-width"
                        v-model="addCabinetForm.etd"
                        type="datetime"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="editStartOptions"
                        placeholder="请选择时间">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="ETA" prop="eta" label-width="70px">
                    <el-date-picker
                        class="c-full-width"
                        v-model="addCabinetForm.eta"
                        type="datetime"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="editStartOptions"
                        placeholder="请选择时间">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="装柜时间" prop="loadTime" label-width="70px">
                    <el-date-picker
                        class="c-full-width"
                        v-model="addCabinetForm.loadTime"
                        type="datetime"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="editStartOptions"
                        placeholder="请选择时间">
                    </el-date-picker>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="装柜仓库" prop="zgPlace" label-width="70px">
                    <f-select
                        :isNeed="addCabinetForm.zgPlace"
                        v-model="addCabinetForm.zgPlace"
                        :fatherVal="addCabinetForm.zgPlace"
                        :dict="'lcl_place'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="文件操作" prop="docId" label-width="70px">
                    <f-select
                        :isNeed="addCabinetForm.docId"
                        v-model="addCabinetForm.docId"
                        :fatherVal="addCabinetForm.docId"
                        :dict="'sys_user_id'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="派送操作" prop="endOperateId" label-width="70px">
                    <f-select
                        :isNeed="addCabinetForm.endOperateId"
                        v-model="addCabinetForm.endOperateId"
                        :fatherVal="addCabinetForm.endOperateId"
                        :dict="'sys_user_id'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="提柜港口" prop="portId" label-width="70px">
                    <f-select
                        :isNeed="addCabinetForm.portId"
                        v-model="addCabinetForm.portId"
                        :dict="'tg_port'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="拆柜仓库" prop="addressId" label-width="70px">
                    <f-select
                        :isNeed="addCabinetForm.addressId"
                        v-model="addCabinetForm.addressId"
                        :dict="'cg_ck'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="是否线上仓位" prop="isOnlineWarehouse" label-width="70px">
                    <f-select
                        :isNeed="addCabinetForm.isOnlineWarehouse"
                        v-model="addCabinetForm.isOnlineWarehouse"
                        :dict="'unit_whether'"></f-select>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="专线仓库代码" prop="lineWarehouseCode" label-width="80px">
                    <el-input v-model="addCabinetForm.lineWarehouseCode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="柜型" label-width="80px" prop="size">
                    <f-select
                        v-model="addCabinetForm.size"
                        :isNeed="addCabinetForm.size"
                        :dict="'cabinet_type'"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="预排方数" prop="preVolume" label-width="80px" :rules="rules.preVolume">
                    <el-input v-model="addCabinetForm.preVolume" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="截仓时间" prop="stopWarehouseTime" label-width="70px">
                    <el-date-picker
                        class="c-full-width"
                        v-model="addCabinetForm.stopWarehouseTime"
                        type="datetime"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="请选择时间">
                    </el-date-picker>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="渠道" label-width="70px" prop="channelId">
                    <f-select
                        v-model="addCabinetForm.channelId"
                        :isNeed="addCabinetForm.channelId"
                        :dict="'channel_set'"
                        @changet="changeChannelName"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="周期" prop="whichWeek" label-width="70px">
                    <el-date-picker
                        id="getWhichWeek"
                        v-model="addCabinetForm.whichWeek"
                        type="week"
                        :format="formatWeekStr"
                        placeholder="选择周"
                        :picker-options="{'firstDayOfWeek' : 1}"
                        @change="changeWhichWeek"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="备注" prop="remark" label-width="70px">
                    <el-input
                        v-model="addCabinetForm.remark"
                        maxlength="255"
                        show-word-limit
                        type="textarea">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <!--                <el-button type="primary" icon="el-icon-search" >保存</el-button>-->
        </el-card>

        <!--            <div class="f-text-right">-->
        <!--                <el-button type="primary" icon="el-icon-s-promotion" @click="handleAdd" :disabled="btnDisabled">保存-->
        <!--                </el-button>-->
        <!--                <el-button plain @click="hadleReset">重置</el-button>-->
        <!--            </div>-->
      </el-row>

      <el-row style="">
        <el-card class="box-card">
          <div slot="header" class="clearfix flex_wrap_between">
            <span>代理信息</span>
            <el-button size="medium" type="primary"
                       @click="saveCabinetTwo"
                       v-if="buttonShowList.saveP && $canEditCabinetInfo()">保存
            </el-button>
          </div>
          <div class="text item">
            <el-form ref="addCabinetFormTwo" :model="addCabinetFormTwo" label-width="120px" size="medium"
                     :rules="rulesTwo">
              <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="订舱代理" prop="bookAgentId" label-width="80px"> <!--待提交 已拒绝-->
                    <f-select
                        :isNeed="addCabinetFormTwo.bookAgentId"
                        v-model="addCabinetFormTwo.bookAgentId"
                        :dataList="addCabinetFormTwo.bookAgentIdList"
                        @click.native="fillAgentIdList"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="拖车行" prop="twoAgentId" label-width="70px"> <!--待提交 已拒绝-->
                    <f-select
                        :isNeed="addCabinetFormTwo.twoAgentId"
                        v-model="addCabinetFormTwo.twoAgentId"
                        :dataList="addCabinetFormTwo.twoAgentIdList"
                        @click.native="fillAgentIdList"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="报关行" prop="declareAgentId" label-width="70px"><!--待提交 已拒绝-->
                    <f-select
                        :isNeed="addCabinetFormTwo.declareAgentId"
                        v-model="addCabinetFormTwo.declareAgentId"
                        :dataList="addCabinetFormTwo.declareAgentIdList"
                        @click.native="fillAgentIdList"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="清关代理" prop="clearAgentId" label-width="80px"><!--待提交 已拒绝-->
                    <f-select
                        :isNeed="addCabinetFormTwo.clearAgentId"
                        v-model="addCabinetFormTwo.clearAgentId"
                        :dataList="addCabinetFormTwo.clearAgentIdList"
                        @click.native="fillAgentIdList"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="国外拆柜代理" prop="dismantleAgentId" label-width="90px"
                                :rules="addCabinetForm.cabinetCategory !='1' && addCabinetForm.weekStatus!='1'?rules.dismantleAgentId:[]">
                    <!--待提交 已拒绝-->
                    <f-select
                        :isNeed="addCabinetFormTwo.dismantleAgentId"
                        v-model="addCabinetFormTwo.dismantleAgentId"
                        :dataList="addCabinetFormTwo.dismantleAgentIdList"
                        @click.native="fillAgentIdList"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="送货代理" prop="deliveryAgentId" label-width="80px">
                    <!--待提交 已拒绝-->
                    <f-select
                        :isNeed="addCabinetFormTwo.deliveryAgentId"
                        v-model="addCabinetFormTwo.deliveryAgentId"
                        :dataList="addCabinetFormTwo.deliveryAgentIdList"
                        @click.native="fillAgentIdList"></f-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-card>
      </el-row>


      <el-row style="">
        <el-card class="box-card">
          <div slot="header" class="clearfix flex_wrap_between">
            <span>文件信息</span>
            <el-button size="medium" type="primary"
                       @click="saveCabinetThree"
                       v-if="buttonShowList.saveF && $canEditCabinetInfo()">保存
            </el-button>
          </div>
          <div class="text item">
            <el-form ref="addCabinetFormThree" :model="addCabinetFormThree" label-width="120px"
                     size="medium"
                     :rules="rulesThree">
              <el-row class="inputs_row">
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="预计件数" prop="ctn" label-width="80px"
                                :rules="infoRules.zhengshu">
                    <el-input v-model="addCabinetFormThree.ctn" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="预计重量" prop="kg" label-width="70px"
                                :rules="infoRules.emptyContainerKg">
                    <el-input v-model="addCabinetFormThree.kg" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="预计体积" prop="cmb" label-width="80px"
                                :rules="infoRules.emptyContainerKg">
                    <el-input v-model="addCabinetFormThree.cmb" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="实际件数" prop="realCtn" label-width="70px"
                                :rules="infoRules.zhengshu">
                    <el-input v-model="addCabinetFormThree.realCtn" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="实际重量" prop="realKg" label-width="90px"
                                :rules="infoRules.emptyContainerKg">
                    <el-input v-model="addCabinetFormThree.realKg" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="实际体积" prop="realCmb" label-width="80px"
                                :rules="infoRules.emptyContainerKg">
                    <el-input v-model="addCabinetFormThree.realCmb" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="截SI时间" prop="si" label-width="80px">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="addCabinetFormThree.si"
                        type="datetime"
                        size="small"
                        placeholder="请选择时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="editStartOptions"
                        format="yyyy-MM-dd HH:mm:ss"
                        style="width: 100%;"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="空柜重" prop="emptyContainerKg" label-width="80px"
                                :rules="infoRules.emptyContainerKg">
                    <el-input v-model="addCabinetFormThree.emptyContainerKg" type="number"
                              @change="getVGM"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="过磅重" prop="weight" label-width="80px"
                                :rules="infoRules.emptyContainerKg">
                    <el-input v-model="addCabinetFormThree.weight" type="number"
                              @change="getVGM"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="VGM" prop="vgm" label-width="80px"
                                :rules="infoRules.emptyContainerKg">
                    <el-input v-model="addCabinetFormThree.vgm" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="15" :lg="4">
                  <el-form-item label="AMS可否自报" prop="isAms" label-width="90px">
                    <f-select
                        :isNeed="addCabinetFormThree.isAms"
                        v-model="addCabinetFormThree.isAms"
                        :fatherVal="addCabinetFormThree.isAms"
                        :dict="'is_default'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="AMS可否报多个" prop="isAmsMore" label-width="90px">
                    <f-select
                        :isNeed="addCabinetFormThree.isAmsMore"
                        v-model="addCabinetFormThree.isAmsMore"
                        :fatherVal="addCabinetFormThree.isAmsMore"
                        :dict="'is_default'"></f-select>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="舱单可否自报" prop="isCangdan" label-width="80px">
                    <f-select
                        :isNeed="addCabinetFormThree.isCangdan"
                        v-model="addCabinetFormThree.isCangdan"
                        :fatherVal="addCabinetFormThree.isCangdan"
                        :dict="'is_default'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="合约" prop="contract" label-width="80px">
                    <f-select
                        :isNeed="addCabinetFormThree.contract"
                        v-model="addCabinetFormThree.contract"
                        :fatherVal="addCabinetFormThree.contract"
                        :dict="'contract'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="签单方式" prop="signAbill" label-width="80px">
                    <f-select
                        :isNeed="addCabinetFormThree.signAbill"
                        v-model="addCabinetFormThree.signAbill"
                        :fatherVal="addCabinetFormThree.signAbill"
                        :dict="'signAbill_type'"></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="免柜期" prop="mianGuiQi" label-width="80px">
                    <el-input v-model="addCabinetFormThree.mianGuiQi" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="换单费" prop="huanDanFee" label-width="80px">
                    <el-input v-model="addCabinetFormThree.huanDanFee" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="目的港费用" prop="podEndFee" label-width="80px">
                    <el-input v-model="addCabinetFormThree.podEndFee" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="AMS单号" prop="amsOrderNo" label-width="80px">
                    <el-select v-model="addCabinetFormThree.amsOrderNoList" clearable filterable
                               multiple
                               @change="getAmsOrderNo" @click.native="getAllOrder">
                      <el-option v-for="(item,index) in allOrderNoList" :value="item.value"
                                 :label="item.text" :key="index"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

              </el-row>
            </el-form>
          </div>
        </el-card>
      </el-row>

      <el-row style="">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>订单信息</span>
            <!--                    <el-button style="float: right; padding: 3px 0" type="text">保存</el-button>-->
            <!--                    <el-button type="primary"  style="float: right;">保存</el-button>&ndash;&gt;-->
          </div>
          <div class="text item">
            <div class="flex_wrap_between">
              <div>
                <el-button
                    type="primary"
                    size="medium"
                    @click="handledel({orderNo: selectedRowOrderNos.join(',')})"
                    :disabled="this.selectedRowOrderNos.length ==0 || isOrderStatus"
                    v-if="buttonShowList.pltg && isShowQingguan() && $canEditCabinetInfo()"
                >批量踢柜
                </el-button>
                <el-button type="primary" @click="guoNei"
                           v-if="buttonShowList.xzgnbg && $canEditCabinetInfo()">
                  国内报关文件
                </el-button>
                <el-button type="primary" @click="tuopanbiao"
                           v-if="buttonShowList.xztbp && $canEditCabinetInfo()">
                  下载托盘标文件
                </el-button>
                <el-button type="primary" @click="downloadChuansi"
                           v-if="buttonShowList.xzcsbl && $canEditCabinetInfo()">
                  船司补料文件
                </el-button>
                <el-button type="primary" @click="downloadVGM"
                           v-if="buttonShowList.xzvgm && $canEditCabinetInfo()">VGM文件
                </el-button>
                <el-button type="primary" @click="downloadGuowaiQingguan"
                           v-if="buttonShowList.xzgwqg && isShowQingguan() && $canEditCabinetInfo()"
                >国外清关文件
                </el-button>

                <el-button type="primary" @click="downloadGuowaiQingguan('1')"
                           v-if="buttonShowList.xzhbfp && isShowQingguan() && $canEditCabinetInfo()"
                >美国合并发票
                </el-button>
                <el-button type="primary"
                           @click="downloadAusAndCANGuowaiQingguan('1')"
                           v-if="buttonShowList.xzja && isShowQingguan() && $canEditCabinetInfo()"
                >加/澳清关文件
                </el-button>
                <el-button type="primary" @click="renZheng"
                           v-if="buttonShowList.xzrj && $canEditCabinetInfo()">认证文件
                </el-button>
                <el-button type="primary" @click="poa"
                           v-if="buttonShowList.xzpoa && $canEditCabinetInfo()">POA文件
                </el-button>
                <el-button type="primary" @click="invoiceCode"
                           v-if="buttonShowList.scfpbm && isShowQingguan() && $canEditCabinetInfo()"
                >生成发票编码
                </el-button>
                <el-dropdown
                    size="medium"
                    placement="bottom"
                    trigger="click"
                    @command="paiGuiOperate"
                    style="margin-left: 10px"
                    v-if="buttonShowList.xzpgb && $canEditCabinetInfo()"
                >

                  <el-button type="primary">
                    排柜表下载(无需勾选)<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown" >
                    <el-dropdown-item command="previewHtml">排柜表预览</el-dropdown-item>
                    <el-dropdown-item command="downloadPaigui">
                      排柜表下载
                    </el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- 待开船 -->
                <el-button class="f-m-l-10" type="primary" @click="downloadPaisong(oldOsContainer.containerNo)"
                           v-if="buttonShowList.xzps && $canEditCabinetInfo()">派送文件
                </el-button>
                <!--                            <el-button class="f-m-l-10" type="primary" @click="paiSong">派送文件</el-button>-->
                <el-button type="primary" @click="downloadOceanFee" :loading="btnLoadingqg"
                           v-if="buttonShowList.xzqgyf && isShowQingguan() && $canEditCabinetInfo()"
                >清关运费文件
                </el-button>
                <el-upload
                    action="/"
                    :show-file-list="false"
                    :http-request="uploadPaiguiFile"
                    :before-upload="beforeUpload"
                    accept=".xls,.xlsx"
                    style="display: inline-flex; margin-left: 10px"
                    v-if="buttonShowList.pgbsc && isShowPaiguiUpload() && $canEditCabinetInfo()"
                >
                  <el-button size="medium" type="primary">排柜表上传</el-button>
                </el-upload>
                <el-button type="primary"
                           style="margin-left: 5px"
                           @click="downloadCargo"
                           v-if="buttonShowList.xzcargo && isShowQingguan() && $canEditCabinetInfo()"
                >CARGO清关文件
                </el-button>
                <el-button type="primary"
                           style="margin-left: 5px"
                           @click="downloadFlying"
                           v-if="buttonShowList.xzflying && isShowQingguan() && $canEditCabinetInfo()"
                >FLYING清关文件
                </el-button>
                <el-button type="primary"
                           style="margin-left: 5px"
                           @click="downloadJapan"
                           v-if="buttonShowList.xzrbqg && isShowQingguan() && $canEditCabinetInfo()"
                >日本清关文件
                </el-button>
                <el-button type="primary"
                           @click="downloadQingguanInvoice"
                           v-if="infomation.podEndContinent === 'EU' && buttonShowList.ozqgfp && isShowQingguan() && $canEditCabinetInfo()"
                >欧洲清关发票
                </el-button>
              </div>
              <div class="right_select">
                订单号：
                <el-input class="order_box" v-model="queryForm.orderNo" placeholder="请输入订单号搜索"
                          style="width: 120px"></el-input>
                是否上传报关资料：
                <f-select class="order_box" :isNeed="queryForm.isCle" :dict="'unit_whether'"
                          v-model="queryForm.isCle" style="width:50px"></f-select>
                <el-button type="primary" @click="handleSearch" v-if="$canEditCabinetInfo()">搜索</el-button>
              </div>
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                :isImporterTypeBg="true"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
              <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                  <el-button type="text" @click="handledel({orderNo: scope.row.orderNo})"
                             v-if="isShowQingguan() && $canEditCabinetInfo()"
                             :disabled="scope.row.orderStatus >= 8">踢柜
                  </el-button>
                </template>
                <template v-else-if="scope.column.property === 'operateId'">
                  <span>{{ parseDict(scope.row.operateId, 'all_user_id') }}</span>
                </template>
                <template v-else-if="scope.column.property === 'docId'">
                  <span>{{ parseDict(scope.row.docId, 'all_user_id') }}</span>
                </template>
                <template v-else-if="scope.column.property === 'orderNo'">
                  <a v-if="$canEditCabinetInfo()"
                     style="color: #00a0e9"
                     @click.prevent="$intoDocPage(scope.row.orderNo)"
                  >{{ scope.row.orderNo }}</a>
                  <span v-else>{{ scope.row.orderNo }}</span>
                </template>

                <template v-else-if="scope.column.property === 'customer'">
                  <a v-if="$canEditCabinetInfo()" type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
                     style="color:#57a3f3;">{{ scope.row.customer }}</a>
                  <span v-else>{{ scope.row.orderNo }}</span>
                </template>
                <template v-else-if="scope.column.property === 'podEnd'">
                  <span>{{ parseDict(scope.row.podEnd, 'sys_port') }}</span>
                </template>
                <template v-else-if="scope.column.property === 'importerType'">
                  <span>{{ parseDict(scope.row.importerType, 'order_importer') }}</span>
                </template>
                <template v-else-if="scope.column.property === 'transportStatus'">
                  <span>{{ parseDict(scope.row.transportStatus, 'transportStatus') }}</span>
                </template>
                <template v-else-if="scope.column.property === 'ctn'">
                  <span>{{ scope.row.ctn }}/{{ scope.row.kg }}/{{ scope.row.cmb }}</span>
                </template>
                <template v-else-if="scope.column.property === 'realCtn'">
                  <span>{{ scope.row.realCtn }}/{{ scope.row.realKg }}/{{ scope.row.realCmb }}</span>
                </template>
                <template v-else-if="scope.column.property === 'isZheng'">
                  <span v-if="scope.row.isZheng >0">是</span>
                  <span v-if="scope.row.isZheng <= 0">否</span>
                </template>
                <template v-else-if="scope.column.property === 'invoiceCheck'">
                  <span style="color:green" v-if="scope.row.invoiceCheck == '1'">是</span>
                  <span v-else style="color: red">否</span>
                </template>
                <template v-else-if="scope.column.property === 'invoiceStatus'">
                  <span style="color: green" v-if="scope.row.invoiceStatus == '1'">已确认</span>
                  <span style="color: red" v-if="scope.row.invoiceStatus != '1'">未确认</span>
                </template>
                <template v-else-if="scope.column.property === 'clearanceOfGoods'">
                  <span v-if="scope.row.clearanceOfGoods > 0">是</span>
                  <span v-if="scope.row.clearanceOfGoods <=0 ">否</span>
                </template>
                <template v-else-if="scope.column.property === 'isInsured'">
                                    <span v-if="scope.row.insurance== '1' && scope.row.isInsured== '0'"
                                          style="color: red">未买保险</span>
                  <span v-else-if="scope.row.isInsured== '1'">已买保险</span>
                  <span v-else>不需要保险</span>
                </template>

                <template v-else-if="scope.column.property === 'shipmentCode'">
                  <!--{{scope.row.lineWarehouseCode + '1'}}
                  {{scope.row.shipmentCode + '2'}}-->
                  <span v-if="compare2StrIsNotEq(scope.row.shipmentCode,scope.row.lineWarehouseCode)"
                        style="color: red">{{ scope.row.shipmentCode }}</span>
                  <!--<span v-if="scope.row.shipmentCode ===  scope.row.lineWarehouseCode">{{scope.row.shipmentCode}}}</span>-->
                  <span v-else>{{ scope.row.shipmentCode }}</span>
                  <!--<span v-else>不需要保险</span>-->
                </template>


                <template v-else-if="scope.column.property === 'hasTax'">
                  <span :style="parseTax2(scope.row) == '未包税' ? 'color: red' : 'color: green'">{{
                      parseTax2(scope.row)
                    }}</span>
                </template>
                <template v-else-if="scope.column.property === 'complianceState'">
                                    <span :style="{color: getComplianceStateColor(scope.row.complianceState)}">{{
                                        parseDict(scope.row.complianceState, "compliance_state")
                                      }}</span>
                </template>
                <template v-else-if="scope.column.property === 'largeInfos'">
                  <div style="display: flex;flex-direction: column">
                    <span v-for="item in scope.row.largeInfos">{{ item }}</span>
                  </div>
                </template>
              </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
              <el-pagination
                  v-bind="ipagination"
                  layout="total, sizes, prev, pager, next, jumper"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange">
              </el-pagination>
            </el-row>
          </div>
        </el-card>

      </el-row>
      <product-module :container-week="addCabinetForm.week" :biekan="isShowQingguan()" ref="pro"></product-module>
    </div>
    <el-dialog
        title="上传报关单"
        :visible.sync="dialogUploadFormVisible"
        :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="上传报关单" :label-width="formLabelWidth" prop="fileId">
          <el-upload
              :action="'/sys/oss/file/upload'"
              :file-list="fileList"
              :http-request="handleUpload"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-upload="beforeUpload"
              :on-exceed="onexceed"
              v-bind="$attrs"
          >
            <template slot="trigger">
              <template v-if="this.$attrs.drag === '' || this.$attrs.drag === true">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              </template>
              <template v-else>
                <template v-if="this.$attrs['list-type'] === 'picture-card'">
                  <i class="el-icon-plus"></i>
                </template>
                <el-button size="small" type="primary" v-else>点击上传</el-button>
              </template>
            </template>
            <div slot="tip" class="el-upload__tip" v-if="tip">{{ tip }}</div>
          </el-upload>

        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUploadFormVisible = false ; this.fileList=[]">取 消</el-button>
        <el-button type="primary" @click="saveUploadClearance">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import QueryMixins from "@/mixins/QueryMixins";
import {getAction, postAction, putAction} from "@/api/manage";
import {setRouteQuery, setUpRouteUtil} from '@/utils/util';
import ProductModule from "@/views/operation/cabinet/ProductModule"
import {validateCmb, validateCtn, validateUnitValue} from "@/utils/validator";
import cabinetRoute from "./cabinetRoute"
import containerDetailButtonClickFunctions from "@/mixins/containerDetailButtonClickFunctions";
import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "@/utils/downloadJasper";

export default {
  name: 'BasicModule',
  mixins: [QueryMixins, containerDetailButtonClickFunctions],
  components: {ProductModule, cabinetRoute},
  data() {
    return {
      canUploadFile: true,
      showCabinet: false,
      divHeight: '',
      editStartOptions: {
        disabledDate: time => {
          if (!this.queryForm.createTimeEnd) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.queryForm.createTimeEnd);
          }
        }
      },
      editStopOptions: {
        disabledDate: time => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
              time.getTime() < new Date(this.queryForm.createTime) ||
              time.getTime() < new Date(1970 - 1 - 1).getTime()    //禁止选择1970年以前的日期
          );
        }
      },
      rulesThree: {
        ctn: [
          {validator: validateCtn, trigger: 'change'}
        ],
        realCtn: [
          {validator: validateCtn, trigger: 'change'}
        ],
        cmb: [
          {validator: validateCmb, trigger: 'change'}
        ],
        realCmb: [
          {validator: validateCmb, trigger: 'change'}
        ],
        kg: [
          {validator: validateCmb, trigger: 'change'}
        ],
        realKg: [
          {validator: validateCmb, trigger: 'change'}
        ],
      },
      rulesTwo: {
        /*bookAgentId: [
            {required: true, message: '请选择订舱代理', trigger: 'change'},
        ],
        twoAgentId: [
            {required: true, message: '请选择拖车行', trigger: 'change'},
        ],
        clearAgentId: [
            {required: true, message: '请选择清关代理', trigger: 'change'},
        ],
        declareAgentId: [
            {required: true, message: '请选择报关行', trigger: 'change'},
        ],
        dismantleAgentId: [
            {required: true, message: '请选择国外拆柜代理', trigger: 'change'},
        ],
        deliveryAgentId: [
            {required: true, message: '请选择送货代理', trigger: 'change'},
        ],*/
      },
      rules: {
        preVolume: [
          {validator: validateUnitValue, trigger: 'change'}
        ],
        endOperateId: [
          {required: true, message: '请选择派送操作', trigger: 'change'},
        ],
        //  shipCompanyId: [
        //      {required: true, message: '请选择船公司', trigger: 'change'},
        //  ],
        bookNo: [
          // {required: true, message: '请输入订舱号', trigger: 'change'},
        ],
        // docId:[
        //   {required:true,message:'请选择文件',trigger:'change'},
        // ],
        // operateId:[
        //   {required:true,message:'请选择操作',trigger:'change'}
        // ],
        // shipNo: [
        //     {required: true, message: '请输入船名航次', trigger: 'change'},
        // ],
        // containerNo: [
        //     {required: true, message: '请选择柜号', trigger: 'change'},
        // ],
        // originalNo: [
        //     {required: true, message: '请输入封条', trigger: 'change'},
        // ],
        /* pol: [
             {required: true, message: '请选择起运港', trigger: 'change'},
         ],
         pod: [
             {required: true, message: '请选择卸货港', trigger: 'change'},
         ],
         podEnd: [
             {required: true, message: '请选择目的港', trigger: 'change'},
         ],
         etd: [
             {required: true, message: '请选择ETD时间', trigger: 'change'},
         ],
         eta: [
             {required: true, message: '请选择ETA时间', trigger: 'change'},
         ],*/
        loadTime: [
          {required: true, message: '请选择装柜时间', trigger: 'change'},
        ],
      },
      tip: "",
      queryForm: {},
      row: {},
      sailForm: {},
      dictCodes: ['all_user_id', 'customer_id', 'sys_port', 'order_importer', 'transportStatus', 'compliance_state'],
      infomation: {},
      disableMixinMounted: false,
      dialogUploadFormVisible: false,
      infomationList: [],
      addCabinetForm: {},
      addCabinetFormTwo: {},
      addCabinetFormThree: {},
      btnLoadingqg: false,
      formLabelWidth: '120px',
      columns: [
        {
          type: 'selection',
          width: 33,
          fixed: 'left'
        },
        {
          prop: 'action',
          label: '操作',
          width: 40,
          fixed: 'left'
        },

        {
          prop: 'invoiceStatus',
          label: '发票状态',
          width: 60,
          fixed: 'left'
        },
        {
          prop: 'isInsured',
          label: '保险状态',
          width: 70,
          fixed: 'left'
        },
        {
          prop: 'orderNo',
          label: '订单号',
          width: 80,
          fixed: 'left'
        },
        {
          prop: 'customer',
          label: '客户名',
          width: 100,
          fixed: 'left'
        },
        {
          prop: 'invoiceCheck',
          label: '包税是否审核',
          width: 65,
          fixed: 'left'
        },
        {
          prop: 'hasTax',
          label: '是否包税',
          width: 95,
          fixed: 'left'
        },
        {
          prop: 'docId',
          label: '客服',
          width: 85,
          fixed: 'left'
        },
        {
          prop: 'operateId',
          label: '操作员',
          width: 85,
          fixed: 'left'
        },
        {
          prop: 'cargoArrived',
          label: '入仓时间',
          width: 85,
        },
        {
          prop: 'clearanceOfGoods',
          label: '是否上传报关资料',
        },
        {
          prop: 'importerType',
          label: '进口商类型',
          width: 65
        },
        {
          prop: 'complianceState',
          label: '关务是否审核',
          width: 65
        },
        {
          prop: 'ctn',
          label: '件/重/体',
          sortable: 'ctn',
          width: 100
        },
        {
          prop: 'realCtn',
          label: '实件/重/体',
          sortable: 'realCtn',
          width: 100
        },

        {
          prop: "shipmentCode",
          label: "仓库代码",
          width: 80
        },
        {
          prop: 'transportStatus',
          label: '渠道名称',
          width: 80
        },
        {
          prop: 'largeInfos',
          label: '超大件',
          width: 100
        },
        {
          prop: 'createBy',
          label: '材积录入人',
          width: 60
        },
        {
          prop: 'remark',
          label: '内部备注',
          width: 100
        },
        {
          prop: 'pgRemark',
          label: '排柜备注',
          width: 100
        },
        {
          prop: 'froRemark',
          label: '客户备注',
          width: 100
        },
        {
          prop: 'storehouseRemark',
          label: '仓库备注',
          width: 100
        },
        {
          prop: 'podEnd',
          label: '目的地',
          width: 100
        },
        {
          prop: 'isZheng',
          label: '认证',
          width: 40
        }
      ],
      url: {
        list: '/operation/container/getOrderList',
        infomation: '/operation/container/infomation',
        updateCirculationState: '/operation/container/updateCirculationStateByBasic',
        orderUpdate: '/operation/container/orderUpdate',

        batchDownload: "/sys/oss/file/batchDownloadFile",  //下载文件
        batchDownloadBaoguanFile: "/sys/oss/file/batchDownloadBaoguanFile",  //国内报关文件下载

        invoiceCode: '/order/os/file/invoiceCode', //生成发票编码

        getPaiGui: "/file/jasper/getPaiGui", //排柜表
        upload: "/sys/oss/file/upload", //文件上传
        uploadPaigui: "/operation/container/uploadPaigui", //排柜表上传
        queryBySo: '/sys/channelShipManage/queryBySo', //订舱信息查询
        untieSo: '/sys/channelShipManage/untieSo',  //解绑订舱号
        updateOsContainerInfo: '/operation/container/updateOsContainerInfo', //更新柜子文件信息

        getAllOrder: "/operation/container/getAllOrder", // 获取柜子的所有单号

        uploadClearance: '/operation/container/uploadClearance',
        orderCompent: "/operation/container/orderCompent", // 订单快速完成

        //下载 文件
        getHblBuLiao: "/file/jasper/getHblBuLiao", //船司补料文件
        getVgm: "/file/jasper/getVgm", //VGM
        getFileContainerId: '/order/os/file/getFileContainerId', //根据类型和柜号获取文件
        getContinent: '/order/os/file/getContinent', //获取柜子目的港所在的洲
        getGuoWaiQingguan: '/file/jasper/getGuoWaiQingguan', //下载国外清关文件
        queryInvoiceCode: '/order/os/file/queryInvoiceCode', //检查发票编码
        getPaiSong: "/file/jasper/getPaiSong",  //派送文件
        getOceanFee: "/file/jasper/getOceanFee", //清关发票文件pdf

      },
      btnDisabled: false,
      fileList: [],
      selectedRowOrderNos: [],
      selectedRowrealCmb: [], // 要一个实际件重体的数据，清关运费没有实际体积没法下载
      oldBookNo: '',  //原始订舱号的值
      oldOsContainer: {}, //原始的osContainer数据
      selectOrderNo: '',  //编辑页 单号查询
      allOrderNoList: [], //ams 所有单号
      amsOrderNoList: [],
      isOrderStatus: false, //批量踢柜是否禁用
      infoRules: {
        emptyContainerKg: [
          {validator: validateCmb, trigger: 'change'}
        ],
        zhengshu: [
          {validator: validateCtn, trigger: 'change'}
        ],
      },
      whichWeekStr: '',
      formatWeekStr: '',
      isUsePost:true
    }
  },
  watch: {
    // infomation(){
    //     //使用$nextTick方法
    //      console.log('换话费')
    //     let that = this;
    //     that.$nextTick(() => {
    //         console.log('元素',that.$refs.bsfixTop.offsetHeight)
    //         that.divHeight = 748 - that.$refs.bsfixTop.offsetHeight + 'px';
    //         console.log('元素',that.divHeight)
    //     });
    // },
    infomationList() {
      //使用$nextTick方法
      let that = this;
      that.$nextTick(() => {
        that.divHeight = 748 - (that.$refs.bsfixTop && that.$refs.bsfixTop.offsetHeight) || 0 + 'px';
        console.log('元素', that.divHeight)
      });
    }
  },
  created() {
    this.initInfomation()
    // this.initOrderList()

    const pattern = /Loading chunk chunk/g;
    const err = 'Loading chunk chunk-085a8a65 failed.'
    const isChunkLoadFailed = err.match(pattern)
    console.log(isChunkLoadFailed)


  },
  mounted() {


  },
  methods: {

    paiGuiOperate(command){
      switch (command) {
        case "previewHtml":
          this.previewHtml(this.url.getPaiGuiUrl + '?containerId=' + this.$route.query.id,{
            t: "html",
          });
          break;
        case "downloadPaigui":
          //下载导入模板
          this.downloadPaigui();
          break;
      }
    },
    //文件上传之前校验文件合法

    changeChannelName(val) {
      this.$set(this.addCabinetForm, 'channelName', val);
    },
    changeWhichWeek() {
      const now = new Date(this.addCabinetForm.whichWeek);
      const nowTime = now.getTime();
      const day = now.getDay();
      const oneDayTime = 24 * 60 * 60 * 1000;
      const mondayTime = nowTime - (day - 1) * oneDayTime;
      const sundayTime = nowTime + (7 - day) * oneDayTime;
      const startTime = this.$momonet(mondayTime).format('MM.DD');
      const endTime = this.$momonet(sundayTime).format('MM.DD');
      let formatY = this.$momonet(now).format('yyyy');
      let formatW = this.$momonet(now).format('WW');
      if (formatW !== '52') {
        formatW = Number(formatW) + Number(1);
      } else {
        formatW = '1';
        formatY = Number(formatY) + Number(1);
      }
      // :format="'yyyy第WW周' + whichWeekStr"
      console.log("选择周期的开始日期：" + startTime + "，结束日期：" + endTime);
      this.whichWeekStr = "(" + startTime + "-" + endTime + ")";
      this.formatWeekStr = formatY + "第" + formatW + '周' + this.whichWeekStr;
      this.$nextTick(() => {
        let a = document.querySelector("#getWhichWeek").value
        // this.queryForm.whichWeek = JSON.stringify(JSON.stringify(a))
        this.addCabinetForm.whichWeekStr = a
      })

    },
    isShowQingguan() {
      if (this.addCabinetForm && this.addCabinetForm['isCt'] === '1') {
        return false;
      } else {
        return true;
      }
    },
    isShowPaiguiUpload() {
      if (this.addCabinetForm && this.addCabinetForm['isPrimary'] === '0') {
        return false;
      } else {
        return true;
      }
    },
    fillAgentIdList() {
      getAction("/operation/container/getAgentIdList?id=" + this.$route.query.id)
          .then(res => {
            if (res.result) {
              this.$set(this.addCabinetFormTwo, 'bookAgentIdList', res.result.bookAgentIdList);
              this.$set(this.addCabinetFormTwo, 'twoAgentIdList', res.result.twoAgentIdList);
              this.$set(this.addCabinetFormTwo, 'declareAgentIdList', res.result.declareAgentIdList);
              this.$set(this.addCabinetFormTwo, 'clearAgentIdList', res.result.clearAgentIdList);
              this.$set(this.addCabinetFormTwo, 'dismantleAgentIdList', res.result.dismantleAgentIdList);
              this.$set(this.addCabinetFormTwo, 'deliveryAgentIdList', res.result.deliveryAgentIdList);
            }
          })
    },
    onexceed() {
      this.$message.warning("最多上传1个文件哦,亲");
    },
    beforeUpload(file) {
      // 对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15
      if (!isLt2M) {
        this.$message.warning('文件大小不能超过15MB');
        this.canUploadFile = false;
        return false;
      }
      let name = "";
      if (file && file.name) {
        name = file.name;
      }
      const isFile = name.indexOf('.') !== -1;
      if (!isFile) {
        this.$message.warning('该文件名不合法');
        this.canUploadFile = false;
        return false;
      }
      this.canUploadFile = true;
      return isFile;
    },
    handleRemove(file) {
      console.log(file)
      if (file.id) {
        const index = this.fileList.indexOf(file);
        const {id} = this.fileList[index]
        deleteAction('/sys/oss/file/delete', {id}).then(res => {
          if (res.success) {
            this.$message.success(res.message);
          }
        }).catch(err => {
          this.$emit('delete', index)
        })
        //如果传递参数为合同，则删除合同

      }
    },
    handlePreview(file) {
      console.log('文件的url', file.url);
      if (!file.url) {
        this.$message.error('下载失败')
        return
      }

      const type = file.url.substring(file.url.lastIndexOf('.') + 1);
      console.log('type', type);
      if (type === 'jpg' || type === 'jpeg' || type === 'img' || type === 'gif' || type === 'png' || type === 'pneg') {
        console.log('是图片预览', file.url);
        this.imgUrl = file.url
        this.visible = true
      }
      // 判断文件类型
      else if (type === 'doc' || type === 'docx' || type === 'xlsx' || type === 'xls' || type === 'ppt' || type === 'pptx') {
        console.log('不是图片下载', file.url);
        // 在当前浏览器直接下载
        document.location.href = file.url
      } else {
        // 图片在浏览器打开 新的页面展示
        window.open(file.url, 'hello')
      }

    },
    oceanFee() {


      // if( this.selectedRowrealCmb == null || this.selectedRowrealCmb ==''){
      //   this.$message.error("请查看当前勾选订单是否有实际体积！！");
      //   return;
      // }
      // if(!this.selectedRowOrderNos.length>0 ){
      //   this.$message.error("请先勾选对应的一条订单后在点击下载清关运费文件！");
      //   return;
      // }
      // if(this.selectedRowOrderNos.length>1){
      //   this.$message.error("只能勾选一条订单进行清关运费文件！");
      //   return;
      // }
      // this.btnLoadingqg=true;
      postAction(this.url.getOceanFee, {
        orderNos: this.selectedRowOrderNos,
        containerId: this.$route.query.id
      }, {responseType: 'blob'}).then(response => {

        let u = window.URL.createObjectURL(response)
        let aLink = document.createElement('a')
        aLink.style.display = 'none'
        aLink.href = u
        aLink.setAttribute('download', "清关运费文件.zip");
        document.body.appendChild(aLink)
        aLink.click()
        document.body.removeChild(aLink)
        window.URL.revokeObjectURL(u);
        // this.btnLoadingqg=false;
      })


    },
    handleUpload(data) {
      if (this.canUploadFile) {
        console.log("上传文件", data);
        const formData = new FormData();
        // data.file.name = data.file.name.replace(/^[ ,，(\n" ")\t]+$/,'');
        formData.append('file', data.file)
        formData.append('dir', 'operate')
        console.log("客户文件", formData);
        postAction(this.url.upload, formData).then((res) => {
          console.log(res)
          if (res.success) {
            this.sailForm.fileId = res.result.id
            this.fileList.push(res.result)
            console.log('文件上传', this.fileList)
          }
        })
      }
    },
    //派送文件
    paiSong() {
      getAction(this.url.getPaiSong + '?containerId=' + this.$route.query.id + '&orderNo=' + this.selectedRowOrderNos.join(','), {}, {responseType: 'blob'}).then(response => {
        let u = window.URL.createObjectURL(response);
        let aLink = document.createElement('a');
        aLink.style.display = 'none';
        aLink.href = u;
        aLink.setAttribute('download', "派送文件.xls");
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
        window.URL.revokeObjectURL(u);
      })
    },
    saveUploadClearance() {
      console.log(this.fileList, 'this.fileList')
      if (!this.fileList.length > 0) {
        this.$message.error("请先上传文件");
        return;
      }
      const formData = {};

      formData.osOrderFileList = this.fileList
      formData.orderNos = this.selectedRowOrderNos
      postAction(this.url.uploadClearance, formData).then((res) => {
        console.log(res)
        if (res.success) {
          this.$message.success("保存成功");
          this.dialogUploadFormVisible = false;
          this.fileList = []
        }
      })


    },
    // 合规是否审核颜色标注
    getComplianceStateColor(id) {
      if (id == '1') {
        return 'rgb(0, 160, 233)'
      } else if (id == '0') {
        return '#606266'
      } else if (id == '2') {
        return 'green'
      } else if (id == '3' || id == '4') {
        return 'red'
      }
    },


    handleSelectionChange(selection) { // 多选
      this.isOrderStatus = false;
      let arr = []
      let arrCmb = []
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['orderNo'])
        arrCmb.push(selection[i]['realCmb'])
        if (selection[i].orderStatus >= 8) {
          this.isOrderStatus = true;
        }
      }
      this.selectedRowOrderNos = [...arr];
      this.selectedRowrealCmb = [...arrCmb];
    },
    handledel(row) {
      console.log(row, '踢柜')
      putAction(this.url.orderUpdate, row)
          .then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.initInfomation()
              /*this.initOrderList()*/
              this.handleQuery();
            }
          })
    },
    handleAdd() {
    },
    hadleReset() {

    },
    initOrderList() {
      let id = this.$route.query.id;
      this.addCabinetFormTwo.id = id
      postAction(this.url.list, {containerId: id})
          .then((res) => {
            if (res.success) {
              const {records, total} = res.result
              this.tableData = records || res.result;
              console.log("表单数据", this.tableData);
              this.ipagination && (this.ipagination.total = total || res.result.length)
            }
          })
    },
    saveCabinetThree() {
      let id = this.$route.query.id;
      this.addCabinetFormThree.containerId = id;
      console.log(this.addCabinetFormThree)
      this.$refs['addCabinetFormThree'].validate((valid) => {
        if (valid) {
          postAction(this.url.updateOsContainerInfo, this.addCabinetFormThree)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                }
              }).catch(err => {
            // this.$message.error(err.message);
          })
        } else {
          return false;
        }
      });
    },

    saveCabinetTwo() {
      let id = this.$route.query.id;
      this.addCabinetFormTwo.id = id
      console.log(this.addCabinetFormTwo)
      this.$refs['addCabinetFormTwo'].validate((valid) => {
        if (valid) {
          putAction(this.url.updateCirculationState, this.addCabinetFormTwo)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  /*  this.initOrderList();*/
                  this.handleQuery();
                }
              })
        } else {
          return false;
        }
      });
    },
    saveCabinet() {
      let id = this.$route.query.id;
      this.addCabinetForm.id = id
      console.log(this.addCabinetForm)
      this.$refs['addCabinetForm'].validate((valid) => {
        if (valid) {
          this.unboundBookNo();
          putAction(this.url.updateCirculationState, this.addCabinetForm)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.initInfomation();
                  this.handleSearch();
                  // this.initOrderList();
                }
              })
        } else {
          return false;
        }
      });
    },
    // 解绑订舱号
    unboundBookNo() {
      console.log(this.addCabinetForm.bookNo, this.oldBookNo, 'this.addCabinetForm.bookNo')
      if (this.addCabinetForm.bookNo && this.addCabinetForm.bookNo !== this.oldBookNo) {
        getAction(this.url.untieSo + '?so=' + encodeURIComponent(this.oldBookNo)).then((res) => {
        });
      }
    },
    initInfomation() {
      let id = this.$route.query.id;
      let that = this;
      console.log(id)
      if (id) {
        getAction(this.url.infomation, {containerId: this.$route.query.id})
            .then(res => {
              console.log(res)
              // console.log(res.result['order'])
              if (res.result['order'] != null) {
                that.infomation = res.result['order']
              } else {
                that.infomation = {}
              }

              if (res.result['list'] != null) {
                that.infomationList = res.result['list']
              } else {
                that.infomationList = []
              }

              if (res.result['osContainer'] != null) {
                // 初始值的保存
                that.addCabinetForm = res.result['osContainer'];
                that.changeWhichWeek();
                that.$emit('changeTabs', that.isShowPaiguiUpload());
                setRouteQuery(that.addCabinetForm.week, that.$route.fullPath);
                that.oldBookNo = that.addCabinetForm.bookNo || '';
                that.addCabinetFormTwo = res.result['osContainer'];
                that.oldOsContainer.shipCompanyId = res.result['osContainer'].shipCompanyId;
                that.oldOsContainer.shipNo = res.result['osContainer'].shipNo;
                that.oldOsContainer.containerNo = res.result['osContainer'].containerNo;
                that.oldOsContainer.pol = res.result['osContainer'].pol;
                that.oldOsContainer.podEnd = res.result['osContainer'].podEnd;
                that.oldOsContainer.etd = res.result['osContainer'].etd;
                that.oldOsContainer.loadTime = res.result['osContainer'].loadTime;
                that.oldOsContainer.bookAgentId = res.result['osContainer'].bookAgentId;
              }
              if (res.result['osContainerInfo'] != null) {
                that.addCabinetFormThree = res.result['osContainerInfo'];
                //回显ams 单号
                if (res.result['osContainerInfo'].amsOrderNo) {
                  this.$set(this.addCabinetFormThree, 'amsOrderNoList', res.result['osContainerInfo'].amsOrderNo.split(','));
                } else {
                  this.$set(this.addCabinetFormThree, 'amsOrderNoList', []);
                }
                // 初始值的保存
                that.oldOsContainer.si = res.result['osContainer'].si;
                that.oldOsContainer.isAms = res.result['osContainer'].isAms;
                that.oldOsContainer.isAmsMore = res.result['osContainer'].isAmsMore;
                that.oldOsContainer.isCangdan = res.result['osContainer'].isCangdan;
                that.oldOsContainer.contract = res.result['osContainer'].contract;
                that.oldOsContainer.mianGuiQi = res.result['osContainer'].mianGuiQi;
                that.oldOsContainer.huanDanFee = res.result['osContainer'].huanDanFee;
                that.oldOsContainer.podEndFee = res.result['osContainer'].podEndFee;
              }
              // 文件信息中第一行的初始值(如果之前有保存-直接显示保存的值，没有保存显示order中的对应值作为初始值)
              if (res.result['osContainerInfo'] == null || (res.result['osContainerInfo'].ctn == null && res.result['osContainerInfo'].kg == null
                  && res.result['osContainerInfo'].cmb == null && res.result['osContainerInfo'].realCtn == null
                  && res.result['osContainerInfo'].realKg == null && res.result['osContainerInfo'].realCmb == null)) {
                this.$set(that.addCabinetFormThree, 'ctn', (res.result['order'] && res.result['order'].ctn) || 0);
                this.$set(that.addCabinetFormThree, 'kg', (res.result['order'] && res.result['order'].kg) || 0);
                this.$set(that.addCabinetFormThree, 'cmb', (res.result['order'] && res.result['order'].cmb) || 0);
                this.$set(that.addCabinetFormThree, 'realCtn', (res.result['order'] && res.result['order'].realCtn) || 0);
                this.$set(that.addCabinetFormThree, 'realKg', (res.result['order'] && res.result['order'].realKg) || 0);
                this.$set(that.addCabinetFormThree, 'realCmb', (res.result['order'] && res.result['order'].realCmb) || 0);
                console.log('文件信息中第一行的初始值赋值', that.addCabinetFormThree);
              }

              if (this.$refs['addCabinetForm']) {
                this.$refs['addCabinetForm'].clearValidate();
              }
              if (this.$refs['addCabinetFormTwo']) {
                this.$refs['addCabinetFormTwo'].clearValidate();
              }
              if (this.$refs['addCabinetFormThree']) {
                this.$refs['addCabinetFormThree'].clearValidate();
              }
            })
      }
    },
    parseTax2({hasTax, yf, fjf}) {

      if (hasTax === '0') {
        return "未包税"
      } else {
        let total = 0.0;
        //hasTax: "{\"fjf\":10.0,\"type\":\"1\",\"yf\":141.0}"
        if (fjf != null) {
          total += parseFloat(fjf)
        }
        if (yf != null) {
          total += parseFloat(yf)
        }
        return "已包税," + "总价格为:" + total + " 运费单价为:" + yf + " 附加费为:" + fjf;

      }

    },

    //船司补料文件
    chuanSi() {
      /* this.downLoad(2, '船司补料文件');*/

      getAction(this.url.getHblBuLiao + '?containerId=' + this.$route.query.id, {}, {responseType: 'blob'}).then(response => {
        let u = window.URL.createObjectURL(response);
        let aLink = document.createElement('a');
        aLink.style.display = 'none';
        aLink.href = u;
        aLink.setAttribute('download', this.infomation.week + "-船司补料文件.xls");
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
        window.URL.revokeObjectURL(u);
      })
    },
    //VGM
    vgm() {

      getAction(this.url.getVgm + '?containerId=' + this.$route.query.id, {}, {responseType: 'blob'}).then(response => {
        let u = window.URL.createObjectURL(response);
        let aLink = document.createElement('a');
        aLink.style.display = 'none';
        aLink.href = u;
        aLink.setAttribute('download', this.infomation.week + "-VGM文件.xls");
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
        window.URL.revokeObjectURL(u);
      })


    },
    //国外清关文件
    gwQingGuan() {
      this.gwQingGuanDownLoad('国外清关文件');
    },
    renZheng() {
      // this.downLoad(4, '认证文件');
      this.downloadFileListAndType(4,"认证文件");

    },
    //POA
    poa() {
      // this.downLoad(5, 'POA文件');
      this.downloadFileListAndType(5,"POA文件");

    },
    guoNei() {
      // this.downLoad(1, '国内报关文件');
      this.downloadFileListAndType(1,"国内报关文件");
    },
    tuopanbiao() {
      // this.downLoad(43, '托盘标文件');
      this.downloadFileListAndType(43,"托盘标文件");
    },
    downLoad(fileType, name) {
      this.fileList = [];
      getAction(this.url.getFileContainerId + '?fileType=' + fileType + '&containerId=' + this.$route.query.id + '&orderNo=' + this.selectedRowOrderNos.join(','))
          .then(res => {
            if (res.success) {
              this.fileList = res.result;
              if (null == this.fileList || this.fileList.length <= 0) {
                this.$message.warning('暂无文件可下载');
              } else {
                let url = this.url.batchDownload
                if(fileType && fileType == 1){
                  url = this.url.batchDownloadBaoguanFile
                }
                downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("POST",url,
                    this.fileList,name+".zip",this);
                // postAction(this.url.batchDownload, this.fileList, {responseType: 'blob'})
                //     .then(response => {
                //       let u = window.URL.createObjectURL(response)
                //       let aLink = document.createElement('a')
                //       aLink.style.display = 'none'
                //       aLink.href = u
                //       aLink.setAttribute('download', name + ".zip");
                //       document.body.appendChild(aLink)
                //       aLink.click()
                //       document.body.removeChild(aLink)
                //       window.URL.revokeObjectURL(u)
                //     })
              }
            }
          })
    },
    //国外清关文件
    async gwQingGuanDownLoad() {
      let pu = false;
      let that = this;
      //判断勾选的单是否都设置发票编码
      await getAction(this.url.queryInvoiceCode + '?orderNo=' + this.selectedRowOrderNos.join(',') + '&containerId=' + this.$route.query.id)
          .then(res => {
            if (res.success) {
              pu = true;
            }
          })
      if (pu) {
        console.log('根据柜子获取目的港')
        //根据柜子获取目的港
        getAction(this.url.getContinent + '?containerId=' + this.$route.query.id).then(res => {
          if (res.success) {
            let continent = res.result;
            if (continent) {
              if (continent === "EU") { //欧洲
                //给出提示 是否按照单号拆分
                this.$confirm(`该柜子的目的港是欧洲，文件是否按照单号拆分?`, '提示', {
                  confirmButtonText: '是',
                  cancelButtonText: '否',
                  type: 'info',
                  closeOnClickModal: false,
                  distinguishCancelAndClose: true,//区分取消与关闭
                }).then(() => {
                  //按照单号拆分
                  getAction(this.url.getGuoWaiQingguan + '?containerId=' + this.$route.query.id
                      + '&continent=' + continent + '&type=1&orderNo=' + this.selectedRowOrderNos.join(','), {}, {responseType: 'blob'}).then(response => {
                    if (response && response.type === 'application/json') {
                      const reader = new FileReader()
                      reader.onload = function () {
                        const message = JSON.parse(reader.result).message
                        that.$message.error(message);// 将错误信息显示出来
                      }
                      reader.readAsText(response)
                      return false;
                    } else {
                      let u = window.URL.createObjectURL(response)
                      let aLink = document.createElement('a')
                      aLink.style.display = 'none'
                      aLink.href = u
                      aLink.setAttribute('download', "国外清关文件.zip");
                      document.body.appendChild(aLink)
                      aLink.click()
                      document.body.removeChild(aLink)
                      window.URL.revokeObjectURL(u)
                    }

                  })

                }).catch(action => {
                  if (action === 'cancel') {
                    //不按照单号拆分
                    getAction(this.url.getGuoWaiQingguan + '?containerId=' + this.$route.query.id
                        + '&continent=' + continent + '&type=2&orderNo=' + this.selectedRowOrderNos.join(','), {}, {responseType: 'blob'}).then(response => {
                      if (response && response.type === 'application/json') {
                        const reader = new FileReader()
                        reader.onload = function () {
                          const message = JSON.parse(reader.result).message
                          that.$message.error(message);// 将错误信息显示出来
                        }
                        reader.readAsText(response)
                        return false;
                      } else {
                        let u = window.URL.createObjectURL(response)
                        let aLink = document.createElement('a')
                        aLink.style.display = 'none'
                        aLink.href = u
                        aLink.setAttribute('download', "国外清关文件.zip");
                        document.body.appendChild(aLink)
                        aLink.click()
                        document.body.removeChild(aLink)
                        window.URL.revokeObjectURL(u)
                      }
                    })
                  } else {
                    return;
                  }


                })

              } else {
                //非欧洲
                getAction(this.url.getGuoWaiQingguan + '?containerId=' + this.$route.query.id
                    + '&continent=' + continent + '&type=2&orderNo=' + this.selectedRowOrderNos.join(','), {}, {responseType: 'blob'}).then(response => {
                  if (response && response.type === 'application/json') {
                    const reader = new FileReader()
                    reader.onload = function () {
                      const message = JSON.parse(reader.result).message
                      that.$message.error(message);// 将错误信息显示出来
                    }
                    reader.readAsText(response)
                    return false;
                  } else {
                    let u = window.URL.createObjectURL(response)
                    let aLink = document.createElement('a')
                    aLink.style.display = 'none'
                    aLink.href = u
                    aLink.setAttribute('download', "国外清关文件.zip");
                    document.body.appendChild(aLink)
                    aLink.click()
                    document.body.removeChild(aLink)
                    window.URL.revokeObjectURL(u)
                  }
                })
              }
            }
          }
        })
      }

    },
    //生成发票编码
    invoiceCode() {
      getAction(this.url.invoiceCode + '?orderNo=' + this.selectedRowOrderNos.join(',') + '&containerId=' + this.$route.query.id)
          .then(res => {
            if (res.success) {
              this.$message.success(res.message);
              this.$refs['pro'] && this.$refs['pro'].handleSearch();

            }
          })
    },
    //排柜表
    paiGui() {
      getAction(this.url.getPaiGui + '?containerId=' + this.$route.query.id, {}, {responseType: 'blob'}).then(response => {
        let u = window.URL.createObjectURL(response);
        let aLink = document.createElement('a');
        aLink.style.display = 'none';
        aLink.href = u;
        aLink.setAttribute('download', "排柜表" + this.infomation.week + ".xls");
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
        window.URL.revokeObjectURL(u);
      })
    },

    handleQuery() {

      this.loading = true
      let form = {...this.queryForm, ...this.filterForm}
      for (let item in form) {
        if (typeof form[item] === 'string') {
          form[item] = form[item].trim()
          if (form[item] === '') {
            form[item] = null
          }
        }
      }
      const {prop, order} = this.isorter
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: 'asc',
      }
      //判断 是否要查询startTime

      if (!params.column) {
        this.$set(params, 'column', 'pgTime')
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage
        params.pageSize = this.ipagination.pageSize
      }

      params.containerId = this.$route.query.id;
      postAction(this.url.list, params).then((res) => {
        console.log(res)
        if (res.success) {
          if (res.result) {
            const {records, total} = res.result
            this.tableData = records || res.result;

            this.ipagination && (this.ipagination.total = total || res.result.length)
          } else {

            this.tableData = [];

            this.ipagination = 0;
          }

        }
      }).finally(() => {
        this.loading = false
      })
    },

    //修改后的排柜表上传
    async uploadPaiguiFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      form.append("dir", 'operate');
      //执行上传方法
      postAction(this.url.upload, form).then((res) => {
        //这里做上传之后的操作
        if (res.success && res.result) {
          //将排柜表的url 更新到 柜子
          var contain = {
            id: this.$route.query.id,
            paiguiOssId: res.result.id
          };
          postAction(this.url.uploadPaigui, contain).then((res) => {
            //这里做上传之后的操作
            if (res.success) {
              this.$message.success(res.message);
            }
          })

        }
      })
    },
    //订舱号 带出订舱信息
    bookNoChange() {
      // 如果选择的是原始的柜子，直接获取原始数据，不需要调用接口
      if (this.addCabinetForm.bookNo == this.oldBookNo) {
        console.log(this.oldOsContainer, 'oldOsContainer')
        this.$set(this.addCabinetForm, 'shipCompanyId', this.oldOsContainer.shipCompanyId);
        this.$set(this.addCabinetForm, 'shipNo', this.oldOsContainer.shipNo);
        this.$set(this.addCabinetForm, 'containerNo', this.oldOsContainer.containerNo);
        this.$set(this.addCabinetForm, 'pol', this.oldOsContainer.pol);
        this.$set(this.addCabinetForm, 'podEnd', this.oldOsContainer.podEnd);
        this.$set(this.addCabinetForm, 'etd', this.oldOsContainer.etd);
        this.$set(this.addCabinetForm, 'loadTime', this.oldOsContainer.loadTime);
        this.$set(this.addCabinetFormTwo, 'bookAgentId', this.oldOsContainer.bookAgentId);
        this.$set(this.addCabinetFormThree, 'si', this.oldOsContainer.si);
        this.$set(this.addCabinetFormThree, 'isAms', this.oldOsContainer.isAms);
        this.$set(this.addCabinetFormThree, 'isAmsMore', this.oldOsContainer.isAmsMore);
        this.$set(this.addCabinetFormThree, 'isCangdan', this.oldOsContainer.isCangdan);
        this.$set(this.addCabinetFormThree, 'contract', this.oldOsContainer.contract);
        this.$set(this.addCabinetFormThree, 'signAbill', this.oldOsContainer.signAbill);
        this.$set(this.addCabinetFormThree, 'mianGuiQi', this.oldOsContainer.mianGuiQi);
        this.$set(this.addCabinetFormThree, 'huanDanFee', this.oldOsContainer.huanDanFee);
        this.$set(this.addCabinetFormThree, 'podEndFee', this.oldOsContainer.podEndFee);
        return;
      }
      //根据 订舱船期id 获取
      getAction(this.url.queryBySo + '?so=' + encodeURIComponent(this.addCabinetForm.bookNo)).then((res) => {
        if (res.success) {
          this.$set(this.addCabinetForm, 'shipCompanyId', res.result.shipCompanyId);
          this.$set(this.addCabinetForm, 'shipNo', res.result.shipNo);
          this.$set(this.addCabinetForm, 'containerNo', res.result.containerNo);
          this.$set(this.addCabinetForm, 'pol', res.result.pol);
          this.$set(this.addCabinetForm, 'podEnd', res.result.podEnd);
          this.$set(this.addCabinetForm, 'etd', res.result.etd);
          this.$set(this.addCabinetForm, 'loadTime', res.result.loadingTime);
          this.$set(this.addCabinetFormTwo, 'bookAgentId', res.result.supplierId);
          this.$set(this.addCabinetFormThree, 'si', res.result.si);
          this.$set(this.addCabinetFormThree, 'isAms', res.result.isAms);
          this.$set(this.addCabinetFormThree, 'isAmsMore', res.result.isAmsMore);
          this.$set(this.addCabinetFormThree, 'isCangdan', res.result.isCangdan);
          this.$set(this.addCabinetFormThree, 'contract', res.result.contract);
          this.$set(this.addCabinetFormThree, 'signAbill', res.result.signAbill);
          this.$set(this.addCabinetFormThree, 'mianGuiQi', res.result.mianGuiQi);
          this.$set(this.addCabinetFormThree, 'huanDanFee', res.result.huanDanFee);
          this.$set(this.addCabinetFormThree, 'podEndFee', res.result.podEndFee);
        }
      });
    },
    // bookNo订舱号点击触发，把之前的订舱号添加的选择列表中
    selectClick() {
      if (this.oldBookNo) {
        let options = this.$refs.bookNo.allOptions;
        options.push({
          text: this.oldBookNo,
          title: this.oldBookNo,
          value: this.oldBookNo
        });
        this.$refs.bookNo.allOptions = options;
      }
    },
    // 自动计算vgm的值
    getVGM() {
      let emptyContainerKg = this.addCabinetFormThree.emptyContainerKg;
      let weight = this.addCabinetFormThree.weight;
      if (emptyContainerKg == undefined || emptyContainerKg == null || emptyContainerKg == '') {
        emptyContainerKg = 0;
      }
      if (weight == undefined || weight == null || weight == '') {
        weight = 0;
      }
      this.$set(this.addCabinetFormThree, 'vgm', parseInt(emptyContainerKg) + parseInt(weight))
    },
    //根据单号搜索 柜子里的订单
    selectContainerOrder() {
      let id = this.$route.query.id;
      postAction(this.url.list, {containerId: id, orderNo: this.selectOrderNo})
          .then((res) => {
            if (res.success) {
              const {records, total} = res.result
              this.tableData = records || res.result;
              console.log("表单数据", this.tableData);
              this.ipagination && (this.ipagination.total = total || res.result.length)
            }
          })

    },
    //获取柜子的所有单号
    getAllOrder() {
      this.allOrderNoList = [];
      getAction(this.url.getAllOrder, {containerId: this.$route.query.id})
          .then((res) => {
            if (res.success) {
              for (var i = 0; i < res.result.length; i++) {
                let obj = {};
                obj.value = res.result[i];
                obj.label = res.result[i];
                this.allOrderNoList.push(obj);
              }
            }
          })
    },
    getAmsOrderNo(amsOrderNoList) {
      if (amsOrderNoList && amsOrderNoList.length && Array.isArray(amsOrderNoList)) {
        this.$set(this.addCabinetFormThree, 'amsOrderNo', amsOrderNoList.join(','))
      }
    },
    orderCompent() {
      this.$confirm('慎点！！订单会直接跳到待锁单，请确认后续所有流程不再跟进且需要贴标的全部贴标完成！！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        getAction(this.url.orderCompent, {containerId: this.$route.query.id})
            .then(res => {
              if (res.success) {
                this.$message.success(res.message);
                this.handleQuery()
              }
            }).catch(err => {
        })
      }).catch(() => {

      })

    }

  },
  computed:{
    buttonShowList(){
      return {
        'gzxxt':this.$btnIsShow('operation/cabinet/Edit','0','柜子线形图'),
        'fastC':this.$btnIsShow('operation/cabinet/Edit','0','订单快速完成'),
        'saveB':this.$btnIsShow('operation/cabinet/Edit','0','保存柜子基本信息'),
        'saveP':this.$btnIsShow('operation/cabinet/Edit','0','保存柜子代理信息'),
        'saveF':this.$btnIsShow('operation/cabinet/Edit','0','保存柜子文件信息'),
        'pltg':this.$btnIsShow('operation/cabinet/Edit','0','批量踢柜'),
        'xzgnbg':this.$btnIsShow('operation/cabinet/Edit','0','下载国内报关文件'),
        'xztbp':this.$btnIsShow('operation/cabinet/Edit','0','下载托盘标文件'),
        'xzcsbl':this.$btnIsShow('operation/cabinet/Edit','0','下载船司补料文件'),
        'xzvgm':this.$btnIsShow('operation/cabinet/Edit','0','下载VGM文件'),
        'xzgwqg':this.$btnIsShow('operation/cabinet/Edit','0','下载国外清关文件'),
        'xzhbfp':this.$btnIsShow('operation/cabinet/Edit','0','下载合并发票文件'),
        'xzja':this.$btnIsShow('operation/cabinet/Edit','0','下载加/澳清关文件'),
        'xzrj':this.$btnIsShow('operation/cabinet/Edit','0','下载认证文件'),
        'xzpoa':this.$btnIsShow('operation/cabinet/Edit','0','下载POA文件'),
        'scfpbm':this.$btnIsShow('operation/cabinet/Edit','0','生成发票编码'),
        'xzpgb':this.$btnIsShow('operation/cabinet/Edit','0','下载排柜表'),
        'xzps':this.$btnIsShow('operation/cabinet/Edit','0','下载派送文件'),
        'xzqgyf':this.$btnIsShow('operation/cabinet/Edit','0','下载清关运费文件'),
        'pgbsc':this.$btnIsShow('operation/cabinet/Edit','0','排柜表上传'),
        'xzcargo':this.$btnIsShow('operation/cabinet/Edit','0','下载CARGO清关文件'),
        'xzflying':this.$btnIsShow('operation/cabinet/Edit','0','下载FLYING清关文件'),
        'xzrbqg':this.$btnIsShow('operation/cabinet/Edit','0','下载日本清关文件'),
        'ozqgfp':this.$btnIsShow('operation/cabinet/Edit','0','欧洲清关发票'),
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.right_select {
  display: flex;
  align-items: center;

  .order_box {
    margin-right: 10px;
  }
}

.el-col {
  white-space: nowrap;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: "Segoe UI";
  color: rgb(102, 102, 102);
}

.margin_15 {
  margin: 0 15px 0 2px;
  line-height: 25px;
}

.margin_2 {
  margin: 0 2px;
  line-height: 25px;
}

.cabinet_box {
  position: absolute;
  z-index: 999;
  top: 20px;
  right: 100px;
}
</style>
