<template>
  <div class="main-full-content">
    <el-card class="box-card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleAdd({})"

          v-if="buttonShowList.add"
          >新增</el-button
        >
        <el-button type="primary" size="medium" @click="handleExportXls"
                   v-if="buttonShowList.dc"
          >导出</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        tableHeight="74vh"
        :cell-style="{ 'text-align': 'center' }"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"

                       v-if="buttonShowList.edit"
              >编辑</el-button
            >
            <el-button type="text" @click="handleDel(scope.row)"
                       v-if="buttonShowList.del"
              >删除</el-button
            >
            <el-button
              type="text"
              @click="handlSet(scope.row)"
              v-if="scope.row.isDefaule === '0'"
              >设为默认</el-button
            >
          </template>
        </template>
      </f-table>
    </el-card>

    <el-dialog
      :title="form.id ? '编辑供应商（提货地址信息）' : '新增供应商（提货地址信息）'"
      v-if="dialogFormVisible"
      :visible.sync="dialogFormVisible"
      width="30%"
      center
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="供应商名"
          prop="name"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.name" autocomplete="off" maxlength="50" show-word-limit></el-input>
        </el-form-item>

        <el-form-item
          label="联系人"
          prop="contact"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.contact" autocomplete="off" maxlength="50" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="tel" :label-width="formLabelWidth">
          <el-input v-model="form.tel" autocomplete="off" maxlength="20" show-word-limit></el-input>
        </el-form-item>
<!--        <el-form-item label="国家" prop="country" :label-width="formLabelWidth">-->
<!--          <el-select-->
<!--            clearable-->
<!--            filterable-->
<!--            class="f-full-width"-->
<!--            v-model="form.country"-->
<!--            @change="initStateList"-->
<!--            placeholder="请选择"-->
<!--            @click.native="initCountryList"-->
<!--          >-->
<!--            <el-option-->
<!--              v-for="(item, index) in countrylist"-->
<!--              :key="index"-->
<!--              :label="item.label"-->
<!--              :value="item.value"-->
<!--            >-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="州/省"  :label-width="formLabelWidth">-->
<!--          <el-select-->
<!--            v-model="form.state"-->
<!--            placeholder="请选择"-->
<!--            @change="initCityList"-->
<!--            filterable-->
<!--            clearable-->
<!--            class="f-full-width"-->
<!--          >-->
<!--            <el-option-->
<!--              v-for="item in statelist"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value"-->
<!--            ></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="城市"  :label-width="formLabelWidth">-->
<!--          <el-select-->
<!--            v-model="form.city"-->
<!--            placeholder="请选择"-->
<!--            filterable-->
<!--            clearable-->
<!--            class="f-full-width"-->
<!--          >-->
<!--            <el-option-->
<!--              v-for="item in citylist"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value"-->
<!--            ></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="提货地址" prop="address" :label-width="formLabelWidth">
          <el-input
            v-model="form.address"
            autocomplete="off"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
          <el-input
            v-model="form.remark"
            autocomplete="off"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="提货司机信息短信推送" :label-width="formLabelWidth" key="isNeedSendTakeDriverPhone" >
          <el-radio v-model="form.isNeedSendTakeDriverPhone" :label="'1'" @click.native.prevent="radioClick4('1')">是</el-radio>
          <!--                    <el-radio v-model="form.saleLockPrice" :label="'2'" @click.native.prevent="radioClick4('2')">是+分</el-radio>-->
          <el-radio v-model="form.isNeedSendTakeDriverPhone" :label="'0'" @click.native.prevent="radioClick4('0')">否</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="medium"
          type="primary"
          @click="saveSupplier"
          :disabled="btnDisable"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { deleteAction, putAction, postAction, getAction } from "@/api/manage";
import { emailPattern, mobilePattern } from "@/utils/pattern";
import {
  getcityList,
  getstateList,
  getallcountries,
} from "../../../api/manage";
export default {
  name: "ImporterModule",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      fsee: false,
      statelist: [],
      citylist: [],
      dialogFormVisible: false,
      form: {},
      formLabelWidth: "130px",
      btnDisable: false,
      row: {},
      visible: false,
      //表单校验
      rules: {
        name: [
          { required: true, message: "请输入供应商名", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        contact: [
          { required: true, message: "请选择联系人", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        country: [{ required: true, message: "请选择国家", trigger: "change" }],
        // state: [{ required: true, message: "请选择州", trigger: "change" }],
        // city: [{ required: true, message: "请选择市", trigger: "change" }],
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          // {
          //   pattern: mobilePattern,
          //   message: "请输入正确的手机号",
          //   trigger: "change",
          // },
        ],
        email: [
          // { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: emailPattern,
            message: "请输入正确的邮箱",
            trigger: "change",
          },
        ],
        address: [
          { max: 255, message: "地址长度不能超过255", trigger: "change" },
        ],
      },
      columns: [
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "name",
          label: "供应商名",
        },
        {
          prop: "contact",
          label: "联系人",
        },
        {
          prop: "tel",
          label: "手机号",
        },
        {
          prop: "email",
          label: "邮箱",
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "address",
          label: "详细地址",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      url: {
        list: "/customer/supplier/list",
        delByid: "/customer/supplier/delete",
        setDefault: "/customer/supplier/setDefault",
        exportXlsUrl: "/customer/supplier/exportXls",
        add: "/customer/supplier/add",
        edit: "/customer/supplier/edit",
        getbyid: "/customer/supplier/queryById",
      },
    };
  },
  created() {
    this.queryForm.cusId = this.$route.query.id;
  },
  methods: {
    radioClick4(e){
      e === this.form.isNeedSendTakeDriverPhone? (this.form.isNeedSendTakeDriverPhone ='') :(this.form.isNeedSendTakeDriverPhone = e)
    },
    initCountryList() {
      if (this.countrylist.length <= 0) {
        getallcountries().then((res) => {
          if (res.success) {
            for (var i = 0; i < res.result.length; i++) {
              let obj = {};
              obj.value = res.result[i].cnName;
              obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
              this.countrylist.push(obj);
            }
          }
        });
      }
    },
    initStateList(val) {
      this.statelist = [];
      this.citylist = [];
      if (val) {
        getstateList({ cnName: val }).then((res) => {
          if (res.success) {
            for (var i = 0; i < res.result.records.length; i++) {
              let obj = {};
              obj.value = res.result.records[i].cname;
              obj.label =
                res.result.records[i].code + "-" + res.result.records[i].cname;
              this.statelist.push(obj);
            }
          }
        });
      } else {
        // this.form.city = '';
        // this.form.state = '';
        // this.form.bdState = '';
        // this.form.bdCity = '';
      }
    },
    initCityList(val) {
      this.citylist = [];
      if (val) {
        getcityList({ cname: val }).then((res) => {
          if (res.success) {
            for (var i = 0; i < res.result.records.length; i++) {
              let obj = {};
              obj.value = res.result.records[i].cname;
              obj.label =
                res.result.records[i].code + "-" + res.result.records[i].cname;
              this.citylist.push(obj);
            }
          }
        });
      } else {
      }
    },
    handleReset() {
      this.queryForm = {};
      this.queryForm.cusId = this.$route.query.id;
      this.ipagination.currentPage = 1;

      this.handleQuery();
    },
    handlSet(row) {
      this.$confirm(`确定将${row.name}设为默认吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(this.url.setDefault + "?ids=" + this.selectedRowKeys, {
            id: row.id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    handleDel(row) {
      this.$confirm(`确定删除${row.name}设吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delByid + "?ids=" + this.selectedRowKeys, {
            id: row.id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    handleAdd() {
      this.form = {isNeedSendTakeDriverPhone:'1'};
      this.fsee = true;
      this.dialogFormVisible = true;
      this.btnDisable = false;
    },
    handleEdit(row) {
      this.btnDisable = false;
      getAction(this.url.getbyid, { id: row.id })
        .then((res) => {
          if (res.success) {
            this.form = res.result;
            this.fsee = true;
          }
        })
      this.fsee = true;
      this.dialogFormVisible = true;
    },
    saveSupplier() {
      //给客户id赋值
      this.btnDisable = true;
      this.form.cusId = this.$route.query.id;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            //编辑
            putAction(this.url.edit, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.dialogFormVisible = false;
                this.handleQuery();
              } else {
                this.btnDisable = false;
              }
            });
          } else {
            //新增
            postAction(this.url.add, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.dialogFormVisible = false;
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          }
        } else {
          this.btnDisable = false;
        }
      });
    },
  },
  computed : {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('customer/modules/SupplierModule','0','新增'),
        'dc':this.$btnIsShow('customer/Edit','0','导出供应商'),
        'edit':this.$btnIsShow('customer/modules/SupplierModule','1','编辑'),
        'del':this.$btnIsShow('customer/modules/SupplierModule','1','删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
