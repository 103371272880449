<template>
    <el-dialog
        :title="title"
        :visible="visible"
        width="560px"
        custom-class="c-custom-dialog"
        :before-close="handleClose"
        :close-on-click-modal="false"
        v-if="visible">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="type" label="订单类型" :rules="rules.type" >
<!--                        <f-select-->
<!--                                v-model="form.type"-->
<!--                                :isNeed="form.type"-->
<!--                                @change="getCusType"-->
<!--                                :dict="'customer_order_type'">-->
<!--                        </f-select>-->
                      <el-select v-model="form.type" filterable clearable >
                        <el-option value="0" label="海运"></el-option>
                        <el-option value="1" label="空运"></el-option>
                        <el-option value="2" label="快递"></el-option>
                        <el-option value="3" label="海外仓"></el-option>
                        <el-option value="4" label="全选" v-if="isZr"></el-option>
                      </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="salesmanId" label="业务员" :rules="rules.salesmanId">
                        <f-select
                            v-model="form.salesmanId"
                            :isNeed="form.salesmanId"
                           :dict="'sys_user_id'">
                        </f-select>
                    </el-form-item>
                </el-col>
<!--                <el-col :span="24" v-if="form.type =='3'">
                    <el-form-item prop="oceanId" label="海外仓运营" :rules="rules.oceanId">
                        <f-select
                                v-model="form.oceanId"
                                :isNeed="form.oceanId"
                                :dict="'sys_user_id'">
                        </f-select>
                    </el-form-item>
                </el-col>-->

<!--                <el-col :span="24">-->
<!--                    <el-form-item prop="docId" label="海外业务员">-->
<!--                        <el-select-->
<!--                                clearable-->
<!--                                filterable-->
<!--                                class="f-full-width"-->

<!--                                v-model="form.oceanSalesmanId"-->
<!--                                :is-need="form.oceanSalesmanId"-->
<!--                                placeholder="请选择">-->
<!--                            <el-option :key="index" v-for="(item,index) in oclist" :value="item.value" :label="item.label"></el-option>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="24">-->
<!--                    <el-form-item prop="docId" label="客服人员">-->
<!--                        <f-select-->
<!--                                v-model="form.docId"-->
<!--                                :isNeed="form.docId"-->
<!--                                :dict="'sys_user_id'">-->
<!--                        </f-select>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="24">-->
<!--                    <el-form-item prop="billmanId" label="账单人员">-->
<!--                        <f-select-->
<!--                                v-model="form.billmanId"-->
<!--                                :isNeed="form.billmanId"-->
<!--                                :dict="'sys_user_id'">-->
<!--                        </f-select>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--              <el-col :span="24">-->
<!--                <el-form-item prop="followList" label="跟进人">-->
<!--                  <f-select-->
<!--                      v-model="form.followList"-->
<!--                      :isNeed="form.followList"-->
<!--                      :multiple="true"-->
<!--                      :dict="'sys_user_id'">-->
<!--                  </f-select>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { putAction,getAction,getallsalesmans,getallusers,getallrealtion } from '@/api/manage'


export default {
    name: 'AddRoleDialog',
    props: {
        visible: {
            type: Boolean
        },
        title: {
            type: String,
            default: '分配'
        },
        ids: {
            type: Array,
            default: () => []
        },
      userName:{
        type :String
      }
    },
    data() {
        const salesmanValid = (rule, value, callback) => {
           if (!this.form.salesmanId && !this.form.oceanSalesmanId){
               callback(new Error('请选择业务员或者海外业务员'))
           }else {
               callback();
           }
        }
        return {

            form: {},
            rules: {
                salesmanId: [
                    {required: true, message: '请选择业务员', trigger: 'change' },
                ],
                type: [
                    { required: true, message: '请选择类型', trigger: 'change' },
                ],
                // docId: [
                //     { required: true, message: '请选择客服人员', trigger: 'change' },
                // ],

            },
            url: {
                asign: '/customer/userpass',
                batchAsign: '/customer/userpassBatch',
                bdFeeTypeList:'/sys/feeType/getList',
                relationlist: '/customer/relation/getRelationById',
                change:'/customer/userchange',
                changebatch:'/customer/userchangeBatch',
                getCustomerDetailByCusId:'/customer/detail/getDetailByCusIdAndType',
            },
            salesmanlist:[],
            doclist:[],
            billmanlist:[],
            oclist:[],
          isZr:false,

        }
    },
    methods: {
        getCusType(val){
          if (val && val !=='4' &&this.title.indexOf('批量')===-1){
            getAction(this.url.getCustomerDetailByCusId,{cusId:this.ids.join(','),type:val})
                .then(res=>{
                  if (res.result){
                    this.$set(this.form,'salesmanId',res.result.salesmanId);
                    this.$set(this.form,'docId',res.result.docId);
                    this.$set(this.form,'billmanId',res.result.billmanId);
                    this.$set(this.form,'oceanId',res.result.oceanId || '');
                  }else {
                    this.$set(this.form,'salesmanId','');
                    this.$set(this.form,'docId','');
                    this.$set(this.form,'billmanId','');
                    this.$set(this.form,'oceanId','');
                  }
                })
          }

        },
        handleSubmit() {

            this.$refs.form.validate((valid) => {
                if (valid) {

                    var url = this.url.asign
                    var params = { ids: this.ids.join(',') }
                    if(this.title === '分配') {
                        url = this.url.asign
                        params = { id: this.ids.join(',') }

                    }
                  if(this.title === '转让') {
                    url = this.url.change
                    params = { id: this.ids.join(',') }
                  }
                  if(this.title === '批量转让') {

                    params = { ids: this.ids.join(',') }
                      url = this.url.changebatch+'?ids='+params.ids
                  }
                    if(this.title === '批量分配') {
                        params = { ids: this.ids.join(',') }
                        url = this.url.batchAsign+'?ids='+params.ids
                    }
                    this.form.id = params.id;
                    putAction(url, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose();
                        this.$parent.handleSearch();
                    })
                }
            })
        },
        handleClose() {
            this.form = {}
            this.$emit('update:visible', false)
            this.$refs.form.resetFields()
        },
        userlist(){

          getallsalesmans().then((res)=>{
            if (res.success){
              //VALUE ID
             for(var i=0;i<res.result.records.length;i++){
               var obj = {};
               obj.value = res.result.records[i].id;
               obj.label = res.result.records[i].username+"-"+res.result.records[i].realname;
               this.salesmanlist.push(obj);

             }
              //label username
            }
          })
        },
      getRelation(val){
          let url = this.url.relationlist;
          const obj = {};
          obj.salesmanId = val;

          if (obj.salesmanId){
            getallrealtion(obj).then((res)=>{
              if (res.success){
                var list =  res.result;
                if (list == null || list.length <=0){
                    this.$set(this.form,'docId','');
                    this.$set(this.form,'billmanId','');
                    this.$set(this.form,'oceanSalesmanId','');
                    // this.$set(this.form,'salesmanId','');
                }
                // consoloe.log('转让',list,list.length);
                  this.billmanlist=[];
                  this.doclist=[];
                  this.oclist=[];
                for(var i=0;i<list.length;i++){
                  let billman = {};
                  billman.value =list[i].billmanId;
                  billman.label =list[i].billman;
                  this.billmanlist.push(billman);
                  let doc = {};
                  doc.value = list[i].docId;
                  doc.label = list[i].doc;
                  this.doclist.push(doc);
                    let oc = {};
                    oc.value = list[i].oceanSalesmanId;
                    oc.label = list[i].oceanSalesman;
                    this.oclist.push(oc);
                  if (i == 0){
                      this.$set(this.form,'docId',list[i].docId);
                      this.$set(this.form,'billmanId',list[i].billmanId);
                      this.$set(this.form,'oceanSalesmanId',list[i].oceanSalesmanId);
                      // this.$set(this.form,'salesmanId',list[i].salesmanId);
                  }
                }
                  // consoloe.log('转让后',this.form);
              }
            })
          }else {
              this.$set(this.form,'docId','');
              this.$set(this.form,'billmanId','');
          }

      }
    },
    created(){
      // this.userlist();
      // this.getCusType('0')

    },
  watch:{
    title: {
      immediate: true,
      handler(val) {
       // consoloe.log('转让的title',val)
        if (val && val.indexOf('转让')!==-1){
          this.isZr=true;
        }else {
          this.isZr=false;
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
