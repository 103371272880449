<template>
  <div style="font-size: 16px">
    <!--        <div ref="fixTop">-->
    <!--            <el-card class="box-card">-->
    <!--                <div style="display: flex;flex-wrap:wrap;color: #666666;">-->
    <!--                    <div class="grid-content bg-purple margin_15">周数 {{infomation.week }}</div>-->
    <!--                    <div class="grid-content bg-purple margin_15">总件数 {{infomation.ctn == null ?0:infomation.realCtn}}</div>-->
    <!--                    <div class="grid-content bg-purple margin_15">总重量 {{infomation.realKg == null ?0:infomation.realKg}}</div>-->
    <!--                    <div class="grid-content bg-purple margin_15">总实体 {{ infomation.realCmb == null ? 0 : infomation.realCmb }}</div>-->
    <!--                </div>-->
    <!--                <div style="display: flex;flex-wrap:wrap;">-->
    <!--                    <div style="display: flex;color: #666666;" v-for="(item, i) in infomationList" :key="i">-->
    <!--                        <div class="grid-content bg-purple margin_2">{{item.content}}</div>-->
    <!--                        <div v-if="i!==infomationList.length-1" class="grid-content bg-purple margin_2">-&#45;&#45;&#45;&#45;&ndash;&gt;</div>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </el-card>-->
    <!--        </div>-->

    <div>
      <el-row style="">
        <el-card class="box-card last_card">
          <div slot="header" class="clearfix flex_wrap_between">
            <span>产品信息</span>
          </div>
          <el-form class="multiline_select" ref="form" :model="queryForm" label-width="60px" size="medium">
            <el-row class="inputs_row no_margin">
              <el-col :span="3">
                <el-form-item label="订单号">
                  <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="唛头">
                  <el-input v-model="queryForm.shipmentId"
                            @keyup.enter.native="handleSearch"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="货物名称" label-width="70px">
                  <el-input v-model="queryForm.name" @keyup.enter.native="handleSearch"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="海关编码" label-width="70px">
                  <el-input v-model="queryForm.hscode" @keyup.enter.native="handleSearch"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="美国海关编码" label-width="70px">
                  <el-input v-model="queryForm.mgHsCode" @keyup.enter.native="handleSearch"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="发票编码" label-width="70px">
                  <el-input v-model="queryForm.invoiceCode"
                            @keyup.enter.native="handleSearch"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label-width="30px">
                  <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                  <!--                                  <el-button-->
                  <!--                                      type="primary"-->
                  <!--                                      @click="updateHscode"-->
                  <!--                                      :disabled="selectionRows.length===0"-->
                  <!--                                      v-if="$btnIsShow('operation/cabinet/Edit','0','修改海关编码')"-->
                  <!--                                  >修改海关编码-->
                  <!--                                  </el-button>-->
                  <el-button plain @click="handleReset">重置</el-button>
                  <el-button type="primary" @click="productEdit" v-if="$canEditCabinetInfo()">产品编辑</el-button>
                  <el-button
                      type="primary"
                      size="medium"
                      v-if="biekan && $canEditCabinetInfo()"
                      @click="downloadExcel(url.downloadContainerInvoice, containerWeek + '-柜子装箱单',{containerId:$route.query.id})"
                  >下载柜子装箱单
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="text item">
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="procudeId"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @queryChange="handleTableQueryChange"
                @sort-change="handleTableChange"
            >

              <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                </template>
                <template v-else-if="scope.column.property === 'orderNo'">
                  <a v-if="$canEditCabinetInfo()"
                      style="color: #00a0e9"
                      @click.prevent="$intoDocPage(scope.row.orderNo)"
                  >{{ scope.row.orderNo }}</a>
                  <span v-else>{{scope.row.orderNo}}</span>
                </template>
                <template v-else-if="scope.column.property === 'name'">
                  <span>{{ scope.row.name}}/{{ scope.row.ename}}</span>
                </template>
                <template v-else-if="scope.column.property === 'invoiceCode'">
                  <span style="color: red">{{scope.row.invoiceCode}}</span>
                </template>
                <template v-else-if="scope.column.property === 'addtionalCode'">
                  <span style="color: red" v-if="scope.row.isRedAddtionalCode === '1'">{{scope.row.addtionalCode}}</span>
                  <span v-else>{{scope.row.addtionalCode}}</span>
                </template>
                <template v-else-if="scope.column.property === 'taxRate'">
                  <span style="color: red" v-if="scope.row.isRedTaxRate === '1'">{{scope.row.taxRate}}</span>
                  <span v-else>{{scope.row.taxRate}}</span>
                </template>
                <template v-else-if="scope.column.property === 'hscode'">
                  <span style="color: red" v-if="scope.row.isRedHscode === '1'">{{scope.row.hscode}}</span>
                  <span v-else>{{scope.row.hscode}}</span>
                </template>
              </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
              <el-pagination
                  v-bind="ipagination"
                  layout="total, sizes, prev, pager, next, jumper"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
              >
              </el-pagination>
            </el-row>
          </div>
        </el-card>
      </el-row>


      <el-dialog title="产品编辑" :visible.sync="dialogFormVisible" :close-on-click-modal="false">

        <el-button type="primary" @click="noNameStatus" :disabled="this.selectedProcudeId.length==0">不显示品名</el-button>
        <el-button type="primary" @click="nameStatus" :disabled="this.selectedProcudeId.length==0">显示品名</el-button>

        <div slot="footer" class="dialog-footer">
          <f-table
              v-loading="loading"
              ref="GTable"
              border
              size="medium"
              row-key="procudeId"
              class="c-th-has-bgcolor"
              :columns="columsEdit"
              :data="tableDataEdit"
              :cell-style="{ 'text-align': 'center' }"
              @selection-change="handleSelectionChangeEdit"
              @sort-change="handleTableChange"
          >
            <template v-slot="scope">
              <template v-if="scope.column.property === 'name'">
                <span>{{ scope.row.name}}/{{ scope.row.ename}}</span>
              </template>
              <template v-else-if="scope.column.property === 'nameStatus'">
                <span v-if="scope.row.nameStatus==='0'" style="color: #dd0000">{{ parseDict(scope.row.nameStatus, "is_default") }}</span>
                <span v-else>{{ parseDict(scope.row.nameStatus, "is_default") }}</span>
              </template>

            </template>
          </f-table>
          <el-row class="f-text-right f-p-v-8">
            <el-pagination
                v-bind="ipaginationEdit"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChangeEdit"
                @current-change="handleCurrentChangeEdit"
            >
            </el-pagination>
          </el-row>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getAction, putAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins";
import {setUpRouteUtil} from '@/utils/util';

export default {
  props:{
    containerWeek:{
      type:String,
      default:''
    },
      biekan:{
          type:Boolean,
          default:true
      }
  },
  name: "ProductModule",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      ipaginationEdit: {
        currentPage: 1,
        pageSize: 50,
        pageSizes: [10, 20, 50, 100],
        total: 0
      },
      divHeight: "",
      queryForm: {},
      row: {},
      dictCodes: ["is_default"],
      infomation: {},
      disableMixinMounted: false,
      dialogFormVisible:false,
      infomationList: [],
      tableDataEdit:[],
      columns: [
        {
          type: "selection",
          width: 33,
        },
        {
          prop: "orderNo",
          label: "订单号",
          width:85
          // width: 80,
        },
        // {
        //     prop: "shipmentId",
        //     label: "唛头",
        //     minWidth: 120,
        // },
        {
          prop: "name",
          label: "货物名称",
          minWidth:100
          // width: 200,
        },
        {
          prop: "hscode",
          label: "海关编码",
          minWidth:120
          // minWidth: 140,
        },
        {
          prop: "addtionalCode",
          label: "301编码",
          // minWidth: 140,
        },
        {
          prop: "taxRate",
          label: "税率",
          minWidth:50
          // width: 100,
        },
        {
          prop: "material",
          label: "材质",
          minWidth:80
          // width: 160,
        },
        {
          prop: "purpose",
          label: "用途",
          minWidth:80
          // width: 100,
        },
        {
          prop: "unitCost",
          label: "成本单价",
          minWidth:40
          // width: 100,

        },
        {
          prop: "unitValue",
          label: "申报单价",
          minWidth:40
          // width: 100,
        },
        {
          prop: "ctn",
          label: "件数",
          minWidth:40
        },
        {
          prop: "kg",
          label: "毛重",
          minWidth:40

        },
        {
          prop: "cmb",
          label: "体积",
          minWidth:50

        },
        {
          prop: "invoiceCode",
          label: "发票编码",
          width:35
          // minWidth:60,
        },
        {
          prop: "qty",
          label: "数量",
          minWidth:40
        },
        {
          prop: "totalValue",
          label: "总价",
          minWidth:50
        },
        {
          prop: "shipmentCountry",
          label: "订单国家",
          minWidth:35
        },
        {
          prop: "country",
          label: "产品国家",
          minWidth:35
        },
        {
          prop: "mgHsCode",
          label: "美国海关编码",
          minWidth:85
        },
        {
          prop: "customsDescription",
          label: "产品描述",
          minWidth:115
        },
        {
          prop: "dutyRate",
          label: "关税税率",
          minWidth:70
        },
        {
          prop: "addtionalDutyCode",
          label: "附加税",
          minWidth:50
        },
        {
          prop: "addtionalDutyRate",
          label: "附加税率",
          minWidth:40
        },
        {
          prop: "preTariff",
          label: "预收关税",
          minWidth:55
        },

      ],
      columsEdit:[
        {
          type: "selection",
          width: 33,
        },
        {
          prop: "nameStatus",
          label: "是否显示",
          width: 30,
        },
        {
          prop: "orderNo",
          label: "订单号",
          width: 80,
        },
        {
          prop: "shipmentId",
          label: "唛头",
          width: 80,
        },
        {
          prop: "name",
          label: "货物名称",
          width: 130,
        },
        {
          prop: "hscode",
          label: "HS CODE",
          width: 130,
        },
      ],
      url: {
        list: "/operation/container/productList",
        updateHsCodeByIds: "/operation/container/updateHsCodeByIds",
        infomation: "/operation/container/infomation",
        noNameStatus: "/order/os/goods/noNameStatus",
        nameStatus: "/order/os/goods/nameStatus",
        downloadContainerInvoice: '/file/jasper/download/container/invoice',
      },
      btnDisabled: false,
      selectedProcudeId: [],
    };
  },
  watch: {
    infomationList() {
      //使用$nextTick方法
      let that = this;
      that.$nextTick(() => {
        that.divHeight = 748 - (that.$refs.fixTop && that.$refs.fixTop.offsetHeight) || 0 + "px";
        console.log("元素", that.divHeight);
      });
    },
  },
  created() {
    this.initInfomation();
    // this.initOrderList();
  },
  mounted() {
  },
  methods: {
    handleSizeChangeEdit(val) {
      this.ipaginationEdit.currentPage = 1
      this.ipaginationEdit.pageSize = val
      this.proudctEditList();
    },
    handleCurrentChangeEdit(val) {
      this.ipaginationEdit.currentPage = val
      this.proudctEditList();
    },
    updateHscode() {
      putAction(this.url.updateHsCodeByIds, this.selectionRows)
          .then(res => {
            this.$message.success(res.message);
            this.handleQuery();
          })
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.containerId = this.$route.query.id
      // this.queryForm.isWhether = '2';
      if (this.$refs['GTable']) {
        this.$refs['GTable'].handleReset();
      }
      this.handleQuery();
    },
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$router.push("/docpage/Edit?orderNo=" + orderNo);

    },
    handleQuery() {
      let id = this.$route.query.id;
      this.loading = true;
      let form = {...this.queryForm, ...this.filterForm};

      for (let item in form) {
        if (typeof form[item] === "string") {
          form[item] = form[item].trim();
          if (form[item] === "") {
            form[item] = null;
          }
        }
      }
      const {prop, order} = this.isorter;
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf("asc") !== -1 ? "asc" : "desc",
      };
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === "startTime") {
          this.$set(params, "column", "startTime");
        } else {
          this.$set(params, "column", "createTime");
        }
        //提货中查询
        if (this.queryForm.column === "distributionTime") {
          this.$set(params, "column", "distributionTime");
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage;
        params.pageSize = this.ipagination.pageSize;
      }
      console.log("查询对象", params);
      params.containerId = id;

      if (typeof (params.unitValue) != "undefined") {
        const r = /^([1-9]*[0-9](\.[0-9]+)?)?$/;
        if (!r.test(params.unitValue)) {
          alert('单价必须为数字');
          this.handleReset();
          return;

        }
      }
      if (typeof (params.cmb) != "undefined") {
        const r = /^([1-9]*[0-9](\.[0-9]+)?)?$/;
        if (!r.test(params.cmb)) {
          alert('体积必须为数字');
          this.handleReset();
          return;
        }
      }
      if (typeof (params.kg) != "undefined") {
        const r = /^([1-9]*[0-9](\.[0-9]+)?)?$/;
        if (!r.test(params.kg)) {
          alert('毛重必须为数字');
          this.handleReset();
          return;
        }
      }
      if (typeof (params.ctn) != "undefined") {
        const r = /^([1-9]*[0-9](\.[0-9]+)?)?$/;
        if (!r.test(params.ctn)) {
          alert('件数必须为数字');
          this.handleReset();
          return;
        }
      }

      getAction(this.url.list, params)
          .then((res) => {
            if (res.success) {
              const {records, total} = res.result;
              this.tableData = records || res.result;

              this.ipagination &&
              (this.ipagination.total = total || res.result.length);
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    handleAdd() {
    },
    hadleReset() {
    },
    initOrderList() {
      let id = this.$route.query.id;
      getAction(this.url.list, {containerId: id})
          .then((res) => {
            if (res.success) {
              console.log(res);
              const {records, total} = res.result;
              this.tableData = records || res.result;
              console.log("表单数据", this.tableData);
              this.ipagination &&
              (this.ipagination.total = total || res.result.length);
            }
          })
    },
    initInfomation() {
      let id = this.$route.query.id;
      let that = this;
      console.log(id);
      if (id) {
        getAction(this.url.infomation, {
          containerId: this.$route.query.id,
        }).then((res) => {
          console.log(res);
          // console.log(res.result['order'])
          if (res.result["order"] != null) {
            that.infomation = res.result["order"];
          } else {
            that.infomation = {};
          }

          if (res.result["list"] != null) {
            that.infomationList = res.result["list"];
          } else {
            that.infomationList = [];
          }

          if (res.result['osContainer'] != null) {
            that.addCabinetForm = res.result['osContainer']
            that.addCabinetFormTwo = res.result['osContainer']
          }

        });
      }
    },
    handleSelectionChangeEdit(selection){
      let arr = []
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['procudeId'])
      }
      this.selectedProcudeId = [...arr];
    },
    //产品编辑
    productEdit(){
      this.form = {};
      this.proudctEditList();
    },
    proudctEditList() {
      this.form.containerId = this.$route.query.id
      if (this.ipaginationEdit) {
        this.form.pageNo = this.ipaginationEdit.currentPage
        this.form.pageSize = this.ipaginationEdit.pageSize
      }
      this.$set(this.form, "column", "createTime");
      this.$set(this.form, "order", "desc");
      getAction(this.url.list, this.form).then((res) => {
        if (res.success) {
          if (res.result) {
            const {records, total} = res.result
            this.tableDataEdit = records || res.result;
            this.ipaginationEdit && (this.ipaginationEdit.total = total || res.result.length)
          } else {
            this.tableDataEdit = [];
            this.ipaginationEdit = 0;
          }
          this.dialogFormVisible = true
        }
      })
    },
    noNameStatus() {
      getAction(this.url.noNameStatus+'?ids='+this.selectedProcudeId.join(","))
          .then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.proudctEditList();
            }
          })
    },
    nameStatus() {
      getAction(this.url.nameStatus+'?ids='+this.selectedProcudeId.join(","))
          .then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.proudctEditList();
            }
          })
    }

  },
};
</script>

<style lang="scss" scoped>
.el-col {
  white-space: nowrap;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: "Segoe UI";
  color: rgb(102, 102, 102);
}

.margin_15 {
  margin: 0 15px 0 2px;
  line-height: 25px;
}

.margin_2 {
  margin: 0 2px;
  line-height: 25px;
}
</style>
