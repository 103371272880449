<template>
    <el-card class="box-card" shadow="hover">
        <div class="content-wrap c-slim-scrollbar">
                <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
                    <el-row>

                         <el-col :span="24">
                        <el-form-item prop="" label="专属注册链接" label-width="120px">
                          <el-button  type="text"  @click="generateUrl">复制链接</el-button>
                          <el-button  type="text"  @click="generateCode">查看二维码</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="username" label="用户名">
                            <el-input v-model="form.username" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item  label="输入密码">
                            <el-input v-model="form.password" type="password" maxlength="15" @input="change($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item  label="确认密码">
                            <el-input v-model="form.passwordConfirm" type="password" maxlength="15" @input="change($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="phone" label="手机">
                            <el-input v-model="form.phone" :disabled="showEnglishOrRealName"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item prop="email" label="邮箱">
                            <el-input v-model="form.email" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>

          <!--          <el-col :span="24">
                        <el-form-item prop="qq" label="QQ">
                            <el-input v-model="form.qq"></el-input>
                        </el-form-item>
                    </el-col>-->

                    <el-col :span="24">
                        <el-form-item prop="englishName" label="英文名">
                            <el-input v-model="form.englishName" :disabled="showEnglishOrRealName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item prop="realname" label="中文名">
                            <el-input v-model="form.realname" :disabled="showEnglishOrRealName"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item prop="sex" label="性别">
                            <el-radio-group v-model="form.sex">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="2">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                        <el-col :span="24">
                            <el-form-item prop="leader" label="直属领导" >
                                <f-select :dict="'sys_user_id'"
                                          v-model="form.leader"
                                          :isNeed="form.leader"
                                ></f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item prop="departIds" label="所属部门" >
                                <tree-dropdown v-model="form.departIds" :data="options" :value="form.departIds"></tree-dropdown>
                            </el-form-item>
                        </el-col>

                </el-row>
            </el-form>
            <div class="el-dialog__footer center_button">
                <!-- <el-button plain size="small" @click="dosome">取消</el-button> -->
                <!-- <el-button type="primary" size="small" @click="updateInfo" :disabled="ifDis">确定</el-button> -->
                <el-button type="primary" size="small" @click="updateInfo"
                           :disabled="ifDis"
                           v-if="buttonShowList.ok"
                >保存</el-button>
            </div>
        </div>
        <code-dialog :visible="codeVisible" :codeInfo="codeInfo" @update:visible="(value) => { codeVisible = value; }"/>
    </el-card>

</template>

<script>
  
    import {putAction, getAction} from '@/api/manage'
    import { mobilePattern } from '@/utils/pattern'
    import Edit from "../supplier/Edit";
    import TreeDropdown from './modules/TreeDropdown'
    import CodeDialog from "@/components/Layout/CodeDialog";

    const form = {
        id: '',
        username: '',
        sex: 1,
        realname: '',
        englishName: '',
        phone: '',
        email: '',
      /*  qq: '',*/
        password: '',
        passwordConfirm: '',
    }

    export default {
        name: 'AddUserDrawer',
        components: {Edit,TreeDropdown,CodeDialog},
        created() {
            this.handleGetDepartData();
            this.getInfo();
        },
        props: {

        },
        data() {
            return {

              showEnglishOrRealName: false,
                ifDis:false,
                options: [],
                roleOptions: [],
                form: {...form},
                rules: {
                    username: [
                        {required: true, message: '请输入用户名', trigger: 'change'},
                    ],
                    sex: [
                        {required: true, message: '选择性别', trigger: 'change'},
                    ],
                    realname: [
                        {required: true, message: '请输入中文名', trigger: 'change'},
                    ],
                    englishName: [
                        {required: true, message: '请输入英文名', trigger: 'change'},
                    ],
                    phone: [
                        {required: true, message: '请输入手机号', trigger: 'change'},
                        // {pattern: mobilePattern, message: '请输入正确的手机号', trigger: 'change' },
                    ],
                    email: [
                        {required: true, message: '请输入邮箱', trigger: 'change'},
                        {type: 'email', message: '请输入正确的邮箱', trigger: 'change'},
                    ],
                  /*  qq: [
                        {required: true, message: '请输入QQ', trigger: 'change'},
                    ],*/
                    password: [

                        {pattern: /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,15}$/ ,message: "密码长度6-15位，至少包含数字跟字母，可以有字符！", trigger: 'change'},
                        {validator: this.validatePass1, trigger: 'change'},
                    ],
                    passwordConfirm: [

                        {pattern: /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,15}$/ ,message: "密码长度6-15位，至少包含数字跟字母，可以有字符！", trigger: 'change'},
                        {validator: this.validatePass2, trigger: 'change'},
                    ],

                },
                url: {
                    info: '/sys/user/info/',
                    edit: '/sys/user/editPersonInfo'
                },
                codeVisible:false,
                codeInfo:{}
            }
        },
        watch: {},
        mounted() {

        },
        methods: {
            dosome() {
            },
            getInfo() {
                getAction(this.url.info + this.$store.state.user.userInfo.username).then((res) => {
                    // consoloe.log('userinfo',res)
                    this.form = res.result;
                    this.$set(this.form, "password", '');
                    this.$set(this.form, "passwordConfirm", '');
                })
            },
            updateInfo(){
                // consoloe.log(this.form)
                this.$refs.form.validate((valid) => {
                    this.form.passwordConfirm = this.form.passwordConfirm===undefined ? "":this.form.passwordConfirm
                    if (!(this.form.password === this.form.passwordConfirm)){
                        this.$message({
                            type: 'warning',
                            message: '两次输入的密码不一致！'
                        })
                        return false
                    }
                    if (valid) {
                        this.ifDis = true
                        this.enabled()
                        let params;
                        //对密码加密
                        if (this.form.password!==''){
                            // let Base64 = require('js-base64').Base64;
                            // const p = Base64.encode(this.form.password);
                            params = {
                                id:this.form.id,
                              /*  qq:this.form.qq,*/
                                password:this.form.password,
                                sex:this.form.sex,
                                leader:this.form.leader,
                                departIds:this.form.departIds,
                                phone:this.form.phone,
                            }
                        }else{
                            params = {
                                id:this.form.id,
                             /*   qq:this.form.qq,*/
                                sex:this.form.sex,
                                leader:this.form.leader,
                                departIds:this.form.departIds,
                                phone:this.form.phone,
                            }
                        }
                        putAction(this.url.edit, params).then((res)=>{
                            if (res.success){
                                this.$message({
                                    type: 'success',
                                    message: '修改成功，请重新登录！',
                                    duration: 8000
                                })
                                this.getInfo();
                                // 修改密码重新登录
                                this.$store.dispatch('user/resetToken').then(() => {
                                  // consoloe.log('原来的store',this.$store)
                                  this.$router.push({'name':'Login'});
                                  localStorage.clear();
                                  store.commit("setToken", '');
                                  // location.reload();
                                  // consoloe.log('后来的store',this.$store);
                                  //清除缓存
                                })
                            }else{
                                this.$message({
                                    type: 'error',
                                    message: '操作失败！'
                                })
                                this.getInfo();
                            }

                        })
                    }
                })
            },
            enabled(){
                setTimeout(()=>{
                    this.ifDis = false;
                },2000)
            },
            validatePass1(rule, value, callback){
                // this.$refs['form'].validateField('passwordConfirm');

                if (value === '' && (this.form.passwordConfirm !== '' && this.form.passwordConfirm !== undefined)) {
                    callback(new Error('请输入密码'))
                    // password 是表单上绑定的字段
                } else if (value === '' && (this.form.passwordConfirm === '' || this.form.passwordConfirm === undefined)){
                    callback()
                } else if (value !== '' && (this.form.passwordConfirm === '' || this.form.passwordConfirm === undefined)){
                    callback(new Error('请输入确认密码!'))
                } else if (value !== this.form.passwordConfirm) {
                    callback(new Error('两次输入密码不一致!'))
                } else {callback()}
            },
            validatePass2(rule, value, callback){
                this.$refs['form'].validateField('password');
                if ((value === '' || value === undefined) && this.form.password !== '') {
                    callback(new Error('请再次输入密码'))
                    // password 是表单上绑定的字段
                } else if ((value === '' || value === undefined) && this.form.password === ''){
                    callback()

                } else if (value === '' && (this.form.passwordConfirm !== '' || this.form.passwordConfirm !== undefined)){
                    callback(new Error('请输入密码!'))
                } else if (value !== this.form.password) {
                    callback(new Error('两次输入密码不一致!'))
                    // this.$refs['form'].validateField('password');
                } else {callback()}
            },
            async handleGetDepartData() {
              await  getAction('/sys/sysDepart/queryTreeList').then(res => {
                  // consoloe.log('获取所属部门',res)
                    this.options = res.result;
                })
            },
            change(e){
              this.$forceUpdate();
            },
            generateUrl(){
                  //  let api = "http://192.168.2.8:8088";  //本地
                 let api = "http://120.25.172.133:9800"  //测试环境
                 if (process.env.VUE_APP_API_URL == "http://8.135.121.105:9000") {
                    // 线上环境
                    api = 'https://do.do2do.com'
                 }
                let _this = this;
                this.$copyText(`${api}/login/registerPhone?realname=${encodeURI(this.form.realname)}&workNo=${this.form.workNo}`).then(() => {
                    _this.$message({
                        message: '复制成功，可以使用ctrl+v快捷键粘贴',
                        type: 'success'
                    });
                }).catch(e => {
                    _this.$message.error('复制失败');
                })
            },
            generateCode(){
                this.codeInfo={
                    realname:this.form.realname,
                    workNo:this.form.workNo
                }
                this.codeVisible=true
            }

        },
      computed:{
          buttonShowList(){
            return {
              'ok':this.$btnIsShow('isystem/PersonInfo','0','保存'),
            }
          }
      }
    }
</script>

<style lang="scss" scoped>
    .box-card {
        padding-top: 20px;
        width: 100%;
        height: 100%;
    }
    .center_button{
        text-align: center;
    }
</style>
