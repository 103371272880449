<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
            <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label="操作人">
            <f-select
                v-model="queryForm.createBy"
                :is-need="queryForm.createBy"
                :dict="'sys_user'"
            >
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="8">
          <el-form-item label="操作时间">
            <div class="f-flex">
              <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTime"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
              <span class="f-p-h-2">~</span>
              <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </div>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label="操作类型">
<!--            <f-select-->
<!--                :dict="'cus_history'"-->
<!--                v-model="queryForm.logType"-->
<!--                :is-need="queryForm.logType"-->
<!--                placeholder="请选择">-->

<!--            </f-select>-->
            <el-input v-model="queryForm.logType"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="4">
          <el-form-item label-width="30px">
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
        </el-card>

    </el-form>
    <el-card class="box-card last_card">
        <div class="f-p-b-15">
        <el-button size="medium" type="primary" icon="el-icon-download" @click="handleExportXls"
                   v-if="buttonShowList.down"
        >导出</el-button>
    </div>
    <div class="c-alert f-m-b-10">
      已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
      <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
    </div>
    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="58vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleThaw(scope.row)">解冻</el-button>
          <el-button type="text" @click="handleDel(scope.row)">删除</el-button>
        </template>
        <template v-else-if="scope.column.property === 'createBy'">
          <span>{{parseDict(scope.row.createBy,'sys_create_by')}}</span>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    </el-card>

  </div>
</template>

<script>
import { putAction, deleteAction,getallusers } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js"
export default {
  name: 'Frozen',
  mixins: [QueryMixins],
  components: {

  },
  data() {
    return {
      dictCodes:['sys_create_by'],
      visible: false,
      row: {},
      props:{
        userName:{
          type :String
        }
      },
      columns: [
        {
          type: 'selection',
          width: 50
        },
        {
          prop: 'createBy',
          label: '操作人',
          sortable: 'custom',
        },
        {
          prop: 'logType',
          label: '操作类型',
          sortable: 'custom',
        },
        {
          prop: 'createTime',
          label: '操作时间',
          sortable: 'custom',
        },
        {
          prop: 'content',
          label: '操作描述',
          sortable: 'custom',
        },
      ],
      url: {
        list: '/customer/log/list',

        exportXlsUrl: '/customer/log/exportXls',


      },
      userlist:[],


    }
  },
  methods: {
    handleEdit(row) {
      this.row = row
      this.visible = true
    },
    initUserList(){
      getallusers().then(
          res=>{
            if (res.success){
              for(var i=0;i<res.result.records.length;i++){
                var obj = {};
                obj.value = res.result.records[i].id;
                obj.label = res.result.records[i].username;
                this.userlist.push(obj);
              }
            }
          }
      )
    },
    handleDel() {
      // this.$confirm(`确定批量删除共?`, '提示', {
      //         confirmButtonText: '确定',
      //         cancelButtonText: '取消',
      //         type: 'success',
      //         closeOnClickModal: false,
      // }).then(() => {
      //     deleteAction(this.url.deleteBatch, {id}).then(res => {
      //         this.$message({
      //             type: 'success',
      //             message: res.message
      //         })
      //         this.handleQuery()
      //     })
      // }).catch(() => {

      // })
    },
    handleBatApproval() {
      var cname = this.selectedRowKeys;
      this.$confirm(`确定将【${cname}】解冻?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.batchjiedong+"?ids="+this.selectedRowKeys.join(',')).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleBatDel() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        // deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
        //     this.$message({
        //         type: 'success',
        //         message: res.message
        //     })
        //     this.handleSearch()
        // })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleThaw(row) {
      this.$confirm(`确定解冻${row.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.jiedong, {id: row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleReset(){
      this.queryForm = {}
      this.queryForm.cusId = this.$route.query.id
      this.ipagination.currentPage = 1

      this.handleQuery()
    },
  },
  created() {
    this.initUserList();
    this.queryForm.cusId = this.$route.query.id
  },
  computed : {
    buttonShowList() {
      return {
        'down':this.$btnIsShow('customer/Edit','0','导出历史记录'),

      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
