<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :span="4">
            <el-form-item label="订单号" label-width="70px">
              <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="运单号" label-width="70px">
              <el-input v-model="queryForm.runOrderNo" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="追踪号" label-width="70px">
              <el-input v-model="queryForm.logNo" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="目的地" label-width="70px">
              <el-input v-model="queryForm.receiveCountry" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="订单状态" label-width="70px">
              <f-select v-model="queryForm.orderStatus" dict="kd_order_status" :is-need="queryForm.orderStatus" @keyup.enter.native="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

    </el-form>
    <el-card class="box-card last_card">


      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="58vh"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js"

export default {
  name: 'KdOrderModule',
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      columns: [
        {
          prop: 'orderNo',
          label: '订单号',
        },
        {
          prop: 'runOrderNo',
          label: '运单号',
        },
        {
          prop: 'logNo',
          label: '追踪号',
        },
        {
          prop: 'receiveCountry',
          label: '目的地',
        },
        {
          prop: 'ctn',
          label: '件数',
        },
        {
          prop: 'updateFeeWeight',
          label: '计费重',
        },
        {
          prop: 'orderStatus',
          label: '订单状态',
        },
        {
          prop: 'createTime',
          label: '下单时间',
        },
      ],
      url: {
        list: '/customer/osOrder/kdlist',
      },
    }
  },
  methods: {

    handleReset() {
      this.queryForm = {}
      this.queryForm.cusId = this.$route.query.id
      this.ipagination.currentPage = 1
      this.handleQuery()
    },
  },
  created() {
    this.queryForm.cusId = this.$route.query.id
  }
}
</script>

<style lang="scss" scoped>

</style>
