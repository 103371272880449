<template>
  <div class="main-full-content">
    <el-card class="box-card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleAdd({})"
          v-if="buttonShowList.add"
          >新增</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="74vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
                       v-if="buttonShowList.edit"
              >编辑</el-button
            >
            <el-button type="text" @click="handleDel(scope.row)"
                       v-if="buttonShowList.del"
              >删除</el-button
            >
          </template>
        </template>
      </f-table>
    </el-card>

    <el-dialog
      :title="form.id ? '编辑子账号' : '新增子账号'"
      v-if="dialogFormVisible"
      :visible.sync="dialogFormVisible"
      width="30%"
      center
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="账户名"
          prop="username"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.username" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phone" :label-width="formLabelWidth">
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="联系人" prop="name" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="medium"
          type="primary"
          @click="savesub"
          :disabled="btnDisable"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { httpAction, deleteAction } from "@/api/manage";
import { emailPattern, mobilePattern } from "@/utils/pattern";
import { telValidator } from "@/utils/validator";

export default {
  name: "SubAccountModule",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: "120px",
      btnDisable: false,
      form: {},
      rules: {
        username: [
          { required: true, message: "请输入账户名", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          // {
          //   pattern: mobilePattern,
          //   message: "请输入正确的联系方式",
          //   trigger: "change",
          // },
          // { validator: telValidator, trigger: "blur" },
        ],

        name: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          // {
          //   pattern: emailPattern,
          //   message: "请输入正确的邮箱",
          //   trigger: "change",
          // },
        ],
      },
      row: {},
      visible: false,
      columns: [
        {
          type: "index",
          label: "#",
          width: 50,
          sortable: "custom",
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "username",
          label: "账户名(登录用)",
          sortable: "custom",
        },

        {
          prop: "phone",
          label: "手机号",
          sortable: "custom",
        },
        {
          prop: "email",
          label: "邮箱",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",
        },
      ],
      url: {
        list: "/customer/getSubAccount",
        add: "/customer/sub/add",
        edit: "/customer/sub/edit",
        del: "/customer/delete",
      },
    };
  },
  methods: {
    handleDel({ id, username }) {
      this.$confirm("确定删除" + username + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.del, { id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    handleEdit(row) {
      this.btnDisable = false;
      this.form = row;
      this.dialogFormVisible = true;
    },
    handleAdd() {
      this.form = {};
      //新增是判断 是否超过了五个
      // let limit = this.ipagination.total;
      // if (limit >= 5) {
      //   this.$message.warning("最多添加五个子账号");
      //   return false;
      // }
      this.form.parentId = this.$route.query.id;
      this.dialogFormVisible = true;
      this.btnDisable = false;
    },
    savesub() {
      this.btnDisable = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let url = this.url.add;
          let method = "post";
          if (this.form.id) {
            url = this.url.edit;
            method = "put";
          }
          httpAction(url, method, this.form)
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.dialogFormVisible = false;
                this.handleQuery();
              } else {
                this.btnDisable = false;
              }
            })
            .catch((err) => {
              this.btnDisable = false;
            });
        } else {
          this.btnDisable = false;
        }
      });
    },
  },
  created() {
    this.form.parentId = this.$route.query.id;
    this.queryForm.parentId = this.$route.query.id;
  },
  computed : {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('customer/modules/SubAccountModule','0','新增'),
        'edit':this.$btnIsShow('customer/modules/SubAccountModule','1','编辑'),
        'del':this.$btnIsShow('customer/modules/SubAccountModule','1','删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
