<template>
  <div class="main-full-content">

    <el-divider content-position="left"></el-divider>
    <el-tabs tab-position="left" @tab-click="handleClick" v-model="clickLabel">
      <template v-for="(tab, index) in tabs">
        <el-tab-pane :name="tab.label" :key="index" :label="tab.label" :lazy="true"
                     v-if="(isShow && tab.label!=='子账号') || tab.label === '基本信息' || ( isShow && tab.label==='子账号' && !parentId)">
          <component :is="tab.component" v-if="tab.show"></component>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import BasicModule from '@/views/customer/modules/BasicModule'
import HistoryModule from '@/views/customer/modules/HistoryModule'
import AddressModule from '@/views/customer/modules/AddressModule'
import InvoiceModule from '@/views/customer/modules/InvoiceModule'
import ContactModule from '@/views/customer/modules/ContactModule'
import SupplierModule from '@/views/customer/modules/SupplierModule'
import CusAccountModule from '@/views/customer/modules/CusAccountModule'
import ImporterModule from '@/views/customer/modules/ImporterModule'
import ProductModule from '@/views/customer/modules/ProductModule'
import VisitModule from '@/views/customer/modules/VisitModule'
import FileModule from '@/views/customer/modules/FileModule'
import ConsignorModule from '@/views/customer/modules/ConsignorModule'
import SubAccountModule from '@/views/customer/modules/SubAccountModule'
import NoticeModule from "@/views/customer/modules/NoticeModule";
import {getUrlKey} from '@/utils/route'
import {getAction} from "@/api/manage";
import InsuranceModule from "@/views/customer/modules/InsuranceModule";
import ReceiveModule from "@/views/customer/modules/ReceiveModule";
import SettlementModule from "@/views/customer/modules/SettlementModule";
import CustomerDetailForm from "@/views/customer/modules/CustomerDetailForm";
import SlycModule from "@/views/customer/modules/SlycModule";
import DisCountModule from "@/views/customer/modules/DisCountModule";
import OsOrderModule from "./modules/OsOrderModule";
import KdOrderModule from "./modules/KdOrderModule";

export default {
  name: 'Edit',
  components: {
    BasicModule,
    HistoryModule,
    AddressModule,
    CusAccountModule,
    InvoiceModule,
    ContactModule,
    SupplierModule,
    ImporterModule,
    ProductModule,
    VisitModule,
    FileModule,
    ConsignorModule,
    SubAccountModule,
    NoticeModule,
    InsuranceModule,
    ReceiveModule,
    SettlementModule,
    CustomerDetailForm,
    SlycModule,
    DisCountModule,
    OsOrderModule,
    KdOrderModule
  },
  data() {
    return {
      tabs: [
        {label: '基本信息', component: 'BasicModule', show: true},
        {label: '客户业务', component: 'CustomerDetailForm', show: true},
        {label: '海运订单', component: 'OsOrderModule', show: true},
        {label: '快递订单', component: 'KdOrderModule', show: true},
        {label: '历史记录', component: 'HistoryModule', show: true},
        {label: '收货地址', component: 'AddressModule', show: true},
        {label: '付款开票', component: 'InvoiceModule', show: true},
        {label: '联系人', component: 'ContactModule', show: true},
        {label: '提货供应商', component: 'SupplierModule', show: true},
        {label: '进口商', component: 'ImporterModule', show: true},
        {label: '银行账户信息', component: 'CusAccountModule', show: true},
        {label: '产品库', component: 'ProductModule', show: true},
        {label: '拜访记录', component: 'VisitModule', show: true},
        {label: '文件管理', component: 'FileModule', show: true},
        {label: '发货人', component: 'ConsignorModule', show: true},
        {label: '收货方', component: 'ReceiveModule', show: true},
        {label: '通知人', component: 'NoticeModule', show: true},
        {label: '被保人', component: 'InsuranceModule', show: true},
        {label: '子账号', component: 'SubAccountModule', show: true},
        {label: '结算对象', component: 'SettlementModule', show: true},
        {label: '优惠减免', component: 'DisCountModule', show: true},
        {label: '森林云仓', component: 'SlycModule', show: true},

      ],
      tabs2: [
        // {label: '基本信息', component: 'BasicModule', show: true},
        {label: '客户业务', component: 'CustomerDetailForm', show: true},
        {label: '海运订单', component: 'OsOrderModule', show: true},
        {label: '快递订单', component: 'KdOrderModule', show: true},
        {label: '历史记录', component: 'HistoryModule', show: true},
        // {label: '收货地址', component: 'AddressModule', show: true},
        // {label: '付款开票', component: 'InvoiceModule', show: true},
        // {label: '联系人', component: 'ContactModule', show: true},
        {label: '提货供应商', component: 'SupplierModule', show: true},
        {label: '进口商', component: 'ImporterModule', show: true},
        {label: '银行账户信息', component: 'CusAccountModule', show: true},
        {label: '产品库', component: 'ProductModule', show: true},
        {label: '拜访记录', component: 'VisitModule', show: true},
        // {label: '文件管理', component: 'FileModule', show: true},
        // { label: '发货人', component: 'ConsignorModule', show: true },
        // { label: '收货方', component: 'ReceiveModule', show: true },
        // { label: '通知人', component: 'NoticeModule', show: true },
        // { label: '被保人', component: 'InsuranceModule', show: true },
        // { label: '子账号', component: 'SubAccountModule', show: true },
        // { label: '结算对象', component: 'SettlementModule', show: true },
        {label: '优惠减免', component: 'DisCountModule', show: true},
        {label: '森林云仓', component: 'SlycModule', show: true},

      ],
      //将页面的id传递至下一层
      pageId: '',
      url: {
        getById: '/customer/getById',
        checkCanSeeCustomerInfo: '/customer/checkCanSeeCustomerInfo',
      },
      //是否显示
      isShow: !!this.$route.query.id,
      parentId: '',
      clickLabel: '基本信息',
      customer: {},
    }
  },
  watch: {
    '$route': {
      deep: true,
      handler(to, from) {
        if (to.path !== '/customer/Edit') {
          // console.log(to,from,'跳转的地址不是/customer/Edit');
          return;
        }
        let clickIndex = 0;
        this.initPageid();
        this.tabs.forEach((item, index) => {
          item.show = false;
          if (this.clickLabel && this.clickLabel == item.label) {
            clickIndex = index
          }
        })

        setTimeout(() => {
          this.tabs[clickIndex].show = true;
        }, 50);
      }
    }
  },
  methods: {
    checkCustomerInfo() {
      let id = this.$route.query.id;
      let flag = this.buttonShowList.seeYs;
      let canSeeBasicInfo = this.buttonShowList.seeB;
      if (flag) {

      } else {
        if (id && id.length > 0) {
          getAction(this.url.checkCanSeeCustomerInfo, {id: id})
              .then(res => {
                if(!canSeeBasicInfo){
                  this.tabs = this.tabs.filter(item => item.label !='基本信息')
                }

              }).catch(err=>{
            this.tabs = this.tabs2;
          })
        }
      }

    },
    handleClick(tab) {
      this.tabs.map(t => {
        if (t.label !== tab.label) {
          t.show = false;
        } else {
          t.show = true;
        }
      })
    },
    async initPageid() {
      this.checkCustomerInfo();
      this.isShow = !!this.$route.query.id;
      var id = getUrlKey('id');
      if (id == null || id == '' || id == 'undefined') {
        this.pageId = '';
      } else {
        this.pageId = id;
        //根据id获取客户名字
        await getAction(this.url.getById, {id: id})
            .then(res => {
              if (res.success) {
                this.customer = res.result;
                this.parentId = res.result.parentId;
              }
            })
      }
    }
  },
  created() {
    this.initPageid();
  },
  beforeCreate() {
  },
  computed : {
    buttonShowList() {
      return {
        'seeYs':this.$btnIsShow('isystem/RoleManage', '0', '是否可以查看客户隐私信息'),
        'seeB':this.$btnIsShow('isystem/RoleManage', '0', '是否可以查看基本信息'),

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-divider--horizontal {
  margin-top: 16px;
  margin-bottom: 20px;

  .el-divider__text {
    font-size: 20px;
  }
}
</style>
