<template>
    <el-dialog title="修改邮箱" :visible="visible" :close-on-click-modal="false" width="560px"
        custom-class="c-custom-dialog" :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="94px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="原始邮箱">
                        <el-input v-model.trim="row.email" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="email" label="新邮箱">
                        <div class="flex_center_start">
                            <el-input v-model.trim="form.email"></el-input>
                            <el-button type="text" @click="hanldeSendEmail" :disabled="!BtnStatus">
                                {{ BtnStatus ? '发送验证码' : countDownTime +
                                '秒后获取' }}</el-button>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="captcha" label="验证码">
                        <el-input v-model.trim="form.captcha"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { httpAction, getAction } from '@/api/manage'
    export default {
        name: 'EditEmailDialog',
        props: {
            visible: {
                type: Boolean
            },
            row: {
                type: Object,
                default: () => { }
            },
        },
        data() {
            return {
                checkKey: null,
                form: {},
                rules: {
                    email: [
                        { required: true, message: '请输入新邮箱', trigger: 'change' },
                    ],
                    captcha: [
                        { required: true, message: '请输入验证码', trigger: 'change' },
                    ],
                },
                url: {
                    sendCodeByEmail: '/sys/sendCodeByEmail',
                    verifyCaptcha: '/sys/verifyCaptcha'
                },
                BtnStatus: true, //是否可以获取验证码
                countDownTime: 120,
            }
        },
        methods: {
            hanldeSendEmail() {
                if(!this.form.email) {
                    this.$message({
                        type: 'warning',
                        message: '请填写新邮箱'
                    })
                    return;
                }
                this.checkKey = new Date().getTime();
                console.log("this.form",)
                let params = {
                    key: this.checkKey,
                    email: this.form.email,
                    cusId: this.row.cusId
                }
                console.log(params, 'params')
                this.$store.commit("setFullscreenLoading", true);
                getAction(this.url.sendCodeByEmail, params).then((res) => {
                    this.$store.commit("setFullscreenLoading", false);
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                    this.countDownTime = 120;
                    this.codeCountDown();
                }).catch(err => {
                    this.$store.commit("setFullscreenLoading", false);
                })
            },
            codeCountDown() {
                this.BtnStatus = false;
                let time = setInterval(() => {
                    if (this.countDownTime <= 1) {
                        this.BtnStatus = true;
                        clearInterval(time);
                    } else {
                        this.countDownTime--;
                    }
                }, 1000);
            },
            handleSubmit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        let params = {
                            captcha: this.form.captcha,
                            checkKey: this.checkKey,
                            cusId: this.row.cusId,
                            email: this.form.email
                        }
                        this.$store.commit("setFullscreenLoading", true);
                        httpAction(this.url.verifyCaptcha, 'post', params).then((res) => {
                            this.$store.commit("setFullscreenLoading", false);
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose()
                            this.$emit('ok')
                        }).catch(err => {
                            this.$store.commit("setFullscreenLoading", false);
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
                this.$refs.form && this.$refs.form.resetFields()
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .el-dialog__body {
        padding: 20px 30px 20px 24px;
    }
</style>