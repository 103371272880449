<template>
  <div class="main-full-content">
    <el-card class="box-card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleAdd({})"
          v-if="buttonShowList.add"
          >新增</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :isorter="isorter"
        :options="typelist"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="74vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
                       v-if="buttonShowList.edit
                && scope.row.settlementType !== '3'"
              >编辑</el-button>
            <el-button type="text" @click="handleDel(scope.row)"
                       v-if="buttonShowList.edit"
              >{{ scope.row.datalevel == '1' ? '启用' : '禁用' }}</el-button>
<!--            <el-button type="text" @click="handleDel(scope.row)"-->
<!--                       v-if="$btnIsShow('customer/modules/SettlementModule','1','删除')"-->
<!--              >删除</el-button>-->
          </template>
          <template v-else-if="scope.column.property === 'type'">
            <span v-if="scope.row.type === '0'">公司负责人</span>
            <span v-else-if="scope.row.type === '1'">报价对接人</span>
            <span v-else-if="scope.row.type === '2'">操作联系人</span>
            <span v-else>财务联系人</span>
          </template>
          <template v-else-if="scope.column.property === 'isDefault'">
            <span v-if="scope.row.isDefault === '0'">否</span>
            <span v-else>是</span>
          </template>
          <template v-else-if="scope.column.property === 'settlementType'">
            <span>{{
              parseDict(scope.row.settlementType, "cus_settle_type")
            }}</span>
          </template>
        </template>
      </f-table>
    </el-card>

    <el-dialog
      :title="form.id ? '编辑结算对象' : '新增结算对象'"
      :visible.sync="dialogFormVisible"
      width="30%"
      center
      :close-on-click-modal="false"
      v-if="dialogFormVisible"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="结算对象"
          prop="settlement"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.settlement"
            :disabled="form.id ? true : false"
            autocomplete="off"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
         <el-form-item
          label="联系人"
          prop="contact"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.contact"
            autocomplete="off"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>

         <el-form-item
          label="电话"
          prop="tel"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.tel"
            autocomplete="off"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
         <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
          <el-input
                  v-model="form.email"
                  autocomplete="off"
                  maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="与客户的关系"
          prop="relation"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.relation"
            autocomplete="off"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
            v-model="form.remark"
            autocomplete="off"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
       
        <el-form-item
          label="对象类型"
          :label-width="formLabelWidth"
          prop="settlementType"
        >
          <f-select
            :dict="'cus_settle_type'"
            :is-need="form.settlementType"
            v-model="form.settlementType"
            v-if="fsee"
            :remove-value="'3'"
            @change="initSelect"
          ></f-select>
        </el-form-item>
<!--        选择客户 就是他自己-->
<!--        <el-form-item-->
<!--          label="对象类型id"-->
<!--          :label-width="formLabelWidth"-->
<!--          prop="settlementTypeId"-->
<!--          v-if="cusVis"-->
<!--        >-->
<!--          <f-select-->
<!--            :dict="'sys_cus_id'"-->
<!--            :is-need="form.settlementTypeId"-->
<!--            v-model="form.settlementTypeId"-->
<!--            :isOne="$route.query.id"-->
<!--            :rules="form.settlementType == '3' ? rules.settlementTypeId : []"-->
<!--            @changet="getcus"-->
<!--            @getOptions="getCusList"-->
<!--          ></f-select>-->
<!--        </el-form-item>-->
        <el-form-item
          label="对象类型id"
          :label-width="formLabelWidth"
          prop="settlementTypeId"
          v-if="salesVis"
        >
          <f-select
            :dict="'salesman'"
            :is-need="form.settlementTypeId"
            v-model="form.settlementTypeId"
            :rules="form.settlementType == '1' ? rules.settlementTypeId : []"
            @changet="getsales"
            @getOptions="getSalesList"
          ></f-select>
        </el-form-item>
        <el-form-item
          label="对象类型id"
          :label-width="formLabelWidth"
          prop="settlementTypeId"
          v-if="suppVis"
        >
          <f-select
            :dict="'supplierId'"
            :is-need="form.settlementTypeId"
            v-model="form.settlementTypeId"
            :rules="form.settlementType == '2' ? rules.settlementTypeId : []"
            @changet="getsupp"
            @getOptions="getSuppList"
          ></f-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="medium"
          type="primary"
          @click="saveContact"
          :disabled="btnDisable"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { mobilePattern, emailPattern } from "@/utils/pattern";
import { deleteAction, postAction, putAction, getAction } from "@/api/manage";

export default {
  name: "SettleModule",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      cusList: [],
      supplist: [],
      saleslist: [],
      cusVis: false,
      salesVis: false,
      suppVis: false,
      fsee: false,
      dictCodes: ["cus_settle_type"],
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      typelist: [
        { value: "0", label: "公司负责人" },
        { value: "1", label: "报价对接人" },
        { value: "2", label: "操作联系人" },
        { value: "3", label: "财务联系人" },
      ],
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      //表单校验
      rules: {
        settlement: [
          { required: true, message: "请输入结算对象", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        relation: [
          {
            required: true,
            message: "请填写结算对象与客户的关系",
            trigger: "change",
          },
        ],
        settlementType: [
          { required: true, message: "请填写对象类型", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请填写邮箱", trigger: "change" },
        ],
        settlementTypeId: [
          {
            required: true,
            message: "请选择结算对象类型的iD",
            trigger: "change",
          },
        ],
      },

      row: {},
      visible: false,
      columns: [
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "settlement",
          label: "结算对象",
          // headerType : 'string',
          minWidth: 100,
        },
          {
          prop: "contact",
          label: "联系人",
          // headerType : 'string',
          minWidth: 100,
        },
          {
          prop: "tel",
          label: "电话",
          // headerType : 'string',
          minWidth: 100,
        },
        {
          prop: "relation",
          label: "与客户的关系",
          // headerType: 'select',
          minWidth: 100,
        },
        {
          prop: "email",
          label: "邮箱",
          // headerType: 'string',
          minWidth: 140,
        },
        {
          prop: "remark",
          label: "备注",
          // headerType: 'string',
          minWidth: 140,
        },
        {
          prop: "settlementType",
          label: "对象类型",
          // headerType: 'datetime',
        },
        {
          prop: "createTime",
          label: "创建时间",
          // headerType: 'datetime',
          minWidth: 140,
        },
      ],
      url: {
        list: "/customer/customerSettlement/list",
        getbyid: "/customer/customerSettlement/getById",
        delbyid: "/customer/customerSettlement/delete",
        add: "/customer/customerSettlement/add",
        edit: "/customer/customerSettlement/edit",
      },
    };
  },
  created() {
    this.queryForm.cusId = this.$route.query.id;
    console.log(this.queryForm.cusId);
  },
  methods: {
    initSelect() {
      this.salesVis = false;
      this.cusVis = false;
      this.suppVis = false;
      let _self = this;
      setTimeout(function () {
        if (_self.form.settlementType == "1") {
          console.log("结算对象类型this.form.settlmentType ==1");
          _self.salesVis = true;
          _self.cusVis = false;
          _self.suppVis = false;
          if (
            !_self.saleslist.find((i) => i.value == _self.form.settlementTypeId)
          ) {
            _self.$set(_self.form, "settlementTypeId", "");
          }
        }
        if (_self.form.settlementType == "2") {
          console.log("结算对象类型this.form.settlmentType ==2");
          _self.salesVis = false;
          _self.cusVis = false;
          _self.suppVis = true;
          if (
            !_self.supplist.find((i) => i.value == _self.form.settlementTypeId)
          ) {
            _self.$set(_self.form, "settlementTypeId", "");
          }
        }
        if (_self.form.settlementType == "3") {
          console.log("结算对象类型this.form.settlmentType ==3");
          _self.salesVis = false;
          _self.cusVis = true;
          _self.suppVis = false;
          if (
            !_self.cusList.find((i) => i.value == _self.form.settlementTypeId)
          ) {
            _self.$set(_self.form, "settlementTypeId", "");
          }
        }
        if (_self.form.settlementType == "0") {
          console.log("结算对象类型this.form.settlmentType ==0");
          _self.salesVis = false;
          _self.cusVis = false;
          _self.suppVis = false;
          _self.$set(_self.form, "settlementTypeId", "");
        }
      }, 300);
    },
    handleEnable(row) {
      var msg = "启用";
      if(row.datalevel == '0') {
          msg = "禁用";
      }
      this.$confirm(`确定` + msg + `${row.settlement}`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
          closeOnClickModal: false,
      })
          .then(() => {
              deleteAction(this.url.delbyid, {id: row.id }).then((res) => {
                  this.$message({
                      type: "success",
                      message: res.message,
                  });
                  this.handleSearch();
              });
          });
    },

    handleDel(row) {
      this.$confirm(`确定删除${row.settlement}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delbyid, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    handleAdd() {
      this.form = {};
      this.fsee = true;
      //判断是否超过10
      let total = this.ipagination.total;
      if (total >= 100) {
        this.$message.warning("结算对象最多100个，无法新增了亲");
      } else {
        this.dialogFormVisible = true;
      }
      this.btnDisable = false;
    },
    getCusList(list) {
      console.log("拿到的集合", list);
      this.cusList = list;
    },
    getSalesList(list) {
      console.log("拿到的集合", list);
      this.saleslist = list;
    },
    getSuppList(list) {
      console.log("拿到的集合", list);
      this.supplist = list;
    },
    getcus(val) {
      console.log("选择的客户", val);
      this.$set(this.form, "settlement", val);
    },
    getsupp(val) {
      console.log("选择的供应商", val);
      this.$set(this.form, "settlement", val.split(":")[0]);
    },
    getsales(val) {
      console.log("选择的业务员", val);
      this.$set(this.form, "settlement", val.split("-")[0]);
    },
    handleEdit(row) {
      console.log(row);
      for (var name in row) {
        this.$set(this.form, name, row[name]);
      }

      //显示弹窗，给对象赋值
      this.dialogFormVisible = true;
      this.fsee = true;
      this.initSelect();
      this.btnDisable = false;
    },
    //新增或修改
    saveContact() {
      //给客户id赋值
      this.btnDisable = true;
      this.form.cusId = this.$route.query.id;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            //有id，编辑
            putAction(this.url.edit, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  //关闭弹窗
                  this.dialogFormVisible = false;
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          } else {
            //新增
            postAction(this.url.add, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.dialogFormVisible = false;
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          }
        } else {
          this.btnDisable = false;
        }
      });
    },
  },
  computed : {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('customer/modules/SettlementModule','0','新增'),
        'edit':this.$btnIsShow('customer/modules/SettlementModule','1','编辑'),

      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
