<!--待收货-->
<template>
    <div class="main-full-content">
        <div class="f-p-b-15 flex_wrap_start_start">
            <div class="flex_center_start_a">
              <el-button
                      class="fontButtonClass"
                      type="primary"
                      size="medium"
                      @click="saveVolume"
                      :disabled="(order.orderStatus != '0' && order.orderStatus != '6' && order.orderStatus != '1' && order.orderStatus != '2') || form.volumeList.length == 0">
                  保存
              </el-button>
              <el-button
                      type="primary"
                      size="medium"
                      @click="handleAddHang"
                      :disabled="order.orderStatus != '0' && order.orderStatus != '6' && order.orderStatus != '1' && order.orderStatus != '2'">
                  新增行
              </el-button>
                <el-button type="primary" size="medium" @click="deleteBatch">批量删除</el-button>
              <div class="el-input--medium" style="display: inline-block;margin-left: 20px">
                  <label for="">件数：</label>
                  <el-input v-model="ctnCount" type="number" @keydown="keyupSubmit(event)"
                            style="max-width: 80px"></el-input>
              </div>
              <el-button type="primary" size="medium" @click="initCtn">生成行</el-button>
              <div class="el-input--medium" style="display: inline-block;margin-left: 20px">
                  <el-input v-model="rowCount" type="number" style="max-width: 80px;"></el-input>
              </div>
              <el-button type="primary" size="medium" @click="addRowCount">相同件数</el-button>
              <el-button type="primary" size="medium" @click="deleteCtn">清除</el-button>
            </div>
            <el-row class="no_margin" style="width: 50%">
              <el-form
                ref="orderForm"
                :model="orderForm"
                label-width="120px"
                @submit.native.prevent
                size="medium">
                    <el-col :span="8">
                      <el-form-item
                              label="运单号"
                              prop="runOrderNo">
                          <el-input v-model="orderForm.runOrderNo"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="14">
                      <el-form-item
                              label="内部备注"
                              prop="insideRemark">
                          <el-input type="textarea" v-model="orderForm.insideRemark" maxlength="500" show-word-limit></el-input>
                      </el-form-item>
                  </el-col>
              </el-form>
            </el-row>
        </div>


        <el-form :model="form" ref="form" :rules="rules" style="width: 50%">
            <el-table
                    :data="form.volumeList"
                    ref="shiptab"
                    :summary-method="getSummaries"
                    :header-cell-style="{ 'text-align': 'center' }"
                    show-summary
                    class="suTable"
                    v-loading="loading"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @select="selected"
            >
                <!--       <el-table-column label="操作" width="55">
                            <template slot-scope="scope">
                                <el-checkbox :label="scope.row.name" v-model="check"></el-checkbox>
                            </template>
                        </el-table-column>-->
                <el-table-column class="aacheng" align='center' type="selection" prop="contentOriginal" label="操作"
                                 width="30">
                </el-table-column>
                <el-table-column prop="num" minWidth="40" label="编号">
                    <template slot-scope="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="actualKg" label="实重(KG)" minWidth="100">
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'volumeList.' + scope.$index + '.actualKg'"
                                :rules="rules.actualKg"
                        >
                            <el-input :ref="'inputEnter' + scope.$index + '0'" v-model="scope.row.actualKg" @keyup.native.enter="focusNext(scope.$index, 0)" @change="inputChange('actualKg',scope.row.actualKg)"></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="actualLong" label="长(CM)" minWidth="100">
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'volumeList.' + scope.$index + '.actualLong'"
                                :rules="rules.actualLong"
                        >
                            <el-input :ref="'inputEnter' + scope.$index + '1'" v-model="scope.row.actualLong" @keyup.native.enter="focusNext(scope.$index, 1)" @change="inputChange('actualLong',scope.row.actualLong)"></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="actualWide" label="宽(CM)" minWidth="100">
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'volumeList.' + scope.$index + '.actualWide'"
                                :rules="rules.actualWide"
                        >
                            <el-input :ref="'inputEnter' + scope.$index + '2'" v-model="scope.row.actualWide" @keyup.native.enter="focusNext(scope.$index, 2)" @change="inputChange('actualWide',scope.row.actualWide)"></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="actualHigh" label="高(CM)" minWidth="100">
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'volumeList.' + scope.$index + '.actualHigh'"
                                :rules="rules.actualHigh"
                        >
                            <el-input :ref="'inputEnter' + scope.$index + '3'" v-model="scope.row.actualHigh" type="number" @keyup.native.enter="focusNext(scope.$index, 3)" @change="inputChange('actualHigh',scope.row.actualHigh)"></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="cmbWeight" label="体积重(KG)" minWidth="100">
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'volumeList.' + scope.$index + '.cmbWeight'"
                                :rules="rules.cmbWeight"
                        >
                            {{ jiSuan2(scope.row) }}
                            <el-input
                                    v-model="scope.row.cmbWeight"
                                    type="number"
                                    disabled
                            ></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="feeWeight" label="计费重" minWidth="100">
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'volumeList.' + scope.$index + '.feeWeight'"
                                :rules="rules.feeWeight"
                        >
                            {{ jiSuan2(scope.row) }}
                            <el-input
                                    v-model="scope.row.feeWeight"
                                    type="number"
                                    disabled
                            ></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="action" label="删除后请保存">
                    <template slot="header">
                      <span style="color: red">删除后请保存</span>
                    </template>
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                @click="removeVolume(scope)"
                                :disabled="order.orderStatus != '0' && order.orderStatus != '6' && order.orderStatus != '1' && order.orderStatus != '2'"
                        >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>

        <el-dialog :title="'提示'" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
                   v-if="dialogFormVisible">
            <span>预计材积件数和实际录入的材积件数不一致，是否继续保存</span>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="medium" @click="sureInvoice">保存</el-button>
                <el-button type="primary" size="medium" @click="sureProblem" v-if="this.order.orderStatus!=='6'">进入问题件
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {deleteAction, getAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import {zhengshuxiaoshuPartten} from "../../../utils/pattern";

    export default {
        name: "VolumeOfWoodModule",
        mixins: [QueryMixins],
        props: {
            order: {
                type: Object,
                default: () => {
                },
            },
        },
        data() {
            return {
                rules: {
                    actualKg: [
                        {required: true, message: "请输入实际重量", trigger: "change"},
                        {
                            pattern: zhengshuxiaoshuPartten,
                            message: "请输入正整数或最多三位小数",
                            trigger: "change",
                        },
                    ],
                    actualLong: [
                        {required: true, message: "请输入长", trigger: "change"},
                        {
                            pattern: zhengshuxiaoshuPartten,
                            message: "请输入正整数或最多三位小数",
                            trigger: "change",
                        },
                    ],
                    actualWide: [
                        {required: true, message: "请输入宽", trigger: "change"},
                        {
                            pattern: zhengshuxiaoshuPartten,
                            message: "请输入正整数或最多三位小数",
                            trigger: "change",
                        },
                    ],
                    actualHigh: [
                        {required: true, message: "请输入高", trigger: "change"},
                        {
                            pattern: zhengshuxiaoshuPartten,
                            message: "请输入正整数或最多三位小数",
                            trigger: "change",
                        },
                    ],
                },
                emailList: [],
                ids: [],
                row: {},
                form: {volumeList: []},
                formLabelWidth: "100px",
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "num",
                        label: "编号",
                    },
                    {
                        prop: "actualKg",
                        label: "实重(KG)",
                        sortable: "custom",
                    },
                    {
                        prop: "actualLong",
                        label: "长(cm)",
                        sortable: "custom",
                    },
                    {
                        prop: "actualWide",
                        label: "宽(cm)",
                        sortable: "custom",
                    },
                    {
                        prop: "actualHigh",
                        label: "高(cm)",
                        sortable: "custom",
                    },
                    {
                        prop: "cmbWeight",
                        label: "体积重(KG)",
                        sortable: "custom",
                    },
                    {
                        prop: "feeWeight",
                        label: "计费重(KG)",
                        sortable: "custom",
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 140,
                    },
                ],
                url: {
                    list: "/order/KdOrderCtnCmb/list",
                    add: "/order/KdOrderCtnCmb/add",
                    edit: "/order/KdOrderCtnCmb/edit",
                    addList: "/order/KdOrderCtnCmb/addList",
                    getById: "/order/KdOrderCtnCmb/queryById",
                    delete: "/order/KdOrderCtnCmb/delete",
                    deleteBatch: "/order/KdOrderCtnCmb/deleteBatch",
                    getByChannelName: "/order/KdOrderChannel/getByChannelName", //根据渠道名称获取渠道
                    sureCtnCmb: "/order/KdOrderCtnCmb/sureCtnCmb", //确认材积
                    problem: "/order/KdOrderProblem/add", //标记问题件
                    queryById: "/order/KdOrderChannel/queryById", //获取渠道
                    editRunOrder: "/order/kdOrder/editRunOrder", //快递订单编辑
                    queryByOrderNo: "/order/kdOrder/queryByOrderNo/",
                },
                kdChannel: {}, //快递渠道
                shijifeeWeight: 0, // 实际计费重
                shijicmbWeight: 0, // 实际体积重
                shijiWeight: 0, // 实重
                problem: {},
                ctnCount: '', //生成行-绑定的值
                ctnCount2: '', // 新增行
                rowCount: '', //相同件数-绑定的值
                rowData: {}, // 选中的行数据
                selectionItemIndexes: '',
                orderForm: {},
                lowShijiWeight:0,    //算上最低重的 实重
                lowShijicmbWeight: 0, // 算上最低重的 体积重
            };
        },
        methods: {
            inputChange(valueName, value) {
              if (this.selectionItemIndexes !== '') {
                this.rowData[valueName] = value;
              }
            },
            focusNext(index, row) {
                let nextRef = '';
                if(row < 3) {
                  nextRef =  'inputEnter' + index + (row + 1)
                } else {
                  nextRef =  'inputEnter' + (index + 1) + 0
                }
                if(this.$refs[nextRef]) {
                  this.$refs[nextRef].focus();
                }
            },
            keyupSubmit(e) {
                var evt = window.event || e;
                if (evt.keyCode == 13) {
                    //回车事件
                    this.initCtn();
                }
            },
            // 单选
            selected(selection, row) {
                if (selection.length === 1) {
                    // this.selectionItemIndexes = selection.length ? this.form.volumeList.indexOf(row) : '';
                    this.selectionItemIndexes = selection.length ? this.form.volumeList.indexOf(selection[0]) : '';
                    this.rowData = {
                        actualHigh: row.actualHigh,
                        actualKg: row.actualKg,
                        actualLong: row.actualLong,
                        actualWide: row.actualWide,
                        cmbWeight: row.cmbWeight,
                        feeWeight: row.feeWeight,
                    };
                }

            },
            // handleSelectionChange(data) {
            //     // if (data.length == 0) {
            //     //     this.selectionItemIndexes = '';
            //     //     this.rowData = {};
            //     // }
            // },
            handleQuery() {
                this.loading = true;
                let form = {...this.queryForm, ...this.filterForm};
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const {prop, order} = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                        this.$set(params, "order", "asc");
                    }
                    //提货中查询
                    if (this.queryForm.column === "distributionTime") {
                        this.$set(params, "column", "distributionTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }

                getAction(this.url.list, params)
                    .then((res) => {
                        if (res.success) {
                            const {records, total} = res.result;
                            this.form.volumeList = records || res.result;

                            this.ipagination &&
                            (this.ipagination.total = total || res.result.length);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            handleAddHang() {
                this.ctnCount2 = this.ctnCount2 * 1 + 1;
                this.form.volumeList.push({
                    actualKg: "",
                    actualLong: "",
                    actualWide: "",
                    actualHigh: "",
                    cmbWeight: "",
                    feeWeight: "",
                });
            },
            //初始化行数
            initCtn() {
                const that = this;
                if (!that.ctnCount) {
                    this.$confirm(`请输入生成行数量`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                        })
                        .catch(() => {
                        });
                    return;
                } else if (that.ctnCount <= 0) {
                    this.$confirm(`请输入正确的生成行数量`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                        })
                        .catch(() => {
                        });
                    return;
                }
                if (this.form.volumeList.length > 0) {
                    this.$confirm(`确定清空已填写的信息吗?`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            let deleteIds = [];
                            this.form.volumeList.forEach((item, index) => {
                              if(item.id) {
                                deleteIds.push(item.id);
                              }
                            })
                            if(deleteIds && deleteIds.length) {
                              postAction(this.url.deleteBatch, deleteIds).then((res) => {
                                this.form.volumeList = [];
                                if (Number(this.ctnCount) > 0) {
                                    for (var i = 0; i < Number(this.ctnCount); i++) {
                                        this.form.volumeList.push({
                                            actualKg: "",
                                            actualLong: "",
                                            actualWide: "",
                                            actualHigh: "",
                                            cmbWeight: "",
                                            feeWeight: "",
                                        });
                                    }
                                }
                              });
                            } else {
                              this.form.volumeList = [];
                              if (Number(this.ctnCount) > 0) {
                                  for (var i = 0; i < Number(this.ctnCount); i++) {
                                      this.form.volumeList.push({
                                          actualKg: "",
                                          actualLong: "",
                                          actualWide: "",
                                          actualHigh: "",
                                          cmbWeight: "",
                                          feeWeight: "",
                                      });
                                  }
                              }
                            }
                        })
                        .catch(() => {
                        });
                } else {
                  if (Number(this.ctnCount) > 0) {
                      for (var i = 0; i < Number(this.ctnCount); i++) {
                          this.form.volumeList.push({
                              actualKg: "",
                              actualLong: "",
                              actualWide: "",
                              actualHigh: "",
                              cmbWeight: "",
                              feeWeight: "",
                          });
                      }
                  }
                }
            },
            // 添加行
            addRowCount() {
                let that = this;
                if (!that.rowCount) {
                    this.$confirm(`请输入相同件数数量`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                        })
                        .catch(() => {
                        });
                } else if (that.rowCount <= 0) {
                    this.$confirm(`请输入正确的相同件数数量`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                        })
                        .catch(() => {
                        });
                } else if (that.rowCount > 0) {
                    if (!that.rowData.actualHigh ||
                        !that.rowData.actualKg ||
                        !that.rowData.actualLong ||
                        !that.rowData.actualWide ||
                        !that.rowData.cmbWeight && feeWeight) {
                        that.$message({
                            message: '请先勾选有数据的一行',
                            type: 'warning'
                        })
                        return;
                    }
                    let j = that.form.volumeList.length - that.selectionItemIndexes;
                    let k = that.selectionItemIndexes;
                    if (j < that.rowCount) { // 空白行小于相同件数
                        that.$message({
                            message: '勾选行后面的行小于相同件数',
                            type: 'warning'
                        })
                    } else {
                        for (let i = 0; i < that.rowCount; i++) {
                            that.form.volumeList[i + k].actualHigh = that.rowData.actualHigh;
                            that.form.volumeList[i + k].actualKg = that.rowData.actualKg;
                            that.form.volumeList[i + k].actualLong = that.rowData.actualLong;
                            that.form.volumeList[i + k].actualWide = that.rowData.actualWide;
                            that.form.volumeList[i + k].cmbWeight = that.rowData.cmbWeight;
                            that.form.volumeList[i + k].feeWeight = that.rowData.feeWeight;
                        }
                    }

                }
            },
            deleteCtn() {
                if (this.selectedRowKeys.length === 0) {
                    this.$message({
                        message: '请先勾选要清除的行',
                        type: 'warning'
                    })
                    return;
                }
                if (this.selectedRowKeys.length > 1) {
                    this.$message({
                        message: '仅勾选单条进行清除',
                        type: 'warning'
                    })
                    return;
                }
                this.$confirm(`确定清空已选择的信息吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        // 清除要先进行删除操作，之后再置空
                        if (this.selectedRowKeys != null && this.selectedRowKeys != '') {
                            deleteAction(this.url.delete, {id: this.selectedRowKeys.join("")}).then((res) => {
                              this.$set(this.form.volumeList, this.selectionItemIndexes, {
                                  actualKg: "",
                                  actualLong: "",
                                  actualWide: "",
                                  actualHigh: "",
                                  cmbWeight: "",
                                  feeWeight: "",
                              })
                              this.selectionItemIndexes = ''
                            });
                        } else {
                            this.$set(this.form.volumeList, this.selectionItemIndexes, {
                              actualKg: "",
                              actualLong: "",
                              actualWide: "",
                              actualHigh: "",
                              cmbWeight: "",
                              feeWeight: "",
                          })
                          this.selectionItemIndexes = ''
                        }
                    })
                    .catch(() => {
                    });
            },
            deleteBatch() {
                if (this.selectedRowKeys.length === 0) {
                    this.$message({
                        message: '请先勾选要批量删除的行',
                        type: 'warning'
                    })
                    return;
                }
                this.$confirm(`确定批量删除已选择的信息吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                        postAction(this.url.deleteBatch, this.selectedRowKeys).then((res) => {
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },


            handleSave() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.form.orderNo = this.order.orderNo;
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                        } else {
                            postAction(this.url.add, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                        }
                    }
                });
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            //保存进入确认发票
            sureInvoice() {
                this.saveAndEdit();
                let isInvoice = false;
                if (this.order.orderStatus !== '6') {
                    isInvoice = true;
                }
                if (this.order.orderStatus == '1' || this.order.orderStatus == '2') {
                    isInvoice = false;
                }
                this.$store.commit("setFullscreenLoading", true);
                //确认材积 实重，体积重，以及计费重
                getAction(this.url.sureCtnCmb + "?orderNo=" + this.order.orderNo + "&feeWeight=" + this.shijifeeWeight + "&cmbWeight=" +
                    this.shijicmbWeight + "&actualWeight=" + this.shijiWeight + "&actualCtn=" + this.form.volumeList.length + "&isInvoice=" + isInvoice)
                    .then((res) => {
                        this.$store.commit("setFullscreenLoading", false);
                        if (res.success) {
                            //刷新表头数据
                            this.getOrderData();
                            this.dialogFormVisible = false;
                            //保存基本信息
                            this.handleorderSubmit();
                            if (isInvoice) {
                                //跳转到确认发票列表
                                this.$router.push("/kdorder/SureInvoice");
                            } else {
                                this.$message.success(res.message);
                            }
                        }
                    }).catch(err => {
                      this.$store.commit("setFullscreenLoading", false);
                    })


            },
            //保存进入问题件
            sureProblem() {
                this.saveAndEdit();
                //确认材积 实重，体积重，以及计费重
                getAction(this.url.sureCtnCmb + "?orderNo=" + this.order.orderNo + "&feeWeight=" + this.shijifeeWeight + "&cmbWeight=" +
                    this.shijicmbWeight + "&actualWeight=" + this.shijiWeight + "&actualCtn=" + this.form.volumeList.length + "&isInvoice=false").then((res) => {
                    if (res.success) {
                        //标记问题件
                        this.problem.orderNo = this.order.orderNo;
                        this.problem.problemType = "1";
                        this.problem.remarks = "下单时的预计材积件数和实际录入的材积件数不一致";
                        postAction(this.url.problem, this.problem)
                            .then((res) => {
                                if (res.success) {
                                    this.dialogFormVisible = false;
                                    //跳转到问题件列表
                                    this.$router.push("/kdorder/kdproblem/ProblemNo");
                                }
                            })
                    }
                })
            },

            async saveAndEdit() {
                this.form.volumeList.forEach(item => {
                    item.orderNo = this.order.orderNo
                })
                await postAction(this.url.addList, this.form.volumeList).then((res) => {
                  this.handleQuery();
                })
            },
            async saveVolume() {
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        //如果实际材积的件数和预计材积的件数不一致给出是否保存的提示
                        if (this.order.ctn !== this.form.volumeList.length) {
                            this.dialogFormVisible = true;
                        } else {
                            //进入确认发票
                            this.sureInvoice();
                        }
                    }
                });
            }
            ,
            removeVolume(scope) {
                this.$confirm(`确定删除材积信息`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        if (scope.row.id) {
                            deleteAction(this.url.delete, {id: scope.row.id}).then((res) => {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                                this.handleQuery();
                            });
                        } else {
                            this.form.volumeList.splice(scope.$index, 1);
                        }
                    })
                    .catch(() => {
                    });
            }
            ,
            jiSuan2(row) {
                if (
                    !row.actualLong ||
                    !row.actualWide ||
                    !row.actualKg ||
                    !row.actualHigh
                ) {
                    row.feeWeight = "";
                    row.cmbWeight = "";
                } else {
                  if (this.selectionItemIndexes !== '') {
                        // 有选中行数
                        // this.rowData = {
                        //     actualHigh: row.actualHigh,
                        //     actualKg: row.actualKg,
                        //     actualLong: row.actualLong,
                        //     actualWide: row.actualWide,
                        //     cmbWeight: row.cmbWeight,
                        //     feeWeight: row.feeWeight,
                        // };
                        this.rowData.cmbWeight = row.cmbWeight
                        this.rowData.feeWeight = row.feeWeight
                    }
                    let cheng = (row.actualLong * row.actualWide * row.actualHigh) /
                        this.kdChannel.cmbBase;
                    //体积重
                    this.$set(row, "cmbWeight", cheng.toFixed(3));
                    if (this.kdChannel.feeType === "1") {
                        if(Number(this.lowShijiWeight) > Number(this.lowShijicmbWeight)){
                            if(Number(row.actualKg) <  Number(this.kdChannel.minFeeKg)){  //实重小于最低计费重
                                this.$set(row, "feeWeight", this.kdChannel.minFeeKg);
                            }else{
                                this.$set(row, "feeWeight", row.actualKg); // 用实重
                            }

                        }else{
                            if(Number(row.cmbWeight) <  Number(this.kdChannel.minFeeKg)){  //实重小于最低计费重
                                this.$set(row, "feeWeight", this.kdChannel.minFeeKg);
                            }else{
                                this.$set(row, "feeWeight", row.cmbWeight); // 用体积重
                            }
                        }
                        /*if(Number(this.shijiWeight) > Number(this.shijicmbWeight)){
                            if(Number(row.actualKg) <  Number(this.kdChannel.minFeeKg)){  //实重小于最低计费重
                                this.$set(row, "feeWeight", this.kdChannel.minFeeKg);
                            }else{
                                this.$set(row, "feeWeight", row.actualKg); // 用实重
                            }

                        }else{
                            if(Number(row.cmbWeight) <  Number(this.kdChannel.minFeeKg)){  //实重小于最低计费重
                                this.$set(row, "feeWeight", this.kdChannel.minFeeKg);
                            }else{
                                this.$set(row, "feeWeight", row.cmbWeight); // 用体积重
                            }
                        }*/
                    }
                    if (this.kdChannel.feeType === "0") {
                        //单票
                        if (Number(cheng) < Number(this.kdChannel.minFeeKg) && Number(row.actualKg) < Number(this.kdChannel.minFeeKg)
                        ) {
                            //判断最低标准是否超重，超重则按照进1方式计算
                            if (Number(this.kdChannel.minFeeKg) > Number(this.kdChannel.overweight)) {
                                this.$set(row, "feeWeight", this.overcountJin(this.kdChannel.minFeeKg));
                            } else {
                                this.$set(row, "feeWeight", this.countJin(this.kdChannel.minFeeKg));
                            }
                        } else if (Number(row.actualKg) > Number(row.cmbWeight)) {
                            if (Number(row.actualKg) > Number(this.kdChannel.overweight)) {
                                this.$set(row, "feeWeight", this.overcountJin(row.actualKg));
                            } else {
                                this.$set(row, "feeWeight", this.countJin(row.actualKg));
                            }
                        } else if (Number(row.cmbWeight) > Number(row.actualKg)) {
                            if (row.cmbWeight > this.kdChannel.overweight) {
                                this.$set(row, "feeWeight", this.overcountJin(row.cmbWeight));
                            } else {
                                this.$set(row, "feeWeight", this.countJin(row.cmbWeight));
                            }
                        } else {
                            if (row.actualKg > this.kdChannel.overweight) {
                                this.$set(row, "feeWeight", this.overcountJin(row.actualKg));
                            } else {
                                this.$set(row, "feeWeight", this.countJin(row.actualKg));
                            }
                        }
                    }
                }
            }
            ,
            getSummaries(param) {
                const {columns, data} = param;
                const sums = [];
                const sumsMin = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = "总计";
                        sumsMin[index] = "总计";
                        return;
                    }
                    const values = data.map((item) => Number(item[column.property]));

                    if (!values.every((value) => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);

                        sumsMin[index] = values.reduce((prev, curr) => {
                              //整票
                              if (this.kdChannel.feeType === "1"){
                                  if (Number(curr) > 0) {
                                      if(Number(curr) < this.kdChannel.minFeeKg){
                                          curr = this.kdChannel.minFeeKg;
                                      }
                                      return prev + curr;
                                  } else {
                                      return prev;
                                  }
                              }else{
                                  const value = Number(curr);
                                  if (!isNaN(value)) {
                                      return prev + curr;
                                  } else {
                                      return prev;
                                  }
                              }

                        }, 0);

                        if (column.property == "actualKg") {
                            this.shijiWeight = sums[index].toFixed(3);
                            this.lowShijiWeight = sumsMin[index];
                            sums[index] = "实重：" + sums[index].toFixed(3);
                        } else if (column.property == "cmbWeight") {
                            this.shijicmbWeight = this.countJin(sums[index]);
                            this.lowShijicmbWeight = sumsMin[index];
                            sums[index] = "体积重：" + this.countJin(sums[index]);
                        } else if (column.property == "feeWeight") {
                            this.shijifeeWeight = this.overcountJin(sums[index]);
                            sums[index] = "计费重：" + this.overcountJin(sums[index]);

                        } else {
                            sums[index] = "";
                        }
                    } else {
                        sums[index] = "";
                    }
                });

                return sums;
            }
            ,
            //未超重
            countJin(val) {
                let zheng = parseInt(val);
                let xiao = parseFloat(val).toFixed(3) - zheng;
                if (0 == xiao) {
                    return zheng;
                }
                if (this.kdChannel.feeMode == 0.5) {
                    //进0.5
                    if (xiao < 0.5) {
                        return zheng + 0.5;
                    } else if (xiao == 0.5 ) {
                        return val;
                    }else{
                        return zheng + 1;
                    }
                }
                if (this.kdChannel.feeMode == 1) {
                    //进1
                    return zheng + 1;
                }
            }
            ,
            //超重进1
            overcountJin(val) {
                let zheng = parseInt(val);
                let xiao = (parseFloat(val).toFixed(3)) - zheng;
                if (0 === xiao) {
                    return zheng;
                }
                return zheng + 1;
            }
            ,
            //根据渠道名称获取渠道
            initChannel() {
                getAction(this.url.queryById + '?id=' + this.form.channel)
                    .then((res) => {
                        if (res.success) {
                            this.kdChannel = res.result;
                        } else {
                            this.$message.warning("获取渠道失败");
                        }
                    })
            }
            ,
            //保存订单基本信息
            async handleorderSubmit() {
                this.orderForm.id = this.order.id;
                await putAction(this.url.editRunOrder, this.orderForm).then((res) => {
                    if (res.success) {
                        //再次查询
                        this.getOrderData();
                    }
                });
            },
            getOrderData() {
              getAction(
                  this.url.queryByOrderNo + "?orderNo=" + this.order.orderNo
              ).then((res) => {
                  //更新父组件的order
                  this.$emit("update", res.result);
              });
            },
        },
        created() {
            if (this.order) {
                for (var name in this.order) {
                    this.$set(this.form, name, this.order[name]);
                    this.queryForm.orderNo = this.form.orderNo;
                }
                this.$set(this.orderForm, 'runOrderNo', JSON.parse(JSON.stringify(this.order)).runOrderNo);
                this.$set(this.orderForm, 'insideRemark', JSON.parse(JSON.stringify(this.order)).insideRemark);
                //根据渠道名称获取渠道
                this.initChannel();
            }

        }
        ,
        mounted() {
            var destination = document.querySelector(".has-gutter").childNodes[0].childNodes[0];
            destination = '<th>操作</th>'
            //   destination = 'block'

        }
    }
    ;

</script>

<style lang="scss" scoped>
    .suTable ::v-deep thead th .el-checkbox {
        visibility: hidden;
    }

    .fontButtonClass {
        width: 36px;
        height: 30px;
        padding: 0;
        line-height: 30px;
        span {
          font-size: 30px !important;
        }
    }

</style>
