<template>
    <el-dialog
            :title="row.id ? '编辑产品' : '新增产品'"
            :visible="visible"
            width="840px"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">
            <el-row class="inputs_row">
                <el-col :span="12">
                    <el-form-item prop="cname" label="中文名">
                        <el-input v-model="form.cname" show-word-limit maxlength="255" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="ename" label="英文名">
                        <el-input v-model="form.ename" show-word-limit maxlength="255" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="country" label="国家">
                        <el-select
                                clearable
                                filterable
                                @click.native="initCountryList"
                                class="f-full-width"
                                v-model="form.country"
                                placeholder="请选择">
                            <el-option
                                    v-for="(item,index) in countrylist"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="hscode" label="海关编码">
                        <el-input v-model="form.hscode" show-word-limit maxlength="50"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="addtionalCode" label="301编码">
                        <f-select v-model="form.addtionalCode" :is-need="form.addtionalCode" dict="hs_code_301"></f-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="taxRate" label="税率">
                        <el-input v-model="form.taxRate">
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="material" label="材质">
                        <el-input v-model="form.material" show-word-limit maxlength="1000" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="purpose" label="用途">
                        <el-input v-model="form.purpose" show-word-limit maxlength="1000" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="materialRate" label="材质比例">
                        <el-input v-model="form.materialRate" show-word-limit maxlength="1000" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="maker" label="生产厂商">
                        <el-input v-model="form.maker" show-word-limit maxlength="1000" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="model" label="产品型号">
                        <el-input v-model="form.model" show-word-limit maxlength="1000" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="size" label="产品尺寸">
                        <el-input v-model="form.size" show-word-limit maxlength="1000" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
              <el-col :span="12">
                <el-form-item prop="brand" label="品牌">
                  <el-input v-model="form.brand" show-word-limit maxlength="100" type="textarea"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="brandType" label="品牌类型">
                  <f-select v-model="form.brandType" :is-need="form.brandType" :dict="'brand_type'"></f-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="exportBenefits" label="出口享惠情况">
                  <f-select v-model="form.exportBenefits" :is-need="form.exportBenefits" :dict="'export_benefits'"></f-select>
                </el-form-item>
              </el-col>
                <el-col :span="12">
                    <el-form-item prop="demand" label="清关要求">
                        <el-select
                                clearable
                                filterable
                                multiple
                                class="f-full-width"
                                v-model="form.demandList"
                                placeholder="请选择">
                            <el-option
                                    v-for="(item,index) in demandList"
                                    :key="index"
                                    :label="item.text"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="makerAdd" label="厂商地址">
                        <el-input v-model="form.makerAdd" type="textarea" rows="3" show-word-limit maxlength="1000" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="description" label="备注">
                        <el-input v-model="form.remark" type="textarea" rows="3" show-word-limit maxlength="1000" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="picUrl" label="图片" ref="picUrl">
                        <f-upload v-model="picList" accept=".png,.jpeg,.pneg,.gif,.img,.jpg" :limit="1" :dir="'customer'"></f-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {httpAction} from '@/api/manage'
    import {getAction, getallcountries} from "@/api/manage";
    import {sanweixiaoshu} from "@/utils/pattern";
    import {noZhongwenValidator} from "@/utils/validator";

    export default {
        name: 'ProductDialog',
        props: {
            visible: {
                type: Boolean
            },
            row: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                picList: [],
                form: {},
                rules: {
                    cname: [
                        {required: true, message: '请输入产品中文名称', trigger: 'change'},
                    ],
                    ename: [
                        {required: true, message: '请输入产品英文名称', trigger: 'change'},
                      {validator:noZhongwenValidator,trigger:'change'},
                    ],
                    country: [
                        {required: true, message: '请选择国家', trigger: 'change'},
                    ],
                    hscode: [
                        {required: true, message: '请输入产品编码', trigger: 'change'},
                    ],
                    taxRate: [
                        {required: true, message: '税率须大于0', trigger: 'change'},
                        {pattern: sanweixiaoshu, message: "税率只能由正整数及3位小数组或者0", trigger: 'change'}
                    ],
                    material: [
                        {required: true, message: '请输入材质', trigger: 'change'},
                        {validator:noZhongwenValidator,trigger:'change'},
                    ],
                    purpose: [
                        {required: true, message: '请输入用途', trigger: 'change'},
                      {validator:noZhongwenValidator,trigger:'change'},
                    ],
                    maker: [
                        {required: true, message: '请输入生产厂商', trigger: 'change'},
                    ],
                    makerAdd: [
                        {required: true, message: '请输入厂商地址', trigger: 'change'},
                    ],
                },
                url: {
                    add: '/customer/product/add',
                    edit: '/customer/product/edit',
                    getDemandList: '/sys/dict/getDictItems/demand',
                },
                demandList: [],
                countrylist: [],
            }
        },
        watch: {
            visible(val) {
                if (val && this.row.id) {
                    this.form = {...this.row}
                }
                if (this.form.demand) {
                    this.$set(this.form, 'demandList', this.form.demand.split(','))
                } else {
                    this.$set(this.form, 'demandList', []);
                }
            },
            picList(val) {
                if (val && val.length > 0) {
                    if (this.$refs['picUrl']) {
                        this.$refs['picUrl'].clearValidate();
                    }
                }
            },
            row: {
                handler(newVal, oldVal) {
                    let list = [];
                    if (newVal.picUrl) {
                        let obj = {};

                        obj.url = newVal.picUrl;

                        if (newVal.picName) {
                            obj.name = newVal.picName;
                        }
                        list.push(obj);

                    } else if (newVal) {
                        for (var name in newVal) {
                            this.$set(this.form, name, newVal[name]);
                        }
                    }
                    this.$set(this, 'picList', list);
                },
                immediate: true,
                deep: true
            }
        },
        methods: {
            initCountryList() {
                if (this.countrylist.length <= 0) {
                    this.countrylist = [];
                    getallcountries().then(res => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                let obj = {};
                                obj.value = res.result[i].cnName;
                                obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                                this.countrylist.push(obj);
                            }
                        }
                    }).catch(err => {
                        this.$message.error(err)
                    })
                }

            },
            handleSubmit() {
                let url = this.url.add
                let method = 'POST'
                if (this.row.id) {
                    url = this.url.edit
                    method = 'PUT'
                }
                this.form.demand = this.form.demandList.join(',');
                if (!this.row.id) {
                  this.form.cusId = this.$route.query.id || '';
                }

                //给图片名字 赋值
                if (this.picList && this.picList.length > 0) {
                    this.form.picUrl = this.picList[0].url;
                    this.form.picName = this.picList[0].name;
                }
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        httpAction(url, method, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
                this.$refs.form.resetFields()
            }
        },
        created() {
            getAction(this.url.getDemandList)
                .then(res => {
                    this.demandList = res.result;
                })
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
