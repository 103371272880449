<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="100px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="渠道名">
              <f-select
                v-model="queryForm.channelId"
                :dict="'discount_list'"
                :is-need="queryForm.channelId"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="费用名">
              <f-select
                v-model="queryForm.feeId"
                :dict="'bd_fee_type'"
                :is-need="queryForm.feeId"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleAdd({})"
          v-if="buttonShowList.add"
          >新增</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="67vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-if="buttonShowList.edit"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="handleDel(scope.row)"
              v-if="buttonShowList.del"
              >删除</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'perferFee'">
            {{ getPerferFee(scope.row) }}
          </template>
        </template>
      </f-table>
    </el-card>

    <el-dialog
      :title="form.id ? '编辑优惠' : '新增优惠'"
      v-if="dialogFormVisible"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="国家"
                prop="country"
                :label-width="formLabelWidth"
              >
                <f-select
                  :dict="'bd_country'"
                  :father-val="form.country"
                  :is-need="form.country"
                  v-model="form.country"
                  autocomplete="off"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="feeIds"
                :label-width="formLabelWidth"
                class="flex_formItem"
              >
                <el-checkbox-group v-model="checkList">
                  <!-- <span class="is_require_form"></span>&nbsp; -->
                  <el-checkbox
                    label="115"
                    :disabled="form.id && form.feeId !== '115'"
                    >海运费</el-checkbox
                  >
                  <el-checkbox
                    label="杂费"
                    :disabled="form.id && form.feeId == '115'"
                    >杂费</el-checkbox
                  >
                </el-checkbox-group>
                <f-select
                  :dict="'bd_fee_unlessHYF'"
                  :father-val="form.feeIds"
                  :is-need="form.feeIds"
                  v-model="form.feeIds"
                  autocomplete="off"
                  :multiple="form.id ? false : true"
                  style="width: 50%"
                  :disabled="form.id && form.feeId === '115'"
                  @change="changeFeeIds"
                ></f-select>
                <template slot="label">
                  <span class="is_require_form"></span>
                </template>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item
                label="渠道"
                prop="channelId"
                :label-width="formLabelWidth"
                ><f-select-n
                  :dict="'discount_list'"
                  :father-val="form.channelId"
                  :is-need="form.channelId"
                  :multiple="!form.id"
                  v-model="form.channelId"
                  autocomplete="off"
                ></f-select-n>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="目的仓库"
                prop="zipFbaCombined"
                :label-width="formLabelWidth"
                ><f-select
                  :dict="'zip_fba_combined'"
                  :father-val="form.zipFbaCombined"
                  :is-need="form.zipFbaCombined"
                  v-model="form.zipFbaCombined"
                  autocomplete="off"
                ></f-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-col :span="12">
            <el-form-item
              label="交货仓库"
              prop="deliveryPoint"
              :label-width="formLabelWidth"
              ><f-select
                :dict="'delivery_point'"
                :father-val="form.deliveryPoint"
                :is-need="form.deliveryPoint"
                v-model="form.deliveryPoint"
                autocomplete="off"
              ></f-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item
              label="费用"
              prop="feeId"
              :label-width="formLabelWidth"
            >
              <f-select
                :dict="'bd_fee_type'"
                :father-val="form.feeId"
                :is-need="form.feeId"
                v-model="form.feeId"
                autocomplete="off"
              ></f-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item
              class="flex_form_item"
              label="客户费用单位"
              :label-width="formLabelWidth"
              prop="unit"
            >
              <f-select
                :dataList="unitList"
                v-model="form.unit"
                :isNeed="form.unit"
                @change="changeUnit"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-show="!isShowKGInput">
            <el-form-item
              label="优惠值"
              prop="perferFee"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="form.perferFee"
                autocomplete="off"
                type="number"
                :placeholder="
                  form.unit === '运费百分比' ? '例如优惠30%，这里填写30' : ''
                "
              ></el-input>
              <template slot="label">
                优惠值
                <el-tooltip placement="bottom" effect="light">
                  <div slot="content">
                    优惠请输入负数，涨价请输入正数<br />按百分比请输入数值，无需附带%（例如优惠-30%，填写-30即可；涨价30%，填写30）
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="有效开始时间"
              prop="stime"
              :label-width="formLabelWidth"
            >
              <el-date-picker
                v-model="form.stime"
                type="datetime"
                size="small"
                placeholder="开始时间"
                default-time="00:00:00"
                :picker-options="editStartOptions"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="有效结束时间"
              prop="etime"
              :label-width="formLabelWidth"
            >
              <el-date-picker
                v-model="form.etime"
                type="datetime"
                size="small"
                placeholder="结束时间"
                default-time="23:59:59"
                :picker-options="editStopOptions"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-show="isShowKGInput">
            <el-form-item
              label="小于1000KG"
              prop="minKgDiscount"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="form.minKgDiscount"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-show="isShowKGInput">
            <el-form-item
              label="大于1000KG"
              prop="maxKgDiscount"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="form.maxKgDiscount"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="描述"
              prop="remark"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="form.remark"
                autocomplete="off"
                maxlength="500"
                show-word-limit
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12"></el-col>
          <el-col :span="12"></el-col>
          <el-col :span="12"></el-col> -->
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="handleClose">取 消 </el-button>
        <el-button size="medium" type="primary" @click="savesub"
          >确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { deleteAction, httpAction } from "@/api/manage";
import { validateNum } from "../../../utils/validator";
import FSelectN from "../../../components/common/FSelect/FSelectN.vue";

export default {
  name: "DisCountModule",
  mixins: [QueryMixins],
  components: { FSelectN },
  data() {
    return {
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.form.etime) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.form.etime);
          }
        },
      },
      editStopOptions: {
        disabledDate: (time) => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
            time.getTime() < new Date(this.form.stime) ||
            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
      perferTypeList: ["数值", "百分比"],
      dialogFormVisible: false,
      formLabelWidth: "120px",
      btnDisable: false,
      form: {},
      rules: {
        channelId: [
          { required: true, message: "请选择渠道", trigger: "change" },
        ],
        feeId: [{ required: true, message: "请选择费用名", trigger: "change" }],
        perferType: [
          { required: true, message: "请选择减免类型", trigger: "change" },
        ],
        perferFee: [
          { required: true, message: "请填写优惠值", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              validateNum(rule, value, callback, false);
            },
            trigger: "blur",
          },
        ],
        country: [{ required: true, message: "请选择国家", trigger: "change" }],
        unit: [
          { required: true, message: "请选择客户费用单位", trigger: "change" },
        ],
        stime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        etime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
      },
      row: {},
      visible: false,
      columns: [
        {
          type: "index",
          label: "#",
          width: 50,
          sortable: "custom",
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "channelName",
          label: "渠道名称",
        },
        {
          prop: "feename",
          label: "费用名称",
        },
        {
          prop: "feenameUs",
          label: "费用英文名",
        },
        {
          prop: "feeCode",
          label: "费用代码",
        },
        {
          prop: "perferFee",
          label: "优惠值",
        },
        {
          prop: "unit",
          label: "客户费用单位",
        },
        {
          prop: "stime",
          label: "有效开始时间",
          sortable: "custom",
        },
        {
          prop: "etime",
          label: "有效结束时间",
          sortable: "custom",
        },
        {
          prop: "remark",
          label: "描述",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",
        },
      ],
      url: {
        list: "/customer/discount/pageList",
        add: "/customer/discount/add",
        edit: "/customer/discount/edit",
        delete: "/customer/discount/delete",
      },
      unitList: [
        { label: "/bl", value: "/bl" },
        { label: "/ctn", value: "/ctn" },
        { label: "/kg", value: "/kg" },
        { label: "/cbm", value: "/cbm" },
        { label: "票", value: "票" },
        { label: "/cbm*天", value: "/cbm*天" },
        { label: "运费百分比", value: "运费百分比" },
      ], //单位列表
      checkList: [],
      isShowKGInput: false,
    };
  },
  methods: {
    handleReset() {
      this.queryForm = {};
      this.queryForm.cusId = this.$route.query.id;
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    handleDel({ id, feename }) {
      this.$confirm("确定删除" + feename + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.delete, { id }).then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.handleSearch();
        });
      });
    },
    handleEdit(row) {
      this.btnDisable = false;
      this.form = JSON.parse(JSON.stringify(row));
      if (this.form.feeId !== "115") {
        this.checkList = ["杂费"];
        this.form.feeIds = this.form.feeId;
      } else {
        this.checkList = [this.form.feeId];
      }
      if (this.form.unit === "/kg") {
        this.isShowKGInput = true;
      } else {
        this.isShowKGInput = false;
      }
      this.dialogFormVisible = true;
    },
    handleAdd() {
      this.form = {};
      this.form.cusId = this.$route.query.id;
      this.dialogFormVisible = true;
      this.rules.perferFee[0].required = true;
      this.btnDisable = false;
    },
    savesub() {
      // this.btnDisable = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let url = this.url.add;
          let method = "post";
          if (this.form.id) {
            url = this.url.edit;
            method = "put";
          }
          const params = JSON.parse(JSON.stringify(this.form));
          if (
            this.checkList.length < 1 ||
            (this.checkList.includes("杂费") && !this.form.feeIds.length) ||
            (this.checkList.length == 2 && !this.form.feeIds.length)
          ) {
            this.$message.error("请选择费用");
            return;
          }
          if (!this.form.id && this.checkList.includes("115")) {
            params.feeIds.push("115");
          }
          if (this.form.id) {
            params.feeIds = [params.feeIds];
          }
          console.log(this.isShowKGInput, "this.isShowKGInput");
          // // 如果选择的不是kg  把字段清除
          if (!this.isShowKGInput) {
            params.maxKgDiscount = "";
            params.minKgDiscount = "";
          } else {
            params.perferFee = "";
          }
          httpAction(url, method, params).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.dialogFormVisible = false;
              this.handleQuery();
            }
            // this.btnDisable = false;
          });
        } else {
          // this.btnDisable = false;
        }
      });
    },
    handleClose() {
      this.checkList = [];
      this.isShowKGInput = false;
      this.dialogFormVisible = false;
    },
    changeFeeIds() {
      if (this.form.feeIds.length > 0 && !this.checkList.includes("杂费")) {
        this.checkList.push("杂费");
      } else if (
        this.form.feeIds.length == 0 &&
        this.checkList.includes("杂费")
      ) {
        let index = this.checkList.findIndex((item) => item === "杂费");
        if (index !== -1) {
          this.checkList.splice(index, 1);
        }
      }
    },
    changeUnit(e) {
      if (e === "/kg") {
        this.rules.perferFee[0].required = false;
        this.isShowKGInput = true;
      } else {
        this.rules.perferFee[0].required = true;
        this.isShowKGInput = false;
      }
    },
    getPerferFee(row) {
      if (row.unit === "/kg" && row.maxKgDiscount && row.minKgDiscount) {
        return row.maxKgDiscount + "," + row.minKgDiscount;
      } else if (row.unit === "/kg" && row.maxKgDiscount) {
        return row.maxKgDiscount;
      } else if (row.unit === "/kg" && row.minKgDiscount) {
        return row.minKgDiscount;
      } else {
        return row.perferFee;
      }
    },
  },
  created() {
    this.form.cusId = this.$route.query.id;
    this.queryForm.cusId = this.$route.query.id;
  },
  computed : {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('customer/modules/DisCountModule', '0', '新增'),
        'edit':this.$btnIsShow('customer/modules/DisCountModule', '1', '编辑'),
        'del':this.$btnIsShow('customer/modules/DisCountModule', '1', '删除'),

      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .flex_formItem {
    .el-form-item__content {
      flex-wrap: nowrap;
      display: flex;
    }
  }
  .el-checkbox-group {
    display: flex;
    flex: 1;
    .el-checkbox {
      margin-right: 10px;
    }
  }
}
.is_require_form {
  margin-right: 0;
}
</style>
