<template>
  <div>
    <div class="title_tab_text">提货信息</div>
    <!-- <el-card class="box-card last_card"> -->
    <div class="f-p-b-15">
      <!--        <el-button type="primary" size="medium" @click="handleAdd2" :disabled="isShow"-->
      <!--            >添加地址</el-button>-->
      <el-button type="primary" size="medium" @click="handleAdd" :disabled="isShow"
      >添加一行
      </el-button
      >
      <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="saveBatch"
          :disabled="isShow"
      >保存
      </el-button
      >
      <el-button
          type="primary"
          size="medium"
          @click="sendTihuo"
          :disabled="isShow || selectedRowKeys.length === 0"
      >发送提货申请
      </el-button>
      <el-button
          type="primary"
          size="medium"
          @click="cancelTakeIds"
          :disabled="isShow || selectedRowKeys.length === 0">
        批量取消</el-button>
    </div>
    <el-form :model="form" :rules="rules" ref="tabref">
      <f-table
          v-loading="loading"
          ref="GTable"
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="(tableData = form.tableData)"
          :cell-class-name="checkboxClassName"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleDel(scope)" :disabled="isShow">删除</el-button>
          </template>
          <template v-else-if="scope.column.property === 'contactAdd'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.contactAdd'"
                :rules="rules.contactAdd"
            >
              <el-input
                  maxlength="250"
                  type="textarea"
                  show-word-limit
                  :disabled="Number(scope.row.thStatus) >0"
                  v-model="scope.row.contactAdd"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'contact'">
            <el-form-item
                    class="el_form_item"
                    :prop="'tableData.' + scope.$index + '.contact'"
                    :rules="rules.contact"
            >
              <el-input
                      maxlength="50"
                      show-word-limit
                      :disabled="Number(scope.row.thStatus) >0"
                      v-model="scope.row.contact"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'addressDetail'">
                <span>单号：{{ $route.query.orderNo }}<br>
                <span>件：{{ scope.row.ctn ? scope.row.ctn : "" }} 重：{{
                    scope.row.kg ? scope.row.kg : ""
                  }} </span> 体：{{ scope.row.cmb ? scope.row.cmb : "" }} <br>
                <span>地址：{{ scope.row.contactAdd ? scope.row.contactAdd : "" }}  </span><br>
                <span>供应商：{{ scope.row.thSupplier ? scope.row.thSupplier : "" }}  </span><br>
                  <span>联系人：{{
                      scope.row.contact ? scope.row.contact : ""
                    }} 电话：{{ scope.row.contactTel ? scope.row.contactTel : "" }} </span><br>
                  <span>提货时间：{{ scope.row.getsTime ? scope.row.getsTime : "" }}  </span><br>
                  <span>备注：<span v-if="scope.row.isBanyun==='1'">搬运</span><span
                      v-if="scope.row.isBanyun==='0'">不需搬运</span>,<span v-if="scope.row.isWeiban==='1'">尾板车</span>
                  <span v-if="scope.row.isWeiban==='0'">不需尾板车</span> </span><br>
                </span>
          </template>
          <template v-else-if="scope.column.property === 'thSupplier'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.contactId'"
                :rules="rules.contactId"
            >
              <f-select v-model="scope.row.contactId" :is-need="scope.row.contactId" :data-list="suppliers"
                        :disabled="Number(scope.row.thStatus) >0"
                        @change="updateSup(scope.row.contactId, scope.$index)"></f-select>
              <!--                    <el-select-->
              <!--                        :disabled="Number(scope.row.thStatus) >0"-->
              <!--                        @click.native="initSupplier"-->
              <!--                        :loading="suploading"-->
              <!--                        clearable-->
              <!--                        filterable-->
              <!--                        placeholder="请选择"-->
              <!--                        @change="updateSup(scope.row.thSupplier, scope.$index)"-->
              <!--                        v-model="scope.row.contactId"-->
              <!--                    >-->
              <!--                        <el-option value="add" label="新增"></el-option>-->
              <!--                        <el-option-->
              <!--                            :label="item.name"-->
              <!--                            :value="item.value"-->
              <!--                            v-for="(item, index) in supplierNamelist"-->
              <!--                            :key="index"-->
              <!--                        ></el-option>-->
              <!--                    </el-select>-->
            </el-form-item>
          </template>

          <template v-else-if="scope.column.property === 'contactTel'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.contactTel'"
                :rules="rules.contactTel"
            >
              <el-input
                  @change="updateSup(scope.row.contact, scope.$index)"
                  :disabled="Number(scope.row.thStatus) >0"
                  maxlength="50"
                  show-word-limit
                  v-model="scope.row.contactTel"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'getsTime'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.getsTime'"
                :rules="rules.getsTime"
            >
              <el-date-picker
                  @change="updateSup(scope.row.contact, scope.$index)"
                  :disabled="Number(scope.row.thStatus) >0"
                  class="c-full-width"
                  v-model="scope.row.getsTime"
                  type="datetime"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择时间"
              >
              </el-date-picker>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'geteTime'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.geteTime'"
                :rules="rules.geteTime"
            >
              <el-date-picker
                  :disabled="Number(scope.row.thStatus) >0"
                  class="c-full-width"
                  v-model="scope.row.geteTime"
                  type="datetime"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择时间"
              >
              </el-date-picker>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'oceanPkgs'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.number2'"
                :rules="rules.number2"
            >
              <el-input
                  :disabled="Number(scope.row.thStatus) >0"
                  v-model="scope.row.oceanPkgs"
                  onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  type="number"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'pcs'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.pcs'"
                :rules="rules.number"
            >
              <el-input
                  :disabled="Number(scope.row.thStatus) >0"
                  v-model="scope.row.pcs"
                  onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  type="number"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'ctn'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.ctn'"
                :rules="rules.ctn"
            >
              <el-input
                  @change="updateSup(scope.row.contact, scope.$index)"
                  :disabled="Number(scope.row.thStatus) >0"
                  v-model="scope.row.ctn"
                  onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  type="number"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'kg'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.kg'"
                :rules="rules.trailerKg"
            >
              <el-input v-model="scope.row.kg" type="number" :disabled="Number(scope.row.thStatus) >0"
                        @change="updateSup(scope.row.contact, scope.$index)"></el-input>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'cmb'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.cmb'"
                :rules="rules.trailerKg"
            >
              <el-input v-model="scope.row.cmb" type="number" :disabled="Number(scope.row.thStatus) >0"
                        @change="updateSup(scope.row.contact, scope.$index)"></el-input>
            </el-form-item>
          </template>

          <template v-else-if="scope.column.property === 'remark'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.remark'"
            >
              <el-input v-model="scope.row.remark" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'isWeiban'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.isWeiban'"
            >
              <f-select v-model="scope.row.isWeiban" :is-need="scope.row.isWeiban" :dict="'unit_whether'"
                        :disabled="Number(scope.row.thStatus) >0"
              ></f-select>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'isBanyun'">
            <el-form-item
                class="el_form_item"
                :prop="'tableData.' + scope.$index + '.isBanyun'"
            >
              <f-select v-model="scope.row.isBanyun" :is-need="scope.row.isBanyun" :dict="'unit_whether'"
                        :disabled="Number(scope.row.thStatus) >0"></f-select>
            </el-form-item>
          </template>
        </template>
      </f-table>
    </el-form>
    <!-- </el-card> -->
    <el-dialog title="添加联系人"
               v-if="supplierVisible"
               :visible.sync="supplierVisible" width="30%" center :close-on-click-modal="false">

      <el-form :model="supplier" :rules="supplierRules" ref="supplier">
        <el-form-item label="供应商名" prop="name" :label-width="formLabelWidth">
          <el-input v-model="supplier.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="公司名" prop="company" :label-width="formLabelWidth">
          <el-input v-model="supplier.company" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact" :label-width="formLabelWidth">
          <el-input v-model="supplier.contact" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="tel" :label-width="formLabelWidth">
          <el-input v-model="supplier.tel" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="国家" prop="country" :label-width="formLabelWidth">
          <el-select
              :loading="coloading"
              @click.native="initCountryList"
              v-model="supplier.country" placeholder="请选择" filterable clearable @change="initStateList">
            <el-option v-for="(item,index) in countrylist" :key="index" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="州/省" prop="state" :label-width="formLabelWidth">
          <el-select
              :loading="stateloading"
              v-model="supplier.state" placeholder="请选择" filterable clearable @change="initCityList">
            <el-option v-for="item in statelist" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市" prop="city" :label-width="formLabelWidth">
          <el-select
              :loading="cityloading"
              v-model="supplier.city" placeholder="请选择" filterable clearable>
            <el-option v-for="item in citylist" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址" prop="address" :label-width="formLabelWidth">
          <el-input v-model="supplier.address" autocomplete="off" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
          <el-input v-model="supplier.remark" autocomplete="off" type="textarea" show-word-limit
                    maxlength="255"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">

        <el-button type="success" plain @click="saveSupplier" :disabled="supplierDisable">确 定</el-button>
        <el-button type="success" plain @click="supplierVisible = false">取 消</el-button>
      </div>

    </el-dialog>
    <el-dialog
        :title="form.id ? '编辑提货信息' : '新增提货信息'"
        v-if="dialogFormVisible"
        :visible.sync="dialogFormVisible"

        width="30%"
        center
        :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
            class="el_form_item"
            label="联系人"
            prop="contact"
            :label-width="formLabelWidth"
        >
          <el-select
              @click.native="initSupplier"
              :loading="suploading"
              clearable
              filterable
              placeholder="请选择"
              v-model="form.contactId"
          >
            <el-option
                :label="item.label"
                :value="item.value"
                v-for="(item, index) in supplierlist"
                :key="index"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
            class="el_form_item"
            label="电话"
            prop="contactTel"
            :label-width="formLabelWidth"
        >
          <el-input v-model="form.contactTel" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item
            class="el_form_item"
            prop="getsTime"
            label="提货时间"
            :label-width="formLabelWidth"
        >
          <el-date-picker
              class="c-full-width"
              v-model="form.getsTime"
              type="datetime"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="editStartOptions"
              placeholder="请选择时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
            class="el_form_item"
            label="详细地址"
            prop="contactAdd"
            :label-width="formLabelWidth"
        >
          <el-input
              v-model="form.contactAdd"
              autocomplete="off"
              type="textarea"
              maxlength="250"
              show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item
            class="el_form_item"
            label="件数"
            prop="ctn"
            :rules="rules.ctn"
            :label-width="formLabelWidth"
        >
          <el-input
              v-model="form.ctn"
              onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
              type="number"
          ></el-input>
        </el-form-item>
        <el-form-item
            class="el_form_item"
            label="重量(KG)"
            prop="kg"
            :rules="rules.trailerKg"
            :label-width="formLabelWidth"
        >
          <el-input
              v-model="form.kg"
              type="number"
              placeholder="请输入重量"
          ></el-input>
        </el-form-item>
        <el-form-item
            class="el_form_item"
            label="体积"
            prop="cmb"
            :label-width="formLabelWidth"
            :rules="rules.trailerKg"
        >
          <el-input
              v-model="form.cmb"
              type="number"
              placeholder="请输入体积"
          ></el-input>
        </el-form-item>
        <el-form-item
            class="el_form_item"
            label="托盘数"
            prop="oceanPkgs"
            :rules="rules.number2"
            :label-width="formLabelWidth"
        >
          <el-input
              v-model="form.oceanPkgs"
              onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
              type="number"
          ></el-input>
        </el-form-item>
        <el-form-item
            class="el_form_item"
            label="pcs"
            prop="pcs"
            :label-width="formLabelWidth"
            :rules="rules.number2"
        >
          <el-input
              v-model="form.pcs"
              onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
              type="number"
          ></el-input>
        </el-form-item>
        <el-form-item
            class="el_form_item"
            label="备注"
            prop="remark"
            :label-width="formLabelWidth"
        >
          <el-input
              v-model="form.remark"
              autocomplete="off"
              type="textarea"
              maxlength="250"
              show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveSupplier" :disabled="btnDisable"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
    <add-take-delivery-info-dialog :visible="takeVisible" :selected-trailer-id-list="selectedIds"
                                   @ok="closeTake"></add-take-delivery-info-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {deleteAction, putAction, postAction, getAction} from "@/api/manage";
import {emailPattern, mobilePattern} from "@/utils/pattern";
import addTakeDeliveryInfoDialog from "../../warehouse2.0/modules/addTakeDeliveryInfoDialog";
import {
  validateCmb,
  validateCtn,
  validateCtn2,
} from "../../../utils/validator";
import Tempcclate from "../../isystem/Dict";
import ElementForm from "../../isystem/activity/refactor/form/ElementForm";
import {getallcountries, getcityList, getstateList, httpAction} from "../../../api/manage";

export default {
  name: "TihuoModule",
  mixins: [QueryMixins],
  components: {
    ElementForm,
    Tempcclate,
    addTakeDeliveryInfoDialog
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => {
      }
    },
    shipRes: {
      type: Object
    },
  },
  data() {
    return {
      suppliers: [],

      takeVisible: false,
      selectedIds: [],
      disableMixinMounted: true,
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.form.geteTime) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.form.geteTime);
          }
        },
      },
      editStopOptions: {
        disabledDate: (time) => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
              time.getTime() < new Date(this.form.getsTime) ||
              time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
      dialogFormVisible: false,
      form: {ctn: "", kg: "", cmb: "", tableData: []},
      formLabelWidth: "120px",
      btnDisable: false,
      row: {},
      visible: false,
      //表单校验
      rules: {
        thSupplier: [
          {required: true, message: "请选择提货服务商", trigger: "blur"},
        ],
        contactId: [
          {required: true, message: "请选择提货服务商", trigger: "blur"},
        ],
        contact: [
          {required: true, message: "请选择联系人", trigger: "change"},
        ],
        getsTime: [
          {required: true, message: "请选择提货开始时间", trigger: "change"},
        ],
        geteTime: [
          {required: true, message: "请选择提货结束时间", trigger: "change"},
        ],
        contactAdd: [
          {required: true, message: "请输入地址", trigger: "change"},
        ],
        contactTel: [
          {required: true, message: "请输入手机号", trigger: "blur"},
          // {
          //   pattern: mobilePattern,
          //   message: "请输入正确的手机号",
          //   trigger: "blur",
          // },
        ],
        number: [{validator: validateCtn, trigger: "change"}],
        ctn: [
            {required:true,message:'不能为空',trigger:'change'},
            {validator: validateCtn, trigger: "change"}],
        number2: [
          // {required:true,message:'不能为空',trigger:'change'},
          {validator: validateCtn2, trigger: "change"}],
        trailerKg: [{required: true, message: '不能为空', trigger: 'change'}, {validator: validateCmb, trigger: "change"}],
      },
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 50,
        },
        {
          prop: "thSupplier",
          label: "供应商",
          minWidth: 160,
          sortable: "custom",
          headerType: 'require'
        },
        {
          prop: "addressDetail",
          label: "提货信息",
          minWidth: 200,

        },
        {
          prop: "contactAdd",
          label: "详细地址",
          minWidth: 200,
          sortable: "custom",
          headerType: 'require'
        },


        {
          prop: "contact",
          label: "联系人",
          minWidth: 160,
          sortable: "custom",
          headerType: 'require'
        },
        {
          prop: "contactTel",
          label: "电话",
          minWidth: 140,
          sortable: "custom",
          headerType: 'require'
        },
        {
          prop: "getsTime",
          label: "提货时间",
          minWidth: 190,
          sortable: "custom",
          headerType: 'require'
        },
        {
          prop: "oceanPkgs",
          label: "打托数",
          minWidth: 60,
          sortable: "custom",
        },
        {
          prop: "pcs",
          label: "PCS",
          minWidth: 60,
          sortable: "custom",
        },
        {
          prop: "ctn",
          label: "件数",
          minWidth: 75,
          sortable: "custom",
          headerType: 'require'
        },
        {
          prop: "kg",
          label: "重量",
          minWidth: 75,
          sortable: "custom",
          headerType: 'require'
        },
        {
          prop: "cmb",
          label: "体积",
          minWidth: 75,
          sortable: "custom",
          headerType: 'require'
        },
        {
          prop: "remark",
          label: "备注",
          sortable: "custom",
        },
        {
          prop: "isBanyun",
          label: "是否需要搬运",
          sortable: "custom",
        },
        {
          prop: "isWeiban",
          label: "是否需要尾板车",
          sortable: "custom",
        },
      ],
      url: {
        list: "/interface/ttOrder/getTrailerList",
        delByid: "/interface/ttOrder/deleteTrailerById/",
        add: "/interface/ttOrder/addOneTrailer",
        saveBatch: "/interface/ttOrder/updateTrailerBatch",
        // sendTihuo: "/order/os/trailer/sendTihuo",
        //新的发起提货接口
        sendTihuo: "/warehouse/takeDelivery/sendTake",
        getSupplierList: "/customer/supplier/getList",
        addSupplier: '/customer/supplier/add',
        //取消接口
        cancelTake: '/warehouse/takeDelivery/updateCancelTakeByIds',

      },
      obj: {},
      tihuoList: [],
      supplierlist: [],
      supplierNamelist: [],
      suploading: false,
      suppliermap: {},
      cusId: "",
      supplier: {},
      supplierVisible: false,
      supplierDisable: true,
      supplierRules: {
        country: [
          {required: true, message: '请选择国家', trigger: 'change'}
        ],
        // state: [
        //     {required: true, message: '请选择州', trigger: 'change'}
        // ],
        // city: [
        //     {required: true, message: '请选择市', trigger: 'change'}
        // ],
        name: [
          {required: true, message: '请输入供应商名', trigger: 'blur'},
          {max: 50, message: '长度不能超过50', trigger: 'blur'}
        ],
        company: [
          {required: true, message: '请输入公司名', trigger: 'blur'},
          {max: 50, message: '长度不能超过50', trigger: 'blur'}
        ],
        contact: [
          {required: true, message: '请输入联系人', trigger: 'blur'},
          {max: 50, message: '长度不能超过50', trigger: 'blur'}
        ],

        tel: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          // {pattern: mobilePattern, message: '请输入正确的手机号', trigger: 'blur'}
        ],
        getStime: [
          {required: true, message: '请选择开始时间', trigger: 'blur'}
        ],
        getEtime: [
          {required: true, message: '请选择结束时间', trigger: 'blur'}
        ],

        address: [
          {required: true, message: '请输入地址', trigger: 'blur'},
          {max: 255, message: '地址长度不能超过255', trigger: 'blur'}
        ]
      },
      coloading: false,
      cityloading: false,
      stateloading: false,
      // 国家基础数据
      countrylist: [],
      // 省基础数据
      statelist: [],
      // 市基础数据
      citylist: [],

    };
  },
  created() {
    this.queryForm.orderNo = this.$route.query.orderNo;
    // this.queryForm.thStatus = "0";
    this.initDocpage();
  },
  methods: {
    closeTake() {
      this.takeVisible = false;
      this.handleQuery();
    },
    openTake() {
      let tableData = this.selectionRows;
      if (!tableData || tableData.length === 0) {
        this.$message.warning('没有提货数据，无法发起提货');
        return false;
      }
      let list = [];
      let ids = [];
      tableData.forEach(s => {
        if (s.thStatus !== '0') {
          list.push(s.id);
        }
        ids.push(s.id);
      });
      if (list && list.length > 0) {
        this.$message.warning('存在已经发起提货的数据，请勿重复发起');
        return false;
      }
      this.selectedIds = ids;
      this.takeVisible = true;
    },
    cancelTakeIds() {
      let params = {ids: this.selectedRowKeys};
      this.$confirm("批量取消提货信息，请选择取消订单还是取消提货?", "提示", {
        confirmButtonText: "取消用车编号的此单号",
        cancelButtonText: "取消用车编号的全部订单",
        distinguishCancelAndClose: true,
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
        params.type = '1';
        postAction(this.url.cancelTake, params)
            .then((res) => {
              this.$message.success(res.message);
              this.handleQuery();
            })
      }).catch((action => {
        if (action === 'cancel') {
          params.type = '2';
          postAction(this.url.cancelTake, params)
              .then((res) => {
                this.$message.success(res.message);
                this.handleQuery();
              })
        }
      }))
    },
    // 初始化国家基础数据
    initCountryList() {
      this.coloading = true

      this.countrylist = []
      getallcountries().then(res => {
        if (res.success) {
          for (var i = 0; i < res.result.length; i++) {
            let obj = {}
            obj.value = res.result[i].cnName
            obj.label = res.result[i].twoCode + '-' + res.result[i].cnName
            this.countrylist.push(obj)
          }
          this.coloading = false
        }
      })

      this.coloading = false

    },
    // 初始化省数据
    initStateList(val) {
      this.stateloading = true
      this.statelist = []
      this.citylist = []
      if (val) {
        getstateList({cnName: val})
            .then((res) => {
              if (res.success) {
                for (var i = 0; i < res.result.records.length; i++) {
                  let obj = {}
                  obj.value = res.result.records[i].cname
                  obj.label = res.result.records[i].code + '-' + res.result.records[i].cname
                  this.statelist.push(obj)
                }
                this.stateloading = false
              }
            })
      } else {
        this.stateloading = false
      }
    },
    // 初始化城市数据
    initCityList(val) {
      this.cityloading = true
      this.citylist = []
      if (val) {
        getcityList({cname: val})
            .then((res) => {
              if (res.success) {
                for (var i = 0; i < res.result.records.length; i++) {
                  let obj = {}
                  obj.value = res.result.records[i].cname
                  obj.label = res.result.records[i].code + '-' + res.result.records[i].cname
                  this.citylist.push(obj)
                }
                this.cityloading = false
              }
            })
      } else {
        this.cityloading = false
      }
    },
    checkboxClassName({row, column, rowIndex, columnIndex}) {
      if (row.thStatus !== "0") {
        return "tb_cell";
      }
    },
    updateSup(name, index) {
      if ("add" === name) {
        //如果是新增，就新增
        this.supplier = {}
        this.supplierVisible = true
        this.supplierDisable = false
        this.$set(this.form.tableData[index], 'contact', '');
      } else {
        if (name) {
          if (this.suppliermap[name]) {
            let obj = this.suppliermap[name];
            this.form.tableData[index].contactAdd = obj.address;
            this.form.tableData[index].contact = obj.contact;
            this.form.tableData[index].contactTel = obj.tel;
            this.form.tableData[index].thSupplier = obj.name;
          }
        }
      }
    },
    formatAddress(obj) {
      let s = "";
      if (obj) {
        s += "单号：" + this.$route.query.orderNo + "\n";
        s += "件：" + obj.ctn + " 重：" + obj.kg + " 体：" + obj.cmb + "\n";
        s += "地址：" + obj.contactAdd + "\n";
        s += "供应商：" + obj.thSupplier + "\n";
        s += "联系人： " + obj.contact + " 电话： " + obj.contactTel + "\n";
        s += "提货时间：" + obj.getsTime + "\n";
        s += "备注: ";
        if (obj.isWeiban === '1') {
          s += "尾板车，";
        }
        if (obj.isBanyun === '1') {
          s += "搬运";
        }
      }
      return s;
    },
    initDocpage() {
      //页面一加载完成，根据订单号获取数据 渲染在头部
      let orderNo = this.$route.query.orderNo;
      console.log("订单号88888", orderNo);
      if (orderNo) {
        for (var name in this.order) {
          this.$set(this.form, name, this.order[name]);
        }
        this.cusId = this.form.cusId;
        console.log("客服页id数据", this.form);
        console.log("客服页cusid数据", this.cusId);
        //给唛头下载的url带参数
      }
    },
    initSupplier() {
      this.supplierlist = [];
      this.supplierNamelist = [];
      this.suploading = true;
      //获取发货人列表
      console.log(this.order, 'order')
      getAction(this.url.getSupplierList, {cusId: this.order.cusId})
          .then((res) => {
            if (res.success) {
              let list = res.result;
              for (var i = 0; i < list.length; i++) {
                let value = list[i].id;
                let label = list[i].contact + ' ' + list[i].name;
                let name = list[i].name;
                this.supplierlist.push({value, label});
                this.supplierNamelist.push({value, name});
              }
              this.suppliermap = this.listToMap(list, "id");
              this.suploading = false;
            } else {
              this.$message.warning("获取被保人列表失败");
              this.suploading = false;
            }
          })
          .catch((err) => {
            this.suploading = false;
          });
    },
    handleSearch() {
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    handleQuery() {
      this.loading = true;
      let form = {...this.queryForm, ...this.filterForm};
      for (let item in form) {
        if (typeof form[item] === "string") {
          form[item] = form[item].trim();
          if (form[item] === "") {
            form[item] = null;
          }
        }
      }
      const {prop, order} = this.isorter;
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf("asc") !== -1 ? "asc" : "desc",
      };
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === "startTime") {
          this.$set(params, "column", "startTime");
        } else {
          this.$set(params, "column", "createTime");
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage;
        params.pageSize = this.ipagination.pageSize;
      }
      console.log("查询对象", params);

      getAction(this.url.list, params)
          .then((res) => {
            if (res.success && res.result) {
              this.tableData = res.result.trailerList;
              this.form.tableData = this.tableData;
              this.suppliers = res.result.suppliers;
              this.suppliermap = this.listToMap(this.suppliers,'id');
              this.ipagination && (this.ipagination.total = this.tableData.length);
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    sendTihuo() {
      //校验
      let ids = this.form.tableData.map(t => {
        return t.id;
      }).join(',');
      //   if (!ids || ids.length <= 0){
      //       this.$message.warning('没有提货数据可以申请呢亲！');
      //       return false;
      //   }
      putAction(this.url.sendTihuo + "?ids=" + ids)
          .then((res) => {
            if (res.success) {
              this.$alert(res.message, '提货结果', {
                dangerouslyUseHTMLString: true
              });
              this.handleQuery();
            }
          })
    },

    saveBatch() {
      let noStatusList = [];
      this.form.tableData.forEach(ff => {
        if (ff.thStatus === '0') {
          noStatusList.push(ff);
        }
      });
      if (!noStatusList || noStatusList.length <= 0) {
        this.$message.warning('没有未提货的数据需要保存呢亲！');
        return false;
      }
debugger
      this.$refs["tabref"].validate((valid) => {
        if (valid) {
          putAction(this.url.saveBatch, noStatusList)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.handleQuery();
                  this.handleClearSelection();
                }
              })
        }
      });
    },
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.tihuoList = [];

      for (let i = 0; i < selection.length; i++) {
        let {id, contactAdd, contact, contactTel, name, getsTime, geteTime} =
            selection[i];
        this.obj.id = id;
        this.obj.contactAdd = contactAdd;
        this.obj.contact = contact;
        this.obj.contactTel = contactTel;
        this.obj.name = name;
        this.obj.getsTime = getsTime;
        this.obj.geteTime = geteTime;

        arr.push(selection[i]["id"]);

      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
      console.log("选择的ids", this.selectedRowKeys.join(","));
      console.log("选择的提货信息", this.tihuoList);
    },
    handleReset() {
      this.queryForm = {};
      this.ipagination.currentPage = 1;

      this.handleQuery();
    },
    saveSupplier() {
      this.supplierDisable = true;
      let url = this.url.addSupplier;
      let method = "post";
      this.supplier.cusId = this.order.cusId;
      this.$refs['supplier'].validate((valid) => {
        if (valid) {

          httpAction(url, method, this.supplier)
              .then(res => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.supplierVisible = false;
                  //重新获取供应商集合
                  this.initSupplier();
                } else {
                  this.supplierDisable = false;
                  this.$message.warning(res.message);
                }

              }).catch(err => {

            this.supplierDisable = false;
            this.$message.error(err.message);
          })
        } else {
          this.supplierDisable = false;
        }
      })
    },

    handleDel(scope) {
      if (scope.row.id) {
        this.$confirm(`确定删除${scope.row.contact}吗`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
          closeOnClickModal: false,
        })
            .then(() => {
              putAction(this.url.delByid + scope.row.id).then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.handleSearch();
              });
            });
      } else {
        this.form.tableData.splice(scope.$index, 1);
      }
    },
    handleAdd2() {
      this.dialogFormVisible = true;
      this.form = {};
    },
    handleAdd() {
      this.form.tableData.push({
        action: "",
        contactAdd: "",
        contact: "",
        contactTel: "",
        getsTime: "",
        oceanPkgs: "",
        pcs: "",
        ctn: "",
        kg: "",
        cmb: "",
        remark: "",
        orderNo: this.$route.query.orderNo,
        name: "",
        thStatus: '0',
      });
    },
    handleEdit(row) {
      this.btnDisable = false;
      getAction(this.url.getbyid, {id: row.id})
          .then((res) => {
            if (res.success) {
              this.form = res.result;
            }
          })
      this.dialogFormVisible = true;
    },
    // saveSupplier() {
    //   //给订单号赋值
    //
    //   this.form.orderNo = this.$route.query.orderNo;
    //   this.form.thStatus = "0";
    //   this.$refs["form"].validate((valid) => {
    //     if (valid) {
    //       //新增
    //       postAction(this.url.add, this.form)
    //         .then((res) => {
    //           if (res.success) {
    //             this.$message.success(res.message);
    //             this.dialogFormVisible = false;
    //             this.handleQuery();
    //           } else {
    //             this.btnDisable = false;
    //           }
    //         })
    //         .catch((err) => {
    //           this.btnDisable = false;
    //         });
    //     } else {
    //       this.btnDisable = false;
    //     }
    //   });
    // },
  },
  watch: {
    shipRes(val) {
      if (val) {
        console.log("父亲传递过来tihuo的数据", val)
        this.tableData = val.osOrderTrailerList;
        this.form.tableData = val.osOrderTrailerList;
        this.suppliers = val.suppliers;
        this.suppliermap = this.listToMap(this.suppliers, 'id');
      } else {
        // this.handleQuery();
      }
    }
  }
};
</script>

<style lang="scss">
</style>
