<template>
    <el-dialog
        title="发送短信"
        :visible="visible"
        width="560px"
        custom-class="c-custom-dialog"
        :before-close="handleClose"
    :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" label-width="94px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="type" label="短信类型">
                        <el-input v-model="form.type"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="contact" label="接收手机号">
                        <el-input v-model="form.contact"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="description" label="短信内容">
                        <el-input v-model="form.description" type="textarea" rows="5"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
export default {
    name: 'MsgDialog',
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        },

    },
    data() {
        return {
            form: {},
            options: [],
            rules: {
                type: [
                    { required: true, message: '请选择类型', trigger: 'change' },
                ],
                contact: [
                    { required: true, message: '请输入接收手机号', trigger: 'change' },
                ],
                description: [
                    { required: true, message: '请输入短信内容', trigger: 'change' },
                ],
            },
            url: {
                add: '/sys/role/add',
                edit: '/sys/role/edit'
            }
        }
    },
    watch: {
        visible(val) {
            if(val && this.row.id) {
                const { id, roleName, roleCode, description } = this.row
                this.form = { id, roleName, roleCode, description }
            } else {
                this.form = {}
            }
        }
    },
    methods: {
        handleSubmit() {
            let url = this.url.add
            let method = 'POST'
            if(this.row.id) {
                url = this.url.edit
                method = 'PUT'
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    httpAction(url, method, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose()
                        this.$emit('ok')
                    })
                }
            })
        },
        handleClose() {
            this.$emit('update:visible', false)
            this.$refs.form.resetFields()
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep
    .el-dialog__body {
        padding: 20px 30px 20px 24px;
    }
</style>
